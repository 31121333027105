import * as React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { SmartPortal } from './SmartPortal';

import styles from "./components/basepage/PageStyle.scss";

// base components
import { BrowserRestrictionPage } from './components/browser/BrowserRestrictionPage';
import { Role } from './common/business/RoleService';
import { AppPolicy } from './common/models/AppPolicy';

// lazy components
const MyWorkplacePage = React.lazy(() => import(/* webpackChunkName: "workplace" */ './components/myworkplace/MyWorkplacePage'));
const DiscoverPage = React.lazy(() => import(/* webpackChunkName: "discover" */ './components/discover/DiscoverPage'));
const TestPage = React.lazy(() => import(/* webpackChunkName: "test" */ './components/test/TestPage'));
const DirectoryPage = React.lazy(() => import(/* webpackChunkName: "directory" */ './components/directory/DirectoryPage'));
const NavigationPage = React.lazy(() => import(/* webpackChunkName: "navigate" */ './components/navigation/NavigationPage'));
const AdminPage = React.lazy(() => import(/* webpackChunkName: "admin" */ './components/admin/AdminPage'));
const ErrorPage = React.lazy(() => import(/* webpackChunkName: "error" */ './components/basepage/ErrorPage'));

export interface ISmartPortalNavKeyProps {
    history: any;
    navKey?: string;
    children?: React.ReactNode | React.ReactNode[];
}

export interface ISmartPortalNavKeyState {
}

export class SmartPortalRoute extends React.Component<ISmartPortalNavKeyProps, ISmartPortalNavKeyState> {

    constructor(props: ISmartPortalNavKeyProps, state: ISmartPortalNavKeyState) {
        super(props);

        SmartPortal.currentHistory = this.props.history;
        if (this.props.navKey)
            SmartPortal.currentPage.setActiveNavKey(this.props.navKey);
        SmartPortal.currentPage.setBodyScrollTop(0);

        this.state = {
        };
    }

    public render(): React.ReactElement<ISmartPortalNavKeyProps> {
        return (
            <div className={styles.PortalRoute} data-navkey={this.props.navKey}>
                {this.props.children}
            </div>
        );
    }
}

export interface ISmartPortalRouterProps {
}

export interface ISmartPortalRouterState {
}

export class SmartPortalRouter extends React.Component<ISmartPortalRouterProps, ISmartPortalRouterState> {

    constructor(props: ISmartPortalRouterProps, state: ISmartPortalRouterState) {
        super(props);

        this.state = {
        };
    }

    public render(): React.ReactElement<ISmartPortalRouterProps> {
        let isIE = !!(document as any).documentMode;
        let root = "/Portal";
        if (SmartPortal.checkAppPolicy(AppPolicy.LicenseProvisioning))
            root = "/Directory";
        return (
            <Router>
                <Switch>
                    <Route exact path="/Portal/" render={({ history }) => (
                        <SmartPortalRoute key="MyWorkplace" history={history} navKey="MyWorkplace">
                            {
                                SmartPortal.checkAppPolicy(AppPolicy.FeatureMyWorkplace) ? <MyWorkplacePage /> : <Redirect to={root} />
                            }
                        </SmartPortalRoute>
                    )} />
                    <Route path="/Portal/MyWorkplace" render={({ history }) => (
                        <SmartPortalRoute key="MyWorkplace" history={history} navKey="MyWorkplace">
                            {
                                SmartPortal.checkAppPolicy(AppPolicy.FeatureMyWorkplace) ? <MyWorkplacePage /> : <Redirect to={root} />
                            }
                        </SmartPortalRoute>
                    )} />
                    <Route path="/Portal/Test" render={({ history }) => (
                        <SmartPortalRoute key="Test" history={history} navKey="Test">
                            <TestPage />
                        </SmartPortalRoute>
                    )} />
                    <Route path="/Portal/Discover" render={({ history }) => (
                        <SmartPortalRoute key="Discover" history={history} navKey="Discover">
                            {
                                SmartPortal.checkAppPolicy(AppPolicy.FeatureMyWorkplace) ?
                                    isIE ? <BrowserRestrictionPage /> :  <DiscoverPage /> : <Redirect to={root} />
                            }
                        </SmartPortalRoute>
                    )} />
                    <Route path="/Admin" render={({ history }) => (
                        <SmartPortalRoute key="Admin" history={history} navKey="Admin">
                            {
                                SmartPortal.isUserInRole(Role.Admin) && SmartPortal.checkAppPolicy(AppPolicy.FeatureAdministration) ? <AdminPage /> : <Redirect to={root} />
                            }
                        </SmartPortalRoute>
                    )} />
                    <Route path="/Directory" render={({ history }) => (
                        <SmartPortalRoute key="Directory" history={history} navKey="Directory">
                            {
                                SmartPortal.checkAppPolicy(AppPolicy.FeatureProvisioning) ?  <DirectoryPage /> : <Redirect to={root} />
                            }
                        </SmartPortalRoute>
                    )} />
                    <Route path="/Navigate" render={({ history }) => (
                        <SmartPortalRoute key="Navigate" history={history} navKey="Navigation">
                            {
                                SmartPortal.checkAppPolicy(AppPolicy.FeatureNavigation) ? <NavigationPage /> : <Redirect to={root} />
                            }
                        </SmartPortalRoute>
                    )} />
                    <Route exact path="*" render={({ history }) => (
                        <SmartPortalRoute key="Error" history={history} navKey="Error">
                            <ErrorPage code={404} />
                        </SmartPortalRoute>
                    )} />
                </Switch>
            </Router>
        );
    }
}