import * as React from 'react';
import styles from "./PanelStyle.scss";
import PanelStrings from "./loc/PanelStrings";
import { PanelActions, Action, ActionExecution } from "./PanelActions";
import { IErrorInfo } from "../../models/SessionInfo";
import { IconButton } from 'office-ui-fabric-react';

export interface IPanelHeaderProps {
    headerText?: string;
    header?: React.ReactNodeArray | React.ReactNode;

    infoTooltip?: string;
    initialExecutionState?: ActionExecution;
    actions?: Action[];
    currentActions?: Action[];
    customActions?: React.ReactNodeArray | React.ReactNode;
    onAction?: (action: Action, value?: any) => Promise<void | boolean>;
    actionTexts?: { [TAction in Action]?: string };
    search?: string;
    errors?: IErrorInfo[];

    contextualMenuClassName?: string;
}

export class PanelHeader extends React.Component<IPanelHeaderProps> {

    constructor(props: IPanelHeaderProps) {
        super(props);
    }

    public render(): React.ReactElement<{}> {
        return (
            <div className={styles.PanelHeader}>
                <div className={styles.PanelHeaderText}>{this.props.header ? this.props.header : <h1>{this.props.headerText}</h1>}</div>
                <PanelActions infoTooltip={this.props.infoTooltip}
                    currentActions={this.props.currentActions}
                    customActions={this.props.customActions}
                    initialExecutionState={this.props.initialExecutionState}
                    actions={this.props.actions}
                    actionTexts={this.props.actionTexts}
                    onAction={(action, value) => {
                        if (this.props.onAction)
                            return this.props.onAction(action, value);
                        return Promise.resolve();
                    }}
                    search={this.props.search}
                    errors={this.props.errors}
                    contextualMenuClassName={this.props.contextualMenuClassName}/>
            </div>
        );
    }
}