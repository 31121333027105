import { IFileServiceFile } from "./FileServiceFile";
import { IPalette } from 'office-ui-fabric-react';

export interface ISiteExportInfo {
    //internalId: string;
    title: string;
    description: string;
    provisionTemplate: string;
    provisionType: SiteProvisioningType | string;
    primaryOwner: string;
    secondaryOwner: string;
    status: SiteStatus | string;
    //statusMessage: string;
    created: Date | string;
    createdBy: string;
    //siteId: string;
    siteUrl: string;
    nickname: string;
    //teamId: string;
    teamUrl: string;
    classification: string;
    language: SiteLanguage | string;
    guestsAccess: SiteInfoGuestsAccess | string;
    sharingCapability: SiteSharingCapability | string;
    visibility: SiteInfoVisibility | string;
    timeZoneId: SiteTimeZone | string;
    hubSite: string;
    archivingDate: Date | string;
    deletionDate: Date | string;
}

export interface ISiteInfo {
    internalId: string;
    title: string;
    description: string;
    webUrl: string;
    provisionTemplate: IProvisionTemplate;
    primaryOwner: string;
    primaryOwnerAccount: IAccount;
    secondaryOwner: string;
    secondaryOwnerAccount: IAccount;
    status: SiteStatus;
    statusMessage: string;
    created: Date;
    createdBy: string;
    createdById: string;
    organization: string;
    organizationId: string;
    teamId?: string;
    siteId?: string;
    additionalData: ISiteAdditionalData;
    lifeCycle: ISiteLifecycle;
    approval: IApproval;
    provisionChange: ISiteInfoChangeBase;
}

export interface IApproval {
    approvalStatus: string;
    approverComments: string;
    approvalTasks: IApprovalTask[];
}

export interface IApprovalTask {
    id: string;
    approverGroupId: string;
    approver: IAccount;
    approverComment: string;
    status:  ApprovalTaskStatus;
    start: Date;
    end: Date;
    completedBy: IAccount;
}

export interface IAccount {
    id: string;
    graphId: string;
    userPrincipalName: string;
    displayName: string;
    mailAddr: string;
    isActive: boolean;
    LastIsActiveCheckDate: Date;
}

export interface IPrincipal{
    graphId: string;
    userPrincipalName: string;
    displayName: string;
    mail: string;
    principalType: PrincipalType;
}

export enum PrincipalType{
    User = 0,
    Group = 1    
}

export interface ISiteLifecycle{
    lastActivityDate: Date;
    archivingDate: Date;
    archivingDatePostponed: Date;
    deletionDate: Date;
    deletionDatePostponed: Date;
    firstArchivingReminderDate: Date;
    firstDeletionReminderDate: Date;
}

export interface ISiteAdditionalData {
    Language?: SiteLanguage;
    TimeZoneId?: SiteTimeZone;
    SharingCapability?: SiteSharingCapability;
    Classification?: string;
    HubSiteId?: string;
    HubSiteTitle?: string;
    RegisterHubSite?: boolean;
    Nickname?: string;
    Visibility?: SiteInfoVisibility;
    GuestsAccess?: SiteInfoGuestsAccess;
    TeamId?: string;
    TeamUrl?: string;
    SiteId?: string;
    Members?: IPrincipal[];
    Visitors?: IPrincipal[];
}

export interface ISiteInfoRequestBase {
    title: string;
    description: string;
    primaryOwner: string;
    secondaryOwner: string;
    //template: SiteTemplate;
    provisionTemplateId: string;
    timeZoneId?: SiteTimeZone;
    sharingCapability?: SiteSharingCapability;
    classification: string;
    hubSiteId: string;
    hubSiteTitle: string;
    registerHubSite: boolean;
    visibility?: SiteInfoVisibility;
    members: IPrincipal[];
}

export interface ISiteInfoRequestSite extends ISiteInfoRequestBase {
    siteUrl: string;
    language: SiteLanguage;
}

export interface ISiteInfoRequestTeams extends ISiteInfoRequestBase {
    nickname: string;
    guestsAccess: SiteInfoGuestsAccess;
}

export interface ISiteInfoChangeBase {
    internalId?: string;
    title: string;
    description: string;
    primaryOwner: string;
    secondaryOwner: string;
    timeZoneId?: SiteTimeZone;
    sharingCapability?: SiteSharingCapability;
    visibility: SiteInfoVisibility;
    classification?: string;
    hubSiteId?: string;
    hubSiteTitle?: string;
    registerHubSite?: boolean;
    statusMessage?: string;
    status?: SiteStatus;
    start?: string;
    end?: string;
    created?: string;
    createdBy?: string;
    createdById?: string;
    organization?: string;
    organizationId?: string;
    directoryItemId?: string;
    approval?: IApproval;
}

export interface ISiteInfoChangeSite extends ISiteInfoChangeBase {
}

export interface ISiteInfoChangeTeam extends ISiteInfoChangeBase {
    guestsAccess?: SiteInfoGuestsAccess;
}

export enum SiteProvisioningType {
    MSTeams = 16,
    SPOSite = 32
}

export interface IProvisionTemplate {
    id: string;
    type: SiteProvisioningType;
    name: string;
    description: string;
    order: number;
    icon: string;
    showInRequest: boolean;

    primaryOwner?: IAccount;
    secondaryOwner?: IAccount;

    settings: IProvisionTemplateSettings;
    lifecycle: IProvisionTemplateLifecycle;
}

export interface IProvisionTemplateSettings {
    titlePrefix?: string;
    prefix?: string;

    createTeamsTabPlanner?: boolean;
    createTeamsTabOneNote?: boolean;
    createSpoTaskList?: boolean;
    createSpoCalenderList?: boolean;

    sharePointSiteTemplate: SharePointSiteTemplate;
    secondaryOwnerChangeable?: boolean;
    classification?: string;
    classificationChangeable?: boolean;
    guestsAccess?: SiteInfoGuestsAccess;
    guestsAccessChangeable?: boolean;
    sharingCapability?: SiteSharingCapability;
    sharingCapabilityChangeable?: boolean;
    visibility?: SiteInfoVisibility;
    visibilityChangeable?: boolean;
    timezone?: SiteTimeZone;
    timezoneChangeable?: boolean;
    language?: SiteLanguage;
    languageChangeable?: boolean;
    hubSite?: IProvisionTemplateHubSiteSetting;
    hubSiteChangeable?: boolean;
    registerHubSite?: boolean;
    registerHubSiteChangeable?: boolean;

    applyTheme?: boolean;
    theme?: Partial<IPalette>;
    siteIcon?: IFileServiceFile;
    requesterGroup?: IProvisionTemplateGroupSetting;
    approverGroup?: IProvisionTemplateGroupSetting;
    msTeamsTemplateId?: string;
}

export interface IProvisionTemplateGroupSetting {
    id: string;
    title: string;
}

export interface IProvisionTemplateHubSiteSetting {
    id: string;
    title: string;
}

export interface IProvisionTemplateLifecycle {
    archive?: IProvisionTemplateLifecycleTime;
    delete?: IProvisionTemplateLifecycleTime;
    remind?: {
        first?: IProvisionTemplateLifecycleTime;
        second?: IProvisionTemplateLifecycleTime;
        last?: IProvisionTemplateLifecycleTime;
    };
}

export interface IProvisionTemplateLifecycleTime {
    days?: number;
    months?: number;
    years?: number;
}

export interface IProvisionTemplateFormData {
    type?: SiteProvisioningType;
    name?: string;
    description?: string;
    order?: number;
    icon?: string;
    showInRequest?: boolean;

    primaryOwner?: IAccount;
    secondaryOwner?: IAccount;

    settings: IProvisionTemplateSettings;
    lifecycle?: IProvisionTemplateLifecycle;
}

export enum SiteInfoVisibility {
    Private = 128,
    Public = 129
}

export enum SiteInfoGuestsAccess {
    Forbid = 256,
    Allow = 257
}

export enum SiteSharingCapability {
    Disabled = 512,
    ExternalUserSharingOnly = 513,
    //ExternalUserAndGuestSharing = 514,
    ExistingExternalUserSharingOnly = 515
}

export enum SiteTimeZone {
    InternationalDateLineWest = 39,
    CoordinatedUniversalTimeMinus11 = 95,
    Hawaii = 15,
    Alaska = 14,
    BajaCalifornia = 78,
    PacificTime_US_Canada = 13,
    Arizona = 38,
    Chihuahua_LaPaz_Mazatlan = 77,
    MountainTime_US_Canada = 12,
    CentralAmerica = 55,
    CentralTime_US_Canada = 11,
    Guadalajara_MexicoCity_Monterrey = 37,
    Saskatchewan = 36,
    Bogota_Lima_Quito = 35,
    EasternTime_US_Canada = 10,
    IndianaEast = 34,
    Caracas = 88,
    Asuncion = 91,
    AtlanticTime_Canada = 9,
    Cuiaba = 81,
    Georgetown_LaPaz_Manaus_SanJuan = 33,
    Santiago = 65,
    Newfoundland = 28,
    Brasilia = 8,
    BuenosAires = 85,
    Cayenne_Fortaleza = 32,
    Greenland = 60,
    Montevideo = 90,
    Salvador = 103,
    CoordinatedUniversalTimeMinus02 = 96,
    MidAtlantic = 30,
    Azores = 29,
    CapeVerdeIsland = 53,
    Casablanca = 86,
    CoordinatedUniversalTime = 93,
    Dublin_Edinburgh_Lisbon_London = 2,
    Monrovia_Reykjavik = 31,
    Amsterdam_Berlin_Bern_Rome_Stockholm_Vienna = 4,
    Belgrade_Bratislava_Budapest_Ljubljana_Prague = 6,
    Brussels_Copenhagen_Madrid_Paris = 3,
    Sarajevo_Skopje_Warsaw_Zagreb = 57,
    WestCentralAfrica = 69,
    Windhoek = 83,
    Amman = 79,
    Athens_Bucharest_Istanbul = 5,
    Beirut = 80,
    Cairo = 49,
    Damascus = 98,
    Harare_Pretoria = 50,
    Helsinki_Kyiv_Riga_Sofia_Tallinn_Vilnius = 59,
    Istanbul = 101,
    Jerusalem = 27,
    MinskOld = 7,
    EastEurope = 104,
    Baghdad = 26,
    Kaliningrad_Minsk = 100,
    Kuwait_Riyadh = 74,
    Minsk = 109,
    Nairobi = 56,
    Tehran = 25,
    AbuDhabi_Muscat = 24,
    Astrakhan_Ulyanovsk = 110,
    Baku = 54,
    Izhevsk_Samara = 106,
    Moscow_StPetersburg_Volgograd = 51,
    PortLouis = 89,
    Tbilisi = 82,
    Yerevan = 84,
    Kabul = 48,
    Islamabad_Karachi = 87,
    Tashkent = 47,
    Chennai_Kolkata_Mumbai_NewDelhi = 23,
    SriJayawardenepura = 66,
    Kathmandu = 62,
    Astana = 71,
    Dhaka = 102,
    Omsk = 115,
    Ekaterinburg = 58,
    YangonRangoon = 61,
    Bangkok_Hanoi_Jakarta = 22,
    Barnaul_GornoAltaysk = 111,
    Novosibirsk = 46,
    Tomsk = 112,
    Beijing_Chongqing_HongKong_Urumqi = 45,
    Krasnoyarsk = 64,
    KualaLumpur_Singapore = 21,
    Perth = 73,
    Taipei = 75,
    Ulaanbaatar = 94,
    Irkutsk = 63,
    Osaka_Sapporo_Tokyo = 20,
    Seoul = 72,
    Adelaide = 19,
    Darwin = 44,
    Brisbane = 18,
    Canberra_Melbourne_Sydney = 76,
    Guam_PortMoresby = 43,
    Hobart = 42,
    Yakutsk = 70,
    SolomonIsland_NewCaledonia = 41,
    Anadyr_PetropavlovskKamchatsky = 108,
    Vladivostok = 68,
    Chokurdakh = 107,
    Sakhalin = 114,
    Auckland_Wellington = 17,
    CoordinatedUniversalTimePlus12 = 97,
    Fiji = 40,
    Magadan = 99,
    PetropavlovskKamchatskyOld = 92,
    Nukualofa = 67,
    Samoa = 16
}

export enum SiteLanguage {

    Arabic = 1025,
    Bulgarian = 1026,
    Catalan = 1027,
    ChineseTraditional = 1028,
    Czech = 1029,
    Danish = 1030,
    German = 1031,
    Greek = 1032,
    English = 1033,
    Finnish = 1035,
    French = 1036,
    Hebrew = 1037,
    Hungarian = 1038,
    Italian = 1040,
    Japanese = 1041,
    Korean = 1042,
    Dutch = 1043,
    NorwegianBokmal = 1044,
    Polish = 1045,
    PortugueseBrazil = 1046,
    Romanian = 1048,
    Russian = 1049,
    Croatian = 1050,
    Slovak = 1051,
    Swedish = 1053,
    Thai = 1054,
    Turkish = 1055,
    Indonesian = 1057,
    Ukrainian = 1058,
    Slovenian = 1060,
    Estonian = 1061,
    Latvian = 1062,
    Lithuanian = 1063,
    Vietnamese = 1066,
    Azerbaijani = 1068,
    Basque = 1069,
    Macedonian = 1071,
    Hindi = 1081,
    Malay = 1086,
    Kazakh = 1087,
    Welsh = 1106,
    Galician = 1110,
    Dari = 1164,
    ChineseSimplified = 2052,
    PortuguesePortugal = 2070,
    SerbianLatin = 2074,
    Irish = 2108,
    Spanish = 3082,
    BosnianLatin = 5146,
    SerbianLatinSerbia = 9242,
    SerbianCyrillicSerbia = 10266
}

export enum SiteStatus {
    ProvisionQueued = 1024,
    ProvisionRunning = 1025,
    ProvisionError = 1026,
    ChangeQueued = 1027,
    ChangeRunning = 1028,
    ChangeError = 1029,
    Active = 1030,
    Archived = 1031,
    Deleted = 1032,
    WaitingForApproval = 1033,
    WaitingForWebHook = 1034,
    WebHookError = 1035,
    WaitingForChangeApproval = 1036,
    WaitingForWebHookApproval = 1037,
    WebHookApprovalError = 1038
}

export enum ApprovalTaskStatus {
    Pending = 8,
    Approved = 9,
    Rejected = 10
}

export enum SharePointSiteTemplate {
    ModernTeamSite =  1, //"STS#3"
    CommunicationSite = 2 // "SITEPAGEPUBLISHING#0"
}
