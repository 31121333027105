import { LocaleService, BaseStrings, Locale } from "../../../business/LocaleService";

export class PeoplePickerStrings extends BaseStrings {
    readonly _ModuleName = "PeoplePickerStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new PeoplePickerStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./PeoplePickerStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    PeoplePicker_UserCreation_Header = "Create user";
    PeoplePicker_UserCreation_Label_DisplayName = "Name";
    PeoplePicker_UserCreation_Label_Info_DisplayName = "Name is the given and surname of the user. For example, 'Chris Blue'";
    PeoplePicker_UserCreation_Label_Password = "Password";
    PeoplePicker_UserCreation_Label_Info_Password = "The password of the account";
    PeoplePicker_UserCreation_Label_AccountName = "User name";
    PeoplePicker_UserCreation_Label_Info_AccountName = "User name is the identifier that the user enters to sign in. For example, 'chris@beedigital.cloud'.";
    PeoplePicker_UserCreation_Error_PasswordComplexity = "The specified password does not comply with the password complexity requirements or contains invalid characters. Please provide a different password.";    
    PeoplePicker_UserCreation_Error_InvalidName = "The name is not valid. Please choose another name.";
    PeoplePicker_UserCreation_Error_AlreadyExists = "A user with this name already exists. Please choose another account name.";
    PeoplePicker_UserCreation_SaveButton = "Save";
    PeoplePicker_UserCreation_CancelButton  = "Cancel";

    PeoplePicker_UserCreation_Link = "New user";

    
}

export default (() => {
    let dict = new PeoplePickerStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();