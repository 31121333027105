import * as React from 'react';
import { SharePointPage } from '../../appstart/spapp/sharepoint/SharePointPage';
import { RequestService } from '../../common/business/RequestService';
import { ServiceLoader } from '../../common/business/ServiceLoader';
import { SmartPortal } from '../../SmartPortal';
import { ISiteDetailsFormData, SiteDetailsEditMode, SiteDetailsForm } from '../provisioning/controls/SiteDetailsForm';
import { ISiteInfo, SiteStatus } from '../../common/models/SiteInfo';
import { DirectoryItem } from '../../common/controls/Directory/controls/DirectoryItem';
import styles from "./SideBarStyle.scss";
import { QuickActionsPanel } from '../quickactions/QuickActionsPanel';
import panelStyles from "../../common/controls/Panel/PanelStyle.scss";

import SideBarStrings from './loc/SideBarStrings';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { PanelHeader } from '../../common/controls/Panel/PanelHeader';
import { Action } from '../../common/controls/Panel/PanelActions';

export interface ISidebarPanelSiteInfoProps {
    expandLink: string;
    showQuickActions?: boolean;
}

export interface ISidebarPanelSiteInfoState {
    siteId: string;
    loading: boolean;
    siteInfo: ISiteInfo;
    isEditMode: boolean;
    siteDetails: ISiteDetailsFormData;
    isFormValid?: boolean;
    formValidationMessage?: string;
    errorMessage?: string;
}

export default class SidebarPanelSiteInfo extends React.Component<ISidebarPanelSiteInfoProps, ISidebarPanelSiteInfoState> {
    private rendered: boolean = false;
    private isRunning: boolean = false;
    private runningItemCheckHandle?: number;

    constructor(props: ISidebarPanelSiteInfoProps) {
        super(props);
        let spSiteId: string;
        const currentPage = SmartPortal.currentPage;
        if (currentPage instanceof SharePointPage) {
            const spPageContext = (currentPage as SharePointPage).getCurrentPageContext();
            spSiteId = spPageContext.site.id.toString();
        }
        else {
            spSiteId = null;
        }

        this.state = {
            siteId: spSiteId,
            loading: true,
            siteInfo: null,
            isEditMode: false,
            siteDetails: null
        }
    }
    
    public componentDidMount(): void {
        if (!this.rendered) {
            this.rendered = true;
            this.loadItem();
        }
    }

    public componentWillUnmount(): void {
        if (this.runningItemCheckHandle) {
            clearInterval(this.runningItemCheckHandle);
            this.runningItemCheckHandle = null;
        }
    }

    public render() {
        return (
            <div>
                {
                    this.props.showQuickActions ? <div className={styles.QuickActionsPage}><QuickActionsPanel headerText={SideBarStrings.SideBarPanel_ActionsTitle} expandLink={this.props.expandLink} closeAction={() => { SmartPortal.currentPage.setRightPanel(null); return Promise.resolve(); }} /></div> : null
                }
                <div className={panelStyles.Panel + " " + styles.SideBarPanel}>
                    <PanelHeader headerText={SideBarStrings.SideBarPanel_SiteInfoTitle}
                        actions={this.state.loading || !this.state.siteInfo ? [!this.props.showQuickActions ? Action.Close : Action.None] : DirectoryItem.getAvailablePanelActions(this.state.siteInfo, [this.props.expandLink && !this.props.showQuickActions ? Action.Expand : Action.None, !this.props.showQuickActions ? Action.Close : Action.None])}
                        onAction={(action) => {
                            if (action === Action.Close) {
                                SmartPortal.currentPage.setRightPanel(null);
                                return Promise.resolve();
                            }
                            if (action === Action.Expand) {
                                SmartPortal.navigateTo(this.props.expandLink);
                            }
                            if (action === Action.Edit) {
                                return new Promise((resolve) => {
                                    this.setState({
                                        isEditMode: true
                                    }, resolve);
                                });
                            }
                            if (action === Action.Save) {
                                if (!this.state.isFormValid)
                                    return Promise.reject({ Message: this.state.formValidationMessage ? this.state.formValidationMessage : SideBarStrings.SideBarPanel_SiteInfo_FormNotValidMessage });
                                return DirectoryItem.saveItemChanges(this.state.siteInfo, this.state.siteDetails).then(() => {
                                    this.setState({
                                        isEditMode: false,
                                    });
                                    this.loadItem();
                                });
                            }
                            if (action === Action.Delete) {
                                return DirectoryItem.onDelete(this.state.siteInfo, () => this.deleteItem().then(() => { location.reload(); }));
                            }
                            if (action === Action.Archive) {
                                return DirectoryItem.onArchive((archive) => this.archiveItem(archive).then(() => { location.reload(); }));
                            }
                            if (action === Action.Unarchive) {
                                return DirectoryItem.onUnarchive((archive) => this.archiveItem(archive).then(() => { location.reload(); }));
                            }
                            return Promise.resolve();
                        }}
                    />
                    <div className={panelStyles.PanelContent + " " + styles.SideBarPanelContent}>
                        {this.state.loading ?
                            <Spinner size={SpinnerSize.medium} /> :
                            this.state.siteInfo ?
                                [
                                    DirectoryItem.getMessages(this.state.siteInfo, () => this.loadItem()),
                                    <SiteDetailsForm
                                        formData={this.state.siteDetails}
                                        editMode={this.state.isEditMode ? SiteDetailsEditMode.EditForm : SiteDetailsEditMode.ViewForm}
                                        onUpdate={(data: ISiteDetailsFormData, valid: boolean, validationMessage: string) => {
                                            this.setState({ siteDetails: data, isFormValid: valid, formValidationMessage: validationMessage });
                                        }}
                                        siteId={this.state.siteInfo.siteId}
                                    />
                                ] :
                                <div>
                                    <span>{this.state.errorMessage}</span>
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private loadItem(): Promise<void> {
        this.setState({ loading: true });
        if (this.runningItemCheckHandle) {
            clearInterval(this.runningItemCheckHandle);
            this.runningItemCheckHandle = null;
        }
        const reqSvc = ServiceLoader.GetService(RequestService);
        return reqSvc.requestAadService({
            method: "GET",
            url: `/api/Directory/Sites/SiteId/${this.state.siteId}`
        }).then(reqSvc.parseApiResponse).then((siteInfo: ISiteInfo) => {

            this.isRunning = siteInfo.status === SiteStatus.ProvisionQueued ||
                siteInfo.status === SiteStatus.ProvisionRunning ||
                siteInfo.status === SiteStatus.ChangeQueued ||
                siteInfo.status === SiteStatus.ChangeRunning ||
                siteInfo.status === SiteStatus.WaitingForWebHook ||
                siteInfo.status === SiteStatus.WaitingForWebHookApproval;

            if (this.isRunning) {
                const checkRunningItem: TimerHandler = () => this.checkRunningItem();
                this.runningItemCheckHandle = setInterval(checkRunningItem, 5000);
            }

            this.setState({
                loading: false,
                siteInfo: siteInfo,
                siteDetails: DirectoryItem.getFromSiteInfo(siteInfo)
            });
        }).catch((reason) => {
            let message = reason.title;
            if (reason && reason.status && reason.status == 404) {
                message = SideBarStrings.SideBarPanel_SiteInfo_Error_NotFound;
            }

            this.setState({
                loading: false,
                errorMessage: message ? message : SideBarStrings.SideBarPanel_SiteInfo_Error_Generic
            });
        });
    }

    private checkRunningItem(): void {
        const reqSvc = ServiceLoader.GetService(RequestService);
        reqSvc.requestAadService({
            method: "GET",
            url: `/api/Directory/Sites/SiteId/${this.state.siteId}`
        }).then(reqSvc.parseApiResponse).then((siteInfo: ISiteInfo) => {
            if (this.state.siteInfo.status !== siteInfo.status) {

                this.isRunning = siteInfo.status === SiteStatus.ProvisionQueued ||
                    siteInfo.status === SiteStatus.ProvisionRunning ||
                    siteInfo.status === SiteStatus.ChangeQueued ||
                    siteInfo.status === SiteStatus.ChangeRunning ||
                    siteInfo.status === SiteStatus.WaitingForWebHook ||
                    siteInfo.status === SiteStatus.WaitingForWebHookApproval;

                if (!this.isRunning) {
                    clearInterval(this.runningItemCheckHandle);
                    this.runningItemCheckHandle = null;
                }

                this.setState({
                    siteInfo: siteInfo,
                    siteDetails: DirectoryItem.getFromSiteInfo(siteInfo)
                });
            }
        });
    }

    private deleteItem(): Promise<void> {
        const reqSvc = ServiceLoader.GetService(RequestService);
        return reqSvc.requestAadService({
            method: "DELETE",
            url: `/api/Directory/Items/${this.state.siteInfo.internalId}`
        }).then(reqSvc.parseApiResponse).then((response) => {

        });
    }

    private archiveItem(archive: boolean): Promise<void> {
        const reqSvc = ServiceLoader.GetService(RequestService);
        return reqSvc.requestAadService({
            method: "POST",
            url: `/api/Directory/Items/${this.state.siteInfo.internalId}/${(archive ? "archive" : "unarchive")}`
        }).then(reqSvc.parseApiResponse).then((response) => {

        });
    }
}
