import * as React from 'react';
import styles from "./PanelStyle.scss";
import PanelStrings from "./loc/PanelStrings";
import { Action } from "./PanelActions";
import { ActionButton, Spinner, SpinnerSize } from 'office-ui-fabric-react';

export interface IPanelFooterProps {
    showMore?: boolean;
    onAction?: (action: Action) => Promise<void | boolean>;
    children?: React.ReactNodeArray | React.ReactNode;
}

export interface IPanelFooterState {
    loading: boolean;
}

export class PanelFooter extends React.Component<IPanelFooterProps, IPanelFooterState> {

    constructor(props: IPanelFooterProps, state: IPanelFooterState) {
        super(props);

        this.state = {
            loading: false
        };
    }

    public render(): React.ReactElement<{}> {
        return (
            <div className={styles.PanelFooter}>
                {this.props.showMore && !this.state.loading ? <div className={styles.PanelFooterMore}>
                    <ActionButton iconProps={{ iconName: 'ChevronDown' }} text={PanelStrings.Action_ShowMore} onClick={() => {
                        this.setState({ loading: true });
                        if (this.props.onAction)
                            this.props.onAction(Action.ShowMore).then(() => {
                                this.setState({ loading: false });
                            }).catch(() => {
                                this.setState({ loading: false });
                            });
                    }} />
                </div> : null}
                {this.props.showMore && this.state.loading ? <div className={styles.PanelFooterMore}>
                    <Spinner title={PanelStrings.Action_Loading} size={SpinnerSize.small} />
                </div> : null}
                {this.props.children ? <div className={styles.PanelFooterContent}>{this.props.children}</div> : null}
            </div>
        );
    }
}