import * as React from 'react';
import * as ReactUltimatePagination from 'react-ultimate-pagination';
import { DefaultButton } from 'office-ui-fabric-react';

function Page(props) {
    return <DefaultButton primary={props.isActive} onClick={props.onClick} text={props.value} />;
}

function Ellipsis(props) {
    return <DefaultButton text="..." disabled />;
}

function FirstPageLink(props) {
    return <DefaultButton onClick={props.onClick} iconProps={{ iconName: 'DoubleChevronLeft' }} />;
}

function PreviousPageLink(props) {
    return <DefaultButton onClick={props.onClick} iconProps={{ iconName: 'ChevronLeft' }} />;
}

function NextPageLink(props) {
    return <DefaultButton onClick={props.onClick} iconProps={{ iconName: 'ChevronRight' }} />;
}

function LastPageLink(props) {
    return <DefaultButton onClick={props.onClick} iconProps={{ iconName: 'DoubleChevronRight' }} />;
}

function Wrapper(props) {
    return <div className='pagination'>{props.children}</div>;
}

var itemTypeToComponent = {
    'PAGE': Page,
    'ELLIPSIS': Ellipsis,
    'FIRST_PAGE_LINK': FirstPageLink,
    'PREVIOUS_PAGE_LINK': PreviousPageLink,
    'NEXT_PAGE_LINK': NextPageLink,
    'LAST_PAGE_LINK': LastPageLink
};

var CardPagination = ReactUltimatePagination.createUltimatePagination({
    itemTypeToComponent: itemTypeToComponent,
    WrapperComponent: Wrapper
});

export { CardPagination };