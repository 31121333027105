import * as React from 'react';
import styles from "./FileUploadStyle.scss";
import FileUploadStrings from "./loc/FileUploadStrings";
import { Icon, IconButton, Spinner, SpinnerSize, ProgressIndicator, Link } from 'office-ui-fabric-react';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { IUploadFile, UploadFileStates, UploadFileErrorTypes, UploadFileResumeTypes } from "../../models/UploadFile";
import * as FileUtil from "../../utils/FileUtil";


export interface IFileUploadItemProps {
    file: IUploadFile;
    onRemove?(): void;
    onResume?: (resumeType: UploadFileResumeTypes) => void;
}

export interface IFileUploadItemState {
}

export class FileUploadItem extends React.Component<IFileUploadItemProps, IFileUploadItemState> {
    
    constructor(props: IFileUploadItemProps, state: IFileUploadItemState) {
        super(props);

        this.state = {
        };
    }

    public render(): React.ReactElement<{}> {
        return (
            <div className={styles.FileUploadItem}>
                <div className={styles.FileUploadItemIcon}>
                    <Icon {...getFileTypeIconProps({ extension: FileUtil.getFileExtension(this.props.file.file), size: 40, imageFileType: 'png' })} />
                </div>
                <div className={styles.FileUploadItemContent}>
                    <div className={styles.FileUploadItemName}>{this.props.file.file.name}</div>
                    <div className={styles.FileUploadItemStatus}>
                        <div dangerouslySetInnerHTML={{ __html: this.props.file.status }} />
                        {this.props.file.state === UploadFileStates.Error ? (
                            this.props.file.errorType === UploadFileErrorTypes.Conflict ?
                                <div>
                                    &nbsp;&#xb7;&nbsp;<Link onClick={(evt) => this.onFileUploadItemResumeClick(evt, UploadFileResumeTypes.Replace)}>{FileUploadStrings.FileUpload_Replace}</Link>
                                    &nbsp;&#xb7;&nbsp;<Link onClick={(evt) => this.onFileUploadItemResumeClick(evt, UploadFileResumeTypes.Rename)}>{FileUploadStrings.FileUpload_Rename}</Link>
                                </div> :
                                this.props.file.errorType !== UploadFileErrorTypes.BadRequest ?
                                    <div>
                                        &nbsp;&#xb7;&nbsp;<Link onClick={(evt) => this.onFileUploadItemResumeClick(evt, UploadFileResumeTypes.Retry)}>{FileUploadStrings.FileUpload_Retry}</Link>
                                    </div> : 
                                    null
                        ): ""}
                    </div>
                    <div className={styles.FileUploadItemProgress}>
                        {this.props.file.state === UploadFileStates.Uploading ? <ProgressIndicator label="" description="" percentComplete={this.props.file.progress} /> : null}
                    </div>
                </div>
                <div className={styles.FileUploadItemActions}>
                    {this.props.file.state === UploadFileStates.Added ?
                        <IconButton iconProps={{ iconName: "Cancel" }} title="Remove" onClick={(evt) => this.onFileUploadItemRemoveClick(evt)} /> :
                        this.props.file.state === UploadFileStates.Uploading ?
                            <Spinner size={SpinnerSize.small} /> :
                            this.props.file.state === UploadFileStates.Queued ?
                                <Icon iconName="HourGlass" /> :
                                this.props.file.state === UploadFileStates.Uploaded ?
                                    <Icon iconName="Completed" className={styles.FileUploadItemUploaded} /> :
                                    <Icon iconName= "ErrorBadge" className={styles.FileUploadItemError} />
                    }
                </div>
            </div>
        );
    }

    private onFileUploadItemResumeClick(evt: any, resumeType: UploadFileResumeTypes) {
        evt.preventDefault();
        evt.stopPropagation();
        if (this.props.onResume)
            this.props.onResume(resumeType);
    }

    private onFileUploadItemRemoveClick(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        if (this.props.onRemove)
            this.props.onRemove();
    }
}
