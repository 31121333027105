const { ceil } = Math;
const { fromCharCode } = String;

export const encode = uint8Array => {
    let extra = 0;
    const output = [];
    const { length } = uint8Array;
    const len = ceil(length / 2);
    for (let j = 0, i = 0; i < len; i++)
        output.push(
            fromCharCode(
                (uint8Array[j++] << 8) +
                (j < length ? uint8Array[j++] : extra++)
            )
        );
    output.push(fromCharCode(extra));
    return output.join('');
};

export const decode = chars => {
    const codes = [];
    const length = chars.length - 1;
    for (let i = 0; i < length; i++) {
        const c = chars.charCodeAt(i);
        codes.push(c >> 8, c & 0xFF);
    }
    if (chars.charCodeAt(length))
        codes.pop();
    return Uint8Array.from(codes);
};