import { ICacheDriver } from './models/CacheDriver';
import { CacheType } from './models/CacheType';
import { ICacheOpts, ICacheSetOpts, ICacheInterfaceOpts, CacheEncoding } from './models/CacheOptions';
import { ICacheService } from './models/CacheService';
import { MapBuilder } from '../utils/MapBuilder';
import { SessionStorageCacheDriver, LocalStorageCacheDriver } from './drivers/StorageCacheDriver';
import { IndexedDBCacheDriver } from './drivers/IndexedDBCacheDriver';

export interface ICacheRequest {
    cacheName: string;
    cacheType: CacheType;
    cacheOpts: ICacheOpts;
    reqFunc: keyof ICacheDriver;
    reqArgs: any[];
}

export interface ICacheResponse {
    success: boolean;
    result: any;
}

export abstract class BaseCacheService implements ICacheService {
    public static readonly CacheKeyPrefix = "sp365-"; // smartportal cache
    public static readonly CacheCleanupDelay = 20; // delay cache cleanup for at least 20 seconds after page load
    public static readonly CacheCleanupInterval = 60 * 60 * 6; // run cache cleanup every 6h
    public static CacheRedirectFn: (req: ICacheRequest) => Promise<ICacheResponse>

    // general settings
    protected abstract cacheName: string;
    protected abstract cacheType: CacheType;
    protected cacheOptions: ICacheOpts = {};
    protected skipCleanup: boolean = false;

    // internal properties
    protected cacheDriver: ICacheDriver;
    private prefixedInterfaces = MapBuilder.NewMap<string, ICacheService>();
    private cleanupTimerId: number;
    private driverInstanceId: number = 0;
    
    public constructor(skipCleanup?: boolean) {
        if(typeof skipCleanup === "boolean")
            this.skipCleanup = skipCleanup;

        window.setTimeout(() => {
            if(!this.skipCleanup)
                this.scheduleCleanupTask();
        }, BaseCacheService.CacheCleanupDelay * 1000);
    }

    public setCacheEntry(key: string, value: any, opts?: ICacheSetOpts): Promise<void> {
        return this.handleCacheRequest("setEntry", [key, value, opts]).then();
    }

    public getCacheEntry(key: string, ignoreTimeout?: boolean): Promise<any> {
        return this.handleCacheRequest("getEntry", [key, ignoreTimeout]);
    }

    public delCacheEntry(key: string): Promise<void> {
        return this.handleCacheRequest("delEntry", [key]).then();
    }

    public cleanupCache(): Promise<void> {
        return this.getCacheEntry("[cleanup]").then((cleanupState) => {
            if (!cleanupState) 
                cleanupState = { lastStart: 0, lastComplete: 0 };

            let timeVal = Math.floor((new Date()).getTime() / 1000);
            if(cleanupState.lastStart && timeVal - cleanupState.lastStart < 30) {
                return;
            }

            cleanupState.lastStart = timeVal;
            return this.setCacheEntry("[cleanup]", cleanupState, {
                encode: CacheEncoding.Plain,
                timeout: 0,
            }).then(() => {
                //console.log("run cache cleanup (" + this.cacheName + ")");
                return this.handleCacheRequest("runCleanup", []);
            }).then(() => {
                cleanupState.lastComplete = Math.floor((new Date()).getTime() / 1000);
                return this.setCacheEntry("[cleanup]", cleanupState, {
                    encode: CacheEncoding.Plain,
                    timeout: 0,
                });
            });
        });
    }

    public getPrefixedCache(prefix: string, opts?: ICacheInterfaceOpts): ICacheService {
        if(!prefix.match(/:$/))
            prefix += ":";

        let prefixedInterface = this.prefixedInterfaces.get(prefix);
        if(!prefixedInterface) {
            prefixedInterface = this.buildPrefixedInterface(prefix, opts);
            this.prefixedInterfaces.set(prefix, prefixedInterface);
        }

        return prefixedInterface;
    }


    private buildPrefixedInterface(prefix: string, options: ICacheInterfaceOpts): ICacheService {
        return {
            setCacheEntry: (key, value, opts) => {
                if(options) {
                    if(!opts)
                        opts = options.setOpts;
                    else if(options.setOpts)
                        Object.assign(opts, options.setOpts);
                }
                return this.setCacheEntry(prefix + key, value, opts);
            },
            getCacheEntry: (key, ignoreTimeout) => this.getCacheEntry(prefix + key, ignoreTimeout),
            delCacheEntry: (key) => this.delCacheEntry(prefix + key),
        };
    }

    protected handleCacheRequest(reqName: keyof ICacheDriver, reqArgs: any[]): Promise<any> {
        if(BaseCacheService.CacheRedirectFn) {
            return BaseCacheService.CacheRedirectFn({
                cacheName: this.cacheName,
                cacheType: this.cacheType,
                cacheOpts: this.cacheOptions,
                reqFunc: reqName,
                reqArgs: reqArgs,
            }).then((res) => {
                if(!res.success)
                    throw res.result;
                return res.result;
            });
        }
        else {
            return this.runCacheDriverFn(reqName, reqArgs);
        }
    }

    private runCacheDriverFn(reqName: keyof ICacheDriver, reqArgs: any[]): any {
        let cacheDriver = this.getCacheDriver();
        let driverId = this.driverInstanceId;
        let driverReqFn = cacheDriver[reqName];
        let result = driverReqFn.apply(cacheDriver, reqArgs);
        if(result && result.then) {
            result = result.then((data) => data, (issue) => {
                if(driverId !== this.driverInstanceId) {
                    // driver changed, retry request execution
                    return this.runCacheDriverFn(reqName, reqArgs);
                }
                throw issue;
            });
        }
        return result;
    }

    protected getCacheDriver(): ICacheDriver {
        if(!this.cacheDriver) {
            switch(this.cacheType) {
                case CacheType.SessionStorage:
                    this.cacheDriver = new SessionStorageCacheDriver(this.cacheName, this.cacheOptions);
                    break;
                case CacheType.LocalStorage:
                    this.cacheDriver = new LocalStorageCacheDriver(this.cacheName, this.cacheOptions);
                    break;
                case CacheType.IndexedDB:
                    this.cacheDriver = new IndexedDBCacheDriver(this.cacheName, this.cacheOptions);
                    break;
            }
            this.driverInstanceId++;
            this.cacheDriver.getReadyPromise().catch((issue) => this.handleCacheException(issue));
        }
        return this.cacheDriver;
    }

    protected handleCacheException(issue: any): void {}

    protected scheduleCleanupTask() {
        this.getCacheEntry("[cleanup]").then((cleanupState) => {
            if (!cleanupState) 
                cleanupState = { lastStart: 0, lastComplete: 0 };

            let timeVal = Math.floor((new Date()).getTime() / 1000);
            let scheduleTime = 0;
            if(cleanupState.lastComplete) {
                let lastRun = cleanupState.lastComplete ? timeVal - cleanupState.lastComplete : 0;
                scheduleTime = lastRun > BaseCacheService.CacheCleanupInterval ? 0 : BaseCacheService.CacheCleanupInterval - lastRun;
            }
            if(cleanupState.lastStart && timeVal - cleanupState.lastStart < 30) {
                scheduleTime += 30;
            }
            
            //console.log("schedule cache cleanup (" + this.cacheName + "): " + scheduleTime + " sec");

            scheduleTime *= 1000; // to ms
            scheduleTime += Math.random() * 10000; // + random delay up to 10 sec

            if(this.cleanupTimerId)
                window.clearTimeout(this.cleanupTimerId);
            this.cleanupTimerId = window.setTimeout(() => {
                this.cleanupTimerId = null;
                this.cleanupCache().then(() => {
                    this.scheduleCleanupTask();
                });
            }, scheduleTime);
        });
    }

}

