import * as React from 'react';
import styles from "./DialogStyle.scss";
import { PanelHeader, PanelFooter, Action, ActionExecution } from "../../controls/Panel";
import { SmartPortal } from '../../../SmartPortal';
import { IErrorInfo } from '../../models/SessionInfo';
import { PrimaryButton } from 'office-ui-fabric-react';
import strings from "./loc/DialogStrings";

export interface IDialogProps {
    className?: string;
    children?: React.ReactNodeArray | React.ReactNode;

    backgroundImage?: string;
    showLogo?: boolean;

    headerText?: string;
    header?: React.ReactNodeArray | React.ReactNode;

    infoTooltip?: string;
    initialExecutionState?: ActionExecution;
    actions?: Action[];
    currentActions?: Action[];
    customActions?: React.ReactNodeArray | React.ReactNode;
    actionTexts?: { [TAction in Action]: string };

    showFooter?: boolean;
    footer?: React.ReactNodeArray | React.ReactNode;
    showMore?: boolean;

    search?: string;
    errors?: IErrorInfo[];
    onAction?: (action: Action, value?: any) => Promise<void>;
}

export interface IDialogState {
}

export class Dialog extends React.Component<IDialogProps, IDialogState> {

    private dialogBodyEl: HTMLElement;

    constructor(props: IDialogProps, state: IDialogState) {
        super(props);

        this.state = {
        };
    }

    public componentDidMount(): void {

    }

    public render(): React.ReactElement<IDialogProps> {

        return (
            <div className={styles.Dialog + " " + styles.Panel + (this.props.className ? " " + this.props.className : "")}>
                {this.props.backgroundImage ? 
                    <div className={styles.DialogSplash + " " + styles.DialogSplashImage}>
                        <div className={styles.DialogBackgroundImage} style={{
                            backgroundImage: "url(" + this.props.backgroundImage + ")"
                        }} />
                    </div>
                :
                    <div className={styles.DialogSplash}></div>
                }
                <div className={styles.DialogContainer}>
                    <div className={styles.DialogLayer}>
                        <div className={styles.DialogContent} ref={(ref) => {
                            if(!ref || (ref as any)._dialogContentListener)
                                return;
                            (ref as any)._dialogContentListener = true;

                            ref.addEventListener("sp365.pagechange", (evt) => {
                                evt.stopPropagation();
                                ref.scrollTop = 0;
                            });
                        }}>
                            <div className={styles.DialogScroll}>
                                <div className={styles.DialogHeader}>
                                    {this.props.showLogo ? 
                                        <div className={styles.PortalLogo}>
                                            <img src={SmartPortal.buildPortalUrl("/images/PortalLogo.png")} />
                                        </div> : 
                                        <PanelHeader headerText={this.props.headerText}
                                                     header={this.props.header}
                                                     infoTooltip={this.props.infoTooltip}
                                                     currentActions={this.props.currentActions}
                                                     customActions={this.props.customActions}
                                                     initialExecutionState={this.props.initialExecutionState}
                                                     actions={this.props.actions}
                                                     actionTexts={this.props.actionTexts}
                                                     errors={this.props.errors}
                                                     onAction={(action, value) => {

                                                if (action === Action.Edit)
                                                    this.setState({ editing: true });
                                                if (action === Action.Save || action === Action.Reset)
                                                    this.setState({ editing: false });

                                                if (this.props.onAction)
                                                    return this.props.onAction(action, value);
                                                return Promise.resolve();
                                            }}
                                                     search={this.props.search}
                                        />
                                    }
                                 </div>
                                <div className={styles.PanelContent + " " + styles.DialogBody + " " + styles.PageContent}>
                                    {this.props.children}
                                </div>
                                {this.props.showFooter || this.props.showMore ? <PanelFooter showMore={this.props.showMore} onAction={(action) => {
                                    if (this.props.onAction)
                                        return this.props.onAction(action);
                                    return Promise.resolve();
                                }}>{this.props.footer}</PanelFooter> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    public static showConfirmDialog(title: string, subject: string, actionName: string = null): Promise<boolean> {
        return new Promise((resolve) => {
            const contentKey = "confirmDialog";
            const portalPage = SmartPortal.currentPage;

            portalPage.setDynamicContent(contentKey, (
                <Dialog headerText={title} key={contentKey} className={styles.ConfirmDialog} actions={[Action.Close]} onAction={(action) => {
                    if (action === Action.Close) {
                        resolve(false);
                        portalPage.setDynamicContent(contentKey, null);
                    }
                    return Promise.resolve();
                }}>
                    <div >
                        <p dangerouslySetInnerHTML={{ __html: subject }}></p>
                        <div className={styles.ConfirmDialogActions}>
                            <PrimaryButton text={actionName ? actionName : strings.Dialog_ConfirmDialog_Confirm} allowDisabledFocus onClick={() => {
                                resolve(true);
                                portalPage.setDynamicContent(contentKey, null);
                            }} />
                            <PrimaryButton text={ strings.Dialog_ConfirmDialog_Cancel} allowDisabledFocus onClick={() => {
                                resolve(false);
                                portalPage.setDynamicContent(contentKey, null);
                            }} />
                        </div>
                    </div>
                </Dialog>
            ));
        });
    }
    public setBodyScrollTop(scrollTop: number): void {
        if (!this.dialogBodyEl)
            return;

        this.dialogBodyEl.scrollTop = scrollTop;
    }
}
