import * as React from 'react';
import styles from "../ProvisioningStyle.scss";
import strings from '../loc/ProvisioningStrings';
import { SiteInfoVisibility, SiteSharingCapability, SiteLanguage, SiteTimeZone, SiteInfoGuestsAccess, SiteProvisioningType, IProvisionTemplate, IAccount, ISiteLifecycle, SiteStatus, ISiteInfoChangeSite, ISiteInfoChangeBase, ISiteInfoChangeTeam, IPrincipal, ISiteInfo, PrincipalType } from '../../../common/models/SiteInfo';
import { Checkbox, TextField, Label, ChoiceGroup, Dropdown, Icon, Spinner, SpinnerSize, TooltipHost, ITextFieldProps, ICheckboxProps, IRenderFunction, Stack, DirectionalHint, IDropdownProps } from 'office-ui-fabric-react';
import PeoplePicker from '../../../common/controls/PeoplePicker/PeoplePicker';
import { GraphPickerDataSource } from '../../../common/controls/PeoplePicker/datasource/GraphPickerDataSource';
import { IPeoplePickerReference, PeoplePickerItemType } from '../../../common/controls/PeoplePicker/model/PeoplePickerItem';
import { SmartPortal } from '../../../SmartPortal';
import { ServiceLoader } from '../../../common/business/ServiceLoader';
import { GraphBatchService } from '../../../common/business/GraphBatchService';
import { RequestService } from '../../../common/business/RequestService';
import { Role } from '../../../common/business/RoleService';

export interface ISiteDetailsFormProps {
    formData: ISiteDetailsFormData;
    editMode?: SiteDetailsEditMode;
    preSelected?: boolean;
    onUpdate?: (data: ISiteDetailsFormData, isValid: boolean, validationMessage?: string) => void;
    siteId?: string;
}

export enum SiteDetailsEditMode {
    ViewForm = 0,
    NewForm = 1,
    EditForm = 2,
    SummaryForm = 3
}

export interface ISiteDetailsFormState {
    siteUrlCheckLoading?: boolean;
    siteUrlCheckStatus?: ISiteDetailsUrlStatus;
    nicknameCheckLoading?: boolean;
    nicknameCheckStatus?: ISiteDetailsNicknameStatus;
    provisionTemplateLoaded: boolean;
    availableProvisionTemplates: IProvisionTemplate[];
    classificationLoaded: boolean;
    availableClassifications: string[];
    defaultClassification: string;
    hubSitesLoaded: boolean;
    availableHubSites: {
        ID: string;
        Title: string;
    }[];
    isAdmin: boolean;
}


export interface ISiteDetailsFormData {
    title: string;
    nickname?: string;
    siteUrl?: string;
    description: string;
    siteType: SiteProvisioningType;
    siteTemplate: IProvisionTemplate;
    primaryOwner: string;
    primaryOwnerAccount: IAccount;
    secondaryOwner: string;
    secondaryOwnerAccount: IAccount;
    visibility: SiteInfoVisibility;
    sharingCapability: SiteSharingCapability;
    guestsAccess: SiteInfoGuestsAccess;
    language: SiteLanguage;
    timeZoneId: SiteTimeZone;
    classification: string;
    hubSiteId: string;
    hubSiteTitle: string;
    registerHubSite: boolean;
    lifeCycle: ISiteLifecycle;
    siteStatus: SiteStatus;
    members: IPrincipal[];
    visitors: IPrincipal[];
    changeItem?: ISiteInfoChangeSite | ISiteInfoChangeTeam;
}

export interface ISiteDetailsUrlStatus {
    valid: boolean;
    url?: string;
}

export interface ISiteDetailsNicknameStatus {
    valid: boolean;
    message?: string;
}

type FormValidationResult = {
    isValid: boolean;
    validationMessage: string;
}

export class SiteDetailsForm extends React.Component<ISiteDetailsFormProps, ISiteDetailsFormState> {
    private peoplePickerDataSource: GraphPickerDataSource;
    private memberPickerDataSource: GraphPickerDataSource
    private urlCheckTimer: number;
    private nicknameCheckTimer: number;
    private provisionTemplateLoading: boolean = false;
    private classificationLoading: boolean = false;
    private hubSitesLoading: boolean = false;
    private initialized: boolean = false;

    constructor(props: ISiteDetailsFormProps, state: ISiteDetailsFormState) {
        super(props);
        this.peoplePickerDataSource = new GraphPickerDataSource({
            allowUsers: true,
            searchUsers: true,
            debounceTime: 500,
            searchMinLen: 2
        });

        this.memberPickerDataSource = new GraphPickerDataSource({
            allowUsers: true,
            searchUsers: true,
            allowGroups: true,
            searchGroups: true,
            debounceTime: 500,
            searchMinLen: 2
        });

        this.state = {
            provisionTemplateLoaded: false,
            availableProvisionTemplates: [],
            classificationLoaded: false,
            availableClassifications: [],
            defaultClassification: "",
            hubSitesLoaded: false,
            availableHubSites: [],
            isAdmin: SmartPortal.isUserInRole(Role.Admin)
        };
    }

    public componentDidMount(): void {
        Promise.all([this.loadProvisionTemplates(), this.loadClassifications(), this.loadHubSites(), this.loadMembers()]).then(e => {
            this.initialized = true;

            switch (this.props.editMode) {
                case SiteDetailsEditMode.NewForm:
                    if (this.props.formData.siteTemplate) {
                        this.notifyFormUpdate({ ...this.setDataFromTemplate(this.props.formData.siteTemplate), siteUrl: null, nickname: null });
                    }
                    else {
                        this.notifyFormUpdate({ ...this.props.formData, siteUrl: null, nickname: null });
                    }
                    break;
                case SiteDetailsEditMode.EditForm:
                case SiteDetailsEditMode.SummaryForm:
                case SiteDetailsEditMode.ViewForm:
                    this.notifyFormUpdate({ ...this.props.formData}); // members: e[3] })
                    break;
            }
        });
    }

    public componentDidUpdate(): void {
        this.loadProvisionTemplates();
        this.loadClassifications();
        this.loadHubSites();
    }

    private loadProvisionTemplates(): Promise<void> {
        if (!this.state.provisionTemplateLoaded && !this.provisionTemplateLoading && (this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm)) {
            this.provisionTemplateLoading = true;
            const reqSvc = ServiceLoader.GetService(RequestService);
            return reqSvc.requestAadService({
                method: "GET",
                url: "/api/directory/provisiontemplates"
            }).then(reqSvc.parseApiResponse).then((result: IProvisionTemplate[]) => {

                result.sort((a, b) => {
                    if (a.type > b.type) return 1;
                    if (a.type < b.type) return -1;
                    if (a.order > b.order) return 1;
                    if (a.order < b.order) return -1;
                    return a.name.localeCompare(b.name);
                });

                this.setState({
                    provisionTemplateLoaded: true,
                    availableProvisionTemplates: result
                }, () => {
                    this.provisionTemplateLoading = false;
                });
            });
        }
        return Promise.resolve();
    }

    private loadClassifications(): Promise<void> {
        if (!this.state.classificationLoaded && !this.classificationLoading && (this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm)) {
            this.classificationLoading = true;
            return ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
                method: "GET",
                siteUrl: SmartPortal.currentSession.tenantUrl,
                restUrl: "GroupSiteManager/GetGroupCreationContext"
            }).then((rspData) => {
                const formData = { ...this.props.formData };
                if (!formData["classification"]) {
                    formData["classification"] = rspData.d.GetGroupCreationContext.DefaultClassification;
                    this.notifyFormUpdate(formData);
                }
                this.setState({
                    classificationLoaded: true,
                    availableClassifications: rspData.d.GetGroupCreationContext.DataClassificationOptions.results,
                    defaultClassification: rspData.d.GetGroupCreationContext.DefaultClassification
                }, () => {
                    this.classificationLoading = false;
                });
            });
        }

        return Promise.resolve();
    }

    private loadMembers(): Promise<IPrincipal[]> {
        // if (this.props.editMode == SiteDetailsEditMode.ViewForm && this.props.formData.siteStatus == SiteStatus.Active) {
        //     const reqSvc = ServiceLoader.GetService(RequestService);
        //     const url = this.props.formData.siteType == SiteProvisioningType.MSTeams ? `/api/directory/teams/${this.props.formData.internalId}/members` : `/api/directory/sites/${this.props.formData.internalId}/members`;
        //     return reqSvc.requestAadService({
        //         method: "GET",
        //         url: url
        //     }).then(reqSvc.parseApiResponse).then((result: IPrincipal[]) => {
        //         return result;
        //     }).catch(reason => {
        //         return [];
        //     });
        // }

        return Promise.resolve([]);
    }

    private loadHubSites(): Promise<void> {
        if (!this.state.hubSitesLoaded && !this.hubSitesLoading) {
            this.hubSitesLoading = true;
            return ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
                method: "GET",
                siteUrl: SmartPortal.currentSession.tenantUrl,
                restUrl: "HubSites"
            }).then((rspData) => {
                this.setState({
                    hubSitesLoaded: true,
                    availableHubSites: [{ ID: null, Title: "" }].concat(rspData.d.results.filter(h => h.ID != this.props.siteId))
                }, () => {
                    this.hubSitesLoading = false;
                });
            });
        }
        return Promise.resolve();
    }

    private isFormValid(formData: ISiteDetailsFormData): boolean {
        return (
            (typeof formData.title === "string" && formData.title.length > 0) &&
            (this.props.editMode !== SiteDetailsEditMode.NewForm || (
                (formData.siteType === SiteProvisioningType.MSTeams &&
                    typeof formData.nickname === "string" && formData.nickname.length > 0 &&
                    !this.state.nicknameCheckLoading && this.state.nicknameCheckStatus && this.state.nicknameCheckStatus.valid) ||
                (formData.siteType === SiteProvisioningType.SPOSite &&
                    typeof formData.siteUrl === "string" && formData.siteUrl.length > 0 &&
                    !this.state.siteUrlCheckLoading && this.state.siteUrlCheckStatus && this.state.siteUrlCheckStatus.valid)
            )) &&
            (typeof formData.description === "string" && formData.description.length > 0) &&
            (typeof formData.primaryOwner === "string" && formData.primaryOwner.length > 0) &&
            (typeof formData.secondaryOwner === "string" && formData.secondaryOwner.length > 0) &&
            (typeof formData.primaryOwner === "string" && typeof formData.secondaryOwner === "string" && formData.primaryOwner.toLowerCase() !== formData.secondaryOwner.toLowerCase())
        );
    }

    private validateForm(formData: ISiteDetailsFormData): FormValidationResult {
        let isValid = true;
        let validationMessage: string = null;

        isValid = (typeof formData.title === "string" && formData.title.length > 0) &&
            (this.props.editMode !== SiteDetailsEditMode.NewForm || (
                (formData.siteType === SiteProvisioningType.MSTeams &&
                    typeof formData.nickname === "string" && formData.nickname.length > 0 &&
                    !this.state.nicknameCheckLoading && this.state.nicknameCheckStatus && this.state.nicknameCheckStatus.valid) ||
                (formData.siteType === SiteProvisioningType.SPOSite &&
                    typeof formData.siteUrl === "string" && formData.siteUrl.length > 0 &&
                    !this.state.siteUrlCheckLoading && this.state.siteUrlCheckStatus && this.state.siteUrlCheckStatus.valid)
            )) &&
            (typeof formData.description === "string" && formData.description.length > 0) &&
            (typeof formData.primaryOwner === "string" && formData.primaryOwner.length > 0) &&
            (typeof formData.secondaryOwner === "string" && formData.secondaryOwner.length > 0);

        if ((typeof formData.primaryOwner === "string" && typeof formData.secondaryOwner === "string" && formData.primaryOwner.toLowerCase() == formData.secondaryOwner.toLowerCase())) {
            isValid = false;
            validationMessage = formData.primaryOwner.length > 0 ? strings.SiteDetailsForm_ValidationMessage_PrimaryAndSecondaryOwnerNotUnique : null;
        }

        return {
            isValid: isValid,
            validationMessage: validationMessage
        };
    }

    private showChangeValues(): boolean {
        return this.props.formData.siteStatus == SiteStatus.WaitingForChangeApproval;
    }

    private renderTextValue(value: string, changeValue: string, inRed = false) {
        if (this.showChangeValues() && value != changeValue)
            return <>
                <div className={styles.FieldValueOld} style={(inRed ? { color: "Red" } : {})}>{value}</div>
                <div className={styles.FieldValueNew}>{changeValue}</div>
            </>;
        else
            return <span style={(inRed ? { color: "Red" } : {})}>{value}</span>;
    }

    private notifyFormUpdate(formData: ISiteDetailsFormData) {
        if (this.props.onUpdate) {
            let validationResult = this.validateForm(formData);
            this.props.onUpdate(formData, validationResult.isValid, validationResult.validationMessage);
        }
        //this.forceUpdate();
    }

    public render(): React.ReactElement<ISiteDetailsFormProps> {
        if (this.props.editMode === SiteDetailsEditMode.EditForm && this.props.formData.hubSiteId && this.state.availableHubSites && !this.state.availableHubSites.find(h => h.ID === this.props.formData.hubSiteId)) {
            this.props.formData.hubSiteId = null;
            this.props.formData.hubSiteTitle = "";
        }
        return (
            <div className={styles.SiteDetailsForm}>
                <div className={styles.FormColumnField}>
                    <div className={styles.FormRowField}>
                        <div className={styles.FormField}>
                            {this.props.editMode === SiteDetailsEditMode.NewForm && !this.props.preSelected ?
                                <div className={styles.FieldInput}>
                                    <Dropdown
                                        label={strings.SiteDetailsForm_SiteType}
                                        placeHolder={strings.SiteDetailsForm_SiteTypePlaceholder}
                                        selectedKey={SiteProvisioningType[this.props.formData.siteType]}
                                        required={true}
                                        options={Object.keys(strings.SiteDetailsForm_SiteTypes).filter(siteType => this.state.availableProvisionTemplates.findIndex(template => template.type.toString() === siteType) >= 0).map((type) => {
                                            return { key: SiteProvisioningType[type], text: strings.SiteDetailsForm_SiteTypes[type] };
                                        })}
                                        onChange={(evt, value) => {

                                            if (this.props.formData.siteType && this.props.formData.siteType == SiteProvisioningType[value.key]) {
                                                return;
                                            }

                                            this.notifyFormUpdate({
                                                ...this.props.formData,
                                                siteType: SiteProvisioningType[value.key],
                                                siteTemplate: null
                                            });
                                        }}
                                    />
                                </div> :
                                [
                                    <div className={styles.FieldTitle}>{strings.SiteDetailsForm_SiteType}</div>,
                                    <div className={styles.FieldValue}>{strings.SiteDetailsForm_SiteTypes[this.props.formData.siteType]}</div>
                                ]
                            }
                        </div>
                    </div>
                    <div className={styles.FormRowField}>
                        <div className={styles.FormField}>
                            {this.props.editMode === SiteDetailsEditMode.NewForm && !this.props.preSelected ?
                                <div className={styles.FieldInput}>
                                    <Dropdown
                                        label={strings.SiteDetailsForm_SiteTemplate}
                                        placeHolder={strings.SiteDetailsForm_SiteTemplatePlaceholder}
                                        disabled={this.props.formData.siteType == null}
                                        selectedKey={this.props.formData.siteTemplate ? this.props.formData.siteTemplate.id : null}
                                        required={true}
                                        options={this.state.availableProvisionTemplates.filter(t => t.type === this.props.formData.siteType).map(t => { return { key: t.id, text: t.name }; })}
                                        onChange={(evt, value) => {
                                            const provisionTemplate = this.state.availableProvisionTemplates.find(t => t.id === value.key);

                                            if (this.props.formData.siteTemplate && this.props.formData.siteTemplate.id === provisionTemplate.id) {
                                                return;
                                            }

                                            this.setState({
                                                siteUrlCheckStatus: null,
                                                nicknameCheckStatus: null
                                            });
                                            this.notifyFormUpdate(this.setDataFromTemplate(provisionTemplate));
                                        }}
                                    />
                                </div> :
                                [
                                    <div className={styles.FieldTitle}>{strings.SiteDetailsForm_SiteTemplate}</div>,
                                    <div className={styles.FieldValue}>{this.props.formData.siteTemplate.name}</div>
                                ]
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.FormField}>
                    {this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm ?
                        <div className={styles.FieldInput}>
                            <TextField prefix={this.provisionTemplateLoading == false && this.props.formData.siteTemplate && this.props.formData.siteTemplate.settings && this.props.formData.siteTemplate.settings.titlePrefix && this.props.formData.siteTemplate.settings.titlePrefix.length > 0 ? this.props.formData.siteTemplate.settings.titlePrefix : undefined}
                                onRenderLabel={(props, defaultRender) => this.onRenderTextFieldLabel(props, defaultRender, strings.SiteDetailsForm_Title_InfoText)} label={strings.SiteDetailsForm_Title} value={this.getTitleWithOutPrefix(this.props.formData.title)} disabled={this.props.formData.siteType == null || this.props.formData.siteTemplate == null} required={true} onChange={(evt, value) => {
                                    let title = this.getTitleWithPrefix(value);
                                    this.notifyFormUpdate({ ...this.props.formData, title: title });
                                }} />
                        </div> :
                        [
                            <div className={styles.FieldTitle}>{strings.SiteDetailsForm_Title}</div>,
                            <div className={styles.FieldValue}>{this.renderTextValue(this.props.formData.title, this.props.formData.changeItem?.title)}</div>
                        ]
                    }
                </div>
                {this.props.formData.siteType === SiteProvisioningType.MSTeams ?
                    <div className={styles.FormField}>
                        {this.props.editMode === SiteDetailsEditMode.NewForm ?
                            <div className={styles.UrlField}>
                                <div className={styles.FieldInput + " " + styles.HalfSize}>
                                    <TextField prefix={this.provisionTemplateLoading == false && this.props.formData.siteTemplate && this.props.formData.siteTemplate.settings && this.props.formData.siteTemplate.settings.prefix && this.props.formData.siteTemplate.settings.prefix.length > 0 ? this.props.formData.siteTemplate.settings.prefix : undefined}
                                        onRenderLabel={(props, defaultRender) => this.onRenderTextFieldLabel(props, defaultRender, strings.SiteDetailsForm_Nickname_InfoText)} label={strings.SiteDetailsForm_Nickname} value={this.getNickNameWithOutPrefix(this.props.formData.nickname)} disabled={this.props.formData.siteTemplate == null} required={true} onChange={(evt, value) => {
                                            let nickName = this.getNickNameWithPrefix(value);
                                            this.notifyFormUpdate({ ...this.props.formData, nickname: nickName });
                                            this.checkNickname(this.props.formData.title, nickName);
                                        }} />
                                </div>
                                {this.state.nicknameCheckLoading ?
                                    <div className={styles.UrlPreview}>
                                        <div className={styles.CheckSpinner}><Spinner size={SpinnerSize.medium} /></div>
                                        <span>{this.props.formData.nickname}</span>
                                    </div> :
                                    !this.state.nicknameCheckStatus ?
                                        <div /> :
                                        this.state.nicknameCheckStatus.valid ?
                                            <div className={styles.UrlPreview + " " + styles.CheckValid}>
                                                <span className={styles.CheckIcon}><Icon iconName="StatusCircleCheckmark" /></span>
                                                <span>{this.props.formData.nickname}</span>
                                            </div> :
                                            <div className={styles.UrlPreview + " " + styles.CheckFailed}>
                                                <span className={styles.CheckIcon}><Icon iconName="StatusCircleErrorX" /></span>
                                                <span>{this.state.nicknameCheckStatus.message ? this.state.nicknameCheckStatus.message : strings.SiteDetailsForm_UrlInvalid}</span>
                                            </div>
                                }
                            </div> :
                            [
                                <div className={styles.FieldTitle}>{strings.SiteDetailsForm_Nickname}</div>,
                                <div className={styles.FieldValue}>{this.props.formData.nickname}</div>
                            ]
                        }
                    </div> : null
                }
                {this.props.formData.siteType === SiteProvisioningType.SPOSite ?
                    <div className={styles.FormField}>
                        {this.props.editMode === SiteDetailsEditMode.NewForm ?
                            [
                                <div className={styles.UrlField}>
                                    <div className={styles.FieldInput + " " + styles.HalfSize}>
                                        <TextField prefix={this.provisionTemplateLoading == false && this.props.formData.siteTemplate && this.props.formData.siteTemplate.settings && this.props.formData.siteTemplate.settings.prefix && this.props.formData.siteTemplate.settings.prefix.length > 0 ? this.props.formData.siteTemplate.settings.prefix : undefined}
                                            onRenderLabel={(props, defaultRender) => this.onRenderTextFieldLabel(props, defaultRender, strings.SiteDetailsForm_Url_InfoText)} label={strings.SiteDetailsForm_Url} value={this.getSiteNameWithoutPrefix(this.props.formData.siteUrl)}
                                            disabled={this.props.formData.siteTemplate == null} required={true} onChange={(evt, value) => {
                                                const siteUrlBase = SmartPortal.currentSession.tenantUrl.replace(/^(https?:\/\/[^\/]+)(\/.*)?$/, "$1") + "/sites/";
                                                let siteUrl = this.getUrlWithPrefix(value);
                                                this.notifyFormUpdate({ ...this.props.formData, siteUrl: siteUrlBase + siteUrl });
                                                this.checkUrl(siteUrlBase, siteUrl);
                                            }} />
                                    </div>
                                    {this.state.siteUrlCheckLoading ?
                                        <div className={styles.UrlPreview}>
                                            <div className={styles.CheckSpinner}><Spinner size={SpinnerSize.medium} /></div>
                                            <span>{this.props.formData.siteUrl}</span>
                                        </div> :
                                        !this.state.siteUrlCheckStatus ?
                                            <div /> :
                                            this.state.siteUrlCheckStatus.valid ?
                                                <div className={styles.UrlPreview + " " + styles.CheckValid}>
                                                    <span className={styles.CheckIcon}><Icon iconName="StatusCircleCheckmark" /></span>
                                                    <span>{this.props.formData.siteUrl}</span>
                                                </div> :
                                                <div className={styles.UrlPreview + " " + styles.CheckFailed}>
                                                    <span className={styles.CheckIcon}><Icon iconName="StatusCircleErrorX" /></span>
                                                    <span>{strings.SiteDetailsForm_UrlInvalid}</span>
                                                </div>
                                    }
                                </div>,
                                !this.state.siteUrlCheckLoading && this.state.siteUrlCheckStatus && !this.state.siteUrlCheckStatus.valid && this.state.siteUrlCheckStatus.url ?
                                    <div className={styles.UrlProposal}>
                                        <span>{strings.format(strings.SiteDetailsForm_UrlProposal, this.state.siteUrlCheckStatus.url)}</span>
                                    </div>
                                    : null
                            ] :
                            [
                                <div className={styles.FieldTitle}>{strings.SiteDetailsForm_Url}</div>,
                                <div className={styles.FieldValue}>{this.props.formData.siteUrl}</div>
                            ]
                        }
                    </div> : null
                }
                <div className={styles.FormField}>
                    {this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm ?
                        <div className={styles.FieldInput}>
                            <TextField onRenderLabel={(props, defaultRender) => this.onRenderTextFieldLabel(props, defaultRender, strings.SiteDetailsForm_Description_InfoText)} label={strings.SiteDetailsForm_Description} value={this.props.formData.description} multiline rows={3} disabled={this.props.formData.siteType == null || this.props.formData.siteTemplate == null} required={true} onChange={(evt, value) => {
                                this.notifyFormUpdate({ ...this.props.formData, description: value });
                            }} />
                        </div> :
                        [
                            <div className={styles.FieldTitle}>{strings.SiteDetailsForm_Description}</div>,
                            <div className={styles.FieldValue}>{this.renderTextValue(this.props.formData.description, this.props.formData.changeItem?.description)}</div>
                        ]
                    }
                </div>
                {this.props.editMode === SiteDetailsEditMode.ViewForm ?
                    <div className={styles.FormField}>
                        <div className={styles.FieldTitle}>{strings.SiteDetailsForm_SiteStatusLabel}</div>
                        <div className={styles.FieldValue}>{strings.SiteDetailsForm_SiteStatus[this.props.formData.siteStatus]}</div>
                    </div> : null
                }
                {
                    this.showField("primaryOwner") ?
                        <div className={styles.FormField}>
                            {this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm ?
                                <div className={styles.FieldInput + " " + styles.HalfSize}>
                                    {this.renderUserEditField(this.props.formData.primaryOwner, "primaryOwner", strings.SiteDetailsForm_PrimaryOwner, this.props.formData.siteType === SiteProvisioningType.MSTeams, this.props.formData.siteType == null || this.props.formData.siteTemplate == null, (value) => {
                                        this.notifyFormUpdate({ ...this.props.formData, primaryOwner: value });
                                    }, strings.SiteDetailsForm_PrimaryOwner_InfoText)}
                                </div> :
                                [
                                    <div className={styles.FieldTitle}>{strings.SiteDetailsForm_PrimaryOwner}</div>,
                                    this.props.editMode !== SiteDetailsEditMode.SummaryForm ?
                                        this.renderUserAccountDisplayField(this.props.formData.primaryOwnerAccount, this.props.formData.changeItem?.primaryOwner) :
                                        this.renderUserDisplayField(this.props.formData.primaryOwner)
                                ]
                            }
                        </div> : null
                }
                {
                    this.showField("secondaryOwner") ?
                        <div className={styles.FormField}>
                            {this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm ?
                                <div className={styles.FieldInput + " " + styles.HalfSize}>
                                    {this.renderUserEditField(this.props.formData.secondaryOwner, "secondaryOwner", strings.SiteDetailsForm_SecondaryOwner, this.props.formData.siteType === SiteProvisioningType.MSTeams, this.props.formData.siteType == null || this.props.formData.siteTemplate == null, (value) => {
                                        this.notifyFormUpdate({ ...this.props.formData, secondaryOwner: value });
                                    }, strings.SiteDetailsForm_SecondaryOwner_InfoText)}
                                </div> :
                                [
                                    <div className={styles.FieldTitle}>{strings.SiteDetailsForm_SecondaryOwner}</div>,
                                    this.props.editMode !== SiteDetailsEditMode.SummaryForm ?
                                        this.renderUserAccountDisplayField(this.props.formData.secondaryOwnerAccount, this.props.formData.changeItem?.secondaryOwner) :
                                        this.renderUserDisplayField(this.props.formData.secondaryOwner)
                                ]
                            }
                        </div> : null
                }
                {
                    this.showField("members") && (this.props.editMode == SiteDetailsEditMode.NewForm  || (this.props.editMode == SiteDetailsEditMode.SummaryForm && !this.props.siteId ) || (this.props.editMode == SiteDetailsEditMode.ViewForm &&  !this.props.siteId)) ?
                        <div className={styles.FormField}>
                            {this.props.editMode === SiteDetailsEditMode.NewForm?
                                <div className={styles.FieldInput}>
                                    {this.renderPrincipalsEditField(this.props.formData.members, "members", strings.SiteDetailsForm_Members, this.props.formData.siteType === SiteProvisioningType.MSTeams, this.props.formData.siteType == null || this.props.formData.siteTemplate == null, (value) => {
                                        this.notifyFormUpdate({ ...this.props.formData, members: value });
                                    }, strings.SiteDetailsForm_Members_InfoText, this.props.formData.siteType === SiteProvisioningType.MSTeams ? false : true, false)}
                                </div> :
                                [
                                    <div className={styles.FieldTitle}>{strings.SiteDetailsForm_Members}</div>,
                                    this.props.editMode !== SiteDetailsEditMode.SummaryForm ?
                                        this.renderPrincipalDisplayField(this.props.formData.members, null) :
                                        this.renderPrincipalDisplayField(this.props.formData.members)
                                ]
                            }
                        </div> : null
                }
                {
                    this.showField("visitors") && this.props.formData.siteType === SiteProvisioningType.SPOSite  && (this.props.editMode == SiteDetailsEditMode.NewForm  || (this.props.editMode == SiteDetailsEditMode.SummaryForm && !this.props.siteId ) || (this.props.editMode == SiteDetailsEditMode.ViewForm &&  !this.props.siteId)) ?
                        <div className={styles.FormField}>
                            {this.props.editMode === SiteDetailsEditMode.NewForm?
                                <div className={styles.FieldInput}>
                                    {this.renderPrincipalsEditField(this.props.formData.visitors, "visitors", strings.SiteDetailsForm_Visitors, false, this.props.formData.siteType == null || this.props.formData.siteTemplate == null, (value) => {
                                        this.notifyFormUpdate({ ...this.props.formData, visitors: value });
                                    }, strings.SiteDetailsForm_Visitors_InfoText, true, false)}
                                </div> :
                                [
                                    <div className={styles.FieldTitle}>{strings.SiteDetailsForm_Visitors}</div>,
                                    this.props.editMode !== SiteDetailsEditMode.SummaryForm ?
                                        this.renderPrincipalDisplayField(this.props.formData.visitors, null) :
                                        this.renderPrincipalDisplayField(this.props.formData.visitors)
                                ]
                            }
                        </div> : null
                }
                {
                    this.showField("classification") ?
                        this.props.formData.classification || (this.state.availableClassifications && this.state.availableClassifications.length > 0) ?
                            <div className={styles.FormField}>
                                {this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm ?
                                    <div className={styles.FieldInput + " " + styles.HalfSize}>
                                        <Dropdown onRenderLabel={(props, defaultRender) => this.onRenderTextFieldLabel(props, defaultRender, strings.SiteDetailsForm_Classification_InfoText)} label={strings.SiteDetailsForm_Classification} options={this.state.availableClassifications.map(c => { return { key: c, text: c }; })} selectedKey={this.props.formData.classification ? this.props.formData.classification : this.state.defaultClassification} disabled={this.props.formData.siteType == null || this.props.formData.siteTemplate == null} required={true} onChange={(evt, option) => {
                                            this.notifyFormUpdate({ ...this.props.formData, classification: option.key as string });
                                        }} />
                                    </div> :
                                    [
                                        <div className={styles.FieldTitle}>{strings.SiteDetailsForm_Classification}</div>,
                                        <div className={styles.FieldValue}>{this.renderTextValue(this.props.formData.classification, this.props.formData.changeItem?.classification)}</div>
                                    ]
                                }
                            </div> : null
                        : null
                }
                {
                    this.showField("guestsAccess") ?
                        this.props.formData.siteType === SiteProvisioningType.MSTeams && ((this.props.formData.siteTemplate && this.props.formData.siteTemplate.settings.guestsAccessChangeable) || this.props.editMode === SiteDetailsEditMode.ViewForm || this.props.editMode === SiteDetailsEditMode.SummaryForm) ?
                            <div className={styles.FormField}>
                                {this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm ?
                                    <div className={styles.FieldInput}>
                                        {
                                            this.renderLabelWithInfoButton(null, strings.SiteDetailsForm_GuestAccess, strings.SiteDetailsForm_GuestAccess_InfoText, true, false)
                                        }
                                        <ChoiceGroup
                                            selectedKey={SiteInfoGuestsAccess[this.props.formData.guestsAccess]}
                                            required={true}
                                            options={Object.keys(strings.SiteDetailsForm_GuestAccesses).map((type) => {
                                                return { key: SiteInfoGuestsAccess[type], text: strings.SiteDetailsForm_GuestAccesses[type] };
                                            })}
                                            onChange={(evt, value) => {
                                                this.notifyFormUpdate({
                                                    ...this.props.formData,
                                                    guestsAccess: SiteInfoGuestsAccess[value.key],
                                                    sharingCapability: SiteInfoGuestsAccess[value.key] === SiteInfoGuestsAccess.Forbid ?
                                                        SiteSharingCapability.Disabled :
                                                        SiteSharingCapability.ExternalUserSharingOnly
                                                });
                                            }}
                                        />
                                    </div> :
                                    [
                                        <div className={styles.FieldTitle}>{strings.SiteDetailsForm_GuestAccess}</div>,
                                        <div className={styles.FieldValue}>{this.renderTextValue(strings.SiteDetailsForm_GuestAccesses[this.props.formData.guestsAccess], (this.props.formData.changeItem as ISiteInfoChangeTeam)?.guestsAccess ? strings.SiteDetailsForm_GuestAccesses[(this.props.formData.changeItem as ISiteInfoChangeTeam)?.guestsAccess] : null)}</div>
                                    ]
                                }
                            </div> : null : null
                }
                {
                    this.showField("sharingCapability") ?
                        this.props.formData.siteType === SiteProvisioningType.SPOSite && ((this.props.formData.siteTemplate && this.props.formData.siteTemplate.settings.sharingCapabilityChangeable) || this.props.editMode === SiteDetailsEditMode.ViewForm || this.props.editMode === SiteDetailsEditMode.SummaryForm) ?
                            <div className={styles.FormField}>
                                {this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm ?
                                    <div className={styles.FieldInput}>
                                        {
                                            this.renderLabelWithInfoButton(null, strings.SiteDetailsForm_SharingCapability, strings.SiteDetailsForm_SharingCapability_InfoText, true, false)
                                        }
                                        <ChoiceGroup
                                            selectedKey={SiteSharingCapability[this.props.formData.sharingCapability]}
                                            required={true}
                                            options={Object.keys(strings.SiteDetailsForm_SharingCapabilities).map((type) => {
                                                return { key: SiteSharingCapability[type], text: strings.SiteDetailsForm_SharingCapabilities[type] };
                                            })}
                                            onChange={(evt, value) => {
                                                this.notifyFormUpdate({ ...this.props.formData, sharingCapability: SiteSharingCapability[value.key] });
                                            }}
                                        />
                                    </div> :
                                    [
                                        <div className={styles.FieldTitle}>{strings.SiteDetailsForm_SharingCapability}</div>,
                                        <div className={styles.FieldValue}>{this.renderTextValue(strings.SiteDetailsForm_SharingCapabilities[this.props.formData.sharingCapability], this.props.formData.changeItem?.sharingCapability ? strings.SiteDetailsForm_SharingCapabilities[this.props.formData.changeItem?.sharingCapability] : null)}</div>
                                    ]
                                }
                            </div> : null : null
                }
                {
                    this.showField("visibility") ?
                        <div className={styles.FormField}>
                            {this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm ?
                                <div className={styles.FieldInput}>
                                    {
                                        this.renderLabelWithInfoButton(null, strings.SiteDetailsForm_Visibility, strings.SiteDetailsForm_Visibility_InfoText, true, this.props.formData.siteType == null || this.props.formData.siteTemplate == null)
                                    }
                                    <ChoiceGroup
                                        selectedKey={SiteInfoVisibility[this.props.formData.visibility]}
                                        disabled={this.props.formData.siteType == null || this.props.formData.siteTemplate == null}
                                        required={true}
                                        options={Object.keys(strings.SiteDetailsForm_Visibilities).map((type) => {
                                            return { key: SiteInfoVisibility[type], text: strings.SiteDetailsForm_Visibilities[type] };
                                        })}
                                        onChange={(evt, value) => {
                                            this.notifyFormUpdate({ ...this.props.formData, visibility: SiteInfoVisibility[value.key] });
                                        }}
                                    />
                                </div> :
                                [
                                    <div className={styles.FieldTitle}>{strings.SiteDetailsForm_Visibility}</div>,
                                    <div className={styles.FieldValue}>{this.renderTextValue(strings.SiteDetailsForm_Visibilities[this.props.formData.visibility], this.props.formData.changeItem?.visibility ? strings.SiteDetailsForm_Visibilities[this.props.formData.changeItem.visibility] : null)}</div>
                                ]
                            }
                        </div> : null
                }
                {
                    this.showField("language") ?
                        this.props.formData.siteType === SiteProvisioningType.SPOSite ?
                            <div className={styles.FormField}>
                                {this.props.editMode === SiteDetailsEditMode.NewForm ?
                                    <div className={styles.FieldInput + " " + styles.HalfSize}>
                                        <Dropdown
                                            label={strings.SiteDetailsForm_Language}
                                            onRenderLabel={(props, defaultRender) => this.onRenderTextFieldLabel(props, defaultRender, strings.SiteDetailsForm_Language_InfoText)}
                                            selectedKey={SiteLanguage[this.props.formData.language]}
                                            disabled={this.props.formData.siteTemplate == null}
                                            required={true}
                                            options={Object.keys(strings.SiteDetailsForm_Languages).map((type) => {
                                                return { key: SiteLanguage[type], text: strings.SiteDetailsForm_Languages[type] };
                                            }).sort((a, b) => a.text.localeCompare(b.text))}
                                            onChange={(evt, value) => {
                                                this.notifyFormUpdate({ ...this.props.formData, language: SiteLanguage[value.key] });
                                            }}
                                        />
                                    </div> :
                                    [
                                        <div className={styles.FieldTitle}>{strings.SiteDetailsForm_Language}</div>,
                                        <div className={styles.FieldValue}>{strings.SiteDetailsForm_Languages[this.props.formData.language]}</div>
                                    ]
                                }
                            </div> : null : null
                }
                {
                    this.showField("timezone") ?
                        <div className={styles.FormField}>
                            {this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm ?
                                <div className={styles.FieldInput}>
                                    <Dropdown
                                        label={strings.SiteDetailsForm_Timezone}
                                        onRenderLabel={(props, defaultRender) => this.onRenderTextFieldLabel(props, defaultRender, strings.SiteDetailsForm_Timezone_InfoText)}
                                        selectedKey={SiteTimeZone[this.props.formData.timeZoneId]}
                                        disabled={this.props.formData.siteType == null || this.props.formData.siteTemplate == null}
                                        required={true}
                                        options={Object.keys(strings.SiteDetailsForm_Timezones).map((zone) => {
                                            return { key: SiteTimeZone[zone], text: strings.SiteDetailsForm_Timezones[zone] };
                                        }).sort((a, b) => a.text.localeCompare(b.text))}
                                        onChange={(evt, value) => {
                                            this.notifyFormUpdate({ ...this.props.formData, timeZoneId: SiteTimeZone[value.key] });
                                        }}
                                    />
                                </div> :
                                [
                                    <div className={styles.FieldTitle}>{strings.SiteDetailsForm_Timezone}</div>,
                                    <div className={styles.FieldValue}>{this.renderTextValue(strings.SiteDetailsForm_Timezones[this.props.formData.timeZoneId], this.props.formData.changeItem?.timeZoneId ? strings.SiteDetailsForm_Timezones[this.props.formData.changeItem?.timeZoneId] : null)}</div>
                                ]
                            }
                        </div> : null
                }
                {
                    this.showField("hubSite") ?
                        ((this.props.formData.hubSiteId || this.props.formData.changeItem?.hubSiteId) && this.state.availableHubSites && this.state.availableHubSites.length > 1) || (this.state.availableHubSites && this.state.availableHubSites.length > 1 && (this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm)) ?
                            <div className={styles.FormField}>
                                {this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm ?
                                    <div className={styles.FieldInput + " " + styles.HalfSize}>
                                        <Dropdown onRenderLabel={(props, defaultRender) => this.onRenderTextFieldLabel(props, defaultRender, strings.SiteDetailsForm_HubSite_InfoText)} label={strings.SiteDetailsForm_HubSite} options={this.state.availableHubSites.map(h => { return { key: h.ID, text: h.Title }; })} selectedKey={this.props.formData.hubSiteId} disabled={this.props.formData.siteType == null || this.props.formData.siteTemplate == null} onChange={(evt, option) => {
                                            this.notifyFormUpdate({ ...this.props.formData, hubSiteId: option.key as string, hubSiteTitle: option.text });
                                        }} />
                                    </div> :
                                    [
                                        <div className={styles.FieldTitle}>{strings.SiteDetailsForm_HubSite}</div>,
                                        <div className={styles.FieldValue}>{
                                            this.state.availableHubSites.find(h => h.ID === this.props.formData.hubSiteId) ? 
                                                this.renderTextValue(this.state.availableHubSites.find(h => h.ID === this.props.formData.hubSiteId)?.Title, this.state.availableHubSites.find(h => h.ID === this.props.formData.changeItem?.hubSiteId)?.Title) :
                                                this.renderTextValue(this.props.formData.hubSiteTitle, this.state.availableHubSites.find(h => h.ID === this.props.formData.changeItem?.hubSiteId)?.Title, true)
                                        }
                                        </div>
                                    ]
                                }
                            </div> : null : null
                }
                {
                    this.showField("registerHubSite") ?
                        (this.props.formData.registerHubSite || this.props.formData.changeItem?.registerHubSite) || (this.state.isAdmin && (this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm)) ?
                            <div className={styles.FormField}>
                                {this.props.editMode === SiteDetailsEditMode.NewForm || this.props.editMode === SiteDetailsEditMode.EditForm ?
                                    <div className={styles.FieldInput + " " + styles.HalfSize}>
                                        <Checkbox onRenderLabel={(props, defaultRender) => this.onRenderTextFieldLabel(props, defaultRender, strings.SiteDetailsForm_RegisterHubSite_InfoText)} label={strings.SiteDetailsForm_RegisterHubSite} checked={this.props.formData.registerHubSite} disabled={this.props.formData.siteType == null || this.props.formData.siteTemplate == null} onChange={(evt, checked) => {
                                            this.notifyFormUpdate({ ...this.props.formData, registerHubSite: checked });
                                        }} />
                                    </div> :
                                    [
                                        <div className={styles.FieldTitle}>{strings.SiteDetailsForm_RegisterHubSite}</div>,
                                        <div className={styles.FieldValue}>{this.renderTextValue(this.props.formData.registerHubSite ? strings.SiteDetailsForm_IsHubSite : strings.SiteDetailsForm_IsNotHubSite, this.props.formData.changeItem?.registerHubSite ? strings.SiteDetailsForm_IsHubSite : strings.SiteDetailsForm_IsNotHubSite)}</div>
                                    ]
                                }
                            </div> : null : null
                }
            </div>
        );
    }
    private getTitleWithOutPrefix(title: string): string {
        if (this.provisionTemplateLoading == false && this.props.formData.siteTemplate && this.props.formData.siteTemplate.settings && this.props.formData.siteTemplate.settings.titlePrefix) {
            if (title.startsWith(this.props.formData.siteTemplate.settings.titlePrefix)) {
                title = title.substring(this.props.formData.siteTemplate.settings.titlePrefix.length);
            }
        }
        return title;
    }
    private getTitleWithPrefix(title: string) {
        if (!title)
            return title;
        if (this.provisionTemplateLoading == false && this.props.formData.siteTemplate && this.props.formData.siteTemplate.settings && this.props.formData.siteTemplate.settings.titlePrefix) {
            title = `${this.props.formData.siteTemplate.settings.titlePrefix}${title}`
        }
        return title;
    }
    private getNickNameWithOutPrefix(nickname: string): string {
        return this.getSiteNameWithoutPrefix(nickname);
    }
    private getNickNameWithPrefix(value: string) {
        return this.getUrlWithPrefix(value);
    }

    public renderPrincipalsEditField(value: IPrincipal[], key: string, label: string, filterUsersWithoutLicences: boolean, disabled: boolean, onChange: (value) => void, infoTooltip?: string, allowGroups?: boolean, required?: boolean): React.ReactElement[] {
        const items: IPeoplePickerReference[] = [];

        if (value && value.length > 0) {

            value.forEach(item => {
                items.push({
                    objectType: item.principalType === PrincipalType.User ? PeoplePickerItemType.User : PeoplePickerItemType.Group,
                    displayName: item.displayName,
                    fieldName: "userPrincipalName",
                    fieldValue: item.userPrincipalName,
                });
            });
        }
        this.memberPickerDataSource.FilterUsersWithoutLicences = filterUsersWithoutLicences ? ["TEAMS1"] : null;
        this.memberPickerDataSource.AllowGroups = allowGroups ?? false;


        return [
            this.renderLabelWithInfoButton(key, label, infoTooltip, required === null || required === undefined ? true : required , disabled),
            <PeoplePicker key={`${key}${this.initialized && this.props.formData.siteTemplate ? this.props.formData.siteTemplate.id : ""}`} inputId={"input-" + key} datasource={this.memberPickerDataSource} uniqueItemsOnly={true} defaultItems={items} itemLimit={20} disabled={disabled} onChange={
                (items) => {
                    onChange(items.map(e => ({
                        graphId: e.objectId,
                        userPrincipalName: e.userPrincipal,
                        displayName: e.displayName,
                        mail: e.mail,
                        principalType: e.objectType == PeoplePickerItemType.Group ? PrincipalType.Group : PrincipalType.User
                    })) as IPrincipal[]);
                }} />
        ];
    }

    public renderUserEditField(value: string, key: string, label: string, filterUsersWithoutLicences: boolean, disabled: boolean, onChange: (value) => void, infoTooltip?: string): React.ReactElement[] {
        const items: IPeoplePickerReference[] = [];
        if (value) {
            items.push({
                objectType: PeoplePickerItemType.User,
                displayName: value,
                fieldName: "userPrincipalName",
                fieldValue: value,
            });
        }
        this.peoplePickerDataSource.FilterUsersWithoutLicences = filterUsersWithoutLicences ? ["TEAMS1"] : null;

        return [
            this.renderLabelWithInfoButton(key, label, infoTooltip, true, disabled),
            <PeoplePicker key={`${key}${this.initialized && this.props.formData.siteTemplate ? this.props.formData.siteTemplate.id : ""}`} inputId={"input-" + key} datasource={this.peoplePickerDataSource} defaultItems={items} disabled={disabled} onChange={
                (items) => {
                    onChange(items.length ? (items[0].userPrincipal || items[0].displayName) : null);
                }} />
        ];
    }

    public renderPrincipalDisplayField(principals: IPrincipal[], changedPrincipals?: IPrincipal[]): React.ReactElement {
        if(!principals || (principals && principals.length == 0))
        {
            return <span> - </span>;
        }
        return <span> {principals.map(e => e.displayName).join("; ")} </span>;
    }

    public renderUserDisplayField(account: string): React.ReactElement {
        return <span>{account}</span>;
    }

    public renderUserAccountDisplayField(account: IAccount, changedAccount: string): React.ReactElement {
        let control: React.ReactElement = null;
        if (account) {
            if (this.showChangeValues() && account.userPrincipalName != changedAccount) {
                control = <div className={styles.FieldValue}>
                    <div className={styles.FieldValueOld}>{account.displayName} ({account.userPrincipalName})</div>
                    <div className={styles.FieldValueNew}>{changedAccount}</div>
                </div>;
            }
            else {
                control = <div className={styles.FieldValue}>{account.displayName} ({account.userPrincipalName})</div>;
                if (!account.isActive)
                    control = <div className={styles.Warning}>
                        {control}
                        <TooltipHost content={strings.SiteDetailsForm_InactiveOwnerWarning}>
                            <Icon iconName="Warning" style={{ fontSize: "1.1rem", color: "orange" }} />
                        </TooltipHost>
                    </div>;
            }
        }
        return control;
    }

    private isValidAlias(alias: string): boolean {
        if (!this.isValidAliasFirstChar(alias.charAt(0))) {
            return false;
        }
        for (let n = 0; n < alias.length; n++) {
            if (!this.isValidAliasChar(alias.charAt(n))) {
                return false;
            }
        }
        return true;
    }

    private isValidAliasChar(char: string): boolean {
        return this.isValidAliasFirstChar(char) || "_" === char;
    }

    private isValidAliasFirstChar(char: string): boolean {
        return char >= "a" && char <= "z" || char >= "A" && char <= "Z" || char >= "0" && char <= "9" || "-" === char;
    }

    private checkNickname(displayName: string, nickname: string): void {
        this.setState({ nicknameCheckLoading: true });
        this.notifyFormUpdate({ ...this.props.formData, nickname: nickname });
        this.debounceNicknameCheck()
            .then(() => this.runNicknameCheck(displayName, nickname))
            .then((urlStatus) => {
                this.setState({
                    nicknameCheckLoading: false,
                    nicknameCheckStatus: urlStatus
                });
                this.notifyFormUpdate({ ...this.props.formData, nickname: nickname });
            });
    }

    private debounceNicknameCheck(): Promise<void> {
        return new Promise((resolve) => {
            if (this.nicknameCheckTimer)
                window.clearTimeout(this.nicknameCheckTimer);

            this.nicknameCheckTimer = window.setTimeout(() => {
                this.nicknameCheckTimer = null;
                resolve();
            }, 500);
        });
    }

    private runNicknameCheck(displayName: string, nickname: string): Promise<ISiteDetailsNicknameStatus> {

        if (!this.isValidAlias(nickname))
            return Promise.resolve({ valid: false, message: "The group email name can't contain symbols." } as ISiteDetailsNicknameStatus);

        return ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
            method: "GET",
            version: "v1.0",
            siteUrl: SmartPortal.currentSession.tenantUrl,
            restUrl: "SP.Directory.DirectorySession/ValidateGroupName(displayName=" + encodeURIComponent("'" + displayName + "'") + ",alias=" + encodeURIComponent("'" + nickname + "'") + ")"
        }).then((rsp) => {
            const error = rsp.d.ValidateGroupName.AliasErrorDetails;
            if (error) {
                return {
                    valid: false,
                    message: error.ValidationErrorMessage
                };
            }
            return {
                valid: true,
            };
        }, (err) => {
            return {
                valid: false
            };
        });
    }

    private getUrlWithPrefix(urlValue: string): string {
        if (!urlValue)
            return urlValue;

        if (this.provisionTemplateLoading == false && this.props.formData.siteTemplate && this.props.formData.siteTemplate.settings && this.props.formData.siteTemplate.settings.prefix) {
            let siteUrlBase = SmartPortal.currentSession.tenantUrl.replace(/^(https?:\/\/[^\/]+)(\/.*)?$/, "$1") + "/sites/";
            if (urlValue.indexOf(siteUrlBase) >= 0) {
                if (urlValue) urlValue = urlValue.replace(siteUrlBase, "");
                urlValue = `${siteUrlBase}${this.props.formData.siteTemplate.settings.prefix}${urlValue}`
            }

            return `${this.props.formData.siteTemplate.settings.prefix}${urlValue}`
        }
        return urlValue;
    }
    private setDataFromTemplate(provisionTemplate: IProvisionTemplate): ISiteDetailsFormData {
        return {
            ...this.props.formData,
            siteTemplate: provisionTemplate,
            primaryOwner: provisionTemplate.primaryOwner ? provisionTemplate.primaryOwner.userPrincipalName : this.props.formData.primaryOwner,
            secondaryOwner: provisionTemplate.secondaryOwner ? provisionTemplate.secondaryOwner.userPrincipalName : this.props.formData.secondaryOwner,
            classification: provisionTemplate.settings.classification ? provisionTemplate.settings.classification : this.props.formData.classification ? this.props.formData.classification : this.state.defaultClassification,
            guestsAccess: provisionTemplate.settings.guestsAccess ? provisionTemplate.settings.guestsAccess : this.props.formData.guestsAccess,
            sharingCapability: provisionTemplate.settings.sharingCapability ? provisionTemplate.settings.sharingCapability : this.props.formData.sharingCapability,
            visibility: provisionTemplate.settings.visibility ? provisionTemplate.settings.visibility : this.props.formData.visibility,
            timeZoneId: provisionTemplate.settings.timezone ? provisionTemplate.settings.timezone : this.props.formData.timeZoneId,
            language: provisionTemplate.settings.language ? provisionTemplate.settings.language : this.props.formData.language,
            hubSiteId: provisionTemplate.settings.hubSite ? provisionTemplate.settings.hubSite.id : null,
            hubSiteTitle: provisionTemplate.settings.hubSite ? provisionTemplate.settings.hubSite.title : "",
            registerHubSite: provisionTemplate.settings.registerHubSite != null ? provisionTemplate.settings.registerHubSite : false,
            siteUrl: null,
            nickname: null
        }
    }

    private getSiteNameWithoutPrefix(urlValue: string): string {
        if (!urlValue)
            return urlValue;
        const siteUrlBase = SmartPortal.currentSession.tenantUrl.replace(/^(https?:\/\/[^\/]+)(\/.*)?$/, "$1") + "/sites/";
        if (urlValue.indexOf(siteUrlBase) >= 0) {
            if (urlValue) urlValue = urlValue.replace(siteUrlBase, "");
        }

        if (this.provisionTemplateLoading == false && this.props.formData.siteTemplate && this.props.formData.siteTemplate.settings && this.props.formData.siteTemplate.settings.prefix) {


            if (urlValue.startsWith(this.props.formData.siteTemplate.settings.prefix)) {
                urlValue = urlValue.substring(this.props.formData.siteTemplate.settings.prefix.length);
            }
        }
        return urlValue;
    }

    private showField(fieldName: string): boolean {
        let showField = true;

        if (this.props.editMode === SiteDetailsEditMode.SummaryForm || this.props.editMode == SiteDetailsEditMode.ViewForm)
            return showField;

        if (this.props.formData.siteTemplate && this.props.formData.siteTemplate.settings && `${fieldName}Changeable` in this.props.formData.siteTemplate.settings) {
            const changeable = this.props.formData.siteTemplate.settings[`${fieldName}Changeable`];
            if (changeable === undefined || changeable === null)
                return showField

            showField = changeable;
        }
        return showField;
    }

    private checkUrl(baseUrl: string, siteName: string): void {
        this.setState({ siteUrlCheckLoading: true });
        this.notifyFormUpdate({ ...this.props.formData, siteUrl: baseUrl + siteName });
        this.debounceUrlCheck()
            .then(() => this.runUrlCheck(baseUrl, siteName))
            .then((urlStatus) => {
                this.setState({
                    siteUrlCheckLoading: false,
                    siteUrlCheckStatus: urlStatus
                });
                this.notifyFormUpdate({ ...this.props.formData, siteUrl: baseUrl + siteName });
            });
    }

    private debounceUrlCheck(): Promise<void> {
        return new Promise((resolve) => {
            if (this.urlCheckTimer)
                window.clearTimeout(this.urlCheckTimer);

            this.urlCheckTimer = window.setTimeout(() => {
                this.urlCheckTimer = null;
                resolve();
            }, 500);
        });
    }

    private runUrlCheck(baseUrl: string, siteName: string): Promise<ISiteDetailsUrlStatus> {

        if (!this.isValidAlias(siteName))
            return Promise.resolve({ valid: false, message: "The site address can't contain symbols." } as ISiteDetailsNicknameStatus);

        return ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
            method: "GET",
            version: "v1.0",
            siteUrl: SmartPortal.currentSession.tenantUrl,
            restUrl: "GroupSiteManager/GetValidSiteUrlFromAlias?alias=" + encodeURIComponent("'" + siteName + "'") + "&isTeamSite=true"
        }).then((rsp) => {
            let rspUrl = rsp.d.GetValidSiteUrlFromAlias as string;
            let reqUrl = baseUrl + siteName;
            return {
                valid: (rspUrl.toLowerCase() === reqUrl.toLowerCase()),
                url: rspUrl
            };
        }, (err) => {
            return {
                valid: false
            };
        });
    }

    private onRenderTextFieldLabel(fieldProps: ITextFieldProps | IDropdownProps | ICheckboxProps, defaultRender: IRenderFunction<ITextFieldProps | IDropdownProps | ICheckboxProps>, infoTooltip?: string): JSX.Element {
        return (
            <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                <span>{defaultRender(fieldProps)}</span>
                {infoTooltip && !fieldProps.disabled ? <TooltipHost content={infoTooltip} directionalHint={DirectionalHint.bottomRightEdge}><span><Icon iconName="Info" /></span></TooltipHost> : null}
            </Stack>
        )
    }

    private renderLabelWithInfoButton(key: string, label: string, infoTooltip: string, required: boolean, disabled: boolean): JSX.Element {
        return (
            <Stack horizontal verticalAlign="center" horizontalAlign="space-between" tokens={{ padding: "0 10px 0 0" }}>
                <Label htmlFor={key ? "input-" + key : null} required={required} disabled={disabled}>{label}</Label>
                {infoTooltip && !disabled ? <TooltipHost content={infoTooltip} directionalHint={DirectionalHint.bottomRightEdge}><span><Icon iconName="Info" /></span></TooltipHost> : null}
            </Stack>
        )
    }
}
