import * as React from 'react';
import BrowserRestrictionStrings from './loc/BrowserRestrictionStrings';
import styles from "./BrowserRestrictionStyle.scss";
import { SmartPortal } from '../../SmartPortal';

export interface IBrowserRestrictionPageProps {
    isDialog?: boolean;
}

export interface IBrowserRestrictionPageState {
    renderedDialog: boolean;
}

export class BrowserRestrictionPage extends React.Component<IBrowserRestrictionPageProps, IBrowserRestrictionPageState> {

    constructor(props: IBrowserRestrictionPageProps, state: IBrowserRestrictionPageState) {
        super(props);

        this.state = {
            renderedDialog: false
        };
    }

    public componentDidMount(): void {
    }

    public render(): React.ReactElement<IBrowserRestrictionPageProps> {
        return (
            <div className={styles.BrowserRestrictionPage}>
                <div className={styles.BrowserRestrictionHeader}>
                <div className={styles.BrowserRestrictionHeaderLayer}>
                        <img src={SmartPortal.buildPortalUrl("/images/BrowserRestriction.png")} />
                    </div>
                </div>
                    <div className={styles.BrowserRestrictionBody}>
                    <h2>{BrowserRestrictionStrings.BrowserRestrictionPage_Intro}</h2>
                    <h1>{BrowserRestrictionStrings.BrowserRestrictionPage_Advice}</h1>
                </div>
            </div>
        );
    }

}
