import { StreamSource, IStreamSource, IStreamDataRequest, IStreamDataResponse, IStreamLazyResponse, IStreamLazyField, BaseCard, DocumentCard, SiteCard, NewsCard, OtherCard } from "..";
import { GraphBatchService, IGraphRequest, ISpRestRequest } from "../../business/GraphBatchService";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { IUserInfo } from "../../models/UserInfo";
import { MapBuilder } from "../../utils/MapBuilder";
import { ServiceLoader } from '../../business/ServiceLoader';
import { SmartPortal } from '../../../SmartPortal';

export abstract class TrendingDataSource<T> {
    public lazyFields: Map<string, IStreamLazyField>;

    public constructor() {
        this.lazyFields = MapBuilder.NewMap<string, IStreamLazyField>([
            ["fileDetails", {
                cache: true,
                serverCache: true,
                cacheTime: 60 * 60 * 24 * 7,
                loadFn: this.loadFileDetails.bind(this),
            }],
            ["hotDetails", {
                cache: true,
                serverCache: true,
                cacheTime: 60 * 30,
                loadFn: this.loadHotDetails.bind(this),
            }],
            ["rootDetails", {
                cache: true,
                serverCache: true,
                cacheTime: 60 * 60 * 24 * 7,
                loadFn: this.loadRootDetails.bind(this)
            }],
            ["siteDetails", {
                cache: true,
                serverCache: true,
                cacheTime: 60 * 60 * 24 * 7,
                loadFn: this.loadSiteDetails.bind(this)
            }],
            ["parentItems", {
                cache: true,
                serverCache: true,
                cacheTime: 60 * 60 * 24 * 2,
                loadFn: this.loadParentItems.bind(this)
            }],
            ["parentSites", {
                cache: true,
                serverCache: true,
                cacheTime: 60 * 60 * 24 * 2,
                loadFn: this.loadParentSites.bind(this)
            }],
            ["thumbnailUrl", {
                cache: true,
                serverCache: true,
                cacheTime: 60 * 60 * 1,
                loadFn: this.loadThumbnailUrl.bind(this)
            }],
            ["analytics", {
                cache: true,
                serverCache: true,
                cacheTime: 60 * 60 * 2,
                loadFn: this.loadAnalytics.bind(this)
            }],
            ["activities", {
                cache: true,
                cacheTime: 60 * 30,
                serverCache: true,
                loadFn: this.loadActivities.bind(this)
            }],
            ["comments", {
                cache: true,
                cacheTime: 60 * 30,
                serverCache: true,
                loadFn: this.loadComments.bind(this)
            }],
            ["commentCount", {
                cache: true,
                cacheTime: 60 * 30,
                serverCache: true,
                loadFn: this.loadCommentCount.bind(this)
            }],
            ["likeCount", {
                cache: true,
                cacheTime: 60 * 30,
                serverCache: true,
                loadFn: this.loadLikeCount.bind(this)
            }],
            ["versions", {
                cache: true,
                cacheTime: 60 * 30,
                serverCache: true,
                loadFn: this.loadVersions.bind(this)
            }],
            ["bannerUrl", {
                cache: true,
                serverCache: true,
                cacheTime: 60 * 60 * 1,
                loadFn: this.loadBannerUrl.bind(this)
            }]
        ]);
    }

    private getUserInfo(userData: any): IUserInfo {
        let userInfo: IUserInfo;
        if (userData && userData.user) {
            userInfo = {
                name: userData.user.displayName,
                mail: userData.user.email,
                upn: userData.user.userPrincipalName,
                country: ""
            };
        }
        else if (userData && userData.name) {
            userInfo = {
                name: userData.name,
                mail: userData.email,
                upn: userData.loginName,
                country: ""
            };
        }
        else
            userInfo = null;
        return userInfo;
    }

    protected abstract parseResponseCard(stream: StreamSource, item: T): BaseCard;

    protected parseDataResponse(stream: StreamSource, request: IStreamDataRequest, res: any, hadSkipToken: boolean): IStreamDataResponse {
        let nextPageToken: string = null;
        if ((nextPageToken = res["@odata.nextLink"])) {
            let nextPageUrl = new URL(nextPageToken);
            nextPageToken = nextPageUrl.searchParams.get("$skiptoken");
        }

        let cards = (res.value as Array<T>).map((item) => {
            return this.parseResponseCard(stream, item);
        });

        let hasMoreData: boolean;
        if (hadSkipToken) {
            hasMoreData = !!nextPageToken;
        }
        else if (request.cardLimit > 0) {
            hasMoreData = (cards.length >= request.cardLimit);
        }
        else {
            hasMoreData = false;
        }

        return {
            cards: cards,
            totalCards: (!hasMoreData ? request.cardIndex + cards.length : 0),
            moreCards: hasMoreData,
            streamObj: {
                skipToken: nextPageToken
            }
        };
    }

    private getServerCacheArg(fieldName: string): number {
        let fieldCfg = this.lazyFields.get(fieldName);
        if(!fieldCfg || !fieldCfg.serverCache || fieldCfg.cacheTime <= 0)
            return null;

        return fieldCfg.cacheTime;
    }

    private loadFileDetails(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        let itemPath: string;
        if(cardRef.itemPath)
            itemPath = cardRef.itemPath;
        else if(cardRef.driveId && cardRef.itemId)
            itemPath = "drives/" + cardRef.driveId + "/items/" + cardRef.itemId;
        else {
            let siteKey = cardRef.siteUrl.replace(/^https?:\/\/([^\/]+)(\/.*)?$/, "$1") + "," + cardRef.siteId + "," + cardRef.webId;
            itemPath = "sites/" + siteKey + "/lists/" + cardRef.listId + "/items/" + cardRef.listItemId + "/driveItem";
        }

        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            path: itemPath + "?$expand=listItem&$select=id,name,listItem,sharepointIds,webUrl,webDavUrl,parentReference",
            version: "v1.0",
            cache: this.getServerCacheArg("fileDetails")
        }).then((rsp) => {
            let parentRef = null;
            if (rsp.parentReference && rsp.parentReference.driveId && rsp.parentReference.id) {
                parentRef = {
                    itemRef: "drives/" + rsp.parentReference.driveId + "/items/" + rsp.parentReference.id,
                    itemPath: rsp.parentReference.path,
                    itemType: "file",
                    driveId: rsp.parentReference.driveId,
                    itemId: rsp.parentReference.id,
                };
            }
            else if (rsp.parentReference) {
                parentRef = {
                    itemRef: rsp.parentReference.id,
                    itemPath: rsp.parentReference.path,
                    itemType: rsp.parentReference.driveType,
                    driveId: rsp.parentReference.driveId,
                    itemId: rsp.parentReference.id
                };
            }

            return {
                data: {
                    name: rsp.name,
                    davUrl: rsp.webDavUrl,
                    webUrl: rsp.webUrl,
                    spItem: {
                        siteUrl: rsp.sharepointIds.siteUrl,
                        webId: rsp.sharepointIds.webId,
                        siteId: rsp.sharepointIds.siteId,
                        listId: rsp.sharepointIds.listId,
                        itemId: rsp.listItem.id,
                    },
                    parentRef: parentRef,
                }
            };
        });
    }

    private loadRootDetails(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            path: (cardRef.itemPath ? cardRef.itemPath.replace(/:$/, "") : "drives/" + cardRef.driveId + "/items/" + cardRef.itemId) + "?$select=id,name,sharepointIds,webUrl,webDavUrl,parentReference",
            version: "v1.0",
            cache: this.getServerCacheArg("rootDetails")
        }).then((rsp) => {
            let parentRef = null;
            if (rsp.parentReference && rsp.parentReference.driveId && rsp.parentReference.id) {
                parentRef = {
                    itemRef: "drives/" + rsp.parentReference.driveId + "/items/" + rsp.parentReference.id,
                    itemPath: rsp.parentReference.path,
                    itemType: "file",
                    driveId: rsp.parentReference.driveId,
                    itemId: rsp.parentReference.id
                };
            }
            else if (rsp.parentReference) {
                parentRef = {
                    itemRef: rsp.parentReference.id,
                    itemPath: rsp.parentReference.path,
                    itemType: rsp.parentReference.driveType,
                    driveId: rsp.parentReference.driveId,
                    itemId: rsp.parentReference.id
                };
            }

            return {
                data: {
                    name: rsp.name,
                    davUrl: rsp.webDavUrl,
                    webUrl: rsp.webUrl,
                    spItem: {
                        siteUrl: rsp.sharepointIds.siteUrl,
                        webId: rsp.sharepointIds.webId,
                        siteId: rsp.sharepointIds.siteId,
                        listId: rsp.sharepointIds.listId,
                    },
                    parentRef: parentRef
                }
            };
        });
    }

    private loadSiteDetails(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            path: "sites/" + cardRef.siteUrl.replace(/^https?:\/\/([^\/]+)(\/.*)?$/, "$1:$2") + "?$select=id,name,displayName,sharepointIds,root,webUrl",
            version: "v1.0",
            cache: this.getServerCacheArg("siteDetails")
        }).then((rsp) => {
            return {
                data: {
                    name: rsp.displayName,
                    webName: rsp.name,
                    webUrl: rsp.webUrl,
                    root: !!rsp.root,
                    spItem: {
                        siteUrl: rsp.sharepointIds.siteUrl,
                        webId: rsp.sharepointIds.webId,
                        siteId: rsp.sharepointIds.siteId
                    },
                }
            };
        });
    }

    private loadParentItems(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        return this.loadParentRecursive(ds, cardRef).then((parents) => {
            return {
                data: parents
            };
        });
    }

    private loadParentRecursive(ds: StreamSource, cardRef: any): Promise<any> {
        return ds.loadLazyData(cardRef, "fileDetails").then((details) => {
            let dataPromise: Promise<any>;
            if (details.parentRef) {
                if (details.parentRef.itemPath && details.parentRef.itemPath.match(/\/root:$/))
                    dataPromise = this.loadParentRoot(ds, details.parentRef);
                else
                    dataPromise = this.loadParentRecursive(ds, details.parentRef);
            }
            else
                dataPromise = Promise.resolve(null);

            return dataPromise.then((parent) => {
                return {
                    item: details,
                    parent: parent
                }
            });
        });
    }

    private loadParentRoot(ds: StreamSource, cardRef: any): Promise<any> {
        return ds.loadLazyData(cardRef, "rootDetails").then((details) => {
            return {
                item: details,
                parent: null
            };
        })
    }

    private loadParentSites(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        let spItemPromise: Promise<any>;
        if (cardRef.driveId)
            spItemPromise = ds.loadLazyData(cardRef, "fileDetails").then((details) => {
                return details.spItem;
            });
        else
            spItemPromise = Promise.resolve({
                siteUrl: cardRef.siteUrl,
                siteId: cardRef.siteId,
            });

        return spItemPromise.then((spItem) => {
            let siteRef = {
                itemRef: "site:" + spItem.siteUrl,
                siteId: spItem.siteId,
                siteUrl: spItem.siteUrl,
            };
            return this.loadSitesRecursive(ds, siteRef).then((parents) => {
                return {
                    data: parents
                };
            })
        });
    }

    private loadSitesRecursive(ds: StreamSource, siteRef: any): Promise<any> {
        return ds.loadLazyData(siteRef, "siteDetails").then((details) => {
            let dataPromise: Promise<any>;
            if (details.root)
                dataPromise = Promise.resolve(null);
            else {
                let parentUrl = details.spItem.siteUrl.substr(0, details.spItem.siteUrl.lastIndexOf("/"));
                let parentRef = {
                    itemRef: "site:" + parentUrl,
                    siteUrl: parentUrl
                };
                dataPromise = this.loadSitesRecursive(ds, parentRef);
            }

            return dataPromise.then((parent) => {
                return {
                    item: details,
                    parent: parent
                }
            });
        })
    }

    private loadHotDetails(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        return ds.loadLazyData(cardRef, "fileDetails").then((details) => {
            let spItem;
            if (!(spItem = details.spItem))
                return { data: null };

            return ServiceLoader.GetService(GraphBatchService).addSpGraphRequest({
                method: "GET",
                siteUrl: spItem.siteUrl,
                restUrl: "drives/" + cardRef.driveId + "/items/" + cardRef.itemId + "?$select=id,name,webUrl,webDavUrl,lastModifiedBy,lastModifiedDateTime&$expand=analytics($expand=allTime($expand=activities),lastSevenDays($expand=activities))",
                version: "v2.1",
                cache: this.getServerCacheArg("hotDetails")
            }).then((rsp) => {
                return {
                    data: {
                        name: rsp.name,
                        webUrl: rsp.webUrl,
                        webDavUrl: rsp.webDavUrl,
                        lastModifyTime: rsp.lastModifiedDateTime,
                        lastModifyUser: this.getUserInfo(rsp.lastModifiedBy),
                        analytics: {
                            allTime: (rsp.analytics.allTime && rsp.analytics.allTime.access ? {
                                accessTotal: rsp.analytics.allTime.access.actionCount,
                                accessUsers: rsp.analytics.allTime.access.actorCount
                            } : null),
                            lastWeek: (rsp.analytics.lastSevenDays && rsp.analytics.lastSevenDays.access ? {
                                accessTotal: rsp.analytics.lastSevenDays.access.actionCount,
                                accessUsers: rsp.analytics.lastSevenDays.access.actorCount
                            } : null),
                        }
                    }
                };
            });
        });
    }

    private loadThumbnailUrl(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        if (cardRef.driveId && cardRef.itemId) {
            return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
                method: "GET",
                path: "drives/" + cardRef.driveId + "/items/" + cardRef.itemId + "/thumbnails?select=c400x400",
                version: "v1.0",
                cache: this.getServerCacheArg("thumbnailUrl")
            }).then((rsp) => {
                return {
                    data: (rsp.value && rsp.value.length) ? rsp.value[0]["c400x400"].url : null,
                };
            });
        }
        else {
            let siteKey = cardRef.siteUrl.replace(/^https?:\/\/([^\/]+)(\/.*)?$/, "$1") + "," + cardRef.siteId + "," + cardRef.webId;

            return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
                method: "GET",
                path: "sites/" + siteKey + "/lists/" + cardRef.listId + "/items/" + cardRef.listItemId + "?$expand=driveItem($expand=thumbnails($select=c400x400);$select=id,thumbnails,parentReference)&$select=id,driveItem",
                version: "v1.0",
                cache: this.getServerCacheArg("thumbnailUrl")
            }).then((rsp) => {
                if(!rsp.driveItem)
                    return null;

                cardRef.driveId = rsp.driveItem.parentReference.driveId;
                cardRef.itemId = rsp.driveItem.id;

                let thumbnails = rsp.driveItem.thumbnails;
                return {
                    data: (thumbnails && thumbnails.length) ? thumbnails[0]["c400x400"].url : null,
                };
            });
        }
    }

    private loadBannerUrl(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        if (cardRef.driveId && cardRef.itemId) {
            return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
                method: "GET",
                path: "drives/" + cardRef.driveId + "/items/" + cardRef.itemId + "/thumbnails?select=c1024x500",
                version: "v1.0",
                cache: this.getServerCacheArg("bannerUrl")
            }).then((rsp) => {
                return {
                    data: (rsp.value && rsp.value.length) ? rsp.value[0]["c1024x500"].url : null,
                };
            });
        }
        else {
            let siteKey = cardRef.siteUrl.replace(/^https?:\/\/([^\/]+)(\/.*)?$/, "$1") + "," + cardRef.siteId + "," + cardRef.webId;

            return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
                method: "GET",
                path: "sites/" + siteKey + "/lists/" + cardRef.listId + "/items/" + cardRef.listItemId + "?$expand=driveItem($expand=thumbnails($select=c1024x500);$select=id,thumbnails,parentReference)&$select=id,driveItem",
                version: "v1.0",
                cache: this.getServerCacheArg("bannerUrl")
            }).then((rsp) => {
                if(!rsp.driveItem)
                    return null;

                cardRef.driveId = rsp.driveItem.parentReference.driveId;
                cardRef.itemId = rsp.driveItem.id;

                let thumbnails = rsp.driveItem.thumbnails;
                return {
                    data: (thumbnails && thumbnails.length) ? thumbnails[0]["c1024x500"].url : null,
                };
            });
        }
    }


    private loadAnalytics(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        return ds.loadLazyData(cardRef, "fileDetails").then((details) => {
            let spItem;
            if (!(spItem = details.spItem))
                return { data: null };

            let itemUrl: string;
            if(cardRef.driveId && cardRef.itemId)
                itemUrl = "drives/" + cardRef.driveId + "/items/" + cardRef.itemId;
            else {
                let siteKey = spItem.siteUrl.replace(/^https?:\/\/([^\/]+)(\/.*)?$/, "$1") + "," + spItem.siteId + "," + spItem.webId;
                itemUrl = "sites/" + siteKey + "/lists/" + spItem.listId + "/items/" + spItem.itemId + "/driveItem";
            }

            return ServiceLoader.GetService(GraphBatchService).addSpGraphRequest({
                method: "GET",
                siteUrl: spItem.siteUrl,
                restUrl: itemUrl + "?$select=id&$expand=" + encodeURIComponent("analytics($expand=allTime($expand=activities),lastSevenDays($expand=activities))"),
                version: "v2.1",
                cache: this.getServerCacheArg("analytics"),
            }).then((rsp) => {
                return {
                    data: {
                        allTime: (rsp.analytics.allTime && rsp.analytics.allTime.access ? {
                            accessTotal: rsp.analytics.allTime.access.actionCount,
                            accessUsers: rsp.analytics.allTime.access.actorCount
                        } : null),
                        lastWeek: (rsp.analytics.lastSevenDays && rsp.analytics.lastSevenDays.access ? {
                            accessTotal: rsp.analytics.lastSevenDays.access.actionCount,
                            accessUsers: rsp.analytics.lastSevenDays.access.actorCount
                        } : null),
                    }
                };
            });
        });
    }

    private loadActivities(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        return ds.loadLazyData(cardRef, "fileDetails").then((details) => {
            let spItem;
            if (!(spItem = details.spItem))
                return { data: null };

            /*
            let urlMatch = /^(https?:\/\/[^\/]+)\//.exec(request.card.webUrl);
            if(!urlMatch)
              return Promise.reject("cannot request activities: invalid web url");
            */

            return ServiceLoader.GetService(GraphBatchService).addSpGraphRequest({
                method: "GET",
                siteUrl: spItem.siteUrl,
                restUrl: "drives/" + cardRef.driveId + "/items/" + cardRef.itemId + "/activities?$top=3",
                version: "v2.0",
                cache: this.getServerCacheArg("activities"),
            });
        }).then((rsp) => {
            return {
                data: rsp.value.map((activity) => {
                    let actions = Object.getOwnPropertyNames(activity.action);
                    let versionIdx = actions.indexOf("version");
                    let versionRef: string = null;
                    if (versionIdx !== -1) {
                        versionRef = activity.action.version;
                        actions.splice(versionIdx, 1);
                    }

                    return {
                        id: activity.id,
                        version: versionRef,
                        action: actions.join(","),
                        user: this.getUserInfo(activity.actor),
                        date: new Date(activity.times.recordedTime)
                    };
                }),
            };
        });
    }

    private loadComments(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        let spItemPromise: Promise<any>;
        if (cardRef.siteUrl && cardRef.listId && cardRef.listItemId)
            spItemPromise = Promise.resolve({
                siteUrl: cardRef.siteUrl,
                listId: cardRef.listId,
                itemId: cardRef.listItemId,
            });
        else
            spItemPromise = ds.loadLazyData(cardRef, "fileDetails").then((details) => {
                return details.spItem;
            });
        
        return spItemPromise.then((spItem) => {
            return ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
                method: "GET",
                siteUrl: spItem.siteUrl,
                restUrl: "web/lists('" + spItem.listId + "')/GetItemById(" + spItem.itemId + ")/Comments?$expand=replies&$top=3",
                cache: this.getServerCacheArg("comments"),
            });
        }).then((rsp) => {
            return {
                data: rsp.d.results.map((comment) => {
                    return {
                        id: "comment-" + comment.id,
                        action: "comment",
                        user: this.getUserInfo(comment.author),
                        date: new Date(comment.createdDate)
                    };
                })
            };
        });
    }

    private loadCommentCount(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        let spItemPromise: Promise<any>;
        if (cardRef.siteUrl && cardRef.listId && cardRef.listItemId)
            spItemPromise = Promise.resolve({
                siteUrl: cardRef.siteUrl,
                listId: cardRef.listId,
                itemId: cardRef.listItemId,
            });
        else
            spItemPromise = ds.loadLazyData(cardRef, "fileDetails").then((details) => {
                return details.spItem;
            });

        return spItemPromise.then((spItem) => {
            return ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
                method: "GET",
                siteUrl: spItem.siteUrl,
                restUrl: "web/lists('" + spItem.listId + "')/GetItemById(" + spItem.itemId + ")/Comments?$count",
                cache: this.getServerCacheArg("commentCount"),
            });
        }).then((rsp) => {
            return {
                data: ((rsp.d ? rsp.d.results : rsp.value) || []).length
            };
        });
    }

    private loadLikeCount(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        let spItemPromise: Promise<any>;
        if (cardRef.siteUrl && cardRef.listId && cardRef.listItemId)
            spItemPromise = Promise.resolve({
                siteUrl: cardRef.siteUrl,
                listId: cardRef.listId,
                itemId: cardRef.listItemId,
            });
        else
            spItemPromise = ds.loadLazyData(cardRef, "fileDetails").then((details) => {
                return details.spItem;
            });

        return spItemPromise.then((spItem) => {
            return ServiceLoader.GetService(GraphBatchService).addSpRestRequest({
                method: "GET",
                siteUrl: spItem.siteUrl,
                restUrl: "web/lists('" + spItem.listId + "')/GetItemById(" + spItem.itemId + ")/likedByInformation?$select=likeCount",
                cache: this.getServerCacheArg("commentCount"),
            });
        }).then((rsp) => {
            return {
                data: rsp.d.likeCount
            };
        });
    }

    private loadVersions(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            path: "drives/" + cardRef.driveId + "/items/" + cardRef.itemId + "/versions?$top=3",
            version: "v1.0",
            cache: this.getServerCacheArg("versions"),
        }).then((rsp) => {
            return {
                data: rsp.value.map((version) => {
                    return {
                        date: new Date(version.lastModifiedDateTime),
                        action: "edit",
                        version: version.id,
                        user: this.getUserInfo(version.lastModifiedBy)
                    };
                })
            };
        });
    }

}

export class DiscoverRecentDataSource extends TrendingDataSource<MicrosoftGraph.UsedInsight> implements IStreamSource {

    public requestData(stream: StreamSource, request: IStreamDataRequest): Promise<IStreamDataResponse> {
        let hadSkipToken = false;
        let args: string[] = [];

        args.push("$filter=" + encodeURIComponent("ResourceVisualization/type ne 'spsite' and ResourceVisualization/type ne 'Web'"));
        if (request.cardLimit > 0)
            args.push("$top=" + request.cardLimit);

        if (request.cardIndex && request.streamObj) {
            if (request.streamObj.skipToken) {
                hadSkipToken = true;
                args.push("$skiptoken=" + request.streamObj.skipToken);
            }
            else
                args.push("$skip=" + request.cardIndex);
        }

        let req: IGraphRequest = {
            method: "GET",
            path: "me/insights/used" + (args.length ? "?" + args.join("&") : ""),
            version: "v1.0"
        };

        return ServiceLoader.GetService(GraphBatchService).addGraphRequest(req).then((res) => {
            return this.parseDataResponse(stream, request, res, hadSkipToken);
        });
    }

    protected parseResponseCard(stream: StreamSource, item: MicrosoftGraph.UsedInsight): BaseCard {
        let cardRefs: any = {
            itemRef: item.resourceReference.id || item.id,
            itemUrl: item.resourceReference.webUrl,
            siteUrl: item.resourceVisualization.containerWebUrl,
            itemType: null,
        };
        let card: BaseCard;
        let match;
        if ((match = /^drives\/([^\/]+)\/items\/([^\/]+)$/.exec(item.resourceReference.id))) {
            cardRefs.driveId = match[1];
            cardRefs.itemId = match[2];
            cardRefs.itemType = "file";

            let docCard = card = new DocumentCard(stream, cardRefs);
            docCard.containerTitle = item.resourceVisualization.containerDisplayName;
            docCard.containerType = item.resourceVisualization.containerType;
            docCard.containerUrl = item.resourceVisualization.containerWebUrl;
        }
        else if ((match = /^sites\/(.+)$/.exec(item.resourceReference.id))) {
            let siteInfo = match[1].split(",");
            cardRefs.itemType = "site";
            cardRefs.itemRef = "site:" + item.resourceReference.webUrl;

            let siteCard = card = new SiteCard(stream, cardRefs);
            siteCard.hostUrl = cardRefs.hostUrl = siteInfo[0];
            siteCard.siteId = cardRefs.siteId = siteInfo[1];
            siteCard.webId = cardRefs.webId = siteInfo[2];
        }
        else {
            cardRefs.itemType = "other";

            card = new OtherCard(stream, cardRefs);
        }

        card.id = item.id;
        card.type = item.resourceVisualization.type;
        card.title = item.resourceVisualization.title;
        card.webUrl = item.resourceReference.webUrl;
        card.updateTime = new Date(item.lastUsed.lastModifiedDateTime);

        return card;
    }
}

export class DiscoverTrendingDataSource extends TrendingDataSource<MicrosoftGraph.Trending> implements IStreamSource {

    public requestData(stream: StreamSource, request: IStreamDataRequest): Promise<IStreamDataResponse> {
        let hadSkipToken = false;
        let args: string[] = [];

        args.push("$filter=" + encodeURIComponent("ResourceVisualization/type ne 'spsite' and ResourceVisualization/type ne 'Web'"));
        if (request.cardLimit > 0)
            args.push("$top=" + request.cardLimit);

        if (request.cardIndex && request.streamObj) {
            if (request.streamObj.skipToken) {
                hadSkipToken = true;
                args.push("$skiptoken=" + request.streamObj.skipToken);
            }
            else
                args.push("$skip=" + request.cardIndex);
        }

        let req: IGraphRequest = {
            method: "GET",
            path: "me/insights/trending" + (args.length ? "?" + args.join("&") : ""),
            version: "v1.0"
        };

        return ServiceLoader.GetService(GraphBatchService).addGraphRequest(req).then((res) => {
            return this.parseDataResponse(stream, request, res, hadSkipToken);
        });
    }

    protected parseResponseCard(stream: StreamSource, item: MicrosoftGraph.Trending): BaseCard {
        let cardRefs: any = {
            itemRef: item.resourceReference.id || item.id,
            itemType: null,
            itemUrl: item.resourceReference.webUrl,
            siteUrl: item.resourceVisualization.containerWebUrl,
            previewImg: item.resourceVisualization.previewImageUrl
        };
        let card: BaseCard;
        let match;
        if ((match = /^drives\/([^\/]+)\/items\/([^\/]+)$/.exec(item.resourceReference.id))) {
            cardRefs.driveId = match[1];
            cardRefs.itemId = match[2];
            cardRefs.itemType = "file";

            let docCard = card = new DocumentCard(stream, cardRefs);
            docCard.containerTitle = item.resourceVisualization.containerDisplayName;
            docCard.containerType = item.resourceVisualization.containerType;
            docCard.containerUrl = item.resourceVisualization.containerWebUrl;
        }
        else if ((match = /^sites\/(.+)$/.exec(item.resourceReference.id))) {
            let siteInfo = match[1].split(",");
            cardRefs.itemType = "site";
            cardRefs.itemRef = "site:" + item.resourceReference.webUrl;

            let siteCard = card = new SiteCard(stream, cardRefs);
            siteCard.hostUrl = cardRefs.hostUrl = siteInfo[0];
            siteCard.siteId = cardRefs.siteId = siteInfo[1];
            siteCard.webId = cardRefs.webId = siteInfo[2];
        }
        else if ((match = /_api\/v2\.0\/drives\/([^\/]+)\/items\/([^\/]+)\/thumbnails/.exec(item.resourceVisualization.previewImageUrl))) {
            let siteInfo = match[1].split(",");
            cardRefs.driveId = match[1];
            cardRefs.itemId = match[2];
            cardRefs.itemType = "file";
            cardRefs.itemRef = "drives/" + match[1] + "/items/" + match[2];

            let newsCard = card = new NewsCard(stream, cardRefs);
        }
        else {
            cardRefs.itemType = "other";

            card = new OtherCard(stream, cardRefs);
        }

        card.id = item.id;
        card.type = item.resourceVisualization.type;
        card.title = item.resourceVisualization.title;
        card.webUrl = item.resourceReference.webUrl;
        card.weight = item.weight;

        return card;
    }

}

interface IDiscoverSearchSiteInfo {
    host: string;
    siteId: string;
    webId: string;
    name: string;
    webUrl: string;
}

export class DiscoverSearchDataSource extends TrendingDataSource<any> implements IStreamSource {
    private rootSiteInfo: Promise<IDiscoverSearchSiteInfo>;
    public queryText: string;
    public queryTemplate: string;
    public sortList: [string, number][];
    private cardParsers: ((stream: StreamSource, data: any) => BaseCard)[] = [];

    public constructor(queryText: string, queryTemplate = "{searchterms}") {
        super();
        this.queryText = queryText;
        this.queryTemplate = queryTemplate;
    }

    public addCardParser(parser: (stream: StreamSource, data: any) => BaseCard) {
        this.cardParsers.push(parser);
    }

    private getRootSiteInfo(): Promise<IDiscoverSearchSiteInfo> {
        if (!this.rootSiteInfo) {
            let req: IGraphRequest = {
                method: "GET",
                path: "sites/root",
                version: "v1.0"
            };

            this.rootSiteInfo = ServiceLoader.GetService(GraphBatchService).addGraphRequest(req).then((res) => {
                let siteInfo = res.id.split(",");
                return {
                    host: siteInfo[0],
                    siteId: siteInfo[1],
                    webId: siteInfo[2],
                    name: res.displayName,
                    webUrl: res.webUrl
                };
            });
        }
        return this.rootSiteInfo;
    }

    public requestData(stream: StreamSource, request: IStreamDataRequest): Promise<IStreamDataResponse> {
        let hadSkipToken = false;
        let reqData: any = {
            Querytext: this.queryText,
            QueryTemplate: this.queryTemplate,
            SelectProperties: {
                results: [
                    "Rank", "DocId", "Title", "Author", "AuthorOWSUSER", "Path", "Description", "LastModifiedTime", "FileExtension", "ParentLink", "SPWebUrl",
                    "contentclass", "IsDocument", "IsContainer", "SiteId", "WebId", "ListId", "ListItemID", "UniqueId", "PromotedState",
                    "ViewsLifeTime", "ViewsRecent", "PictureThumbnailURL", "LikesCount",
                ]
            }
        };

        if (request.cardLimit > 0)
            reqData.RowLimit = request.cardLimit;
        if (request.cardIndex && request.streamObj)
            reqData.StartRow = request.cardIndex;

        if (this.sortList && this.sortList.length) {
            reqData.EnableSorting = true;
            reqData.SortList = {
                results: this.sortList.map((sortField) => {
                    return {
                        Property: sortField[0],
                        Direction: sortField[1]
                    };
                })
            };
        }

        let req: ISpRestRequest = {
            method: "POST",
            siteUrl: SmartPortal.currentSession.tenantUrl,
            restUrl: "search/postquery",
            data: {
                request: reqData
            }
        };

        return ServiceLoader.GetService(GraphBatchService).addSpRestRequest(req, true).then((res) => {
            let relevantResults = res.d.postquery.PrimaryQueryResult.RelevantResults;
            let searchResults = (relevantResults.Table.Rows.results as any[]).map((resultRow) => {
                let rowObj = {};
                (resultRow.Cells.results as any[]).forEach((resultCell) => {
                    let resultVal;
                    switch (resultCell.ValueType) {
                        case "Null":
                            resultVal = null;
                            break;
                        case "Edm.DateTime":
                            resultVal = new Date(resultCell.Value);
                            break;
                        case "Edm.Double":
                            resultVal = parseFloat(resultCell.Value);
                            break;
                        case "Edm.Int32":
                        case "Edm.Int64":
                            resultVal = parseInt(resultCell.Value);
                            break;
                        case "Edm.Boolean":
                            resultVal = (resultCell.Value === "true");
                            break;
                        default:
                            resultVal = resultCell.Value;
                            break;
                    }
                    rowObj[resultCell.Key] = resultVal;
                });
                return rowObj;
            });

            return this.parseDataResponse(stream, request, {
                value: searchResults
            }, hadSkipToken);
        });
    }

    protected parseResponseCard(stream: StreamSource, item: any): BaseCard {
        let cardRefs: any = {
            itemRef: "search/" + item.DocId,
            itemUrl: item.Path,
            siteUrl: item.SPWebUrl,
            siteId: item.SiteId,
            webId: item.WebId,
            listId: item.ListId,
            listItemId: item.ListItemID,
            itemType: null
        };
        let card: BaseCard;

        for (let idx = 0; idx < this.cardParsers.length; idx++) {
            if ((card = this.cardParsers[idx](stream, item))) {
                break;
            }
        }

        if (!card && (item.IsDocument && item.FileExtension === "aspx" && item.PromotedState == 2))
            card = this.parseNewsCard(stream, item, cardRefs);

        if (!card) {
            cardRefs.itemType = "other";

            card = new OtherCard(stream, cardRefs);
        }

        card.id = "search/" + item.DocId;
        card.type = item.contentclass;
        card.title = item.Title;
        card.webUrl = item.Path;
        card.weight = item.Rank;
        card.updateTime = item.LastModifiedTime;

        return card;
    }

    private parseNewsCard(stream: StreamSource, item: any, cardRefs: any): BaseCard {
        let card = new NewsCard(stream, cardRefs);
        //card.thumbnail = item.PictureThumbnailURL;
        //card.likeCount = item.LikesCount;
        //card.viewCount = item.ViewsLifeTime;
        //card.recentViewCount = item.ViewsRecent;
        const authorOwsData = this.parseOWSUser(item.AuthorOWSUSER);
        card.authorMail = authorOwsData[0];
        card.authorName = authorOwsData[1] ? authorOwsData[1] : item.Author?.split(";")[0];
        card.authorLoginName = authorOwsData[2];
        card.description = item.Description;
        return card;
    }

    private parseOWSUser(owsUser: string): string[] {
        let owsData = owsUser.split(" | ");
        let owsClaim = owsData[2].split(" ");
        owsData[2] = owsClaim[1];
        return owsData;

    }

}
