import * as React from 'react';
import styles from '../DiscoverTabStyle.scss';
import { IContextualMenuItem, ContextualMenu } from "office-ui-fabric-react";

export interface ICardContextMenuProps {
  menuItems: IContextualMenuItem[];
  setControl(control: ICardContextMenuControl): void;
}

export interface ICardContextMenuState {
  position?: number[];
  isVisible: boolean;
}

export interface ICardContextMenuControl {
  showMenu(position?:number[]): void;
  hideMenu(): void;
}

export class CardContextMenu extends React.Component<ICardContextMenuProps, ICardContextMenuState> {
  private ctxPosMarkerEl: Element;

  constructor(props: ICardContextMenuProps, state: ICardContextMenuState) {
    super(props);

    this.state = {
      isVisible: false,
      position: null
    };
    this.props.setControl({
      showMenu: (pos?: number[]) => {
        this.setVisibility(true, pos);
      },
      hideMenu: () => {
        this.setVisibility(false);
      }
    })
  }

  public render(): React.ReactElement<ICardContextMenuProps> {
    return (
      <div className={styles.CardContextMenu}>
        <div className={styles.CardCtxMenuPosMarker} ref={(markerEl) => {
          this.ctxPosMarkerEl = markerEl;
        }} style={{
          left: this.state.position ? this.state.position[0]+"px" : "0",
          top: this.state.position ? this.state.position[1]+"px" : "0"
        }}></div>
        <ContextualMenu
          items={this.props.menuItems}
          hidden={!this.state.isVisible}
          target={this.ctxPosMarkerEl}
          
          onItemClick={(evt, item) => {
            this.setVisibility(false);
          }}
          onDismiss={(evt, all) => {
            this.setVisibility(false);
          }}
        />
      </div>
    );
  }

  private setVisibility(visible: boolean, pos?: number[]) {
    this.setState({
      position: pos,
      isVisible: visible
    });
  }

}
