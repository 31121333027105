import * as React from "react";
import Truncate from 'react-truncate';
import styles from '../DiscoverTabStyle.scss';
import { IContextualMenuItem, Icon } from "office-ui-fabric-react";
import DiscoverTabStrings from '../loc/DiscoverTabStrings';
import * as CardStream from '../../../common/cardstream';
import { CardContextMenu, ICardContextMenuControl } from "./CardContextMenu";
import { Action } from "../../../common/controls/Panel/PanelActions";
import { renderFriendlyDate } from '../../../common/utils/DateHandler';
import { BaseCard } from '../../../common/cardstream';

export interface INewsCardProps {
    card: CardStream.NewsCard;
    minWidth: number;
    height: number;
    display: Action;
}

export interface INewsCardState {
}

export class NewsCard extends React.Component<INewsCardProps, INewsCardState> {
    private updateCallback: () => void;
    private listenUpdates: boolean;
    private listenCard: BaseCard;
    private cardContainerEl: HTMLDivElement;
    private ctxmenuCtrl: ICardContextMenuControl;

    private truncateTitleRef;
    private truncateDescriptionRef;
    private truncateParentSiteRef;

    constructor(props: INewsCardProps, state: INewsCardState) {
        super(props);

        this.state = {
        };

        this.updateCallback = () => {
            this.forceUpdate();
        };
    }

    public componentWillUnmount(): void {
        if(this.listenUpdates) {
            this.listenUpdates = false;
            this.props.card.removeUpdateCallback(this.updateCallback);
        }
    }

    public componentDidUpdate(prevProps) {
        if (this.props.display !== prevProps.display) {
            this.truncateTitleRef.onResize();
            this.truncateDescriptionRef.onResize();
            this.truncateParentSiteRef.onResize();
        }
    }

    public render(): React.ReactElement<{}> {
        if(this.listenCard !== this.props.card && this.listenCard) {
            this.listenUpdates = false;
            this.listenCard.removeUpdateCallback(this.updateCallback);
        }
        if(!this.listenUpdates) {
            this.listenUpdates = true;
            this.listenCard = this.props.card;
            this.props.card.addUpdateCallback(this.updateCallback);
        }

        let cardThumbnail = this.props.card.thumbnail;

        let cardSrcIconClass = "sharepoint";

        return (
            <div className={styles.CardItem + " " + (this.props.display === Action.DisplaySmallCard ? styles.CardItemSmall : styles.CardItemLarge)} style={{ minWidth: this.props.minWidth, height: this.props.height }}>
                <div className={styles.CardContainer + " " + styles.NewsCard} ref={(el) => {
                    this.cardContainerEl = el;
                }} onContextMenu={(evt) => {
                    let element = evt.target as Element;
                    let isLink = false;
                    do {
                        isLink = element.matches("a");
                    } while (!isLink && (element = element.parentElement));

                    if (!isLink) {
                        this.openContextMenu(evt);
                        evt.preventDefault();
                    }
                }}>
                    <CardContextMenu setControl={(control) => {
                        this.ctxmenuCtrl = control;
                    }} menuItems={this.getCtxMenuItems()} />

                    <div className={styles.CardContent}>
                        <div className={styles.CardImage}>
                            {!cardThumbnail ?
                                <div className={styles.GenericImage}>
                                </div>
                                :
                                <div className={styles.ThumbImage}>
                                    <img src={cardThumbnail} className={styles.ThumbImgEl} onError={() => {
                                        this.props.card.thumbnail = null;
                                    }} />
                                </div>
                            }
                        </div>
                        <div className={styles.CardStats}>
                            <div className={styles.CardStat}>
                                <div className={styles.CardStatIcon}>
                                    <Icon iconName="View" />
                                </div>
                                <div className={styles.CardStatText}>{DiscoverTabStrings.format(DiscoverTabStrings.Stat_Views, (this.props.card.viewCount || 0).toString())}</div>
                            </div>
                            <div className={styles.CardStat}>
                                <div className={styles.CardStatIcon}>
                                    <Icon iconName="Message" />
                                </div>
                                <div className={styles.CardStatText}>{DiscoverTabStrings.format(DiscoverTabStrings.Stat_Comments, (this.props.card.commentCount || 0).toString())}</div>
                            </div>
                            {this.props.card.isLazyDataReady("likeCount") ?
                                <div className={styles.CardStat}>
                                    <div className={styles.CardStatIcon}>
                                        <Icon iconName="Like" />
                                    </div>
                                    <div className={styles.CardStatText}>{DiscoverTabStrings.format(DiscoverTabStrings.Stat_Like, (this.props.card.likeCount || 0).toString())}</div>
                                </div>
                                : null}
                            <div className={styles.CardStatSpacer}></div>
                        </div>
                        <div className={styles.CardHeader}>
                            <div className={styles.CardTitle}>
                                <a href={this.props.card.webUrl} target="_blank">
                                    <Truncate ref={ref => { this.truncateTitleRef = ref; return true; }} lines={2} trimWhitespace={true}>
                                        {this.props.card.title}
                                    </Truncate>
                                </a>
                            </div>
                        </div>
                        <div className={styles.CardBodySpacer}>
                            <div className={styles.CardBody}>
                                <div className={styles.CardDescription}>
                                    <Truncate ref={ref => { this.truncateDescriptionRef = ref; return true; }} lines={4} trimWhitespace={true}>
                                        {this.props.card.description}
                                    </Truncate>
                                </div>
                            </div>
                            {this.props.card.authorName ?
                                <div className={styles.CardActivityList}>
                                    <div className={styles.CardActivity}>
                                        <div className={styles.CardActivityIcon}>
                                            <div className={styles.ActivityCircle}></div>

                                        </div>
                                        <div className={styles.CardActivityTitle}>
                                            <span className={styles.ActivityActor}>{this.props.card.authorName}</span>
                                        </div>
                                        <div className={styles.CardActivityInfo}>
                                            {this.props.card.updateTime ? renderFriendlyDate(this.props.card.updateTime) : null}
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                        <div className={styles.CardFooter}>
                            <div className={styles.CardFooterSrcIcon}>
                                {cardSrcIconClass ? <div className={["ms-BrandIcon--icon16", "ms-BrandIcon--" + cardSrcIconClass].join(" ")} /> : null}
                            </div>
                            <div className={styles.CardFooterBreadcrumb}>
                                {(() => {
                                    let parentLinks: JSX.Element[] = [];
                                    let linkCount = 0;
                                    let parentSite = this.props.card.sites;

                                    while (parentSite) {
                                        if (linkCount !== 0) {
                                            parentLinks.unshift((
                                                <div key={(linkCount++)} className={styles.CBSeperator}>&#x3E;</div>
                                            ));
                                        }

                                        parentLinks.unshift((
                                            <div key={(linkCount++)}>
                                                <a href={parentSite.item.webUrl} target="_blank">
                                                    <Truncate ref={ref => { this.truncateParentSiteRef = ref; return true; }} className={styles.CBLink}>{parentSite.item.name}</Truncate>
                                                </a>
                                            </div>
                                        ));

                                        parentSite = parentSite.parent;
                                    }

                                    return parentLinks;
                                })()}
                            </div>
                            <div className={styles.CardFooterMenu}>
                                <div className={styles.MenuActionPnl} onClick={(evt) => {
                                    this.openContextMenu(evt);
                                }}>
                                    <Icon iconName="MoreVertical" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getCtxMenuItems(): IContextualMenuItem[] {
        let menuItems: IContextualMenuItem[] = [];

        let openUrl: string;
        if ((openUrl = this.props.card.webUrl)) {
            menuItems.push({
                key: 'open',
                text: DiscoverTabStrings.CtxMenu_OpenInTab,
                iconProps: {
                    iconName: 'FabricOpenFolderHorizontal'
                },
                onClick: () => {
                    var win = window.open(openUrl, '_blank');
                    win.focus();
                }
            });
        }

        menuItems.push({
            key: 'copyLink',
            text: DiscoverTabStrings.CtxMenu_CopyLink,
            iconProps: {
                iconName: 'Link'
            },
            onClick: () => console.log('copyLink clicked')
        });

        return menuItems;
    }

    private openContextMenu(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        let rect = this.cardContainerEl.getBoundingClientRect();
        let posX = event.clientX - rect.left;
        let posY = event.clientY - rect.top;
        this.ctxmenuCtrl.showMenu([posX, posY]);
    }

}
