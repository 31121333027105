import { BaseCard } from ".";
import { ICacheService } from '../cache/models/CacheService';

export interface IStreamDataRequest {
    cardIndex: number;
    cardLimit: number;
    streamObj: any;
}

export interface IStreamDataResponse {
    cards: BaseCard[];
    totalCards: number;
    moreCards: boolean;
    streamObj?: any;
}

export interface IStreamLazyResponse {
    data: any;
}

export interface IStreamLazyField {
    cache?: boolean;
    serverCache?: boolean;
    cacheKey?: string;
    cacheTime?: number;
    loadFn(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse>;
}

export interface IStreamSource {
    lazyFields: Map<string, IStreamLazyField>;
    requestData: (stream: StreamSource, request: IStreamDataRequest) => Promise<IStreamDataResponse>;
}

export class StreamSource {
    public readonly cacheService: ICacheService;
    public batchSize: number;
    private source: IStreamSource;
    private lazyData: Map<string, Promise<any>>;

    public constructor(source: IStreamSource, cacheService: ICacheService) {
        this.cacheService = cacheService;
        this.source = source;
        this.lazyData = new Map<string, Promise<any>>();
    }

    public requestData(request: IStreamDataRequest): Promise<IStreamDataResponse> {
        return this.source.requestData(this, request);
    }

    public loadLazyData(cardRef: any, dataKey: string): Promise<any> {
        if (!this.source.lazyFields.has(dataKey))
            return Promise.resolve(undefined);
        let lazyField = this.source.lazyFields.get(dataKey);
        let cacheKey: string = cardRef.itemRef + "-" + (lazyField.cacheKey || "lazyfld") + "-" + dataKey;

        if (this.lazyData.has(cacheKey))
            return this.lazyData.get(cacheKey);

        let dataPromise: Promise<any>;
        if (lazyField.cache && this.cacheService)
            dataPromise = this.cacheService.getCacheEntry(cacheKey, true);
        else
            dataPromise = Promise.resolve(undefined);

        dataPromise = dataPromise.catch(() => { return undefined; })
            .then((cacheRes) => {
                let resData;
                if (!cacheRes || cacheRes.timeout) {
                    resData = lazyField.loadFn(this, cardRef).then((rsp) => {
                        let data;
                        if ((!(data = rsp.data) || (Array.isArray(data) && data.length === 0)) && cacheRes && !cacheRes.timeout)
                            return cacheRes.data;

                        if (lazyField.cache && this.cacheService) {
                            return this.cacheService.setCacheEntry(cacheKey, data, {
                                timeout: lazyField.cacheTime
                            }).then(() => {
                                return data;
                            });
                        }
                        else
                            return data;
                    });
                }
                else
                    resData = cacheRes.data;

                return resData;
            });

        this.lazyData.set(cacheKey, dataPromise);
        window.setTimeout(() => {
            if (this.lazyData.get(cacheKey) === dataPromise) {
                this.lazyData.delete(cacheKey);
            }
        }, 1000 * 60);
        return dataPromise;
    }
}
