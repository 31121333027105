import { StreamSource } from '..';

export interface ILazyFieldConfig {
  lazyField: string;
  setterFn(lazyData: any): void;
}

export abstract class BaseCard {
  public readonly _source: StreamSource;
  public readonly _refobj: any;
  protected updateCallbacks: (() => void)[];
  protected lazyFieldCfg: {[key: string]: ILazyFieldConfig};
  protected lazyFields: Map<string, Promise<void>>;
  protected lazyRequests: Map<string, Promise<void>>;
  protected dataFields: Map<string, any>;
  private pendingNotify: boolean;

  public get id(): string { return this.getDataField("id"); };
  public set id(val: string) { this.setDataField("id", val); };

  public get title(): string { return this.getDataField("title"); };
  public set title(val: string) { this.setDataField("title", val); };

  public get type(): string { return this.getDataField("type"); };
  public set type(val: string) { this.setDataField("type", val); };

  public get weight(): number { return this.getDataField("weight"); };
  public set weight(val: number) { this.setDataField("weight", val); };

  public get updateTime(): Date { return this.getDataField("updateTime"); };
  public set updateTime(val: Date) { this.setDataField("updateTime", val); };

  public get webUrl(): string { return this.getDataField("webUrl"); };
  public set webUrl(val: string) { this.setDataField("webUrl", val); };

  constructor(source: StreamSource, refobj: any){
    this._source = source;
    this._refobj = refobj;
    this.updateCallbacks = [];
    this.lazyFieldCfg = {};
    this.lazyFields = new Map<string, Promise<any>>();
    this.dataFields = new Map<string, any>();
    this.pendingNotify = false;
  }

  public isLazyDataReady(dataKey: string): boolean {
    return this.dataFields.has(dataKey);
  }

  public isLazyDataLoading(dataKey: string): boolean {
    return this.lazyFields.has(dataKey);
  }

  protected setDataField(dataKey: string, data: any, skipNotify?: boolean): void {
    this.dataFields.set(dataKey, data);
    
    if(!skipNotify)
      this.notifyUpdate();
  }

  protected getDataField(dataKey: string): any {
    if(this.dataFields.has(dataKey))
      return this.dataFields.get(dataKey);
    if(this.lazyFieldCfg.hasOwnProperty(dataKey))
      this.awaitLazyData(dataKey);
    return undefined;
  }

  public awaitLazyData(dataKey: string): Promise<any> {
    let promise: Promise<any>;
    if(this.dataFields.has(dataKey))
      promise = Promise.resolve(this.dataFields.get(dataKey));
    else if(this.lazyFields.has(dataKey))
      promise = this.lazyFields.get(dataKey);
    else {
      promise = this.loadLazyData(dataKey).then(() => {
        this.lazyFields.delete(dataKey);
        return this.dataFields.get(dataKey);
      });
      this.lazyFields.set(dataKey, promise);
    }
    return promise;
  }

  private loadLazyData(dataKey: string): Promise<void> {
    if(!this.lazyFieldCfg.hasOwnProperty(dataKey))
      return Promise.reject("unknown lazy field '" + dataKey + "'");
    
    let fieldCfg = this.lazyFieldCfg[dataKey];
    return this._source.loadLazyData(this._refobj, fieldCfg.lazyField).then(fieldCfg.setterFn);
  }

  public addUpdateCallback(callback: () => void): void {
    if(this.updateCallbacks.indexOf(callback) !== -1)
      return;
    
    this.updateCallbacks.push(callback);
  }

  public removeUpdateCallback(callback: () => void): void {
    let idx: number;
    if((idx = this.updateCallbacks.indexOf(callback)) === -1)
      return;

    this.updateCallbacks.splice(idx, 1);
  }

  protected notifyUpdate(): void {
    if(this.updateCallbacks.length === 0)
      return;
    if(this.pendingNotify)
      return;
    this.pendingNotify = true;

    window.setTimeout(() => {
      this.pendingNotify = false;
      this.updateCallbacks.forEach((callback) => {
        callback();
      });
    }, 50);
  }

}
