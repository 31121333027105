import moment from "moment";
import { SmartPortal } from "../../SmartPortal";
import { ServiceLoader } from './ServiceLoader';

export abstract class BaseStrings {
    abstract readonly _SupportedLocales: ILocaleDictMap<BaseStrings>;
    abstract readonly _ModuleName: string;
    abstract _CurrentLocale: Locale;

    public getModuleName(): string {
        return this._ModuleName;
    }

    public format(template: string, ...replacements: string[]): string {
        var args = Array.prototype.slice.call(arguments, 1);
        return template.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    };
}

export type ILocaleInfoMap = {
    [locale in Locale]: ILocaleInfo;
}

export type ILocaleDictMap<T extends BaseStrings> = {
    [locale in Locale]?: () => Promise<T>;
}

export interface ILocaleInfo {
    localeCode: string;
    displayName: string;

    codeRegExp?: RegExp;
    parentLocale?: Locale;
    localeInit?(): Promise<void> | void;
}

export enum Locale {
    EN = "EN",
    ENGB = "ENGB",
    DE = "DE",
    DECH = "DECH",
}

export class LocaleService {
    public static readonly _LocaleInfos: ILocaleInfoMap = {
        [Locale.EN]: {
            localeCode: "en-US",
            displayName: "English (US)",
            codeRegExp: /^en-?/,
            localeInit: () => {
                moment.locale("en");
            },
        },
        [Locale.ENGB]: {
            localeCode: "en-GB",
            displayName: "English (GB)",
            parentLocale: Locale.EN,
            localeInit: () => import(/* webpackChunkName: "locale_en-gb" */ "moment/locale/en-gb").then(() => {
                moment.locale("en-GB");
            }),
        },
        [Locale.DE]: {
            localeCode: "de-DE",
            displayName: "Deutsch (DE)",
            codeRegExp: /^de-?/,
            localeInit: () => import(/* webpackChunkName: "locale_de" */ "moment/locale/de").then(() => {
                moment.locale("de");
            }),
        },
        [Locale.DECH]: {
            localeCode: "de-CH",
            displayName: "Deutsch (CH)",
            parentLocale: Locale.DE,
            localeInit: () => import(/* webpackChunkName: "locale_de-ch" */ "moment/locale/de-ch").then(() => {
                moment.locale("de-CH");
            }),
        },
    };

    private static _registeredModules: { [module: string]: BaseStrings } = {};

    public static RegisterStrings(strings: BaseStrings): void {
        let moduleName = strings.getModuleName();
        this._registeredModules[moduleName] = strings;
        if (SmartPortal && SmartPortal.isAppStarted) {
            ServiceLoader.GetService(LocaleService).initModule(moduleName);
        }
    }

    private knownLocales: Locale[];
    private currentLocale?: Locale;
    private currentModules: { [module: string]: BaseStrings };
    private loadingPromises: Promise<void>[] = [];

    public constructor() {
        this.knownLocales = Object.keys(LocaleService._LocaleInfos) as any as Locale[];

        if ((this.currentLocale = this.getCurrentLocale())) {
            let localeInfo = LocaleService._LocaleInfos[this.currentLocale];

            if (localeInfo.localeInit) {
                this.addLoadingPromise(localeInfo.localeInit());
            }
        }
        this.currentModules = {};

        Object.keys(LocaleService._registeredModules).forEach((module) => this.initModule(module));
    }

    public isReady(): boolean {
        return this.loadingPromises.length === 0;
    }

    public getReadyPromise(): Promise<void> {
        return this.loadingPromises.length > 0 ? Promise.all(this.loadingPromises).then(() => {}) : Promise.resolve();
    }

    private addLoadingPromise(promise: Promise<void> | void) {
        if(!promise)
            return;

        this.loadingPromises.push(promise);
        promise.finally(() => {
            let promiseIdx = this.loadingPromises.indexOf(promise);
            if(promiseIdx !== -1)
                this.loadingPromises.splice(promiseIdx, 1);
        });
    }

    private getCurrentLocale(): Locale {

        if (this.currentLocale) {
            return this.currentLocale;
        }

        // get locale from session
        if (SmartPortal.currentSession && SmartPortal.currentSession.userLocale && (this.currentLocale = this.resolveLocaleCode(SmartPortal.currentSession.userLocale))) {
            return this.currentLocale;
        }

        // get locale from browser
        let navigator: any = window.navigator;
        if (navigator && (this.currentLocale = this.resolveLocaleCode(navigator.language || navigator.userLanguage))) {
            return this.currentLocale;
        }

        this.currentLocale = this.resolveLocaleCode("en-US");
        return this.currentLocale;
    }

    public getCurrentLocaleInfo(): ILocaleInfo {
        return this.getLocaleInfo(this.getCurrentLocale());
    }

    public getLocaleInfo(locale: Locale): ILocaleInfo {
        return LocaleService._LocaleInfos[locale];
    }

    private resolveLocaleCode(code: string): Locale {
        if (typeof code !== "string" || !code) {
            code = "en-US";
        }
        for (let i = 0; i < this.knownLocales.length; i++) {
            let localeRef = this.knownLocales[i];
            let localeInfo = LocaleService._LocaleInfos[localeRef];

            if (localeInfo.localeCode.localeCompare(code) === 0) {
                return localeRef;
            }
        }
        for (let i = 0; i < this.knownLocales.length; i++) {
            let localeRef = this.knownLocales[i];
            let localeInfo = LocaleService._LocaleInfos[localeRef];

            if (localeInfo.codeRegExp && code.match(localeInfo.codeRegExp)) {
                return localeRef;
            }
        }
        return this.resolveLocaleCode("en-US");
    }

    private initModule(moduleName: string): void {
        let moduleInfo = LocaleService._registeredModules[moduleName];

        if (this.currentLocale != null && moduleInfo._CurrentLocale != this.currentLocale) {
            // localize module

            let localeRef = moduleInfo._SupportedLocales[this.currentLocale];
            if (!localeRef) {
                let parentLocale = this.currentLocale;
                while ((parentLocale = LocaleService._LocaleInfos[parentLocale].parentLocale) && (localeRef = moduleInfo._SupportedLocales[parentLocale]) === null) { }
            }
            if (localeRef) {
                this.addLoadingPromise(localeRef().then((localeObj) => {
                    this.mergeLocalizedModule(moduleInfo, localeObj);
                }));
                
            }
        }

        this.currentModules[moduleName] = moduleInfo;
    }

    private mergeLocalizedModule(baseModule: BaseStrings, localized: BaseStrings) {
        baseModule._CurrentLocale = localized._CurrentLocale;

        Object.keys(baseModule).forEach((moduleKey) => {
            if (moduleKey[0] === "_" || !baseModule.hasOwnProperty(moduleKey))
                return;

            baseModule[moduleKey] = localized[moduleKey];
        });
    }


    public getLocalizedString(stringKey: string): string {
        let keyParts: string[];
        if (!stringKey || (keyParts = stringKey.split(".")).length !== 2)
            return null;

        let stringsRef = this.currentModules[keyParts[0]];
        if (!stringsRef)
            return null;

        return stringsRef[keyParts[1]] || null;
    }

}
