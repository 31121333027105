import { LocaleService, BaseStrings, Locale } from "../../../common/business/LocaleService";

export class FileUploadDialogStrings extends BaseStrings {
    readonly _ModuleName = "FileUploadDialogStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new FileUploadDialogStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./FileUploadDialogStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    FileUpload_Title = "Upload file";
    FileUpload_Location = "Storage location:";
    FileUpload_LocationPlaceholder = "Select storage location";

    FileUpload_SelectLocation = "Select";
    FileUpload_UploadButton = "Upload";
    FileUpload_CancelButton = "Cancel";
    FileUpload_CloseButton = "Close";
    FileUpload_OpenSaveLocationButton = "Open storage location";

    FileUpload_Complete = "Successfully uploaded";
    FileUpload_Error = "An error has occured";
    FileUpload_Conflict = "A file with this name already exists";
    FileUpload_Progress = "uploading";
    FileUpload_AccessDenied = "Access denied. Please check if you have write access";
}

export default (() => {
    let dict = new FileUploadDialogStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
