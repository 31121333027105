import { ServiceLoader } from "./ServiceLoader";
import { PromiseDfd } from '../utils/PromiseDfd';
import { MapBuilder } from "../utils/MapBuilder";
import { RequestService } from './RequestService';

export interface IGraphApiOptions {
    batchDelay?: number;
    batchLimit?: number;
}

export interface IBatchRequest {
    method: string;
    batch?: string;
    cache?: number;
    scopes?: string[];
}

export interface IGraphRequest extends IBatchRequest {
    version: string;
    path: string;
    data?: any;
}

export interface ISpRestRequest extends IBatchRequest {
    siteUrl: string;
    restUrl: string;
    version?: string;
    data?: any;
}

export interface ISpGraphRequest extends IBatchRequest {
    siteUrl: string;
    restUrl: string;
    version: string;
    data?: any;
}


enum GraphRequestType {
    GraphApi = 1,
    SpRestApi = 2,
    SpGraphApi = 3
}

enum GraphResponseEncoding {
    Default = 0,
    BlobData = 1,
    PlainText = 2,
}

interface IGraphBatchRequest {
    id: string;
    type: GraphRequestType;
    method: string;
    args: TGraphRequestApiArgs;
    data?: any;
    cache?: number;
    scopes?: string[];
}

type TGraphRequestApiArgs = IGraphGraphApiArgs | IGraphSpRestApiArgs | IGraphSpGraphApiArgs;

interface IGraphGraphApiArgs {
    version: string,
    path: string;
}

interface IGraphSpRestApiArgs {
    site: string;
    path: string;
}

interface IGraphSpGraphApiArgs {
    version: string,
    site: string;
    path: string;
}

interface IGraphBatchBody {
    requests: IGraphBatchRequest[];
}

class GraphPendingRequest {
    public readonly reqid: string;
    public readonly reqtype: GraphRequestType;
    public readonly method: string;
    public readonly reqargs: TGraphRequestApiArgs;
    public readonly reqdata: any;
    public readonly cache: number;
    public readonly scopes: string[];
    public readonly promise: Promise<any>;
    public startCallback: () => void;
    private promiseDfd: PromiseDfd<any>;
    private hasResponse: boolean;

    public constructor(reqtype: GraphRequestType, method: string, reqargs: TGraphRequestApiArgs, data: any, cache: number, scopes: string[]) {
        this.reqtype = reqtype;
        this.method = method;
        this.reqargs = reqargs;
        this.reqdata = data;
        this.cache = cache;
        this.scopes = scopes;

        this.hasResponse = false;

        this.promiseDfd = new PromiseDfd<any>();
        this.promise = this.promiseDfd.promise;
    }

    public processResponse(success: boolean, result: any, encoding: GraphResponseEncoding = GraphResponseEncoding.Default): void {
        if (this.hasResponse)
            return;
        this.hasResponse = true;

        switch(encoding) {
            case GraphResponseEncoding.BlobData:
                let binData = new Uint8Array(result.length);
                for(let i = 0; i < binData.length; i++){
                    binData[i] = result.data.charCodeAt(i);
                }
                result = binData;
                break;
        }

        if (success)
            this.promiseDfd.resolve(result);
        else
            this.promiseDfd.reject(result);
    }
}

class GraphRequestBatch {
    public started: boolean;
    public ready: boolean;
    private batchLimit: number;
    private startPromiseDfd: PromiseDfd<void>;
    public startPromise: Promise<void>;
    private readyPromiseDfd: PromiseDfd<void>;
    public readyPromise: Promise<void>;
    public requests: GraphPendingRequest[];
    public startCallback: () => void;


    public constructor(startDelay?: number, batchLimit?: number) {
        this.started = false;
        this.ready = false;
        this.batchLimit = batchLimit || 20;
        this.requests = [];

        this.startPromiseDfd = new PromiseDfd<void>();
        this.startPromise = this.startPromiseDfd.promise;

        this.readyPromiseDfd = new PromiseDfd<void>();
        this.readyPromise = this.readyPromiseDfd.promise;

        window.setTimeout(() => {
            this.executeBatch();
        }, startDelay || 10);
    }

    public addRequest(req: GraphPendingRequest): Promise<any> {
        this.requests.push(req);
        if (this.requests.length >= this.batchLimit) {
            this.executeBatch();
        }
        return req.promise;
    }

    public executeBatch(): void {
        if (this.started)
            return;

        let reqPromise: Promise<void>;
        let reqsvc = ServiceLoader.GetService(RequestService);

        if (this.requests.length === 0) {
            // no request
            reqPromise = Promise.resolve();
        }
        else {
            // package request
            let requests: IGraphBatchRequest[] = this.requests.map((item, idx) => {
                if (item.startCallback)
                    item.startCallback();
                
                let req: IGraphBatchRequest = {
                    id: idx.toString(),
                    type: item.reqtype,
                    method: item.method,
                    args: item.reqargs,
                    data: item.reqdata,
                };
                if(item.cache)
                    req.cache = item.cache;
                if(item.scopes)
                    req.scopes = item.scopes;
                return req;
            });
            let body: IGraphBatchBody = {
                requests: requests 
            };

            reqPromise = reqsvc.requestAadService({
                method: "POST",
                url: "/api/graphapi/batch",
                data: JSON.stringify(body),
                header: MapBuilder.NewMap<string, string>([
                    ["Content-Type", "application/json"]
                ])
            }).then((rspData) => {
                if (!rspData.success)
                    throw rspData.response;
                return JSON.parse(rspData.response);
            }).then((responses) => {
                this.requests.forEach((request, idx) => {
                    let response = responses.responses[idx.toString()];

                    if (response.response && response.response.error)
                        request.processResponse(false, response.response.error);
                    else if (response.status >= 300 || response.status < 200)
                        request.processResponse(false, response.response, response.encoding);
                    else
                        request.processResponse(true, response.response, response.encoding);
                });
            }, (error) => {
                this.requests.forEach((request) => {
                    request.processResponse(false, error);
                });
            });
        }

        this.started = true;
        if (this.startCallback)
            this.startCallback();
        this.startPromiseDfd.resolve();
        reqPromise.then(() => {
            this.ready = true;
            this.readyPromiseDfd.resolve();
        }, (err) => {
            console.error(err);
            this.ready = true;
            this.readyPromiseDfd.reject();
        });
    }
}

export class GraphBatchService {

    private pendingBatches: Map<string, GraphRequestBatch>;
    private options: IGraphApiOptions;

    constructor(options?: IGraphApiOptions) {
        this.pendingBatches = new Map<string, GraphRequestBatch>();
        if (!(this.options = options))
            this.options = {};
        if (!this.options.batchDelay)
            this.options.batchDelay = 100;
        if (!this.options.batchLimit)
            this.options.batchLimit = 40;
    }

    private getPendingBatch(batchKey: string): GraphRequestBatch {
        if (!batchKey)
            batchKey = "#";
        if (this.pendingBatches.has(batchKey))
            return this.pendingBatches.get(batchKey);

        let batch = new GraphRequestBatch(this.options.batchDelay, this.options.batchLimit);
        this.pendingBatches.set(batchKey, batch);
        batch.startCallback = () => {
            if (this.pendingBatches.get(batchKey) === batch) {
                this.pendingBatches.delete(batchKey);
            }
        };
        return batch;
    }

    private addRequest(reqtype: GraphRequestType, method: string, reqargs: TGraphRequestApiArgs, reqdata: any, batch: string, skipQueue: boolean, serverCache: number, scopes: string[]) {
        let req = new GraphPendingRequest(reqtype, method, reqargs, reqdata, serverCache, scopes);
        if(skipQueue) {
            let batch = new GraphRequestBatch(this.options.batchDelay, this.options.batchLimit);
            batch.addRequest(req);
            batch.executeBatch();
        }
        else {
            this.getPendingBatch(batch).addRequest(req);
        }
        return req.promise;
    }

    public addGraphRequest(request: IGraphRequest, skipQueue?: boolean): Promise<any> {
        return this.addRequest(GraphRequestType.GraphApi, request.method, {
            version: request.version,
            path: request.path,
        }, request.data, request.batch, skipQueue, request.cache, request.scopes);
    }

    public addSpRestRequest(request: ISpRestRequest, skipQueue?: boolean): Promise<any> {
        return this.addRequest(GraphRequestType.SpRestApi, request.method, {
            site: request.siteUrl,
            path: request.restUrl,
        }, request.data, request.batch, skipQueue, request.cache, request.scopes);
    }

    public addSpGraphRequest(request: ISpGraphRequest, skipQueue?: boolean): Promise<any> {
        return this.addRequest(GraphRequestType.SpRestApi, request.method, {
            version: request.version,
            site: request.siteUrl,
            path: request.restUrl,
        }, request.data, request.batch, skipQueue, request.cache, request.scopes);
    }

}
