import { SmartPortal } from '../../SmartPortal';
import PageStrings from '../../components/basepage/loc/PageStrings'

export class PromptService {
    private _blocked: string[];
    private _release: () => void;

    private getPromptMessage(): string {
        return PageStrings.Navigation_PromptMessage;
    }

    public constructor() {
        this._blocked = [];
    }

    public blockNavigation(key: string): void {
        if (this._blocked.indexOf(key) === -1) {
            if (this._blocked.length === 0) {
                window.addEventListener("beforeunload", this.blockEventListener);
                if (SmartPortal.currentHistory && SmartPortal.currentHistory.block)
                    this._release = SmartPortal.currentHistory.block(this.getPromptMessage());
            }
            this._blocked.push(key);
        }
    }

    public releaseNavigation(key: string): void {
        const index = this._blocked.indexOf(key);
        if (index >= 0) {
            this._blocked.splice(index, 1);
            if (this._blocked.length === 0) {
                window.removeEventListener("beforeunload", this.blockEventListener);
                if (this._release)
                    this._release();
            }
        }
    }

    public isNavigationBlocked(): boolean {
        return this._blocked.length > 0;
    }

    public checkBlockedNavigation(key: string): boolean {
        if (this._blocked.indexOf(key) >= 0) {
            const ok = window.confirm(this.getPromptMessage());
            if (ok) this.releaseNavigation(key);
            return ok;
        }
        return true;
    }

    private blockEventListener(event) {
        event.preventDefault();
        event.returnValue = this.getPromptMessage();
        return event;
    }
}