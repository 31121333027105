import * as React from 'react';
import { SmartPortal } from '../../SmartPortal';
import PageStrings from './loc/PageStrings';
import styles from "./PageStyle.scss";

export interface IAccessDeniedPageProps {
}

export interface IAccessDeniedPageState {
}

export class AccessDeniedPage extends React.Component<IAccessDeniedPageProps, IAccessDeniedPageState> {

    constructor(props: IAccessDeniedPageProps, state: IAccessDeniedPageState) {
        super(props);

        this.state = {
        };
    }

    public render(): React.ReactElement<IAccessDeniedPageProps> {
        return (
            <div className={styles.AccessDenied}>
                <div className={styles.AccessDeniedHeader}>
                    <img src={SmartPortal.buildPortalUrl("/images/AccessDeniedHeader.png")} />
                </div>
                <h1>{PageStrings.AccessDenied_Title}</h1>
                <p dangerouslySetInnerHTML={{ __html: PageStrings.AccessDenied_Intro }}></p>
            </div>
        );
    }

}
