import { LocaleService, BaseStrings, Locale } from "../../../common/business/LocaleService";

export class BrowserRestrictionStrings extends BaseStrings {
    readonly _ModuleName = "BrowserRestrictionStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new BrowserRestrictionStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./BrowserRestrictionStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    BrowserRestrictionPage_Intro = "This function is not available for Internet Explorer.";
    BrowserRestrictionPage_Advice = "Use a modern browser such as Edge or Chrome to enjoy SmartPortal365 with all its advantages!";
    BrowserRestrictionPage_Message = "Unfortunately, not all functions are available to you with Internet Explorer. Use a modern browser (e.g. Edge, Chrome) to enjoy the advantages.";
}

export default (() => {
    let dict = new BrowserRestrictionStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
