import { IPeoplePickerItem, PeoplePickerItemType } from '../model/PeoplePickerItem';

export interface IPickerDataSourceProps {
    cacheTimeout?: number;
    debounceTime?: number;
    searchMinLen?: number;
}

interface IPickerDataSourceSuggestionCache {
    term: string;
    items: IPeoplePickerItem[];
    time: Date;
}

export abstract class PickerDataSource<TProps extends IPickerDataSourceProps> {
    protected props: TProps;
    private suggestionCache: IPickerDataSourceSuggestionCache[] = [];
    private debounceTimer: number;
    private debounceTerm: string;

    public constructor(props: TProps) {
        this.props = props;
    }

    public getSuggestions(term: string): Promise<IPeoplePickerItem[]> {
        this.debounceTerm = null;
        if(typeof term !== "string" || (this.props.searchMinLen && term.length < this.props.searchMinLen))
            return null;

        let now = new Date();
        let cacheTerm = term.trim().toLowerCase();
        for(let i = this.suggestionCache.length - 1; i >= 0; i--) {
            let cacheEntry = this.suggestionCache[i];

            if(this.props.cacheTimeout && (now.getTime() - cacheEntry.time.getTime())/1000 > this.props.cacheTimeout) {
                this.suggestionCache.splice(i, 1);
                continue;
            }
            if(cacheEntry.term.length > cacheTerm.length || cacheTerm.substr(0, cacheEntry.term.length) !== cacheEntry.term)
                continue;
            
            if(cacheEntry.term === cacheTerm)
                return Promise.resolve(cacheEntry.items);
            else
                return Promise.resolve(this.filterSuggestions(cacheEntry.items, term));
        }

        this.debounceTerm = term;
        
        let promise: Promise<void>;
        if(this.props.debounceTime)
            promise = this.debounceCall();
        else
            promise = Promise.resolve();

        return promise.then(() => {
            return this.loadSuggestions(this.debounceTerm);
        }).then((items) => {
            if(this.suggestionCache.length > 50)
                this.suggestionCache.shift();

            this.suggestionCache.push({
                term: cacheTerm,
                items: items,
                time: new Date(),
            });

            return items;
        });
    }

    private debounceCall(): Promise<void> {
        return new Promise((resolve) => {
            if(this.debounceTimer)
                window.clearTimeout(this.debounceTimer);

            this.debounceTimer = window.setTimeout(() => {
                this.debounceTimer = null;
                resolve();
            }, this.props.debounceTime);
        });
    }

    protected abstract loadSuggestions(term: string): Promise<IPeoplePickerItem[]>;
    protected abstract filterSuggestions(items: IPeoplePickerItem[], term: string): IPeoplePickerItem[];
    public abstract getPickerItemByField(itemType: PeoplePickerItemType, fieldName: string, fieldValue: string): Promise<IPeoplePickerItem>;

}
