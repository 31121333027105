import { BaseCard, StreamSource } from "..";

export class OtherCard extends BaseCard {

  public get modifyTime(): Date { let val = this.getDataField("modifyTime"); return val ? new Date(val) : null; };
  public set modifyTime(val: Date) { this.setDataField("modifyTime", val ? val.toString() : null); };

  public constructor(source: StreamSource, itemRef: any){
    super(source, itemRef);
  }

}
