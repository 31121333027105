import * as React from 'react';
import strings from './loc/ProvisioningStrings';
import styles from "./ProvisioningStyle.scss";
import { Dialog } from '../../common/controls/Dialog/Dialog';
import { Action } from "../../common/controls/Panel";
import { ServiceLoader } from '../../common/business/ServiceLoader';
import { RequestService } from '../../common/business/RequestService';
import { Spinner, SpinnerSize, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { SiteTemplateForm } from './controls/SiteTemplateForm';
import { SiteDetailsForm, ISiteDetailsFormData, SiteDetailsEditMode } from './controls/SiteDetailsForm';
import { SiteInfoVisibility, SiteSharingCapability, SiteLanguage, SiteTimeZone, SiteInfoGuestsAccess, SiteProvisioningType, IProvisionTemplate, ISiteInfoRequestSite, ISiteInfoRequestTeams, IAccount, IPrincipal } from '../../common/models/SiteInfo';
import { IAccountInfo } from 'react-aad-msal';

export interface ISiteRequestDialogProps {
    onReturn: () => void;
}

export interface ISiteRequestDialogState {
    loading: boolean;
    submitting: boolean;
    submitMessage?: string;
    currentStep: number;
    provisionTemplate?: IProvisionTemplate;
    siteDetails: ISiteDetailsFormData;
    isFormValid?: boolean;
    formValidationMessage?: string;
}

export class SiteRequestDialog extends React.Component<ISiteRequestDialogProps, ISiteRequestDialogState> {
    
    private containerEl: HTMLElement;
    private detailsForm: SiteDetailsForm;
    private summaryForm: SiteDetailsForm;

    constructor(props: ISiteRequestDialogProps, state: ISiteRequestDialogState) {
        super(props);

        this.state = {
            loading: false,
            submitting: false,
            currentStep: 0,
            siteDetails: {                
                title: "",
                description: "",
                siteType: null,
                siteTemplate: null,
                primaryOwner: "",
                primaryOwnerAccount: null,
                secondaryOwner: "",
                secondaryOwnerAccount: null,
                visibility: SiteInfoVisibility.Private,
                sharingCapability: SiteSharingCapability.ExternalUserSharingOnly,
                guestsAccess: SiteInfoGuestsAccess.Forbid,
                language: SiteLanguage.English,
                timeZoneId: SiteTimeZone.Amsterdam_Berlin_Bern_Rome_Stockholm_Vienna,
                classification: "",
                hubSiteId: null,
                hubSiteTitle: null,
                registerHubSite: false,
                lifeCycle: null,
                siteStatus: null,
                members: [],
                visitors: []
            }
        };
    }

    public render(): React.ReactElement<ISiteRequestDialogProps> {
        let currentControl: JSX.Element = null;
        let headerText = strings.SiteRequestDialog_Title;

        if(this.state.loading)
            currentControl = <Spinner title="Loading Dialog" size={SpinnerSize.large} />;
        else {
            switch (this.state.currentStep) {
                case 0:
                    currentControl = <SiteTemplateForm
                        onReturn={(provisionTemplate?: IProvisionTemplate) => {
                            this.notifyPageChange();
                            const siteDetails = { ...this.state.siteDetails };
                            siteDetails.siteType = provisionTemplate ? provisionTemplate.type : null;
                            siteDetails.siteTemplate = provisionTemplate;

                            this.setState({
                                provisionTemplate: provisionTemplate,
                                siteDetails: siteDetails,
                                currentStep: 1,
                                isFormValid: false
                            });
                        }} />;
                    break;
                case 1:
                    headerText = strings.SiteDetailsForm_CreateRoomTitle;
                    currentControl = (
                        <div className={styles.SiteDetailsFormContainer}>
                            <SiteDetailsForm
                                ref={(ref) => this.detailsForm = ref}
                                formData={this.state.siteDetails}
                                editMode={SiteDetailsEditMode.NewForm}
                                preSelected={!!this.state.provisionTemplate}
                                onUpdate={(data, valid, validationMessage) => {
                                    this.setState({ siteDetails: data, isFormValid: valid, formValidationMessage: validationMessage });
                                }}
                            />
                            <div className={styles.SiteRequestActions}>
                                <div>
                                    <DefaultButton text={strings.SiteDetailsForm_BackButton} type="button" onClick={() => {
                                        this.notifyPageChange();
                                        this.setState({ currentStep: 0 });
                                    }} />
                                </div>
                                <div className={styles.SpacerAction}></div>
                                {this.props.onReturn ? <div>
                                    <PrimaryButton text={strings.SiteDetailsForm_ReviewButton} type="button" disabled={!this.state.isFormValid} onClick={() => {
                                        if (!this.detailsForm)
                                            return;
                                        this.notifyPageChange();
                                        this.setState({
                                            currentStep: 2,
                                            submitting: false,
                                        });
                                    }} />
                                </div> : null}
                            </div>
                        </div>
                    );
                    break;
                case 2:
                    headerText = strings.SiteDetailsForm_CreateRoomTitle;
                    currentControl = (
                        <div className={styles.SiteDetailsFormContainer}>
                            <SiteDetailsForm
                                ref={(ref) => this.summaryForm = ref}
                                formData={this.state.siteDetails}
                                editMode={SiteDetailsEditMode.SummaryForm}
                            />
                            <div className={styles.SiteRequestActions}>
                                <div>
                                    <DefaultButton text={strings.SiteDetailsForm_BackButton} type="button" onClick={(evt) => {
                                        this.notifyPageChange();
                                        this.setState({ currentStep: 1 });
                                    }} />
                                </div>
                                <div className={styles.SpacerAction}></div>
                                {this.props.onReturn ? <div>
                                    <PrimaryButton text={strings.SiteSummaryForm_CreateRoomButton} type="button" disabled={!this.state.isFormValid} onClick={(evt) => {
                                        if (!this.summaryForm)
                                            return;
                                        this.notifyPageChange();
                                        this.setState({
                                            currentStep: 3,
                                            submitting: true,
                                        });

                                        this.startSiteProvisioning(this.state.siteDetails).then(() => {
                                            this.setState({
                                                submitting: false,
                                            });
                                        }).catch(error => {
                                            this.setState({
                                                submitting: false,
                                                submitMessage: error.validationMessage ? error.validationMessage : JSON.stringify(error)
                                            });
                                        });
                                    }} />
                                </div> : null}
                            </div>
                        </div>
                    );
                    break;
                case 3:
                    headerText = strings.SiteRequestComplete_PanelTitle;
                    currentControl = (
                        <div>
                            <div className={styles.SiteRequestSubmit}>
                                {this.state.submitting ?
                                    <Spinner size={SpinnerSize.medium} /> :
                                    <div className={styles.NeedsPanelSubtext}>
                                        {this.state.submitMessage ? this.state.submitMessage : strings.SiteRequestComplete_PanelSubtext}
                                    </div>
                                }
                            </div>
                            <div className={styles.SiteRequestActions}>
                                <div className={styles.SpacerAction}></div>
                                <div>
                                    <PrimaryButton text={strings.SiteRequestComplete_CloseButton} type="button" onClick={(evt) => this.props.onReturn()} />
                                </div>
                            </div>
                        </div>
                    );
                    break;
            }
        }

        return (
            <Dialog headerText={headerText} className={styles.SiteRequestDialog} actions={[Action.Close]} onAction={(action) => {
                if (action === Action.Close) {
                    this.props.onReturn();
                }
                return Promise.resolve();
            }} errors={!this.state.isFormValid && this.state.formValidationMessage ? [{ type: "validationError", message: this.state.formValidationMessage }] : null}>
                <div className={styles.SiteRequestDialogContent} ref={(ref) => this.containerEl = ref}>
                    {currentControl}
                </div>
            </Dialog>
        );
    }

    private notifyPageChange() {
        if(!this.containerEl)
            return;

        let event = new CustomEvent("sp365.pagechange", {
            bubbles: true,
        });
        this.containerEl.dispatchEvent(event);
    }

    private startSiteProvisioning(siteDetails: ISiteDetailsFormData): Promise<void> {

        let siteRequest: ISiteInfoRequestSite | ISiteInfoRequestTeams;
        let apiPath: string;

        switch(siteDetails.siteType) {
            case SiteProvisioningType.MSTeams:
                apiPath = "/api/directory/teams";
                siteRequest = {
                    title: siteDetails.title,
                    description: siteDetails.description,
                    primaryOwner: siteDetails.primaryOwner,
                    secondaryOwner: siteDetails.secondaryOwner,
                    provisionTemplateId: siteDetails.siteTemplate.id,
                    timeZoneId: siteDetails.timeZoneId,
                    sharingCapability: siteDetails.sharingCapability,
                    nickname: siteDetails.nickname,
                    visibility: siteDetails.visibility,
                    guestsAccess: siteDetails.guestsAccess,
                    classification: siteDetails.classification,
                    hubSiteId: siteDetails.hubSiteId,
                    hubSiteTitle: siteDetails.hubSiteTitle,
                    registerHubSite: siteDetails.registerHubSite,                    
                    members: siteDetails.members
                } as ISiteInfoRequestTeams;
                break;
            case SiteProvisioningType.SPOSite:
                apiPath = "/api/directory/sites";
                siteRequest = {
                    title: siteDetails.title,
                    description: siteDetails.description,
                    primaryOwner: siteDetails.primaryOwner,
                    secondaryOwner: siteDetails.secondaryOwner,
                    provisionTemplateId: siteDetails.siteTemplate.id,
                    timeZoneId: siteDetails.timeZoneId,
                    sharingCapability: siteDetails.sharingCapability,
                    siteUrl: siteDetails.siteUrl,
                    visibility: siteDetails.visibility,
                    language: siteDetails.language,
                    classification: siteDetails.classification,
                    hubSiteId: siteDetails.hubSiteId,
                    hubSiteTitle: siteDetails.hubSiteTitle,
                    registerHubSite: siteDetails.registerHubSite,
                    members: siteDetails.members,
                    visitors : siteDetails.visitors
                } as ISiteInfoRequestSite;
                break;
        }

        let reqsvc = ServiceLoader.GetService(RequestService);
        
        return reqsvc.requestAadService({
            method: "POST",
            url: apiPath,
            header: new Map<string, string>([
                ["Content-Type", "application/json"]
            ]),
            data: JSON.stringify(siteRequest)
        }).then(reqsvc.parseApiResponse).then();
    }
}
