
export class MapBuilder<K, V> {

    public static NewMap<K, V>(initData: [K, V][] = null): Map<K, V> {
        let map = new Map<K, V>();

        if(initData) {
            initData.forEach((data) => map.set(data[0], data[1]));
        }

        return map;
    }

}

