
import { LocaleService } from "./common/business/LocaleService";
import { ServiceLoader } from "./common/business/ServiceLoader";
import { RequestService } from "./common/business/RequestService";

import { UserType, IErrorList, IContextAndSessionInfo } from "./common/models/SessionInfo";
import { IUserInfo } from "./common/models/UserInfo";
import { DynPageContent } from "./components/basepage/DynPageContent";
import { IConfiguration } from './common/models/Configuration';
import { LicensingService } from './common/business/LicensingService';
import { RoleService, Role } from './common/business/RoleService';
import { AppPolicy } from './common/models/AppPolicy';
import { IBasePage } from './components/basepage/BasePage';
import { IAppBuildInfo } from './common/models/AppBuildInfo';
import { days } from './common/utils/DateHandler';

import SmartPortalStyles from "./styles/SmartPortal.scss";
import PageStyle from "./components/basepage/PageStyle.scss";

export module SmartPortal {

    export class Services {
        public static readonly ServiceLoader = ServiceLoader;
        public static readonly RequestService = RequestService;
    }

    export class Enums {
        public static UserType = UserType;
        public static AppPolicy = AppPolicy;
    }

    export interface IFormDataRef {
        type: string;
        data: any;
    }
    
    export let appBuildInfo: IAppBuildInfo = process.env.BuildInfo as any;
    export let portalBaseUrl: string = "/";
    export let currentPage: IBasePage = null;
    export let currentSession: IContextAndSessionInfo = null;
    export let currentUserId: string = null;
    export let currentUser: IUserInfo = null;
    export let currentDynPageContent: DynPageContent = null;
    export let configuration: IConfiguration = null;
    export let currentHistory: any = null;
    export let currentErrors: IErrorList = null;
    export let currentFormData: IFormDataRef = null;
    export let isAppStarted: boolean = false;
    export let currentHost: "Main" | "SPApp" | "MSTeams" = "Main";

    export function startApp(sessionInfo: IContextAndSessionInfo, host: "Main" | "SPApp" | "MSTeams"): Promise<void> {
        currentSession = sessionInfo;
        currentHost = host;

        currentUser = sessionInfo ? sessionInfo.currentUser : null;
        currentUserId = sessionInfo ? sessionInfo.userId : null;            

        configuration = sessionInfo ? sessionInfo.configuration : null;
        isAppStarted = true;
        return ServiceLoader.GetService(LocaleService).getReadyPromise();
    }

    export function getLocalizedString(stringKey: string) {
        return ServiceLoader.GetService(LocaleService).getLocalizedString(stringKey);
    }

    export function checkAppPolicy(policy: AppPolicy): boolean {
        return ServiceLoader.GetService(LicensingService).checkPolicy(policy);
    }

    export function checkAppPolicyExpires(policy: AppPolicy): boolean {
        var expiresOn = ServiceLoader.GetService(LicensingService).getPolicyExpire(policy);
        return days(expiresOn) <= 14;
    }

    export function getClassName(className: string) {
        if (SmartPortalStyles[className]) return SmartPortalStyles[className];
        if (PageStyle[className]) return PageStyle[className];
        return className;
    }

    export function navigateTo(url: string, openInTab = false) {
        if (url.match(/^https?:\/\//) || openInTab) {
            var win = window.open(url, '_blank');
            win.focus();
        }
        else if (currentHistory) {
            currentHistory.push(url);
        } else {
            location.href = url;
        }
    }

    export function buildPortalUrl(path: string): string {
        let url = portalBaseUrl;
        if(!url.match(/\/$/))
            url += "/";
        if(path.match(/^\//))
            url += path.substr(1);
        else
            url += path;
        return url;
    }

    export function isUserInRole(role: Role): boolean {
        return ServiceLoader.GetService(RoleService).IsUserInRole(role);
    }

    export function printDebugLog(message: string, ...args: any[]): void {
        if(!SmartPortal.appBuildInfo.debug)
            return;
        
        let logArgs = args || [];
        logArgs.unshift(message);
        console.log.apply(console, logArgs);
    }
}
