import { LocaleService, BaseStrings, Locale } from "../../../business/LocaleService";

export class FolderPickerStrings extends BaseStrings {
    readonly _ModuleName = "FolderPickerStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new FolderPickerStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./FolderPickerStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    FolderPicker_SelectButton = "Select";
    FolderPicker_CancelButton = "Cancel";
    FolderPicker_FileTypeColumnName = "File type";
    FolderPicker_NameColumnName = "Name";
    FolderPicker_ShowMore = "Show more";
    FolderPicker_Loading = "Loading";
    FolderPicker_Placeholder = "Search";
}

export default (() => {
    let dict = new FolderPickerStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
