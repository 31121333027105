import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PageContext } from '@microsoft/sp-page-context';
import { IBasePage, IPortalPageOverlay } from '../../../components/basepage/BasePage';
import { IPortalPanelOptions, PortalPanel } from '../../../components/basepage/PortalPanel';
import { PortalLeftBar, PortalLeftBarNavDict } from '../../../components/basepage/PortalLeftBar';
import { SmartPortal } from '../../../SmartPortal';
import SharePointStyle from "./SharePointStyle.scss";
import SmartPortalStyles from "../../../styles/SmartPortal.scss";
import { DynPageContent } from '../../../components/basepage/DynPageContent';
import { ServiceLoader } from '../../../common/business/ServiceLoader';
import { RequestService } from '../../../common/business/RequestService';
import { SideBarButton } from '../../../components/sideBar/SideBarButton';

export interface ISharePointPageProps {
    pageContext: PageContext;
    renderLeftBar?: boolean;
    renderPanels?: boolean;
    activeNavKey?: string;
    navBarInRightPanel?: boolean;
    navItems?: PortalLeftBarNavDict;
}

export class SharePointPage implements IBasePage {
    private props: ISharePointPageProps;
    private mutationObserver: MutationObserver;
    private spWorkspaceEl: Element;
    private spThemeStyleEl: Element;
    private activeNavKey: string;
    private leftBarControl: PortalLeftBar;
    private leftPanelControl: PortalPanel;
    private rightPanelControl: PortalPanel;
    private activeOverlays: IPortalPageOverlay[] = [];
    private isDetached: boolean;

    public constructor(props: ISharePointPageProps) {
        SmartPortal.currentPage = this;
        this.props = props;
        this.activeNavKey = props.activeNavKey;

        document.body.classList.add(SharePointStyle.SmartPortalIntegration);
        document.body.addEventListener("mousedown", (evt) => this.onPageMouseDown(evt));
        this.mutationObserver = this.initMutationObserver(document.body, {
            ".spAppAndPropertyPanelContainer": (element) => this.injectSpAppBody(element),
            ".sp-App-body": (element) => this.injectSpAppBody(element),
            "#HeaderButtonRegion": (element) => this.injectSpHeaderButtons(element),
        }, true);

        this.injectSpThemeStyle();
    }

    public detachPage(): void {
        this.isDetached = true;
        this.mutationObserver.disconnect();
        this.detachSpThemeStyle();
        this.detachSpAppBody();
        this.detachSpHeaderButtons();
    }

    public setActiveNavKey(navKey: string) {
        if (this.leftBarControl)
            this.leftBarControl.setActiveNavKey(navKey);
        else
            this.activeNavKey = navKey;
    }

    public setPreviousNavKey() {
        if (this.leftBarControl)
            this.leftBarControl.setPreviousNavKey();
    }

    public setDynamicContent(name: string, children: React.ReactNode | React.ReactNode[] | null): void {
        if (SmartPortal.currentDynPageContent) {
            SmartPortal.currentDynPageContent.setContent(name, children);
        }
    }

    public setLeftPanel(panelProps: IPortalPanelOptions): void {
        if (!this.leftPanelControl)
            return;
        
        if(panelProps)
            this.leftPanelControl.showPanel(panelProps);
        else
            this.leftPanelControl.hidePanel();
    }

    public setRightPanel(panelProps: IPortalPanelOptions): void {
        if (!this.rightPanelControl)
            return;
        
        if(panelProps)
            this.rightPanelControl.showPanel(panelProps);
        else
            this.rightPanelControl.hidePanel();
    }

    public addPageOverlay(overlay: IPortalPageOverlay) {
        for (let i = 0; i < this.activeOverlays.length; i++) {
            if (this.activeOverlays[i].overlayName === overlay.overlayName) {
                // dismiss active overlay
                this.activeOverlays[i].dismissCallback();
                this.activeOverlays.splice(i, 1);
                break;
            }
        }

        this.activeOverlays.push(overlay);
    }

    public removePageOverlay(overlayName: string, runDismiss: boolean = true) {
        for (let i = 0; i < this.activeOverlays.length; i++) {
            if (this.activeOverlays[i].overlayName === overlayName) {
                // dismiss active overlay
                if(runDismiss)
                    this.activeOverlays[i].dismissCallback();
                this.activeOverlays.splice(i, 1);
                break;
            }
        }
    }

    public setBodyScrollTop(scrollTop: number): void {
    }

    public getCurrentPageContext(): PageContext {
        return this.props.pageContext;
    }

    private initMutationObserver(container: Element, actions: {[selector: string]: (element: Element) => void}, runinitially: boolean = false): MutationObserver {
        let observer = new MutationObserver((records) => {
            for (let i = 0; i < records.length; i++) {
                let record = records[i];

                for(let aIdx = 0; aIdx < record.addedNodes.length; aIdx++) {
                    for(let selector in actions) {
                        if(!actions.hasOwnProperty(selector))
                            continue;
                        let containerEl: Element;
                        if ((containerEl = this.getMatchingChildNode(record.addedNodes[aIdx], selector))) {
                            actions[selector](containerEl);
                        }
                    }
                }
            }
        });

        observer.observe(container, {
            subtree: true,
            childList: true
        });

        if(runinitially) {
            for(let selector in actions) {
                if(!actions.hasOwnProperty(selector))
                    continue;
                let containerEl: Element;
                if ((containerEl = this.getMatchingChildNode(container, selector))) {
                    actions[selector](containerEl);
                }
            }
        }

        return observer;
    }

    private getMatchingChildNode(node: Node, selector: string): Element {
        if(node.nodeType !== 1)
            return null;
        let element = node as Element;
        if((element.matches || (element as any).msMatchesSelector || element.webkitMatchesSelector).call(element, selector))
            return element;
        var subEl;
        if((subEl = element.querySelector(selector)))
            return subEl;
        return null;
    }

    private getMatchingParentNode(node: Node, selector: string): Element {
        if(node.nodeType !== 1)
            return null;
        let element = node as Element;
        do {
            if((element.matches || (element as any).msMatchesSelector || element.webkitMatchesSelector).call(element, selector))
                return element;
        } while((element = element.parentElement) && element !== document.body);
        return null;
    }

    private injectSpThemeStyle(): Promise<void> {
        let reqsvc = ServiceLoader.GetService(RequestService);
        return reqsvc.requestAadService({
            method: "GET",
            url: "/Theming/Color"
        }).then(reqsvc.parseApiResponse).then((themeCssCode) => {
            if(this.isDetached)
                return;

            let themeStyleEl = this.spThemeStyleEl as HTMLStyleElement;
            if(!themeStyleEl) {
                this.spThemeStyleEl = themeStyleEl = document.createElement("style");
                themeStyleEl.setAttribute("type", "text/css");

                let headEl = document.getElementsByTagName("head")[0];
                headEl.appendChild(themeStyleEl);
            }

            if ((themeStyleEl as any).styleSheet) // IE
                (themeStyleEl as any).styleSheet.cssText = themeCssCode;
            else {
                themeStyleEl.innerHTML = "";
                themeStyleEl.appendChild(document.createTextNode(themeCssCode));
            }
        });
    }

    private detachSpThemeStyle() {
        if(!this.spThemeStyleEl || !this.spThemeStyleEl.parentElement)
            return;

        this.spThemeStyleEl.parentElement.removeChild(this.spThemeStyleEl);
        this.spThemeStyleEl = null;
    }

    private injectSpAppBody(element: Element) {
        let portalWorkspaceEl = this.getMatchingParentNode(element, "." + SharePointStyle.SPWorkSpace);
        if(portalWorkspaceEl)
            return; // do not inject twice!

        // create our own workspace element and move the sharepoint workspace inside
        this.spWorkspaceEl = element;
        portalWorkspaceEl = document.createElement("div");
        portalWorkspaceEl.className = SharePointStyle.SPWorkSpace;
        element.parentElement.replaceChild(portalWorkspaceEl, element);

        if(this.props.renderLeftBar) {
            // inject leftbar
            let leftbarEl = this.renderLeftBar();
            portalWorkspaceEl.appendChild(leftbarEl);
        }

        if (this.props.renderPanels && !this.props.navBarInRightPanel) {
            // inject left side panel
            let leftPanelEl = this.renderLeftPanel();
            portalWorkspaceEl.appendChild(leftPanelEl);
        }

        // inject placeholder for dialogs, etc.
        let dynContentEl = this.renderDynPageContent();
        portalWorkspaceEl.appendChild(dynContentEl);
        
        // insert sharepoint workspace
        element.classList.add(SharePointStyle.SharePointRoot);
        portalWorkspaceEl.appendChild(element);

        if(this.props.renderPanels) {
            // inject right side panel
            let rightPanelEl = this.renderRightPanel();
            portalWorkspaceEl.appendChild(rightPanelEl);
        }
    }

    private detachSpAppBody() {
        if(!this.spWorkspaceEl)
            return;

        let portalWorkspaceEl = this.spWorkspaceEl.parentElement;
        portalWorkspaceEl.parentElement.replaceChild(this.spWorkspaceEl, portalWorkspaceEl);
        this.spWorkspaceEl = null;
    }

    private injectSpHeaderButtons(element: Element) {
        if(element.querySelector("#SmartPortalSiteInfoButton_container"))
            return;

        let buttonEl = document.createElement("div");
        buttonEl.id = "SmartPortalSiteInfoButton_container";

        let topbarContainer = element.closest(".o365cs-base");

        let control =
            <SideBarButton navBar={this.props.navBarInRightPanel} navItems={this.props.navBarInRightPanel
                ? this.props.navItems
                : null} buttonContainer={topbarContainer || element}></SideBarButton>;
        ReactDOM.render(control, buttonEl);
        
        let firstChildEl = element.firstElementChild;
        if(firstChildEl)
            element.insertBefore(buttonEl, firstChildEl);
        else
            element.appendChild(buttonEl);
    }

    private detachSpHeaderButtons() {
        let element: Element;
        if((element = document.body.querySelector("#SmartPortalSiteInfoButton_container")))
            element.parentElement.removeChild(element);
        

    }


    private renderLeftBar(): Element {
        let container = document.createElement("div");
        container.classList.add(SharePointStyle.LeftBarContainer);

        let control = <PortalLeftBar 
            ref={(ref) => {
                this.leftBarControl = ref;
            }}
            navItems={this.props.navItems}
            activeNavKey={this.activeNavKey}
        />;
        ReactDOM.render(control, container);

        return container;
    }

    private renderLeftPanel(): Element {
        let container = document.createElement("div");
        container.classList.add(SharePointStyle.PanelContainer);

        let control = <PortalPanel 
            ref={(ref) => {
                this.leftPanelControl = ref;
            }}
            panelName="PortalLeftPanel"
            className={SharePointStyle.PortalLeftPanel}
        />;
        ReactDOM.render(control, container);

        return container;
    }

    private renderRightPanel(): Element {
        let container = document.createElement("div");
        container.classList.add(SharePointStyle.PanelContainer);

        let control = <PortalPanel 
            ref={(ref) => {
                this.rightPanelControl = ref;
            }}
            panelName="PortalRightPanel"
            className={SharePointStyle.PortalRightPanel}
        />;
        ReactDOM.render(control, container);

        return container;
    }

    private renderDynPageContent(): Element {
        let container = document.createElement("div");
        container.classList.add(SharePointStyle.DynContentContainer);
        container.classList.add(SmartPortalStyles.SmartPortal);

        let control = <DynPageContent />;
        ReactDOM.render(control, container);

        return container;
    }

    private onPageMouseDown(evt: MouseEvent) {
        let targetEl = evt.target as Element;

        for (let i = 0; i < this.activeOverlays.length; i++) {
            if (this.checkTargetInOverlay(this.activeOverlays[i], targetEl))
                continue;

            if (this.activeOverlays[i].dismissCallback()) {
                this.activeOverlays.splice(i, 1);
                i--;
            }
        }
    }

    private checkTargetInOverlay(overlay: IPortalPageOverlay, target: Element): boolean {
        let container = target;
        do {
            for (let i = 0; i < overlay.contentSelector.length; i++) {
                let doesMatch = (container.matches || (container as any).msMatchesSelector).call(container, overlay.contentSelector[i]);
                if (doesMatch)
                    return true;
            }
        } while ((container = container.parentElement) && container !== document.body);

        return false;
    }

}
