import { StreamSource, IStreamSource, IStreamDataRequest, IStreamDataResponse, IStreamLazyResponse, IStreamLazyField, BaseCard, DocumentCard } from "..";
import { GraphBatchService, IGraphRequest } from "../../business/GraphBatchService";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { IUserInfo } from "../../models/UserInfo";
import { MapBuilder } from "../../utils/MapBuilder";
import { ServiceLoader } from '../../business/ServiceLoader';
import { EFileTypeExtensions } from '../../utils/FileTypeProperties';

export abstract class ConsumerRecentDataSource<T> {
    public lazyFields: Map<string, IStreamLazyField>;
    protected abstract parseResponseCard(stream: StreamSource, item: T): BaseCard;
    public constructor() {
        this.lazyFields = MapBuilder.NewMap<string, IStreamLazyField>([
            ["fileDetails", {
                cache: true,
                serverCache: true,
                cacheTime: 60 * 60 * 24 * 7,
                loadFn: this.loadFileDetails.bind(this),
            }],
            ["thumbnailUrl", {
                cache: true,
                serverCache: true,
                cacheTime: 60 * 60 * 1,
                loadFn: this.loadThumbnailUrl.bind(this)
            }]
        ]);
    }

    protected parseDataResponse(stream: StreamSource, request: IStreamDataRequest, res: any, hadSkipToken: boolean): IStreamDataResponse {
        let nextPageToken: string = null;
        if ((nextPageToken = res["@odata.nextLink"])) {
            let nextPageUrl = new URL(nextPageToken);
            nextPageToken = nextPageUrl.searchParams.get("$skiptoken");
        }

        let cards = (res.value as Array<T>).map((item) => {
            return this.parseResponseCard(stream, item);
        });

        let hasMoreData: boolean;
        if (hadSkipToken) {
            hasMoreData = !!nextPageToken;
        }
        else if (request.cardLimit > 0) {
            hasMoreData = (cards.length >= request.cardLimit);
        }
        else {
            hasMoreData = false;
        }

        return {
            cards: cards,
            totalCards: (!hasMoreData ? request.cardIndex + cards.length : 0),
            moreCards: hasMoreData,
            streamObj: {
                skipToken: nextPageToken
            }
        };
    }

    private getServerCacheArg(fieldName: string): number {
        let fieldCfg = this.lazyFields.get(fieldName);
        if (!fieldCfg || !fieldCfg.serverCache || fieldCfg.cacheTime <= 0)
            return null;

        return fieldCfg.cacheTime;
    }
    private loadFileDetails(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        let itemPath: string;
        if (cardRef.itemPath)
            itemPath = cardRef.itemPath;
        else if (cardRef.driveId && cardRef.itemId)
            itemPath = "drives/" + cardRef.driveId + "/items/" + cardRef.itemId;

        let query = "?$select=id,name,sharepointIds,webUrl,webDavUrl,parentReference";
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            path: itemPath + query,
            version: "v1.0",
            cache: this.getServerCacheArg("fileDetails")
        }).then((rsp) => {
            let parentRef = null;
            if (rsp.parentReference && rsp.parentReference.driveId && rsp.parentReference.id) {
                parentRef = {
                    itemRef: "drives/" + rsp.parentReference.driveId + "/items/" + rsp.parentReference.id,
                    itemPath: rsp.parentReference.path,
                    itemType: "file",
                    driveId: rsp.parentReference.driveId,
                    itemId: rsp.parentReference.id,
                };
            }
            else if (rsp.parentReference) {
                parentRef = {
                    itemRef: rsp.parentReference.id,
                    itemPath: rsp.parentReference.path,
                    itemType: rsp.parentReference.driveType,
                    driveId: rsp.parentReference.driveId,
                    itemId: rsp.parentReference.id
                };
            }

            let spItem = null;
            if (rsp.sharepointIds) {
                spItem = {
                    siteUrl: rsp.sharepointIds.siteUrl,
                    webId: rsp.sharepointIds.webId,
                    siteId: rsp.sharepointIds.siteId,
                    listId: rsp.sharepointIds.listId,
                    itemId: rsp.listItem.id,
                };
            }

            return {
                data: {
                    name: rsp.name,
                    davUrl: rsp.webDavUrl,
                    webUrl: rsp.webUrl,
                    spItem: spItem,
                    parentRef: parentRef,
                }
            };
        });
    }

    private loadThumbnailUrl(ds: StreamSource, cardRef: any): Promise<IStreamLazyResponse> {
        if (cardRef.driveId && cardRef.itemId) {
            return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
                method: "GET",
                path: "drives/" + cardRef.driveId + "/items/" + cardRef.itemId + "/thumbnails?select=c400x400",
                version: "v1.0",
                cache: this.getServerCacheArg("thumbnailUrl")
            }).then((rsp) => {
                return {
                    data: (rsp.value && rsp.value.length) ? rsp.value[0]["c400x400"].url : null,
                };
            });
        }
        else {
            return Promise.resolve(null);
        }
    }
}


export class ConsumerDiscoverRecentDataSource extends ConsumerRecentDataSource<MicrosoftGraph.DriveItem> implements IStreamSource {
    public requestData(stream: StreamSource, request: IStreamDataRequest): Promise<IStreamDataResponse> {
        let hadSkipToken = false;
        let args: string[] = [];
        if (request.cardLimit > 0)
            args.push("$top=" + request.cardLimit);

        if (request.cardIndex && request.streamObj) {
            if (request.streamObj.skipToken) {
                hadSkipToken = true;
                args.push("$skiptoken=" + request.streamObj.skipToken);
            }
            else
                args.push("$skip=" + request.cardIndex);
        }

        let recentReq: IGraphRequest = {
            method: "GET",
            path: "me/drive/recent" + (args.length ? "?" + args.join("&") : ""),
            version: "v1.0"
        };
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest(recentReq).then((res) => {
            return this.parseDataResponse(stream, request, res, hadSkipToken);
        });
    }

    parseResponseCard(stream: StreamSource, item: MicrosoftGraph.DriveItem): BaseCard {

        let dataItem = item.remoteItem || item;
        let cardRefs: any = {
            itemRef: dataItem.id,
            itemUrl: dataItem.webUrl,
            siteUrl: dataItem.parentReference.path,
            driveId: dataItem.parentReference.driveId,
            itemId: dataItem.id,
            itemType: "file"
        };
        let card: BaseCard;

        let docCard = card = new DocumentCard(stream, cardRefs);
        docCard.lastModifyUser = this.getUserInfo(item.lastModifiedBy);
        if(dataItem.fileSystemInfo.lastAccessedDateTime){
            docCard.lastAccessTime = new Date(dataItem.fileSystemInfo.lastAccessedDateTime);
        }
        if(dataItem.fileSystemInfo.lastModifiedDateTime){
            docCard.lastModifyTime = new Date(dataItem.fileSystemInfo.lastModifiedDateTime);
        }

        
        if(dataItem.parentReference.driveType == "personal"){
            docCard.containerType = null;
            
            if(!item.remoteItem || (dataItem.parentReference && item.parentReference.driveId ==  dataItem.parentReference.driveId)){
                docCard.containerTitle = "OneDrive";
                if(dataItem.parentReference)
                    docCard.containerUrl = "https://onedrive.live.com/?cid=" + dataItem.parentReference.driveId;
            }
        }

        card.id = dataItem.id;
        card.type = this.getType(item);
        card.title = dataItem.name;
        card.webUrl = dataItem.webUrl;
        card.updateTime = new Date(dataItem.lastModifiedDateTime);
        return card;
    }

    private getType(driveItem: MicrosoftGraph.DriveItem): string {

        let item = driveItem.remoteItem || driveItem;

        if (item && item.package && item.package.type)
            return  item.package.type == "oneNote" ? "OneNote" : item.package.type ; //item.package.type;
        if (item.name) {
            let fileExtension = item.name.substring(item.name.lastIndexOf('.'), item.name.length);
            return Object.keys(EFileTypeExtensions).find(key => EFileTypeExtensions[key] === fileExtension);
        }
    }

    private getUserInfo(userData: any): IUserInfo {
        let userInfo: IUserInfo;
        if (userData && userData.user) {
            userInfo = {
                name: userData.user.displayName,
                mail: userData.user.email,
                upn: userData.user.userPrincipalName,
                country: ""
            };
        }
        else if (userData && userData.name) {
            userInfo = {
                name: userData.name,
                mail:userData.email,
                upn: userData.loginName,
                country: ""
            };
        }
        else
            userInfo = null;
        return userInfo;
    }
}