import * as React from 'react';
import styles from "./PageStyle.scss";
import { SmartPortal } from '../../SmartPortal';
import PageStrings from './loc/PageStrings';
import { QuickActionsPanel } from '../quickactions/QuickActionsPanel';

export interface IQuickActionsProps {
}

export interface IQuickActionsState {
}

export class QuickActions extends React.Component<IQuickActionsProps, IQuickActionsState> {

    constructor(props: IQuickActionsProps, state: IQuickActionsState) {
        super(props);
        this.state = {
        };
    }

    public componentDidMount(): void {
    }

    public render(): React.ReactElement<IQuickActionsProps> {
        return (
            <QuickActionsPanel renderInRightPanel={true} />
        );
    }
}

