
export enum EFileTypeExtensions {
  PowerPoint = ".pptx",
  Word = ".docx",
  Excel = ".xlsx",
  Pdf = ".pdf",
  OneNote = ".onetoc2",
  OneNotePage = ".one",
  InfoPath = ".infopathxml",
  Visio = ".vsdx",
  Publisher = ".pubx",
  Project = ".mppx",
  Access = ".accdb",
  Mail = ".mail",
  Csv = ".csv",
  Archive = ".zip",
  Xps = ".xps",
  Audio = ".wav",
  Video = ".avi",
  Image = ".png",
  Web = ".html",
  Text = ".txt",
  Xml = ".xml",
  Site = ".aspx",
  Story = "_story",
  ExternalContent = "_external",
  Folder = "_folder",
  Other = "_other"
}

export enum EFileTypeAppLinks {
  PowerPoint = "ms-powerpoint:ofe|u|",
  Word = "ms-word:ofe|u|",
  Excel = "ms-excel:ofe|u|",
  Csv = "ms-excel:ofe|u|.csv",
  Visio = "ms-visio:ofe|u|",
  InfoPath = "ms-infopath:ofe|u|",
  Access = "ms-access:ofe|u|",
  Publisher = "ms-publisher:ofe|u|",
  Project = "ms-project:ofe|u|",
  OneNote = "onenote:",
  OneNotePage = "onenote:",
}
