import { AppPolicy } from '../models/AppPolicy';
import { SmartPortal } from '../../SmartPortal';
import { ServiceLoader } from './ServiceLoader';
import { RequestService } from './RequestService';

export type AppPoliciesDict = { [policy in AppPolicy]: IPolicyInfo };

export interface ISessionPolicyInfo {
    time: string;
    policies: AppPoliciesDict;
}

export interface IPolicyInfo {
    expire: string;
}

export class LicensingService {
    private policyTime: Date;
    private policyDict: AppPoliciesDict = null;
    private isRefreshing = false;

    public constructor() {
        let sessionPolicies = SmartPortal.currentSession.licenses;
        if(sessionPolicies) {
            this.policyTime = new Date(sessionPolicies.time);
            this.policyDict = sessionPolicies.policies;
        }
    }

    private refreshPolicies(): Promise<void> {
        let reqsvc = ServiceLoader.GetService(RequestService);
        return reqsvc.requestAadService({
            method: "GET",
            url: "/api/session/RefreshPolicies?accountId=" + SmartPortal.currentUserId
        }).then(reqsvc.parseApiResponse).then((policyInfo: ISessionPolicyInfo) => {
            this.policyTime = new Date(policyInfo.time);
            this.policyDict = policyInfo.policies;
        });
    }

    private triggerPolicyRefresh() {
        if (this.isRefreshing)
            return;

        let refreshTimeout = new Date(new Date().getTime() - (1000 * 60 * 60 * 2)); // 2h
        if (this.policyDict && this.policyTime > refreshTimeout)
            return;

        this.isRefreshing = true;
        window.setTimeout(() => {
            this.refreshPolicies().catch().then(() => {
                this.isRefreshing = false;
            });
        }, 5000);
    }

    public checkPolicy(policy: AppPolicy): boolean {
        this.triggerPolicyRefresh();

        if(!this.policyDict)
            return false;
        if(!this.policyDict.hasOwnProperty(policy))
            return false;

        let policyInfo = this.policyDict[policy];
        if(policyInfo.expire && new Date(policyInfo.expire) < new Date())
            return false;
        
        return true;
    }

    public getPolicyExpire(policy: AppPolicy): Date {
        this.triggerPolicyRefresh();

        if(!this.policyDict)
            return null;
        if(!this.policyDict.hasOwnProperty(policy))
            return null;

        let policyInfo = this.policyDict[policy];
        if(policyInfo.expire)
            return new Date(policyInfo.expire);
        
        return null;
    }

}
