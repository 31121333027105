import { LocaleService, BaseStrings, Locale } from "../../../business/LocaleService";

export class DirectoryStrings extends BaseStrings {
    readonly _ModuleName = "DirectoryStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new DirectoryStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./DirectoryStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    Directory_ProvisionTypeColumn = "Type";
    Directory_TitleColumn = "Title";
    Directory_DescriptionColumn = "Description";
    Directory_ProvisionTemplateColumn = "Template";
    Directory_StatusColumn = "Status";
    Directory_OwnerColumn = "Owner";
    Directory_SubstituteColumn = "Substitute";
    Directory_CreatedColumn = "Created";
    Directory_CreatedByColumn = "Created by";
    Directory_StatusProvisionQueued = "Provisioning waiting in the queue";
    Directory_StatusProvisionRunning = "Provisioning is in progress";
    Directory_StatusChangeQueued = "A change is waiting in the queue";
    Directory_StatusChangeRunning = "A change is in progress";
    Directory_StatusWaitingForApproval = "Waiting for approval";
    Directory_StatusWaitingForChangeApproval = "Waiting for approval of a change";
    Directory_StatusWaitingForWebHook = "Waiting for external system";
    Directory_StatusWaitingForWebHookApproval = "Waiting for approval in external system";
    Directory_StatusArchived = "Archived";
    Directory_StatusActive = "Active";
    Directory_StatusDeleted = "Deleted";
    Directory_PrimaryOwnerInactiveWarning = "The owner of the room is no longer active. Please select a new owner";
    Directory_SecondaryOwnerInactiveWarning = "The substitute of the room is no longer active. Please select a new substitute";
    Directory_SiteUrlColumn = "Site address";
    Directory_NicknameColumn = "Group alias";
    Directory_TeamUrlColumn = "Team address";
    Directory_ClassificationColumn = "Classification";
    Directory_LanguageColumn = "Language";
    Directory_GuestsAccessColumn = "Guest access";
    Directory_SharingCapabilityColumn = "Sharing capability";
    Directory_VisibilityColumn = "Visibility";
    Directory_TimeZoneColumn = "Timezone";
    Directory_HubSiteColumn = "Hub association";
    Directory_ArchivingColumn = "Archiving";
    Directory_DeletionColumn = "Deletion";

    Directory_Item_ConfirmDeletionTitle = "Delete workspace";
    Directory_Item_ConfirmDeletionSubject = "With this action you delete this workspace. This means that all content is deleted and will no longer be available to you in the future.<br/><br/>Do you want to continue?";
    Directory_Item_ConfirmDeletionButton = "Delete";
    Directory_Item_ConfirmArchivingTitle = "Archive workspace";
    Directory_Item_ConfirmArchivingnSubject = "With this action you archive this workspace. This means that all content is now read-only.<br/><br/>Do you want to continue?";
    Directory_Item_ConfirmArchivingButton = "Archive";
    Directory_Item_ConfirmUnarchivingTitle = "Activate workspace";
    Directory_Item_ConfirmUnarchivingnSubject = "With this action you reactivate this workspace. This means that all contents can be accessed normally again.<br/><br/>Do you want to continue?";
    Directory_Item_ConfirmUnarchivingButton = "Activate";
    Directory_Item_ConfirmCancelButton = "Cancel";
    Directory_Item_ConfirmRejectTitle = "Reject?";
    Directory_Item_ConfirmRejectButton = "Reject";
    Directory_Item_ConfirmRejectCommentFieldLabel = "Comment";
    Directory_Item_WarningMessageArchiving = "This room will be archived {{untilAction}}. You can postpone the archiving by {{postponeBy}}";
    Directory_Item_WarningMessageDeletion = "This room will be deleted {{untilAction}}. You can postpone the deletion by {{postponeBy}}";
    Directory_Item_ButtonPostpone = "Postpone";

    Directory_Item_FormNotValidMessage = "Please fill in all the required fields.";
}

export default (() => {
    let dict = new DirectoryStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
