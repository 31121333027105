import { FolderPickerDataSource, FolderPickerItemResult, FolderPickerMoreItemResult, FolderPickerSearchItemResult, getSkipToken } from "../DataSources/FolderPickerDataSource"
import { FolderPickerItem } from "../Models/FolderPickerItem"
import { GraphDriveChildren, GraphDriveItem } from "../Models/GraphResults"
import { ServiceLoader } from '../../../business/ServiceLoader';
import { GraphBatchService } from '../../../business/GraphBatchService';
import { IBreadcrumbItem } from 'office-ui-fabric-react';

export class OneDriveFolderPickerDataSource implements FolderPickerDataSource {
    initialFolder: FolderPickerItem;
    private currentFolder: FolderPickerItem;
    private breadcrumbItems: IBreadcrumbItem[];
    private skipToken: string;
    private currentSearch: string;
    initialBreadcrumbItems: IBreadcrumbItem[] = [{ text: "OneDrive", key: "root", as: "h2", onClick: null }];
   
    reset(): void {
        this.currentFolder = null;
        this.breadcrumbItems = null;
        this.skipToken = null;
    }

    loadItems(parentItem: FolderPickerItem): Promise<FolderPickerItemResult> {
        this.currentSearch = null;
        const prom = new Promise<FolderPickerItemResult>((resolve,reject) => {
            if (parentItem) {
                this.getOneDriveFolderChildren(parentItem.id, parentItem.driveId, null, this.currentSearch).then((children) => {
                    this.skipToken = getSkipToken(children);
                    const items = this.mapToFolderPickerItems(children.value);
                    this.currentFolder = parentItem;
                    this.breadcrumbItems.push({
                        key: this.currentFolder.key,
                        text: this.currentFolder.name,
                        as: "h2",
                        onClick: null
                    });
                    resolve({
                        items: items,
                        currentFolder: this.currentFolder,
                        breadcrumbItems: this.breadcrumbItems,
                        hasMoreItems: this.skipToken ? true : false,
                        searchable: false
                    });
                }).catch((error) => {
                    if (console) console.error(error);
                    reject(error);
                });
            } else {
                Promise.all([
                    this.getOneDriveRoot(),
                    this.getOneDriveRootChildren(null, this.currentSearch)
                ]).then((response) => {
                    const root = response[0];
                    const children = response[1];
                    this.skipToken = getSkipToken(children);
                    const items = this.mapToFolderPickerItems(children.value);
                    this.currentFolder = this.mapToFolderPickerItem(root);
                    this.breadcrumbItems = [...this.initialBreadcrumbItems];
                    resolve({
                        items: items,
                        currentFolder: this.currentFolder,
                        breadcrumbItems: this.breadcrumbItems,
                        hasMoreItems: this.skipToken ? true : false,
                        searchable: false
                    });
                }).catch((error) => {
                    if (console) console.error(error);
                    reject(error);
                });
            }

        });
        return prom;
    }

    loadMoreItems(): Promise<FolderPickerMoreItemResult> {
        const prom = new Promise<FolderPickerMoreItemResult>((resolve, reject) => {
            if(!this.skipToken){
                resolve({
                    moreItems: [],
                    hasMoreItems: false
                });
                return;
            }
            if (this.currentFolder.key === "root") {
                this.getOneDriveRootChildren(this.skipToken, this.currentSearch).then((moreChildren) => {
                    this.skipToken = getSkipToken(moreChildren);
                    const moreItems = this.mapToFolderPickerItems(moreChildren.value);
                    resolve({
                        moreItems: moreItems,
                        hasMoreItems: this.skipToken ? true : false
                    });
                }).catch((error) => {
                    if (console) console.error(error);
                    reject(error);
                });
            }
            else {
                this.getOneDriveFolderChildren(this.currentFolder.id, this.currentFolder.driveId, this.skipToken, this.currentSearch).then((moreChildren) => {
                    this.skipToken = getSkipToken(moreChildren);
                    const moreItems = this.mapToFolderPickerItems(moreChildren.value);
                    resolve({
                        moreItems: moreItems,
                        hasMoreItems: this.skipToken ? true : false
                    });
                }).catch((error) => {
                    if (console) console.error(error);
                    reject(error);
                });
            }
        });
        return prom;
    }
    
    searchItems(search: string): Promise<FolderPickerSearchItemResult> {
        this.currentSearch = search;
        const prom = new Promise<FolderPickerSearchItemResult>((resolve, reject) => {
            if (this.currentFolder.key === "root") {
                this.getOneDriveRootChildren(null, this.currentSearch).then((children) => {
                    this.skipToken = getSkipToken(children);
                    const items = this.mapToFolderPickerItems(children.value);
                    resolve({
                        items: items,
                        hasMoreItems: this.skipToken ? true : false
                    });
                }).catch((error) => {
                    if (console) console.error(error);
                    reject(error);
                });
            }
            else {
                this.getOneDriveFolderChildren(this.currentFolder.id, this.currentFolder.driveId, null, this.currentSearch).then((children) => {
                    this.skipToken = getSkipToken(children);
                    const items = this.mapToFolderPickerItems(children.value);
                    resolve({
                        items: items,
                        hasMoreItems: this.skipToken ? true : false
                    });
                }).catch((error) => {
                    if (console) console.error(error);
                    reject(error);
                });
            }
        });
        return prom;
    }

    private mapToFolderPickerItems(items: GraphDriveItem[]): FolderPickerItem[] {
        return items.map(item => this.mapToFolderPickerItem(item));
    }

    private mapToFolderPickerItem(item: GraphDriveItem): FolderPickerItem {
        return {
            key: item.id + "|" + item.parentReference.driveId,
            id: item.id,
            name: item.name,
            webUrl: item.webUrl,
            isFolder: (item.folder ? true : false),
            driveId: item.parentReference.driveId
        }
    }

    onBreadcrumbClick(item: IBreadcrumbItem): Promise<FolderPickerItemResult> {       
        if (item.key === "root") {
            return this.loadItems(this.initialFolder);
        } else {
            const index = this.breadcrumbItems.findIndex(e => e.key === item.key);
            this.breadcrumbItems = this.breadcrumbItems.slice(0, index);
            const key = item.key.split("|");
            return this.loadItems({ 
                id: key[0], 
                driveId: key[1], 
                name: item.text, 
                key: item.key, 
                webUrl: "", 
                isFolder: true 
            });
        }
    }

    private getOneDriveRoot(): Promise<GraphDriveItem> {
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            version: "v1.0",
            path: "me/drive/items/root?$select=id,webUrl,name,folder,file,parentReference"
        });
    }

    private getOneDriveRootChildren(skipToken: string | null, search?: string): Promise<GraphDriveChildren> {
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            version: "v1.0",
            path: `me/drive/items/root/children?$select=id,webUrl,name,folder,file,parentReference&$filter=folder ne null` + (search ? " and startswith(name,'" + encodeURIComponent(search) + "')" : "") + (skipToken ? "&$skiptoken=" + skipToken : "")
        });
    }

    //private async getOneDriveFolder(id: string, driveId: string): Promise<GraphDriveItem> {
    //    return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
    //        method: "GET",
    //        version: "v1.0",
    //        path: "me/drives/" + driveId + "/items/" + id + "?$select=id,webUrl,name,folder,file,parentReference"
    //    });
    //}

    private async getOneDriveFolderChildren(id: string, driveId: string, skipToken: string | null, search?: string): Promise<GraphDriveChildren> {
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            version: "v1.0",
            path: `me/drives/${driveId}/items/${id}/children?$select=id,webUrl,name,folder,file,parentReference&$filter=folder ne null` + (search ? " and startswith(name,'" + encodeURIComponent(search) + "')" : "") + (skipToken ? "&$skiptoken=" + skipToken : "")
        });
    }
}