import * as React from 'react';
import DiscoverPanel, { DiscoverViewMode, View } from '../discover/DiscoverPanel';
import DiscoverStyles from '../discover/DiscoverTabStyle.scss'

export interface ISideBarPanelDiscoverProps {
  expandLink?: string;
}

export interface ISideBarPanelDiscoverState {
}

export default class SideBarPanelDiscover extends React.Component<ISideBarPanelDiscoverProps, ISideBarPanelDiscoverState> {
  constructor(props: ISideBarPanelDiscoverProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className={DiscoverStyles.DiscoverPage}>
          <DiscoverPanel rows={4} viewMode={DiscoverViewMode.SideBar} expandLink={this.props.expandLink}/>
      </div>
    );
  }
}
