import { StreamSource } from "./StreamSource";
import { BaseCard } from ".";

export interface ISteamCardsRange {
    index: number;
    count: number;
    cards: BaseCard[];
    total: number;
    hasMore: boolean;
}

export interface IStreamManagerProps {
    sortField: string;
    preloadLimit: number;
}

interface IStreamManagerSourceState {
    sourceId: number;
    loadIdx: number;
    streamObj: any;
    bufCount: number;
    hasMore: boolean;
}

interface IStreamManagerBufItem {
    source: IStreamManagerSourceState;
    card: BaseCard;
}

interface IStreamManagerState {
    sources: IStreamManagerSourceState[];
    cardStream: BaseCard[];
    cardBuffer: IStreamManagerBufItem[];
    prefetchPromise: Promise<void>;
    haveMore: boolean;
}

export class StreamManager {
    private streamProps: IStreamManagerProps;
    private streamSources: StreamSource[];
    private streamState: IStreamManagerState;

    public constructor(props: IStreamManagerProps) {
        this.streamProps = props;
        this.streamSources = [];
        this.resetStreamState();
    }

    private resetStreamState() {
        this.streamState = {
            sources: this.streamSources.map((source, idx) => {
                return {
                    sourceId: idx,
                    loadIdx: 0,
                    streamObj: null,
                    bufCount: 0,
                    hasMore: true
                };
            }),
            cardStream: [],
            cardBuffer: [],
            haveMore: (this.streamSources.length > 0),
            prefetchPromise: null
        };
    }

    public clearStreamSources(): void {
        if (this.streamSources.length > 0) {
            this.streamSources.splice(0, this.streamSources.length);
            this.resetStreamState();
        }
    }

    public setStreamSortField(field: string): void {
        this.streamProps.sortField = field;
        this.resetStreamState();
    }

    public addStreamSource(streamSource: StreamSource): void {
        this.streamSources.push(streamSource);
        this.resetStreamState();
    }

    private prefetchCards(count: number): Promise<void> {
        if (this.streamState.prefetchPromise)
            return this.streamState.prefetchPromise;

        let fetchPromises: Promise<void>[] = [];
        let haveMore = false;

        this.streamState.sources.forEach((sourceState) => {
            let sourceFns = this.streamSources[sourceState.sourceId];
            if (((count > 0 && sourceState.bufCount < sourceFns.batchSize) || count === 0) && sourceState.hasMore) {
                fetchPromises.push(sourceFns.requestData({
                    cardIndex: sourceState.loadIdx,
                    cardLimit: sourceFns.batchSize,
                    streamObj: sourceState.streamObj
                }).then((response) => {
                    Array.prototype.push.apply(this.streamState.cardBuffer, response.cards.map((card) => {
                        return {
                            source: sourceState,
                            card: card
                        };
                    }));
                    sourceState.bufCount += response.cards.length;
                    sourceState.loadIdx += response.cards.length;
                    if ((sourceState.hasMore = response.moreCards) && response.cards.length === 0)
                        sourceState.hasMore = false;
                    if (sourceState.hasMore)
                        haveMore = true;
                    if (response.streamObj)
                        sourceState.streamObj = response.streamObj;
                }));
            }
        });

        let prefetchPromise = Promise.all(fetchPromises).then(() => {
            this.streamState.haveMore = haveMore;
            if (this.streamProps.sortField)
                this.sortCardBuffer();

            let idx: number;
            let cards: BaseCard[] = [];
            for (idx = 0; idx < this.streamState.cardBuffer.length && (this.streamState.cardBuffer[idx].source.bufCount > 1 || !this.streamState.cardBuffer[idx].source.hasMore); idx++) {
                let cardBuf = this.streamState.cardBuffer[idx];
                cardBuf.source.bufCount--;
                cards.push(cardBuf.card);
            }
            if (idx > 0) {
                this.streamState.cardBuffer.splice(0, idx);
                Array.prototype.push.apply(this.streamState.cardStream, cards);
            }
        });

        this.streamState.prefetchPromise = prefetchPromise;
        return prefetchPromise.then(() => {
            if (this.streamState.prefetchPromise === prefetchPromise)
                this.streamState.prefetchPromise = null;
        });
    }

    private resolveObject(obj: Object, path: string): any {
        let current = obj;
        path.split('.').forEach((p) => {
            if (!current) return current;
            current = current[p];
        });
        return current;
    }

    private sortCardBuffer() {
        this.streamState.cardBuffer.sort((objA, objB) => {

            let valA = this.resolveObject(objA.card, this.streamProps.sortField);
            let valB = this.resolveObject(objB.card, this.streamProps.sortField);

            if (valA === valB)
                return 0;
            return valA > valB ? -1 : 1;
        });
    }

    public requestCardRange(index: number, limit: number): Promise<ISteamCardsRange> {
        let fetchPromise: Promise<void>;
        let endIdx = index + limit;
        let bufLen = this.streamState.cardStream.length - endIdx;
        if (bufLen < 0) {
            fetchPromise = this.prefetchCards(endIdx - this.streamState.cardStream.length);
        }
        else if (endIdx === 0) {
            fetchPromise = this.prefetchCards(0);
        }
        else {
            fetchPromise = Promise.resolve();
            if (this.streamProps.preloadLimit && bufLen < this.streamProps.preloadLimit)
                this.prefetchCards(this.streamProps.preloadLimit - (bufLen < 0 ? 0 : bufLen));
        }

        return fetchPromise.then(() => {
            let cards = this.streamState.cardStream.slice(index, endIdx);

            return {
                index: index,
                count: cards.length,
                cards: cards,
                total: (this.streamState.cardStream.length + this.streamState.cardBuffer.length + (this.streamState.haveMore ? limit : 0)),
                hasMore: (endIdx < this.streamState.cardStream.length || this.streamState.haveMore)
            };
        });
    }

}
