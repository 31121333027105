

export interface IRequestOptions {
    method: string;
    url: string;
    header?: Map<string, string>;
    data?: any;
    scopes?: string[];
}

export interface IRequestResponse {
    success: boolean;
    status: number;
    response: any;
    header: Map<string, string>;
}

export class RequestService {
    private requestHandler: (request: IRequestOptions) => Promise<IRequestResponse>;
    private reauthHandler: (response: IRequestResponse) => Promise<boolean>;

    public requestAadService(reqOpts: IRequestOptions): Promise<IRequestResponse> {
        let headers = reqOpts.header || new Map<string, string>();
        headers.set("X-Requested-With", "XMLHttpRequest");

        if(this.requestHandler) {
            return this.requestHandler({
                method: reqOpts.method,
                url: reqOpts.url,
                data: reqOpts.data,
                header: headers,
            });
        }
        else {
            return this.startXhrRequest(reqOpts.method, reqOpts.url, reqOpts.data, headers);
        }
    }

    private startXhrRequest(method: string, url: string, body: any, header: Map<string, string>, isRetry?: boolean): Promise<IRequestResponse> {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open(method, url);

            header.forEach((hVal, hKey) => {
                xhr.setRequestHeader(hKey, hVal);
            });

            xhr.addEventListener("load", (evt) => {
                let response = this.captureXhrResponse(xhr);

                let isAuthError = false;
                if (response.status == 500 && response.response && typeof response.response === "string" && response.response.indexOf("SessionState:NotRegistered") > -1) {
                   isAuthError = true;                
                }
                if (response.status == 401 && response.header.has("sp365-auth-action") && response.header.get("sp365-auth-action") == "RedirectToIdentityProvider") {
                    isAuthError = true;
                }

                if (isAuthError && this.reauthHandler) {
                    let reauthRes = this.reauthHandler(response);

                    if(typeof reauthRes === "object" && reauthRes) {
                        reauthRes.then((retry) => {
                            if(retry && !isRetry)
                                this.startXhrRequest(method, url, body, header, true).then(resolve, reject);
                            else
                                resolve(response);
                        }, () => {
                            return;
                        });
                    }
                }
                else
                    resolve(response);
            });
            xhr.addEventListener("error", (evt) => {
                let response = this.captureXhrResponse(xhr);
                reject(response);
            });

            xhr.send(body);
        });
    }

    private captureXhrResponse(xhr: XMLHttpRequest): IRequestResponse {
        let rspHeaders = xhr.getAllResponseHeaders().split("\r\n");
        let headerMap = new Map<string, string>();
        rspHeaders.forEach((rspHead) => {
            let head = rspHead.split(": ", 2);
            if (head.length === 2)
                headerMap.set(head[0], head[1]);
        });

        return {
            success: (xhr.status >= 200 && xhr.status <= 299),
            status: xhr.status,
            response: xhr.response,
            header: headerMap
        }
    }

    public parseApiResponse(response: IRequestResponse): Promise<any> {
        return new Promise((resolve, reject) => {
            let rspData: any = null;
            try {
                if (typeof response.response === "string")
                    rspData = JSON.parse(response.response);
            } catch (ex) { };
            if (!rspData)
                rspData = response.response;

            if (response.success)
                resolve(rspData);
            else
                reject(rspData);
            
        });
    }

    public setExternalRequestHandler(handler: (request: IRequestOptions) => Promise<IRequestResponse>) {
        this.requestHandler = handler;
    }

    public setAuthErrorHandler(handler: (response: IRequestResponse) => Promise<boolean>) {
        this.reauthHandler = handler;
    }

}
