import * as React from 'react';
import styles from "./PeoplePickerStyle.scss";
import strings from "./loc/PeoplePickerStrings"
import { Dropdown, DefaultButton, PrimaryButton, IRenderFunction, Stack, Icon, TooltipHost, DirectionalHint, TextField, ITextFieldProps, FocusTrapCallout, FocusZone, FocusZoneTabbableElements, ICheckboxProps, IDropdownProps, IconButton, Label, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { ServiceLoader } from '../../business/ServiceLoader';
import { GraphBatchService } from '../../business/GraphBatchService';
import { RequestService } from '../../business/RequestService';

export interface IPeoplePickerUserCreationCalloutProps {
  targetEl: HTMLElement;
  onCalloutClose: () => void;
  onUserCreated: (user: AadUserCreationInformation) => void;
}

export type AadUserCreationInformation = {
  id: string;
  displayName: string;
  userPrincipalName: string;
  password: string;
}

export interface IPeoplePickerUserCreationCalloutState {
  isLoading: boolean;
  displayName: string;
  password: string;
  showPassword: boolean;
  name: string;
  domain: string;
  availableDomains: string[];
  domainsLoading: boolean;
  domainsLoaded: boolean;
  defaultDomain: string;
}

export default class PeoplePickerUserCreationCallout extends React.Component<IPeoplePickerUserCreationCalloutProps, IPeoplePickerUserCreationCalloutState> {
  private passwordErrorMessage: string;
  private accountNameErrorMessage: string;

  constructor(props: IPeoplePickerUserCreationCalloutProps) {
    super(props);

    this.state = {
      isLoading: false,
      displayName: "",
      name: "",
      password: "",
      showPassword: false,
      domain: null,
      defaultDomain: "",
      domainsLoaded: false,
      domainsLoading: false,
      availableDomains: [],
    }
  }

  componentDidMount() {
    if (!this.state.domainsLoaded) {
      this.loadAvailableDomains();
    }
  }

  private loadAvailableDomains() {
    ServiceLoader.GetService(GraphBatchService).addGraphRequest({
      method: "GET",
      path: "organization?$select=verifiedDomains",
      version: "V1.0"
    }).then((rspData) => {
      if (rspData.value) {
        var domainNames = (rspData.value[0].verifiedDomains as any[]).map(e => e.name);
        this.setState({
          availableDomains: domainNames,
          domainsLoaded: true,
          domainsLoading: false,
          defaultDomain: (rspData.value[0].verifiedDomains as any[]).filter(e => e.isDefault).map(e => e.name)[0],
        });
      }
    });
  }

  public render() {
    return (
      <>
        <FocusTrapCallout
          role="alertdialog"
          className={styles.PeoplePickerUserCreationCallout}
          gapSpace={0}
          target={this.props.targetEl}
          onDismiss={(evt: any) => this.props.onCalloutClose && this.props.onCalloutClose()}
          setInitialFocus={true}
          directionalHint={DirectionalHint.leftCenter}
        >
          <FocusZone className={styles.PeoplePickerUserCreationCalloutFocusZone} handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation={true}>
            <form onSubmit={(evt) => this.onCalloutSave(evt)}>
              <div className={styles.FormHeader}>
                <h2>{strings.PeoplePicker_UserCreation_Header}</h2>
              </div>

              {
                this.state.isLoading ? <Spinner size={SpinnerSize.medium} style={{ position: "absolute", top: "25px", right: "25px" }} /> : null
              }
              <div className={styles.FormBody}>
                <TextField tabIndex={1} onRenderLabel={(props, defaultRender) => this.onRenderTextFieldLabel(props, defaultRender, strings.PeoplePicker_UserCreation_Label_Info_DisplayName)} disabled={this.state.isLoading} label={strings.PeoplePicker_UserCreation_Label_DisplayName} onChange={(evt, value) => { this.setState({ ...this.state, displayName: value }); }} required={true} value={this.state.displayName} maxLength={255}></TextField>
                <div>
                  <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                    <Label required={true} disabled={this.state.isLoading} >{strings.PeoplePicker_UserCreation_Label_AccountName}</Label>
                    {
                      this.state.isLoading ? null : <TooltipHost content={strings.PeoplePicker_UserCreation_Label_Info_AccountName} directionalHint={DirectionalHint.bottomRightEdge}><span><Icon iconName="Info" /></span></TooltipHost>
                    }
                  </Stack>
                  <div className={styles.UserNameFieldValueContainer}>
                    <TextField tabIndex={2} errorMessage={this.accountNameErrorMessage} disabled={this.state.isLoading} onChange={(evt, value) => { this.setState({ ...this.state, name: value }); this.accountNameErrorMessage = null; }} required={true} value={this.state.name} maxLength={255}></TextField>
                    <div className={styles.AtSymbol}>{"@"}</div>
                    <Dropdown tabIndex={3} style={{ width: "100%" }} disabled={!this.state.domainsLoaded || this.state.isLoading} options={this.state.availableDomains.map(c => { return { key: c, text: c }; })} selectedKey={this.state.domain ? this.state.domain : this.state.defaultDomain} onChange={(event, value) => this.setState({ domain: value.key as string })} ></Dropdown>
                  </div>
                </div>
                <div className={styles.PasswordFieldValueContainer}>
                  <TextField tabIndex={4} disabled={this.state.isLoading} errorMessage={this.passwordErrorMessage} value={this.state.password} onChange={(event, value) => { this.setState({ password: value }); this.passwordErrorMessage = null; }} type={this.state.showPassword ? "text" : "password"} onRenderLabel={(props, defaultRender) => this.onRenderTextFieldLabel(props, defaultRender, strings.PeoplePicker_UserCreation_Label_Password)} label={strings.PeoplePicker_UserCreation_Label_Password} required={true} />
                  <IconButton iconProps={{ iconName: "RedEye" }} onClick={() => this.setState((prevState) => ({ showPassword: !prevState.showPassword }))}></IconButton>
                </div>
              </div>

              <div className={styles.FormActions}>
                <PrimaryButton disabled={this.state.isLoading} type="submit" tabIndex={5}>{strings.PeoplePicker_UserCreation_SaveButton}</PrimaryButton>
                <DefaultButton disabled={this.state.isLoading} onClick={(evt) => this.onCalloutCancel(evt)} tabIndex={6}>{strings.PeoplePicker_UserCreation_CancelButton}</DefaultButton>
              </div>
            </form>
          </FocusZone>
        </FocusTrapCallout>
      </>
    );
  }

  private onCalloutCancel(evt: any) {
    evt.preventDefault();
    this.props.onCalloutClose && this.props.onCalloutClose();
  }

  private async onCalloutSave(evt: any): Promise<void> {
    evt.preventDefault();
    const user = await this.createUser();
    if (user != null) {
      this.props.onUserCreated && this.props.onUserCreated(user);
    }
  }

  private async createUser(): Promise<AadUserCreationInformation> {
    this.setState({
      isLoading: true
    });
    const rqService = ServiceLoader.GetService(RequestService);
    try {
      const result = await rqService.requestAadService({
        method: "POST",
        url: "/api/admin/Users",
        header: new Map<string, string>([
          ["Content-Type", "application/json"]
        ]),
        data: JSON.stringify({
          displayName: this.state.displayName,
          userPrincipalName: this.state.name + "@" + (this.state.domain ? this.state.domain : this.state.defaultDomain),
          passWord: this.state.password
        })
      });
      const response = await rqService.parseApiResponse(result);
      response.password = this.state.password;
      return (response as AadUserCreationInformation);
    }
    catch (ex) {
      if (ex.error_code && ex.error_code == 409) {
        this.accountNameErrorMessage = strings.PeoplePicker_UserCreation_Error_AlreadyExists;
      }

      if (!ex.success && ex.errors && ex.errors.errors) {
        if ((ex.errors.errors as any[]).find(e => e.message && e.message.indexOf("The specified password does not comply with password complexity requirements") >= 0) ||
          (ex.errors.errors as any[]).find(e => e.message && e.message.indexOf("Property passwordProfile.password") >= 0)) {
          this.passwordErrorMessage = strings.PeoplePicker_UserCreation_Error_PasswordComplexity;
        }
        if ((ex.errors.errors as any[]).find(e => e.message && e.message.indexOf("Invalid value specified for property 'mailNickname'") >= 0) ||
          (ex.errors.errors as any[]).find(e => e.message && e.message.indexOf("Property userPrincipalName is invalid") >= 0)
        ) {
          this.accountNameErrorMessage = strings.PeoplePicker_UserCreation_Error_InvalidName;
        }
      }
    }
    finally {
      this.setState({
        isLoading: false
      });
    }
    return null;
  }

  private onRenderTextFieldLabel(fieldProps: ITextFieldProps | IDropdownProps | ICheckboxProps, defaultRender: IRenderFunction<ITextFieldProps | IDropdownProps | ICheckboxProps>, infoTooltip?: string): JSX.Element {
    return (
      <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
        <span>{defaultRender(fieldProps)}</span>
        {infoTooltip && !fieldProps.disabled ? <TooltipHost content={infoTooltip} directionalHint={DirectionalHint.bottomRightEdge}><span><Icon iconName="Info" /></span></TooltipHost> : null}
      </Stack>
    )
  }
}
