import { BaseCard, StreamSource } from "..";
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { EFileTypeExtensions, EFileTypeAppLinks } from "../../utils/FileTypeProperties";
import { FileIconType } from "@uifabric/file-type-icons/lib/FileIconType";
import { IActivityInfo } from "../../models/ActivityInfo";

export class DocumentCard extends BaseCard {

    public get davUrl(): string { return this.getDataField("davUrl"); };
    public set davUrl(val: string) { this.setDataField("davUrl", val); };

    public get thumbnail(): string { return this.getDataField("thumbnail"); };
    public set thumbnail(val: string) { this.setDataField("thumbnail", val); };

    public get analytics(): any { return this.getDataField("analytics"); };
    public set analytics(val: any) { this.setDataField("analytics", val); };

    public get spItem(): any { return this.getDataField("spItem"); };
    public set spItem(val: any) { this.setDataField("spItem", val); };

    public get activities(): IActivityInfo[] { return this.getDataField("activities"); };
    public set activities(val: IActivityInfo[]) { this.setDataField("activities", val); };

    public get containerTitle(): string { return this.getDataField("containerTitle"); };
    public set containerTitle(val: string) { this.setDataField("containerTitle", val); };

    public get containerUrl(): string { return this.getDataField("containerUrl"); };
    public set containerUrl(val: string) { this.setDataField("containerUrl", val); };

    public get containerType(): string { return this.getDataField("containerType"); };
    public set containerType(val: string) { this.setDataField("containerType", val); };

    public get comments(): any { return this.getDataField("comments"); };
    public set comments(val: any) { this.setDataField("comments", val); };

    public get versions(): any { return this.getDataField("versions"); };
    public set versions(val: any) { this.setDataField("versions", val); };

    public get parents(): any { return this.getDataField("parents"); };
    public set parents(val: any) { this.setDataField("parents", val); };

    public get sites(): any { return this.getDataField("sites"); };
    public set sites(val: any) { this.setDataField("sites", val); };

    public get lastModifyTime(): Date { return this.getDataField("lastModifyTime"); };
    public set lastModifyTime(val: Date) { this.setDataField("lastModifyTime", val); };

    public get lastAccessTime(): Date { return this.getDataField("lastAccessTime"); };
    public set lastAccessTime(val: Date) { this.setDataField("lastAccessTime", val); };

    public get lastModifyUser(): any { return this.getDataField("lastModifyUser"); };
    public set lastModifyUser(val: any) { this.setDataField("lastModifyUser", val); };

    public get fileTypeIconClass(): string { return this.getFileIcon(); };
    public get openInAppLink(): string { return this.getOpenAppLink(); };

    public constructor(source: StreamSource, itemRef: any) {
        super(source, itemRef);

        this.lazyFieldCfg = {
            "thumbnail": {
                lazyField: "thumbnailUrl",
                setterFn: (data) => { this.thumbnail = data; }
            },
            "davUrl": {
                lazyField: "fileDetails",
                setterFn: (data) => { this.davUrl = data.davUrl; }
            },
            "lastModifyTime": {
                lazyField: "hotDetails",
                setterFn: (data) => { this.lastModifyTime = data?  data.lastModifyTime : null; this.title = data ? data.name: this.title; }
            },
            "lastModifyUser": {
                lazyField: "hotDetails",
                setterFn: (data) => { this.lastModifyUser = data ? data.lastModifyUser : null; }
            },
            "analytics": {
                lazyField: "hotDetails",
                setterFn: (data) => { this.analytics = data ?  data.analytics : null; }
            },
            "spItem": {
                lazyField: "fileDetails",
                setterFn: (data) => { this.spItem = data ? data.spItem : null; }
            },
            "activities": {
                lazyField: "activities",
                setterFn: (data) => { this.activities = data; }
            },
            "comments": {
                lazyField: "comments",
                setterFn: (data) => { this.comments = data; }
            },
            "versions": {
                lazyField: "versions",
                setterFn: (data) => { this.versions = data; }
            },
            "parents": {
                lazyField: "parentItems",
                setterFn: (data) => { this.parents = data; }
            },
            "sites": {
                lazyField: "parentSites",
                setterFn: (data) => { this.sites = data; }
            },
        };
    }

    private getFileIcon(): string {           
        if (!this.type)
            return null;
        let extension = EFileTypeExtensions[this.type];
        let extprops: any = {
            size: 48
        };        
        if (!extension) {
            extprops.type = FileIconType.genericFile;
        }
        else if (extension[0] === "_") {
            switch (extension) {
                case "_story":
                    extprops.type = FileIconType.multiple;
                    break;
                case "_external":
                    extprops.type = FileIconType.sharedFolder;
                    break;
                case "_folder":
                    extprops.type = FileIconType.folder;
                    break;
                case "_other":
                default:
                    extprops.type = FileIconType.genericFile;
                    break;
            }
        }
        else {
            extprops.extension = extension;
        }
        let iconprops = getFileTypeIconProps(extprops);
        return iconprops ? iconprops.iconName : null;
    }

    private getOpenAppLink(): string {
        if (!this.type)
            return null;

        let linkPrefix = EFileTypeAppLinks[this.type];
        if (!linkPrefix)
            return null;

        return linkPrefix + (this.davUrl ? this.davUrl : this.webUrl);
    }

}
