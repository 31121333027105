import { BaseCacheDriver } from './BaseCacheDriver';
import { ICacheDriver } from '../models/CacheDriver';
import { ICacheOpts, ICacheSetOpts, CacheEncoding } from '../models/CacheOptions';
import { BaseCacheService } from '../BaseCacheService';
import { ICacheMetadata } from '../models/CacheMetadata';
import { MapBuilder } from '../../utils/MapBuilder';

export abstract class StorageCacheDriver extends BaseCacheDriver implements ICacheDriver {
    protected abstract cacheStorage: Storage;

    private dataEntryPrefix: string;
    private metaEntryPrefix: string;

    public constructor(cacheName: string, cacheOpts: ICacheOpts) {
        super(cacheName, cacheOpts);

        this.dataEntryPrefix = BaseCacheService.CacheKeyPrefix + this.cacheName.trim() + ":d:";
        this.metaEntryPrefix = BaseCacheService.CacheKeyPrefix + this.cacheName.trim() + ":m:";
    }

    public dispose(): void {
    }

    public getReadyPromise(): Promise<void> {
        return Promise.resolve();
    }

    private getDataEntry(key: string): any {
        let dataKey = this.dataEntryPrefix + key;
        return this.cacheStorage.getItem(dataKey);
    }

    private putDataEntry(key: string, entry: any): void {
        let dataKey = this.dataEntryPrefix + key;
        this.cacheStorage.setItem(dataKey, entry);
    }

    private getMetaEntry(key: string): ICacheMetadata {
        let metaKey = this.metaEntryPrefix + key;
        let metaJson = this.cacheStorage.getItem(metaKey);
        if(!metaJson)
            return null;

        let metaObj = JSON.parse(metaJson);
        return metaObj as ICacheMetadata;
    }

    private putMetaEntry(entry: ICacheMetadata): void {
        let metaKey = this.metaEntryPrefix + entry.key;
        let metaJson = JSON.stringify(entry);
        this.cacheStorage.setItem(metaKey, metaJson);
    }

    private updateMetaEntry(key: string, patch: Map<keyof ICacheMetadata, any>): ICacheMetadata {
        let metaObj = this.getMetaEntry(key);
        if (!metaObj)
            return null;

        patch.forEach((value, prop) => {
            if(prop === "key")
                return;
            if (typeof value === "function")
                value = value(metaObj);
            (metaObj[prop] as any) = value;
        });
        this.putMetaEntry(metaObj);

        return metaObj;
    }

    public delEntry(key: string): Promise<void> {
        return Promise.resolve().then(() => {
            let dataKey = this.dataEntryPrefix + key;
            let metaKey = this.metaEntryPrefix + key;

            this.cacheStorage.removeItem(metaKey);
            this.cacheStorage.removeItem(dataKey);
        });
    }

    public setEntry(key: string, value: any, opts?: ICacheSetOpts): Promise<void> {
        return Promise.resolve().then(() => {
            let metaObj = this.getMetaEntry(key);
            if(!metaObj) {
                metaObj = {
                    key: key,
                    rev: 1
                };
            }
            else {
                metaObj.rev++;
            }

            metaObj.enc = (opts && "encode" in opts ? opts.encode : this.cacheOpts.defaultEncoding || CacheEncoding.Plain);
            metaObj.out = (opts && typeof opts.timeout === "number" ? opts.timeout : this.cacheOpts.defaultTimeout || 0);
            metaObj.pri = (opts && typeof opts.priority === "number" ? opts.priority : this.cacheOpts.defaultPriority || 100);

            let timeVal = Math.floor((new Date()).getTime() / 1000);
            metaObj.rac = 0;
            metaObj.lut = timeVal;
            if(metaObj.out)
                metaObj.out += timeVal;

            let dataObj = this.encodeCacheData(value, metaObj.enc, false);
            metaObj.vls = dataObj ? dataObj.length : 0;

            this.putDataEntry(key, dataObj);
            this.putMetaEntry(metaObj);
        });
    }

    public getEntry(key: string, ignoreTimedOut?: boolean): Promise<any> {
        return Promise.resolve().then(() => {
            let timeVal = Math.floor((new Date()).getTime() / 1000);

            let metaObj = this.updateMetaEntry(key, MapBuilder.NewMap<keyof ICacheMetadata, any>([
                ["lat", timeVal],
                ["rac", (metaObj) => { return metaObj.rac + 1; }]
            ]));
            if(!metaObj)
                return null;

            let timeout = (metaObj.out && metaObj.out < timeVal);
            if (timeout && !ignoreTimedOut)
                return null;
            
            let dataObj = this.getDataEntry(key);
            let resData = this.decodeCacheData(dataObj, metaObj.enc, false);
            return ignoreTimedOut ? {
                data: resData,
                timeout: timeout
            } : resData;
        });
    }

    public runCleanup(): Promise<void> {
        return Promise.resolve().then(() => {
            let metaPrefixLen = this.metaEntryPrefix.length;
            let dataPrefixLen = this.dataEntryPrefix.length;

            let metaKeys: string[] = [];
            let dataKeys: string[] = [];
            for(let idx = this.cacheStorage.length - 1; idx >= 0; idx--) {
                let key = this.cacheStorage.key(idx);

                if(key.length >= metaPrefixLen && key.substr(0, metaPrefixLen) === this.metaEntryPrefix)
                    metaKeys.push(key.substr(metaPrefixLen));
                else if(key.length >= dataPrefixLen && key.substr(0, dataPrefixLen) === this.dataEntryPrefix)
                    dataKeys.push(key.substr(dataPrefixLen));
            }
            
            let timeVal = Math.floor((new Date()).getTime() / 1000);
            for(let idx = 0; idx < metaKeys.length; idx++) {
                let dataKeyIdx = dataKeys.indexOf(metaKeys[idx]);
                if(dataKeyIdx === -1) {
                    // data entry missing - delete meta entry
                    this.cacheStorage.removeItem(this.metaEntryPrefix + metaKeys[idx]);
                    continue;
                }
                dataKeys.splice(dataKeyIdx, 1);

                let metaObj = this.getMetaEntry(metaKeys[idx]);
                let clearEntry = false;
                if(metaObj.out && metaObj.out < timeVal)
                    clearEntry = true;
                else if(this.cacheOpts.accessTimeout && timeVal - metaObj.lat > this.cacheOpts.accessTimeout)
                    clearEntry = true;
                else if(this.cacheOpts.updateTimeout && timeVal - metaObj.lut > this.cacheOpts.updateTimeout)
                    clearEntry = true;

                if(clearEntry) {
                    this.cacheStorage.removeItem(this.metaEntryPrefix + metaKeys[idx]);
                    this.cacheStorage.removeItem(this.dataEntryPrefix + metaKeys[idx]);
                }
            }
            for(let idx = 0; idx < dataKeys.length; idx++) {
                // meta entry missing - delete data entry
                this.cacheStorage.removeItem(this.dataEntryPrefix + dataKeys[idx]);
            }
        });
    }

}

export class SessionStorageCacheDriver extends StorageCacheDriver {
    protected cacheStorage = window.sessionStorage;

    public constructor(cacheName: string, cacheOpts: ICacheOpts) {
        super(cacheName, cacheOpts);

        if(!window.sessionStorage)
            throw "sessionStorage not supported";
    }
}

export class LocalStorageCacheDriver extends StorageCacheDriver {
    protected cacheStorage = window.localStorage;

    public constructor(cacheName: string, cacheOpts: ICacheOpts) {
        super(cacheName, cacheOpts);

        if(!window.localStorage)
            throw "localStorage not supported";
    }
}
