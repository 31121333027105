import { LocaleService, BaseStrings, Locale } from "../../../common/business/LocaleService";

export class QuickActionsStrings extends BaseStrings {
    readonly _ModuleName = "QuickActionsStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new QuickActionsStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./QuickActionsStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    QuickActions_Title = "Quick Actions";
    QuickActions_Info = "";

    QuickActions_Header = "Quick Actions";
    QuickActions_UploadPersonalDocument = "Upload personal document";
    QuickActions_UploadPersonalDocumentDescription = "Upload a document to OneDrive to save it for yourself and access it online";
    QuickActions_UploadDocument = "Upload document";
    QuickActions_UploadDocumentDescription = "Upload a document to SharePoint or Microsoft Teams to share it with the people in that group";
    QuickActions_UploadDocumentHere = "Upload document";
    QuickActions_UploadDocumentHereDescription = "Upload a document to this SharePoint or Microsoft Teams to share it with the people in that group";
    QuickActions_CreateSharePointNews = "Create SharePoint News";
    QuickActions_CreateSharePointNewsDescription = "Create a news in SharePoint, which is visible for all colleagues in your company";
    QuickActions_CreateSharePointHereNews = "Create SharePoint News";
    QuickActions_CreateSharePointNewsHereDescription = "Create a news here in this SharePoint, which is visible for people in that group";
    QuickActions_RequestSharePointSite = "Create workspace";
    QuickActions_RequestSharePointSiteDescription = "Create a workspace in SharePoint or Microsoft Teams for collaboration with internal and/or external participants";
}

export default (() => {
    let dict = new QuickActionsStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
