
export enum AppPolicy {
    FeatureMyWorkplace = "FeatureMyWorkplace",
    FeatureDiscovery = "FeatureDiscovery",
    FeatureNavigation = "FeatureNavigation",
    FeatureProfessional = "FeatureProfessional",
    FeatureAdministration = "FeatureAdministration",
    FeatureTrial = "FeatureTrial",

    FeatureProvisioning = "FeatureProvisioning",
    FeatureTemplates = "FeatureTemplates",
    FeatureWebHook = "FeatureWebHook",
    
    LicenseFree = "LicenseFree",
    LicenseProfessional = "LicenseProfessional",
    LicenseTrialProfessional = "LicenseTrialProfessional",
    LicenseCollaboration = "LicenseCollaboration",
    LicenseTrialCollaboration = "LicenseTrialCollaboration",
    LicenseProvisioning = "LicenseProvisioning",
    LicenseTrialProvisioning = "LicenseTrialProvisioning"
}
