import { LocaleService, BaseStrings, Locale } from "../../../business/LocaleService";

export class GuidedTourStrings extends BaseStrings {
    readonly _ModuleName = "GuidedTourStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new GuidedTourStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./GuidedTourStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    GuidedTour_Controls_SkipTour = "Skip tour";
    GuidedTour_Controls_Next = "Next";
    GuidedTour_Controls_Finish = "Finish";    
    GuidedTour_Controls_MoreLinkLabel = "Learn more";
    GuidedTour_Label_Of = "of";

    GuidedTour_Portal_Welcome_Title = "Welcome";
    GuidedTour_Portal_Welcome_Content = "Welcome to SmartPortal365. You have chosen a great product and you can use this guide to familiarize yourself with SmartPortal365 and get more information about how it works.";
    GuidedTour_Portal_Welcome_MoreLink = "";

    GuidedTour_Portal_CompanyNewsSlider_Title = "Company News Slider";
    GuidedTour_Portal_CompanyNewsSlider_Content = "The \"news-carousel\" highlights the news of the SharePoint homepage of your company. Here you can see the five most recent news items in a rotating display format.";
    GuidedTour_Portal_CompanyNewsSlider_MoreLink = "/help/docs/en/newsrotator";
    GuidedTour_Portal_CompanyNewsSlider_ImagePath = "/images/guided_tour/GuidedTour_MyWorkplace_CompanyNewsSlider.png";

    GuidedTour_Portal_CompanyNews_Title = "News from my company";
    GuidedTour_Portal_CompanyNews_Content = "Find SharePoint news from your organization.";
    GuidedTour_Portal_CompanyNews_MoreLink = "/help/docs/en/companynews";
    GuidedTour_Portal_CompanyNews_ImagePath = "/images/guided_tour/GuidedTour_MyWorkplace_CompanyNews.png";

    GuidedTour_Portal_CompanyNewsSeeMore_Title = "Show more";
    GuidedTour_Portal_CompanyNewsSeeMore_Content = "With this function you can display more company news.";
    GuidedTour_Portal_CompanyNewsSeeMore_MoreLink = "";

    GuidedTour_Portal_CompanyNewsFilter_Title = "Filter and sort";
    GuidedTour_Portal_CompanyNewsFilter_Content = "Using the adjacent icons, you have the possibility to filter the content and prioritize it according to different categories (date, relevance, etc.).";
    GuidedTour_Portal_CompanyNewsFilter_MoreLink = "/help/docs/en/companynews/";
    
    GuidedTour_Portal_GlobalLinks_Title = "Global links";
    GuidedTour_Portal_GlobalLinks_Content = "In the \"Global Links\" area you find links for applications and content, which have been defined by your administrator for all users in your company.";
    GuidedTour_Portal_GlobalLinks_MoreLink = "/help/docs/en/quick-links";
    GuidedTour_Portal_GlobalLinks_ImagePath = "/images/guided_tour/GuidedTour_MyWorkplace_GlobalLinks.png";

    GuidedTour_Portal_PersonalLinks_Title = "My links";
    GuidedTour_Portal_PersonalLinks_Content = "In the \"My Links\" section you find useful quick links for your Microsoft Office Online applications. For example, you can use them to access your inbox directly or to create your own links.";
    GuidedTour_Portal_PersonalLinks_MoreLink = "/help/docs/en/quick-links/#customize-my-links-to-your-needs";
    GuidedTour_Portal_PersonalLinks_ImagePath = "/images/guided_tour/GuidedTour_MyWorkplace_MyLinks.png";

    GuidedTour_Portal_PersonalLinksEdit_Title = "Edit/new link";
    GuidedTour_Portal_PersonalLinksEdit_Content = "By clicking the \"Edit\" icon you can edit the whole \"My Links\" section and add new links or categories.";
    GuidedTour_Portal_PersonalLinksEdit_MoreLink = "/help/docs/en/quick-links/#customize-my-links-to-your-needs";
    GuidedTour_Portal_PersonalLinksEdit_ImagePath = "/images/guided_tour/GuidedTour_MyWorkplace_PersonalLinksEdit.png";

    GuidedTour_Portal_QuickBar_MyWorkplace_Title = "Quick Bar - My workplace";
    GuidedTour_Portal_QuickBar_MyWorkplace_Content = "Under \"My Workplace\" is the SmartPortal365 home page with news from your company and teams, as well as the Quick Links. ";
    GuidedTour_Portal_QuickBar_MyWorkplace_MoreLink = "/help/docs/en/yourworkspace";

    GuidedTour_Portal_QuickBar_Discover_Title = "Quick Bar - Discover";
    GuidedTour_Portal_QuickBar_Discover_Content = "With the quickbar \"Discover\" you find documents from the workspaces in your organization that are relevant to you, or that you have recently worked on.";
    GuidedTour_Portal_QuickBar_Discover_MoreLink = "/help/docs/en/discover";
    GuidedTour_Portal_QuickBar_Discover_ImagePath = "/images/guided_tour/GuidedTour_MyWorkplace_QuickBar_Discover.png";


    GuidedTour_Portal_QuickBar_Navigate_Title = "Quick Bar - Navigate";
    GuidedTour_Portal_QuickBar_Navigate_Content = "\"Navigate\" lets you quickly and easily navigate through your teams, projects, and intranet sites and access other sites you follow.";
    GuidedTour_Portal_QuickBar_Navigate_MoreLink = "/help/docs/en/navigate/";
    GuidedTour_Portal_QuickBar_Navigate_ImagePath = "/images/guided_tour/GuidedTour_MyWorkplace_QuickBar_Navigation.png";

    GuidedTour_Portal_QuickBar_Directory_Title = "Quick Bar - Directory";
    GuidedTour_Portal_QuickBar_Directory_Content = "With \"Directory\" you will find all SharePoint sites or teams created and managed through SmartPortal365";
    GuidedTour_Portal_QuickBar_Directory_MoreLink = "/help/docs/en/directory";
    GuidedTour_Portal_QuickBar_Directory_ImagePath = "/images/guided_tour/GuidedTour_MyWorkplace_QuickBar_Directory.png";

    GuidedTour_Portal_QuickBar_Admin_Title = "Quick Bar - Admin";
    GuidedTour_Portal_QuickBar_Admin_Content = "All global settings of SmartPortal365 can be found under \"Administration\". This menu item is only displayed if you are defined as SmartPortal365 administrator.";
    GuidedTour_Portal_QuickBar_Admin_MoreLink = "/help/docs/en/admin/";

    GuidedTour_Portal_QuickActions_Title = "Quick - Actions";
    GuidedTour_Portal_QuickActions_Content = "If you click on this \"Plus\" you have the possibility to upload personal documents or documents to SharePoint and create news or workspaces.";
    GuidedTour_Portal_QuickActions_MoreLink = "/help/docs/en/quick-actions";
    GuidedTour_Portal_QuickActions_ImagePath = "/images/guided_tour/GuidedTour_MyWorkplace_QuickActions.png";

    GuidedTour_Portal_UserProfile_Title = "User profile";
    GuidedTour_Portal_UserProfile_Content = "In the user profile you can edit and add to your profile information or start the guided tour again.";
    GuidedTour_Portal_UserProfile_MoreLink = "/help/docs/en/userprofile";
    GuidedTour_Portal_UserProfile_ImagePath = "/images/guided_tour/GuidedTour_MyWorkplace_UserProfile.png";

    GuidedTour_Portal_PremiumAd_Title = "Switch to Pro/Paid Version";
    GuidedTour_Portal_PremiumAd_Content = "Here you can switch to the SmarPortal365 PRO version and benefit from even more useful features.";
    GuidedTour_Portal_PremiumAd_MoreLink = "";

    GuidedTour_Portal_InternetNews_Title = "Internet News";
    GuidedTour_Portal_InternetNews_Content = "News from the world. This feature is only available for users with the SmartPortal365 Free Edition. The search results are provided by Microsoft Bing.";
    GuidedTour_Portal_InternetNews_MoreLink = "/help/docs/en/internetnews";

    //Discover
    GuidedTour_Discover_Title = "Content from SharePoint and OneDrive";
    GuidedTour_Discover_Content = "With the Quickbar \"Discover\" you can find documents of the workspaces in your company that are relevant for you or that you have recently worked on. This way you are immediately up to date with the latest information.";
    GuidedTour_Discover_MoreLink = "/help/docs/en/discover";
    GuidedTour_Discover_ImagePath = "/images/guided_tour/GuidedTour_Discover.png";

    GuidedTour_Discover_Filter_Title = "Filter and view";
    GuidedTour_Discover_Filter_Content = "In addition to the tile size, you can also filter the display of posts by \"Popular\" (the most used documents) and \"Recent\" (the most recently added and edited documents.";
    GuidedTour_Discover_Filter_MoreLink = "/help/docs/en/discover/";
    GuidedTour_Discover_Filter_ImagePath = "";

    //Navigation
    GuidedTour_Navigation_Title = "Global Navigation";
    GuidedTour_Navigation_Content = "<p>Here you will find all workgroups, projects or intranet pages on which you have at least reading rights.</p><p>The navigation contains an input field for quick search at the top.</p>";
    GuidedTour_Navigation_MoreLink = "/help/docs/en/navigate";
    GuidedTour_Navigation_ImagePath = "/images/guided_tour/GuidedTour_Navigation.png";

    GuidedTour_Navigation_Search_Title = "Search";
    GuidedTour_Navigation_Search_Content = "In the \"Search\" field you can search for workgroups, teams and projects for which you are authorized.";
    GuidedTour_Navigation_Search_MoreLink = "";

    GuidedTour_Navigation_Filter_Owner_Title = "Owner filter";
    GuidedTour_Navigation_Filter_Owner_Content = "With the \"Owner filter\" you can define if you want to show only your workgroups or if you want to show all workgroups, teams or projects.";
    GuidedTour_Navigation_Filter_Owner_MoreLink = "";

    GuidedTour_Navigation_Filter_Type_Title = "Type filter";
    GuidedTour_Navigation_Filter_Type_Content = "With this Option you can filter by type: SharePoint Sites, Microsoft Teams Workspaces and your favorites.";
    GuidedTour_Navigation_Filter_Type_MoreLink = "";

    GuidedTour_Navigation_Filter_Template_Title = "Template filter";
    GuidedTour_Navigation_Filter_Template_Content = "With this filter the navigation can be filtered by existing SmartPortal365 Templates for Microsoft Teams or SharePoint Online Sites.";
    GuidedTour_Navigation_Filter_Template_MoreLink = "";

    GuidedTour_Navigation_TileView_Title = "Tile navigation";
    GuidedTour_Navigation_TileView_Content = "You can also display the navigation as a tile instead of the tree navigation.";
    GuidedTour_Navigation_TileView_MoreLink = "/help/docs/en/navigatetiles";

    //Directory
    GuidedTour_Directory_Title = "Directory";
    GuidedTour_Directory_Content = "The directory contains all sites and teams created by SmartPortal365. You can use it to manage the sites and teams created by SmartPortal365 and also create new sites.";
    GuidedTour_Directory_MoreLink = "/help/docs/en/directory";
    GuidedTour_Directory_ImagePath = "/images/guided_tour/GuidedTour_Directory.png";

    GuidedTour_Directory_Filter_Title = "Search and filter";
    GuidedTour_Directory_Filter_Content = "You can use additional filters to display only your sites/teams or use sorting to change the order, e.g. from new to old.";
    GuidedTour_Directory_Filter_MoreLink = "/help/docs/en/directory";

    GuidedTour_Directory_Detail_Title = "Workspace detail";
    GuidedTour_Directory_Detail_Content = "By clicking on the workspace title in the directory the \"Site Information\" pop-up appears that allows to change the properties of the workspace and archive or delete a workspace. This can only be done by the owner or substitute of the workspace or by an administrator.";
    GuidedTour_Directory_Detail_MoreLink = "/help/docs/en/directory";
    GuidedTour_Directory_Detail_ImagePath = "/images/guided_tour/GuidedTour_Directory_Detail_Content.png";

    //TileNavigation
    GuidedTour_TileNavigation_Title = "Tile navigation";
    GuidedTour_TileNavigation_Content = "With the tile navigation you have the possibility to access all workgroups, projects or intranet pages on which you have at least read rights.";
    GuidedTour_TileNavigation_MoreLink = "/help/docs/en/navigatetiles";
    GuidedTour_TileNavigation_ImagePath = "/images/guided_tour/GuidedTour_NavigationTiles.png";

    GuidedTour_TileNavigation_Filter_Title = "Search and filter";
    GuidedTour_TileNavigation_Filter_Content = "You can use additional filters to show only your sites/teams or filter by category or template.";
    GuidedTour_TileNavigation_Filter_MoreLink = "";

    //Administration
    GuidedTour_Admin_Consent_Title = "Admin consent";
    GuidedTour_Admin_Consent_Content = "SmartPortal365 uses the Microsoft APIs to present information from your M365 tenant, as well as to create M365 artifacts.";
    GuidedTour_Admin_Consent_MoreLink = "/help/docs/en/security/#administrator-consent";

    GuidedTour_Admin_Permissions_Title = "Permissions";
    GuidedTour_Admin_Permissions_Content = "Define administrators who can make adjustments to the portal via this page and review and approve / reject requests to create SharePoint sites and teams and approve/reject them.";
    GuidedTour_Admin_Permissions_MoreLink = "/help/docs/en/security";

    GuidedTour_Admin_Colors_Title = "Colors";
    GuidedTour_Admin_Colors_Content = "Change SmartPortal365 colors and customize the interface to match your corporate colors.";
    GuidedTour_Admin_Colors_MoreLink = "/help/docs/en/branding/#colors";

    GuidedTour_Admin_Logo_Title = "Logo";
    GuidedTour_Admin_Logo_Content = "Here you can change the logo of SmartPortal365 header and insert your company logo. We recommend you upload the logo as PNG, with a height of 37px.";
    GuidedTour_Admin_Logo_MoreLink = "/help/docs/en/branding/#logo";

    GuidedTour_Admin_GlobalLinks_Title = "Global links";
    GuidedTour_Admin_GlobalLinks_Content = "Manage the global applications with SmartPortal365.The global links allow to define DeepL accesses for applications and content for all users in SmartPortal365.";
    GuidedTour_Admin_GlobalLinks_MoreLink = "/help/docs/en/globallinks";
    GuidedTour_Admin_GlobalLinks_ImagePath = "/images/guided_tour/GuidedTour_Admin_GlobalLinks.png";

    GuidedTour_Admin_PersonalLinks_Title = "Personal default links";
    GuidedTour_Admin_PersonalLinks_Content = "With this setting you can enable or disable initial and one-time added default personal links that are available in users \"My Links\" section on SmartPortal365 \"My Workplace\" homepage.";
    GuidedTour_Admin_PersonalLinks_MoreLink = "/help/docs/en/defaultperslinks";

    GuidedTour_Admin_Templates_Title = "Templates";
    GuidedTour_Admin_Templates_Content = "In addition to the default templates, custom templates can also be created and managed for Microsoft Teams or SharePoint Online sites.";
    GuidedTour_Admin_Templates_MoreLink = "/help/docs/en/provisioning/";
    GuidedTour_Admin_Templates_ImagePath = "/images/guided_tour/GuidedTour_Admin_Templates.png";

    GuidedTour_Admin_Classification_Title = "Classification";
    GuidedTour_Admin_Classification_Content = "This setting allows to use the optional site classification available under Microsoft 365, for example, to define the confidentiality of the workspace.";
    GuidedTour_Admin_Classification_MoreLink = "/help/docs/en/provisioning/#classification";
    GuidedTour_Admin_Classification_ImagePath = "/images/guided_tour/GuidedTour_Admin_Classification.png";
 
    GuidedTour_Admin_Webhook_Title = "External system";
    GuidedTour_Admin_Webhook_Content = "This setting allows events within SmartPortal365, such as the creation of a workspace, to be reacted to in external systems via WebHooks.";
    GuidedTour_Admin_Webhook_MoreLink = "/help/docs/en/webhook";
}

export default (() => {
    let dict = new GuidedTourStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
