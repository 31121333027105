export interface ICardFilter {
  searchFilter: string;
  roleFilterKey: string;
  typeFilterKey: string;
}

export class CardFilter implements ICardFilter {
  public searchFilter = "";
  public roleFilterKey = "";
  public typeFilterKey = "";
}