import { FolderPickerItem } from "../Models/FolderPickerItem"
import { IBreadcrumbItem } from 'office-ui-fabric-react';
import * as UrlUtil from "../../../utils/UrlUtil";

export interface FolderPickerItemResult {
    items: FolderPickerItem[];
    currentFolder: FolderPickerItem;
    breadcrumbItems: IBreadcrumbItem[];
    hasMoreItems: boolean;
    searchable: boolean;
}

export interface FolderPickerSearchItemResult {
    items: FolderPickerItem[];
    hasMoreItems: boolean;
}

export interface FolderPickerMoreItemResult {
    moreItems: FolderPickerItem[];
    hasMoreItems: boolean;
}

export interface FolderPickerDataSource {
    initialFolder: FolderPickerItem;
    loadItems(parentItem: FolderPickerItem | null): Promise<FolderPickerItemResult>;
    loadMoreItems(): Promise<FolderPickerMoreItemResult>;
    searchItems(search: string): Promise<FolderPickerSearchItemResult>;
    onBreadcrumbClick(item: IBreadcrumbItem): Promise<FolderPickerItemResult>;
    reset(): void;
    initialBreadcrumbItems: IBreadcrumbItem[];
}

export const getSkipToken = (res: any): string => {
    let nextPageToken: string;
    if ((nextPageToken = res["@odata.nextLink"])) {
        nextPageToken = UrlUtil.getParameterByName("$skiptoken", nextPageToken);
        //const nextPageUrl = new URL(nextPageToken);
        //nextPageToken = nextPageUrl.searchParams.get("$skiptoken");
    }
    return nextPageToken;
}