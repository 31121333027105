
export enum CacheEncoding {
    Plain,
    Compress,
}

export interface ICacheOpts {
    defaultEncoding?: CacheEncoding; // default encoding for cache entries
    defaultTimeout?: number; // default timeout for cache entries
    defaultPriority?: number; // default priority for cache entries
    accessTimeout?: number; // time since last access before cleanup
    updateTimeout?: number; // time since last update before cleanup
}

export interface ICacheSetOpts {
    encode?: number;
    timeout?: number;
    priority?: number;
}

export interface ICacheInterfaceOpts {
    setOpts: ICacheSetOpts;
}
