import { BaseCard, StreamSource } from "..";

export class NewsCard extends BaseCard {

  public get thumbnail(): string { return this.getDataField("thumbnail"); };
  public set thumbnail(val: string) { this.setDataField("thumbnail", val); };

  public get banner(): string { return this.getDataField("banner"); };
  public set banner(val: string) { this.setDataField("banner", val); };

  public get likeCount(): number { return this.getDataField("likeCount"); };
  public set likeCount(val: number) { this.setDataField("likeCount", val); };

  public get commentCount(): number { return this.getDataField("commentCount"); };
  public set commentCount(val: number) { this.setDataField("commentCount", val); };

  public get viewCount(): number { return this.getDataField("viewCount"); };
  public set viewCount(val: number) { this.setDataField("viewCount", val); };

  public get recentViewCount(): number { return this.getDataField("recentViewCount"); };
  public set recentViewCount(val: number) { this.setDataField("recentViewCount", val); };
  
  public get authorName(): string { return this.getDataField("authorName"); };
  public set authorName(val: string) { this.setDataField("authorName", val); };

  public get authorMail(): string { return this.getDataField("authorMail"); };
  public set authorMail(val: string) { this.setDataField("authorMail", val); };

  public get authorLoginName(): string { return this.getDataField("authorLoginName"); };
  public set authorLoginName(val: string) { this.setDataField("authorLoginName", val); };

  public get authorImage(): string { return this.getDataField("authorImage"); };
  public set authorImage(val: string) { this.setDataField("authorImage", val); };

  public get description(): string { return this.getDataField("description"); };
  public set description(val: string) { this.setDataField("description", val); };

  public get sites(): any { return this.getDataField("sites"); };
  public set sites(val: any) { this.setDataField("sites", val); };

  public constructor(source: StreamSource, itemRef: any){
    super(source, itemRef);

    this.lazyFieldCfg = {
      "sites": {
        lazyField: "parentSites",
        setterFn: (data) => { this.sites = data; }
      },
      "thumbnail": {
        lazyField: "thumbnailUrl",
        setterFn: (data) => { this.thumbnail = data; }
      },      
      "banner": {
        lazyField: "bannerUrl",
        setterFn: (data) => { this.banner = data; }
      },
      "commentCount": {
        lazyField: "commentCount",
        setterFn: (data) => { this.commentCount = data; }
      },
      "likeCount": {
        lazyField: "likeCount",
        setterFn: (data) => { this.likeCount = data; }
      },
      "viewCount": {
        lazyField: "analytics",
        setterFn: (data) => { this.viewCount = data.allTime ? data.allTime.accessTotal : null; }
      },
      "recentViewCount": {
        lazyField: "analytics",
        setterFn: (data) => { this.viewCount = data.lastWeek ? data.lastWeek.accessTotal : null; }
      },
      "authorImage": {
        lazyField: "analytics",
        setterFn: (data) => { this.viewCount = data.lastWeek ? data.lastWeek.accessTotal : null; }
      },
    };
  }

}
