import * as React from 'react';
import styles from "./FileUploadStyle.scss";
import { SmartPortal } from '../../../SmartPortal';
import FileUploadStrings from "./loc/FileUploadStrings";
import { FileUploadItem } from "./FileUploadItem"
import Dropzone from 'react-dropzone';
import { PrimaryButton } from 'office-ui-fabric-react';
import { IUploadFile, UploadFileStates, UploadFileResumeTypes } from "../../models/UploadFile";
import * as FileUtil from "../../utils/FileUtil";
import * as GuidHandler from "../../utils/GuidHandler";

export interface IFileUploadProps {
    files: IUploadFile[];
    saving?: boolean;
    saved?: boolean;
    onFileAdded: (file: IUploadFile) => void;
    onFileRemoved: (index: number) => void;
    onFileResumed: (index: number, resumeType: UploadFileResumeTypes) => void;
}

export interface IFileUploadState {
}

export class FileUpload extends React.Component<IFileUploadProps, IFileUploadState> {

    private dropzoneRef;

    constructor(props: IFileUploadProps, state: IFileUploadState) {
        super(props);

        this.state = {
        };

        this.openDialog = this.openDialog.bind(this);
    }

    private openDialog() {
        if (this.dropzoneRef) {
            this.dropzoneRef.open();
        }
    };

    public render(): React.ReactElement<{}> {
        return (
            <div className={styles.FileUpload}>
                <Dropzone ref={ref => { this.dropzoneRef = ref; return true; }}
                    noClick={this.props.files.length === 0 || this.props.saving || this.props.saved}
                    noKeyboard={true}
                    onDrop={acceptedFiles => acceptedFiles.forEach(file => {
                        const uploadFile: IUploadFile = {
                            id: GuidHandler.getNewGuid(),
                            file: file,
                            size: FileUtil.getFileSize(file),
                            state: UploadFileStates.Added,
                            progress: 0,
                            errorType: undefined
                        };
                        if (this.props.onFileAdded)
                            this.props.onFileAdded(uploadFile);
                    })}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: styles.FileUploadDragZone })}>
                            <input {...getInputProps()} />
                            {this.props.files.length === 0 ?
                                <div className={styles.FileUploadContent}>
                                    <div className={styles.FileUploadImage}>
                                        <img src={SmartPortal.buildPortalUrl("/images/Upload.png")} />
                                    </div>
                                    <div className={styles.FileUploadDragDropText}>
                                        <span>{FileUploadStrings.FileUpload_DragDropText}</span>
                                    </div>
                                    <div className={styles.FileUploadOpenExplorer}>
                                        <PrimaryButton text={FileUploadStrings.FileUpload_OpenExplorer} allowDisabledFocus onClick={this.openDialog} />
                                    </div>
                                </div> :
                                <div className={styles.FileUploadFileList}>
                                    {this.props.files.map((file, index) => <FileUploadItem file={file} onRemove={() => {
                                        if (this.props.onFileRemoved)
                                            this.props.onFileRemoved(index);
                                    }} onResume={(resumeType) => {
                                        if (this.props.onFileResumed) {
                                            this.props.onFileResumed(index, resumeType);
                                        }
                                    }} />)}
                                </div>
                            }
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    }
}
