import * as React from 'react';
import styles from "./GuidedTourStyle.scss";
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { IButtonProps, Spinner } from 'office-ui-fabric-react';
import { ServiceLoader } from '../../business/ServiceLoader';
import { RequestService } from '../../business/RequestService';
import { SmartPortal } from '../../../SmartPortal';
import { GuidedTourStep, Tour } from '../../business/GuidedTourService';
import { ICacheService } from '../../cache/models/CacheService';
import { GenericCacheService } from '../../cache/GenericCacheService';
import strings from "./loc/GuidedTourStrings";
import { PortalPage } from '../../../components/basepage/PortalPage';

export interface IGuidedTourProps {
    tour: Tour;
}

interface IGuidedTourState {
    currentStepIndex: number;
    tourStateLoaded: boolean;
    steps: GuidedTourStep[];
    isTourComplete: boolean;
    showTour: boolean;
    isSaving: boolean;
}

interface MyEvent {
    detail: string;
}

export default class GuidedTour extends React.Component<IGuidedTourProps, IGuidedTourState> {

    private targetElement: string;
    private completedStepIds: string[];
    private cacheService: ICacheService;

    constructor(props: IGuidedTourProps) {
        super(props);
        this.completedStepIds = [];
        this.cacheService = ServiceLoader.GetService(GenericCacheService).getPrefixedCache(`GuidedTour`);

        this.state = {
            currentStepIndex: 0,
            steps: [],
            tourStateLoaded: false,
            isTourComplete: false,
            showTour: true,
            isSaving: false
        };
    }

    componentDidMount(): void {
        //We only show a guided tour inside SmartPortal 365 (not in Teams or SPO)
        if (!(SmartPortal.currentPage instanceof PortalPage)) {
            return;
        }

        const body = document.querySelector(".sp365-PortalBody");
        body?.addEventListener("scroll", this.handleScroll.bind(this));
        window.addEventListener("resize", this.handleResize.bind(this));
        this.loadTourState();
    }

    componentWillUnmount(): void {
        if (!(SmartPortal.currentPage instanceof PortalPage)) {
            return;
        }
        
        const body = document.querySelector(".sp365-PortalBody");
        body?.removeEventListener("scroll", this.handleScroll.bind(this));
        window.removeEventListener("resize", this.handleResize.bind(this));
    }

    private handleOnClose(ev: any) {
        if (ev?.type === "scroll" || ev?.type === "resize") {
            return;
        }

        this.setState({
            showTour: false
        });
    }

    private handleResize() {
        this.forceUpdate();
    }

    private handleScroll() {
        this.forceUpdate();
    }

    private onNextClick() {        

        this.saveTourState().then(() => {
            this.setState((prevState, props) => ({
                currentStepIndex: this.state.steps.length > prevState.currentStepIndex - 1 ? prevState.currentStepIndex + 1 : prevState.currentStepIndex,
                isSaving: false
            }));
        }).catch(reason => {
            this.setState({
                isSaving: false
            });
            if(console){
                console.log(reason);
            }
        });
    }

    private onPrevClick() {
        this.setState((prevState, props) => {
            return {
                ...prevState, currentStepIndex: prevState.currentStepIndex === 0 ? 0 : prevState.currentStepIndex - 1,
                loading: false
            };
        });
    }

    private onSkipTourClick() {
        this.saveTourState(true).then((() => {
            this.setState({
                showTour: false,
                isSaving: false
            });
        }));
    }

    public render() {
        if (!this.state.showTour)
            return null;

        let currentStep: GuidedTourStep = null;
        let nextButtonProps: IButtonProps = null;
        let backButtonProps: IButtonProps = null;
        if (this.state.tourStateLoaded === true && this.state.steps.length > 0) {
            nextButtonProps = { children: this.state.currentStepIndex + 1 === this.state.steps.length ? strings.GuidedTour_Controls_Finish : strings.GuidedTour_Controls_Next };
            
            if(this.state.isSaving){
                nextButtonProps.children = <Spinner/>
                nextButtonProps.disabled = true;
            }   
            nextButtonProps.hidden = nextButtonProps.disabled;
            nextButtonProps.onClick = this.onNextClick.bind(this);

            if (this.state.currentStepIndex === 0 && !this.state.isSaving ) {
                backButtonProps = { children: strings.GuidedTour_Controls_SkipTour };            
                backButtonProps.onClick = this.onSkipTourClick.bind(this);
            }

            currentStep = this.state.steps[this.state.currentStepIndex];

            if (currentStep) {
                const element = document.querySelector(currentStep.target);
                if (!element && this.state.isSaving === false && !this.completedStepIds.includes(currentStep.id)) {
                    setTimeout(() => this.onNextClick(), 1000);
                    return null;
                }
            }

            if (currentStep && this.targetElement !== currentStep.target) {
                this.targetElement = currentStep.target;
                const element = document.querySelector(currentStep.target);
                element?.scrollIntoView(false);
            }
        }
        
        return (
            <>
                {
                    this.state.tourStateLoaded == true && currentStep ? <div>
                        <TeachingBubble firstFocusableSelector="ms-TeachingBubble-primaryButton"  footerContent={this.state.steps.length > 0 && this.state.currentStepIndex !== 0 ? `${this.state.currentStepIndex + 1} ${strings.GuidedTour_Label_Of} ${this.state.steps.length}` : null} onDismiss={(ev) => { this.handleOnClose(ev) }} hasCloseButton={true} illustrationImage={currentStep.illustrationImage} isWide={currentStep.isWide} focusTrapZoneProps={{ forceFocusInsideTrap: true, isClickableOutsideFocusTrap: false }} target={currentStep.target} secondaryButtonProps={backButtonProps} headline={currentStep.headLine} primaryButtonProps={nextButtonProps} >
                            {
                                <div dangerouslySetInnerHTML={{ __html: currentStep.content }}></div>
                            }
                            {
                                currentStep.seeMoreLink && currentStep.seeMoreLink !== "" ?
                                    <p>
                                        <a className={styles.LearnMoreLink} href={currentStep.seeMoreLink} target="_blank">{strings.GuidedTour_Controls_MoreLinkLabel}</a>
                                    </p>
                                    : null
                            }
                        </TeachingBubble>
                    </div> : null
                }
            </>
        );
    }

    private saveTourState(skipTour?: boolean): Promise<void> {

        let step: GuidedTourStep = null;
        this.setState({
            isSaving:true
        });
        const isTourComplete = skipTour ?? this.state.steps.length - 1 === this.state.currentStepIndex;
        if (skipTour) {

            this.props.tour.steps.forEach((item) => {
                this.completedStepIds.push(item.id);
                this.completedStepIds = this.completedStepIds.filter(function (item, pos, self) {
                    return self.indexOf(item) === pos;
                });
            });
        } else {
            step = this.state.steps[this.state.currentStepIndex];
            this.completedStepIds.push(step.id);
            this.completedStepIds = this.completedStepIds.filter(function (item, pos, self) {
                return self.indexOf(item) === pos;
            });
        }

        const reqSvc = ServiceLoader.GetService(RequestService);
        const data = new Map<string, any>();
        data[`GuidedTour_${this.props.tour.name}`] = { complete: isTourComplete, completedSteps: this.completedStepIds };

        return reqSvc.requestAadService({
            method: "Post",
            url: "/api/UserData/SetProfileFlags",
            header: new Map<string, string>([
                ["Content-Type", "application/json"]
            ]),

            data: JSON.stringify({
                flags: data
            })
        }).then(reqSvc.parseApiResponse).then((response) => {

            if (isTourComplete) {
                this.cacheService.setCacheEntry(`tourComplete_${this.props.tour.name}_` + SmartPortal.currentUserId, true, { timeout: 24 * 60 * 60 * 1 });
            }
        });
    }


    private async loadTourState(): Promise<void> {

        const isTourComplete = await this.cacheService.getCacheEntry(`tourComplete_${this.props.tour.name}_` + SmartPortal.currentUserId);
        if (isTourComplete === true)
            return;

        const reqSvc = ServiceLoader.GetService(RequestService);
        await reqSvc.requestAadService({
            method: "Post",
            url: "/api/UserData/GetProfileFlags",
            header: new Map<string, string>([
                ["Content-Type", "application/json"]
            ]),
            data: JSON.stringify({
                accountId: SmartPortal.currentUserId,
                flags: [`GuidedTour_${this.props.tour.name}`]
            })
        }).then(reqSvc.parseApiResponse).then(async(response) => {
            if (response && response.flags && response.flags[`GuidedTour_${this.props.tour.name}`])
                this.completedStepIds = response.flags[`GuidedTour_${this.props.tour.name}`].completedSteps as string[];

            const openSteps = [...this.props.tour.steps].filter((item) => this.completedStepIds.indexOf(item.id) < 0);

            if(openSteps && openSteps.length === 0) {
                await this.cacheService.setCacheEntry(`tourComplete_${this.props.tour.name}_` + SmartPortal.currentUserId, true, { timeout: 24 * 60 * 60 * 1 });
            }

            this.setState({
                steps: openSteps,
                tourStateLoaded: true
            });
        });
    }
}
