import React from 'react';

import { SmartPortalApp } from '../../common/utils/SmartPortalApp';
import { PortalPage } from "../../components/basepage/PortalPage";
import { AccessDeniedPage } from "../../components/basepage/AccessDeniedPage";
import { Dialog } from "../../common/controls/Dialog/Dialog";
import { NavigationLoader } from '../../components/navigation/NavigationLoader';
import { IPageContextInfo, ISessionInfo, IContextAndSessionInfo } from "../../common/models/SessionInfo";
import { SmartPortal } from '../../SmartPortal';
import { ServiceLoader } from '../../common/business/ServiceLoader';
import { RequestService } from '../../common/business/RequestService';
import { ThemeUtil } from '../../common/utils/ThemeUtil';
import { SmartPortalRouter } from '../../SmartPortalRouter';
import { PortalLeftBarNavDict } from '../../components/basepage/PortalLeftBar';
import { AppPolicy } from '../../common/models/AppPolicy';
import { RoleService, Role } from '../../common/business/RoleService';

import PortalBaseStyles from "../../styles/SmartPortal.scss";
import PortalPageStyles from "../../components/basepage/PageStyle.scss";


export module SmartPortalMain {

    export class Components {
        public static readonly PortalPage = PortalPage;
        public static readonly AccessDeniedPage = AccessDeniedPage;
        public static readonly Dialog = Dialog;
        public static readonly NavigationLoader = NavigationLoader;
        public static readonly SmartPortalRouter = SmartPortalRouter;
        public static readonly AccountDeleted = React.lazy(() => import(/* webpackChunkName: "session" */ '../../components/session/AccountDeleted'));
        public static readonly CountryRestriction = React.lazy(() => import(/* webpackChunkName: "session" */ '../../components/session/CountryRestriction'));
        public static readonly ErrorPage = React.lazy(() => import(/* webpackChunkName: "error" */ '../../components/basepage/ErrorPage'));
        public static readonly LoggedOut = React.lazy(() => import(/* webpackChunkName: "session" */ '../../components/session/LoggedOut'));
        public static readonly RegistrationForm = React.lazy(() => import(/* webpackChunkName: "session" */ '../../components/session/RegistrationForm'));
        public static readonly ProfileEditForm = React.lazy(() => import(/* webpackChunkName: "session" */ '../../components/session/ProfileEditForm'));
        public static readonly WelcomePage = React.lazy(() => import(/* webpackChunkName: "session" */ '../../components/session/WelcomePage'));
    };

    export class Styles {
        public static readonly PortalBase = PortalBaseStyles;
        public static readonly PortalPage = PortalPageStyles;
    }

    export class Navigation {
        public static getItems(type: "Items" | "UnselectableItems"): PortalLeftBarNavDict {
            switch (type) {
                case "Items":
                    return {
                        "MyWorkplace": {
                            iconName: "Work",
                            navLink: "/Portal/MyWorkplace",
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_MyWorkplace"),
                            hidden: (!SmartPortal.checkAppPolicy(AppPolicy.FeatureMyWorkplace))
                        },
                        "Discover": {
                            iconName: "CompassNW",
                            navLink: "/Portal/Discover", 
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_Discover"),
                            hidden: (!SmartPortal.checkAppPolicy(AppPolicy.FeatureDiscovery))
                        },
                        "Navigation": { 
                            iconName: "ThumbnailView", 
                            onClick: Components.NavigationLoader.OpenNavHandler, 
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_GlobalNavigation"), 
                            hidden: (SmartPortal.currentSession.userType === SmartPortal.Enums.UserType.PrivateAccount || !SmartPortal.checkAppPolicy(AppPolicy.FeatureNavigation))
                        },
                        "Directory": { 
                            iconName: "CompanyDirectory", 
                            navLink: "/Directory", 
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_Directory"),
                            hidden: (SmartPortal.currentSession.userType === SmartPortal.Enums.UserType.PrivateAccount || !SmartPortal.checkAppPolicy(AppPolicy.FeatureProvisioning)) 
                        },
                        "Admin": { 
                            iconName: "Settings", 
                            navLink: "/Admin", 
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_Admin"), 
                            hidden: (SmartPortal.currentSession.userType === SmartPortal.Enums.UserType.PrivateAccount || !SmartPortal.checkAppPolicy(AppPolicy.FeatureAdministration) || !ServiceLoader.GetService(RoleService).IsUserInRole(Role.Admin)) 
                        },
                        "_spacer": { 
                            iconName: null, 
                            navLabel: null, 
                            className: Styles.PortalPage.PortalNavSpacer, 
                            spacer: true 
                        },
                        "Support": { 
                            iconName: "Help", 
                            navLink: SmartPortal.getLocalizedString("PageStrings.AppNavBar_SupportUrl"), 
                            className: Styles.PortalPage.PortalNavSupport, 
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_Support"),
                            openInTab: true,
                            unselectable: true 
                        }
                    };
                case "UnselectableItems":
                    return {
                        "MyWorkplace": { 
                            iconName: "Work", 
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_MyWorkplace"),
                            hidden: (!SmartPortal.checkAppPolicy(AppPolicy.FeatureMyWorkplace))
                        },
                        "Discover": { 
                            iconName: "CompassNW", 
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_Discover"),
                            hidden: (!SmartPortal.checkAppPolicy(AppPolicy.FeatureDiscovery))
                        },
                        "Navigation": { 
                            iconName: "ThumbnailView", 
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_GlobalNavigation"),
                            hidden: (SmartPortal.currentSession.userType === SmartPortal.Enums.UserType.PrivateAccount || !SmartPortal.checkAppPolicy(AppPolicy.FeatureNavigation))
                        },
                        "Directory": { 
                            iconName: "CompanyDirectory", 
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_Directory"), 
                            hidden: (SmartPortal.currentSession.userType === SmartPortal.Enums.UserType.PrivateAccount || !SmartPortal.checkAppPolicy(AppPolicy.FeatureProvisioning)) 
                        },
                        "Admin": { 
                            iconName: "Settings", 
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_Admin"), 
                            hidden: (SmartPortal.currentSession.userType === SmartPortal.Enums.UserType.PrivateAccount || !SmartPortal.checkAppPolicy(AppPolicy.FeatureAdministration) || !ServiceLoader.GetService(RoleService).IsUserInRole(Role.Admin)) 
                        },
                        "_spacer": { 
                            iconName: null, 
                            navLabel: null, 
                            className: Styles.PortalPage.PortalNavSpacer, 
                            spacer: true 
                        },
                        "Support": { 
                            iconName: "Help", 
                            className: Styles.PortalPage.PortalNavSupport, 
                            navLabel: SmartPortal.getLocalizedString("PageStrings.AppNavBar_Support") 
                        }
                    };
                default:
                    return {}
            }
        }
    }

    export let currentApp: SmartPortalApp = null;

    export function startApp(pageContextInfo: IPageContextInfo, sessionInfo: ISessionInfo): SmartPortalApp {
        if (!currentApp) {
            ServiceLoader.GetService(RequestService).setAuthErrorHandler((response) => {
                location.href = "/Session/Login";
                return Promise.resolve(false);
            });
            
            if(sessionInfo.organizationName && !document.title.startsWith(`SmartPortal365 | ${sessionInfo.organizationName}`))
                document.title = document.title.replace("SmartPortal365", `SmartPortal365 | ${sessionInfo.organizationName}`);

            let contextAndSessionInfo = Object.assign<IContextAndSessionInfo, IPageContextInfo, ISessionInfo>({} as any, pageContextInfo, sessionInfo);
            let appPromise = SmartPortal.startApp(contextAndSessionInfo, "Main");
            ThemeUtil.applyThemeColors();

            SmartPortal.currentErrors = {
                errors: pageContextInfo.pageErrors || []
            };
            SmartPortal.currentFormData = (pageContextInfo.formType) ? {
                type: pageContextInfo.formType,
                data: pageContextInfo.formData
            } : null;

            currentApp = new SmartPortalApp(appPromise);
        }
        return currentApp;
    }
}

(() => { // export global SmartPortal namespace
    let SmartPortalModule = Object.create(SmartPortal);
    Object.assign(SmartPortalModule, SmartPortalMain);
    SmartPortalModule.Main = SmartPortalMain;
    (window as any).SmartPortal = SmartPortalModule;
})();
