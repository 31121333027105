import { LocaleService, BaseStrings, Locale } from "../../../common/business/LocaleService";
import { SiteLanguage, SiteTimeZone, SiteProvisioningType, SiteStatus, SiteInfoGuestsAccess, SiteSharingCapability, SiteInfoVisibility } from '../../../common/models/SiteInfo';

export class ProvisioningStrings extends BaseStrings {
    readonly _ModuleName = "ProvisioningStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new ProvisioningStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./ProvisioningStrings.de").then((res) => res.default),
        
    };
    _CurrentLocale: Locale = Locale.EN;

    SiteRequestDialog_Title = "Create workspace";

    SiteTemplateForm_Description = "Create a workspace for collaboration with internal and external participants. Here you will get an overview of the available options. In the following step you can further configure your workspace.";
    SiteTemplateForm_SkipIntroLink = "Switch directly to the request form";
    
    SiteDetailsForm_SiteType = "Type";
    SiteDetailsForm_SiteTypePlaceholder = "Select type";
    SiteDetailsForm_SiteTypes: { [type in SiteProvisioningType]: string } = {
        [SiteProvisioningType.MSTeams]: "Microsoft Teams",
        [SiteProvisioningType.SPOSite]: "SharePoint Site",
    };
    SiteDetailsForm_SiteTemplate = "Template";
    SiteDetailsForm_SiteTemplatePlaceholder = "Select template";

    SiteDetailsForm_Title = "Name of the workspace";
    SiteDetailsForm_Title_InfoText = "The name under which the workspace should be visible in the navigation for all users, e.g: Marketing Strategy 2021";
    SiteDetailsForm_Url = "Site address";
    SiteDetailsForm_Url_InfoText = "A unique web address, under which the workspace should be accessible, e.g: Marketing2021";
    SiteDetailsForm_Nickname = "Group alias";
    SiteDetailsForm_Nickname_InfoText = "The group alias is also an email address and must therefore be unique, e.g: Marketing2021";
    SiteDetailsForm_UrlInvalid = "This URL cannot be used";
    SiteDetailsForm_UrlProposal = "A valid URL is: {0}";
    SiteDetailsForm_Description = "Description";
    SiteDetailsForm_Description_InfoText = "A good description of the workplace enables your colleagues to easily find the workspace, e.g: All necessary documents concerning our Marketing Strategy 2021 are stored here.";
    SiteDetailsForm_SiteStatus: { [type in SiteStatus]: string } = {
        [SiteStatus.Active]: "Active",
        [SiteStatus.Archived]: "Archived",
        [SiteStatus.Deleted]: "Deleted",
        [SiteStatus.ProvisionQueued]: "Provisioning is waiting in the queue",
        [SiteStatus.ProvisionRunning]: "Provisioning is in progress",
        [SiteStatus.ProvisionError]: "An error occurred during the provisioning",
        [SiteStatus.ChangeQueued]: "A change is waiting in the queue",
        [SiteStatus.ChangeRunning]: "A change is in progress",
        [SiteStatus.ChangeError]: "An error occurred during the change",
        [SiteStatus.WaitingForApproval]: "Waiting for approval",
        [SiteStatus.WaitingForWebHook]: "Waiting for external system",
        [SiteStatus.WaitingForWebHookApproval]: "Waiting for approval in external system",
        [SiteStatus.WebHookError]: "An error occurred in the external system",
        [SiteStatus.WaitingForChangeApproval]: "Waiting for approval of a change",
        [SiteStatus.WebHookApprovalError]: "An error occurred during approval in the external system"
    };

    SiteDetailsForm_PrimaryOwner = "Owner";
    SiteDetailsForm_PrimaryOwner_InfoText = "The owner of the workspace who will manage members and be responsible for the content of the workspace, e.g.: felix.mustermann@test.cloud";
    SiteDetailsForm_SecondaryOwner = "Substitute";
    SiteDetailsForm_SecondaryOwner_InfoText = "A deputy who should have the same rights as the owner of a workspace, e.g. felix.mustermann@test.cloud";
    SiteDetailsForm_InactiveOwnerWarning = "The owner / substitute of the room is no longer active. Please select a new owner / substitute";    
    SiteDetailsForm_SiteStatusLabel = "Status";
    SiteDetailsForm_Visibility = "Visibility";
    SiteDetailsForm_Visibility_InfoText= "Private: only team members can see and access the workspace. Public: everyone in the company can see the workspace and join it.";
    SiteDetailsForm_Visibilities: { [type in SiteInfoVisibility]: string } = {
        [SiteInfoVisibility.Private]: "Private - only team members can see the workspace and access it",
        [SiteInfoVisibility.Public]: "Public - everyone in the company can see the workspace and join it"
    };
    SiteDetailsForm_Classification = "Classification";
    SiteDetailsForm_Classification_InfoText = "A classification indicates for which user group the content should be available. The classification is purely informative.";
    SiteDetailsForm_SharingCapability = "Share SharePoint sites with externals?";
    SiteDetailsForm_SharingCapability_InfoText = "Here you can define if you want to share your SharePoint pages with people outside your organization. You can also define here if you want to share them with new external contacts or if you only want to share your content with existing external contacts.";
    SiteDetailsForm_SharingCapabilities: { [type in SiteSharingCapability]: string } = {
        [SiteSharingCapability.ExternalUserSharingOnly]: "Yes - with new and existing guests",
        [SiteSharingCapability.ExistingExternalUserSharingOnly]: "Yes - with existing guests only",
        [SiteSharingCapability.Disabled]: "No"
    };
    SiteDetailsForm_GuestAccess = "Enable guest access in Microsoft Teams?";
    SiteDetailsForm_GuestAccess_InfoText = "Guest access lets people outside your organisation access documents and information of your Microsoft Teams workspace.";
    SiteDetailsForm_GuestAccesses: { [type in SiteInfoGuestsAccess]: string } = {
        [SiteInfoGuestsAccess.Allow]: "Yes",
        [SiteInfoGuestsAccess.Forbid]: "No"
    };
    SiteDetailsForm_Language = "Language";
    SiteDetailsForm_Language_InfoText = "Select the language in which you want SmartPortal365 to be displayed.";
    SiteDetailsForm_Languages: { [language in SiteLanguage]: string } = {
        1025: "Arabic",
        1068: "Azerbaijani",
        1069: "Basque",
        5146: "Bosnian (Latin)",
        1026: "Bulgarian",
        1027: "Catalan",
        2052: "Chinese (Simplified)",
        1028: "Chinese (Traditional)",
        1050: "Croatian",
        1029: "Czech",
        1030: "Danish",
        1164: "Dari",
        1043: "Dutch",
        1033: "English",
        1061: "Estonian",
        1035: "Finnish",
        1036: "French",
        1110: "Galician",
        1031: "German",
        1032: "Greek",
        1037: "Hebrew",
        1081: "Hindi",
        1038: "Hungarian",
        1057: "Indonesian",
        2108: "Irish",
        1040: "Italian",
        1041: "Japanese",
        1087: "Kazakh",
        1042: "Korean",
        1062: "Latvian",
        1063: "Lithuanian",
        1071: "Macedonian",
        1086: "Malay",
        1044: "Norwegian (Bokmål)",
        1045: "Polish",
        1046: "Portuguese (Brazil)",
        2070: "Portuguese (Portugal)",
        1048: "Romanian",
        1049: "Russian",
        10266: "Serbian (Cyrillic, Serbia)",
        9242: "Serbian (Latin, Serbia)",
        2074: "Serbian (Latin)",
        1051: "Slovak",
        1060: "Slovenian",
        3082: "Spanish",
        1053: "Swedish",
        1054: "Thai",
        1055: "Turkish",
        1058: "Ukrainian",
        1066: "Vietnamese",
        1106: "Welsh"
    };
    SiteDetailsForm_Timezone = "Timezone";
    SiteDetailsForm_Timezone_InfoText = "The selected time zone determines the date display of the documents in the workspace.";
    SiteDetailsForm_Timezones: { [zone in SiteTimeZone]: string } = {
        39: "(UTC-12:00) International Date Line West",
        95: "(UTC-11:00) Coordinated Universal Time-11",
        15: "(UTC-10:00) Hawaii",
        14: "(UTC-09:00) Alaska",
        78: "(UTC-08:00) Baja California",
        13: "(UTC-08:00) Pacific Time (US and Canada)",
        38: "(UTC-07:00) Arizona",
        77: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
        12: "(UTC-07:00) Mountain Time (US and Canada)",
        55: "(UTC-06:00) Central America",
        11: "(UTC-06:00) Central Time (US and Canada)",
        37: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
        36: "(UTC-06:00) Saskatchewan",
        35: "(UTC-05:00) Bogota, Lima, Quito",
        10: "(UTC-05:00) Eastern Time (US and Canada)",
        34: "(UTC-05:00) Indiana (East)",
        88: "(UTC-04:30) Caracas",
        91: "(UTC-04:00) Asuncion",
        9: "(UTC-04:00) Atlantic Time (Canada)",
        81: "(UTC-04:00) Cuiaba",
        33: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
        65: "(UTC-04:00) Santiago",
        28: "(UTC-03:30) Newfoundland",
        8: "(UTC-03:00) Brasilia",
        85: "(UTC-03:00) Buenos Aires",
        32: "(UTC-03:00) Cayenne, Fortaleza",
        60: "(UTC-03:00) Greenland",
        90: "(UTC-03:00) Montevideo",
        103: "(UTC-03:00) Salvador",
        96: "(UTC-02:00) Coordinated Universal Time-02",
        30: "(UTC-02:00) Mid-Atlantic",
        29: "(UTC-01:00) Azores",
        53: "(UTC-01:00) Cabo Verde",
        86: "(UTC) Casablanca",
        93: "(UTC) Coordinated Universal Time",
        2: "(UTC) Dublin, Edinburgh, Lisbon, London",
        31: "(UTC) Monrovia, Reykjavik",
        4: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
        6: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
        3: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
        57: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
        69: "(UTC+01:00) West Central Africa",
        83: "(UTC+01:00) Windhoek",
        79: "(UTC+02:00) Amman",
        5: "(UTC+02:00) Athens, Bucharest",
        80: "(UTC+02:00) Beirut",
        49: "(UTC+02:00) Cairo",
        98: "(UTC+02:00) Damascus",
        50: "(UTC+02:00) Harare, Pretoria",
        59: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
        27: "(UTC+02:00) Jerusalem",
        7: "(UTC+02:00) Minsk (old)",
        104: "(UTC+02:00) E. Europe",
        100: "(UTC+02:00) Kaliningrad",
        26: "(UTC+03:00) Baghdad",
        101: "(UTC+03:00) Istanbul",
        74: "(UTC+03:00) Kuwait, Riyadh",
        109: "(UTC+03:00) Minsk",
        51: "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
        56: "(UTC+03:00) Nairobi",
        25: "(UTC+03:30) Tehran",
        24: "(UTC+04:00) Abu Dhabi, Muscat",
        110: "(UTC+04:00) Astrakhan, Ulyanovsk",
        54: "(UTC+04:00) Baku",
        106: "(UTC+04:00) Izhevsk, Samara",
        89: "(UTC+04:00) Port Louis",
        82: "(UTC+04:00) Tbilisi",
        84: "(UTC+04:00) Yerevan",
        48: "(UTC+04:30) Kabul",
        58: "(UTC+05:00) Ekaterinburg",
        87: "(UTC+05:00) Islamabad, Karachi",
        47: "(UTC+05:00) Tashkent",
        23: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
        66: "(UTC+05:30) Sri Jayawardenepura",
        62: "(UTC+05:45) Kathmandu",
        71: "(UTC+06:00) Astana",
        102: "(UTC+06:00) Dhaka",
        115: "(UTC+06:00) Omsk",
        61: "(UTC+06:30) Yangon (Rangoon)",
        22: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
        111: "(UTC+07:00) Barnaul, Gorno-Altaysk",
        64: "(UTC+07:00) Krasnoyarsk",
        46: "(UTC+07:00) Novosibirsk",
        112: "(UTC+07:00) Tomsk",
        45: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
        63: "(UTC+08:00) Irkutsk",
        21: "(UTC+08:00) Kuala Lumpur, Singapore",
        73: "(UTC+08:00) Perth",
        75: "(UTC+08:00) Taipei",
        94: "(UTC+08:00) Ulaanbaatar",
        20: "(UTC+09:00) Osaka, Sapporo, Tokyo",
        72: "(UTC+09:00) Seoul",
        70: "(UTC+09:00) Yakutsk",
        19: "(UTC+09:30) Adelaide",
        44: "(UTC+09:30) Darwin",
        18: "(UTC+10:00) Brisbane",
        76: "(UTC+10:00) Canberra, Melbourne, Sydney",
        43: "(UTC+10:00) Guam, Port Moresby",
        42: "(UTC+10:00) Hobart",
        99: "(UTC+10:00) Magadan",
        68: "(UTC+10:00) Vladivostok",
        107: "(UTC+11:00) Chokurdakh",
        114: "(UTC+11:00) Sakhalin",
        41: "(UTC+11:00) Solomon Is., New Caledonia",
        108: "(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky",
        17: "(UTC+12:00) Auckland, Wellington",
        97: "(UTC+12:00) Coordinated Universal Time+12",
        40: "(UTC+12:00) Fiji",
        92: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old",
        67: "(UTC+13:00) Nuku'alofa",
        16: "(UTC+13:00) Samoa",
    }
    SiteDetailsForm_HubSite = "Hub association";
    SiteDetailsForm_HubSite_InfoText = "By assigning the working space to a hub site, the workspace is linked to the menu of the hub site.";
    SiteDetailsForm_RegisterHubSite = "Hub site registration";
    SiteDetailsForm_RegisterHubSite_InfoText = "Make this site into a hub site to connect related sites and give them a shared experience.";
    SiteDetailsForm_IsHubSite = "Yes";    
    SiteDetailsForm_IsNotHubSite = "No";
    SiteDetailsForm_NextButton = "Next";
    SiteDetailsForm_BackButton = "Back";
    SiteDetailsForm_CreateRoomTitle = "Details of your workspace";
    SiteDetailsForm_ReviewButton = "Review";
    SiteDetailsForm_Members = "Members";
    SiteDetailsForm_Members_InfoText = "Can edit and view content";
    SiteDetailsForm_Visitors = "Visitors";
    SiteDetailsForm_Visitors_InfoText = "Can view content";
    
    SiteDetailsForm_ValidationMessage_PrimaryAndSecondaryOwnerNotUnique = "Owner and substitute can not be the same person";

    SiteSummaryForm_CreateRoomTitle = "Summary of your details";
    SiteSummaryForm_CreateRoomButton = "Create now";

    SiteRequestComplete_PanelTitle = "Already in process";
    SiteRequestComplete_PanelSubtext = "Your room will now be created directly for you and you will receive a message when everything is successfully completed.";
    SiteRequestComplete_CloseButton = "Close";
}

export default (() => {
    let dict = new ProvisioningStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
