
import { IImageProps } from "office-ui-fabric-react/lib/Image";
import { SmartPortal } from "../../SmartPortal";
import { GenericCacheService } from "../cache/GenericCacheService";
import strings from "../controls/GuidedTour/loc/GuidedTourStrings"
import { AppPolicy } from "../models/AppPolicy";
import { UserType } from "../models/SessionInfo";
import { RequestService } from "./RequestService";
import { Role } from "./RoleService";
import { ServiceLoader } from "./ServiceLoader";

export enum GuidedTours {
    Portal,
    PortalPersonal,
    Discover,
    Navigation,
    Directory,
    Admin,
    NavigationPage
}

export interface GuidedTourStep {
    target: string;
    id: string;
    headLine: string;
    content: string;
    illustrationImage?: IImageProps;
    isWide?: boolean;
    appPolicy?: AppPolicy;
    userRole?: Role;
    userType?: UserType;
    additionalFilter?: () => boolean;
    seeMoreLink: string;
}

export interface Tour {
    name: string;
    steps: GuidedTourStep[];
}

export class GuidedTourService {

    static getGuidedTour(tour: GuidedTours): Tour {
        let guidedTour: Tour;

        switch (tour) {
            case GuidedTours.Portal:
                guidedTour = {
                    name: `${GuidedTours[GuidedTours.Portal]}`,
                    steps: [
                        { id: "100", target: ".sp365-LogoContainer", headLine: strings.GuidedTour_Portal_Welcome_Title, content: strings.GuidedTour_Portal_Welcome_Content, seeMoreLink:strings.GuidedTour_Portal_Welcome_MoreLink },
                        { id: "200", appPolicy: AppPolicy.FeatureProfessional, target: ".sp365-CompanyNews .sp365-PanelHeaderText", content: strings.GuidedTour_Portal_CompanyNewsSlider_Content, headLine: strings.GuidedTour_Portal_CompanyNewsSlider_Title, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_CompanyNewsSlider_ImagePath) }, seeMoreLink: strings.GuidedTour_Portal_CompanyNewsSlider_MoreLink },
                        { id: "300", target: ".sp365-SharePointNews .sp365-PanelHeaderText", content: strings.GuidedTour_Portal_CompanyNews_Content, headLine: strings.GuidedTour_Portal_CompanyNews_Title, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_CompanyNews_ImagePath) }, isWide: true, seeMoreLink: strings.GuidedTour_Portal_CompanyNews_MoreLink },
                        { id: "400",  additionalFilter: ()=>{ return !SmartPortal.checkAppPolicy(AppPolicy.FeatureProfessional)}, target: ".sp365-InternetNews .sp365-PanelHeaderText", headLine: strings.GuidedTour_Portal_InternetNews_Title, content: strings.GuidedTour_Portal_InternetNews_Content, seeMoreLink:strings.GuidedTour_Portal_InternetNews_MoreLink },
                        { id: "500", appPolicy: AppPolicy.FeatureProfessional, target: ".sp365-PanelFooterMore .ms-Button", content: strings.GuidedTour_Portal_CompanyNewsSeeMore_Content, headLine: strings.GuidedTour_Portal_CompanyNewsSeeMore_Title, seeMoreLink: strings.GuidedTour_Portal_CompanyNewsSeeMore_MoreLink },
                        { id: "600", appPolicy: AppPolicy.FeatureProfessional, target: ".sp365-SharePointNews .sp365-PanelActions .sp365-PanelActionView", content: strings.GuidedTour_Portal_CompanyNewsFilter_Content, headLine: strings.GuidedTour_Portal_CompanyNewsFilter_Title, seeMoreLink: strings.GuidedTour_Portal_CompanyNewsFilter_MoreLink },
                        { id: "700", appPolicy: AppPolicy.FeatureProfessional, target: ".sp365-OrganizationWorkplaceLinks .sp365-PanelHeaderText", content: strings.GuidedTour_Portal_GlobalLinks_Content, headLine: strings.GuidedTour_Portal_GlobalLinks_Title, seeMoreLink: strings.GuidedTour_Portal_GlobalLinks_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_GlobalLinks_ImagePath) }, isWide: true },
                        { id: "800", target: ".sp365-PersonalWorkplaceLinks .sp365-PanelHeaderText", content: strings.GuidedTour_Portal_PersonalLinks_Content, headLine: strings.GuidedTour_Portal_PersonalLinks_Title, seeMoreLink: strings.GuidedTour_Portal_PersonalLinks_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_PersonalLinks_ImagePath) }, isWide: true },
                        { id: "900", target: ".sp365-PersonalWorkplaceLinks .sp365-PanelActions .sp365-PanelActionEdit", content: strings.GuidedTour_Portal_PersonalLinksEdit_Content, headLine: strings.GuidedTour_Portal_PersonalLinksEdit_Title, seeMoreLink: strings.GuidedTour_Portal_PersonalLinksEdit_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_PersonalLinksEdit_ImagePath) }},
                        { id: "1000",  appPolicy: AppPolicy.FeatureMyWorkplace, target: "div.sp365-PortalNavItem[data-sp365navkey='MyWorkplace']", content: strings.GuidedTour_Portal_QuickBar_MyWorkplace_Content, headLine: strings.GuidedTour_Portal_QuickBar_MyWorkplace_Title, seeMoreLink: strings.GuidedTour_Portal_QuickBar_MyWorkplace_MoreLink },
                        { id: "1100", appPolicy: AppPolicy.FeatureDiscovery, target: "div.sp365-PortalNavItem[data-sp365navkey='Discover']", content: strings.GuidedTour_Portal_QuickBar_Discover_Content, headLine: strings.GuidedTour_Portal_QuickBar_Discover_Title, seeMoreLink: strings.GuidedTour_Portal_QuickBar_Discover_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_QuickBar_Discover_ImagePath) } },
                        { id: "1200", appPolicy: AppPolicy.FeatureNavigation, userType: UserType.WorkAccount, target: "div.sp365-PortalNavItem[data-sp365navkey='Navigation']", content: strings.GuidedTour_Portal_QuickBar_Navigate_Content, headLine: strings.GuidedTour_Portal_QuickBar_Navigate_Title, seeMoreLink: strings.GuidedTour_Portal_QuickBar_Navigate_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_QuickBar_Navigate_ImagePath) } },
                        { id: "1300", appPolicy: AppPolicy.FeatureProvisioning, target: "div.sp365-PortalNavItem[data-sp365navkey='Directory']", content: strings.GuidedTour_Portal_QuickBar_Directory_Content, headLine: strings.GuidedTour_Portal_QuickBar_Directory_Title, seeMoreLink: strings.GuidedTour_Portal_QuickBar_Directory_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_QuickBar_Directory_ImagePath) } },
                        { id: "1400", appPolicy: AppPolicy.FeatureAdministration, userRole: Role.Admin, target: "div.sp365-PortalNavItem[data-sp365navkey='Admin']", content: strings.GuidedTour_Portal_QuickBar_Admin_Content, headLine: strings.GuidedTour_Portal_QuickBar_Admin_Title, seeMoreLink: strings.GuidedTour_Portal_QuickBar_Admin_MoreLink },
                        { id: "1500", target: ".sp365-PortalNavRight .sp365-PortalNavQuickActions", content: strings.GuidedTour_Portal_QuickActions_Content, headLine: strings.GuidedTour_Portal_QuickActions_Title, seeMoreLink: strings.GuidedTour_Portal_QuickActions_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_QuickActions_ImagePath) }, isWide: true },
                        { id: "1600", target: ".sp365-PortalNavRight .sp365-PortalNavUser", content: strings.GuidedTour_Portal_UserProfile_Content, headLine: strings.GuidedTour_Portal_UserProfile_Title, seeMoreLink: strings.GuidedTour_Portal_UserProfile_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_UserProfile_ImagePath) }, isWide: true },
                        { id: "1700", additionalFilter: ()=>{ return !SmartPortal.checkAppPolicy(AppPolicy.FeatureProfessional)}, target: ".sp365-WorkplacePremiumAd.sp365-WorkplacePanel", content: strings.GuidedTour_Portal_PremiumAd_Content, headLine: strings.GuidedTour_Portal_PremiumAd_Title, seeMoreLink: strings.GuidedTour_Portal_PremiumAd_MoreLink }
                    ]
                };
                break;
            case GuidedTours.Discover:
                guidedTour = {
                    name: `${GuidedTours[GuidedTours.Discover]}`,
                    steps: [
                        { id: "100", target: ".sp365-Panel.sp365-DiscoverTab .sp365-PanelHeaderText", headLine: strings.GuidedTour_Discover_Title, content: strings.GuidedTour_Discover_Content, seeMoreLink: strings.GuidedTour_Discover_MoreLink},
                        { id: "200", target: ".sp365-Panel.sp365-DiscoverTab .sp365-PanelActions", headLine: strings.GuidedTour_Discover_Filter_Title, content: strings.GuidedTour_Discover_Filter_Content, seeMoreLink: strings.GuidedTour_Discover_Filter_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Discover_Filter_ImagePath)}}
                    ]
                };
                break;
            case GuidedTours.Navigation:
                guidedTour = {
                    name: `${GuidedTours[GuidedTours.Navigation]}`,
                    steps: [
                        { id: "100", target: ".sp365-Navigation .sp365-PanelHeaderText", headLine: strings.GuidedTour_Navigation_Title, content: strings.GuidedTour_Navigation_Content, seeMoreLink: strings.GuidedTour_Navigation_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Navigation_ImagePath)}, isWide: true},
                        { id: "200", target: ".sp365-Navigation .sp365-PanelActionSearch", headLine: strings.GuidedTour_Navigation_Search_Title, content: strings.GuidedTour_Navigation_Search_Content, seeMoreLink:strings.GuidedTour_Navigation_Search_MoreLink},
                        { id: "300", target: ".sp365-Navigation .sp365-PanelActionView", headLine: strings.GuidedTour_Navigation_Filter_Owner_Title, content: strings.GuidedTour_Navigation_Filter_Owner_Content, seeMoreLink:strings.GuidedTour_Navigation_Filter_Owner_MoreLink},
                        { id: "400", target: ".sp365-Navigation .sp365-PanelActionType", headLine: strings.GuidedTour_Navigation_Filter_Type_Title, content: strings.GuidedTour_Navigation_Filter_Type_Content, seeMoreLink:strings.GuidedTour_Navigation_Filter_Type_MoreLink},
                        { id: "500", appPolicy: AppPolicy.FeatureProvisioning, target: ".sp365-Navigation .sp365-PanelActionCustomTemplate", headLine: strings.GuidedTour_Navigation_Filter_Template_Title, content: strings.GuidedTour_Navigation_Filter_Template_Content, seeMoreLink:strings.GuidedTour_Navigation_Filter_Template_MoreLink},
                        { id: "600", appPolicy: AppPolicy.FeatureProvisioning, target: ".sp365-Navigation .sp365-PanelActionExpand", headLine: strings.GuidedTour_Navigation_TileView_Title, content: strings.GuidedTour_Navigation_TileView_Content, seeMoreLink:strings.GuidedTour_Navigation_TileView_MoreLink}
                    ]
                };
                break;
                case GuidedTours.Directory:
                    guidedTour = {
                        name: `${GuidedTours[GuidedTours.Directory]}`,
                        steps: [
                            { id: "100", target: ".sp365-DirectoryPanel .sp365-PanelHeaderText", headLine: strings.GuidedTour_Directory_Title, content: strings.GuidedTour_Directory_Content, seeMoreLink: strings.GuidedTour_Directory_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Directory_ImagePath)}},
                            { id: "200", target: ".sp365-DirectoryPanel .sp365-PanelActions", headLine: strings.GuidedTour_Directory_Filter_Title, content: strings.GuidedTour_Directory_Filter_Content, seeMoreLink:strings.GuidedTour_Directory_Filter_MoreLink},
                            { id: "300", target: ".sp365-DirectoryPanel .sp365-Directory .sp365-DirectoryRow .ms-DetailsRow .is-row-header", headLine: strings.GuidedTour_Directory_Detail_Title, content: strings.GuidedTour_Directory_Detail_Content, seeMoreLink: strings.GuidedTour_Directory_Detail_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Directory_Detail_ImagePath)}, isWide:true}

                        ]
                    };
                    break;
                case GuidedTours.NavigationPage:
                    guidedTour = {
                        name: `${GuidedTours[GuidedTours.NavigationPage]}`,
                        steps: [
                            { id: "100", target: ".sp365-NavigationPage .sp365-NavigationPanel .sp365-PanelHeaderText", headLine: strings.GuidedTour_TileNavigation_Title, content: strings.GuidedTour_TileNavigation_Content, seeMoreLink: strings.GuidedTour_TileNavigation_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_TileNavigation_ImagePath)}, isWide: true},
                            { id: "200", target: ".sp365-NavigationPage .sp365-NavigationPanel .sp365-PanelActions", headLine: strings.GuidedTour_TileNavigation_Filter_Title, content: strings.GuidedTour_TileNavigation_Filter_Content, seeMoreLink:strings.GuidedTour_TileNavigation_Filter_MoreLink}
                        ]
                    };
                    break;
                case GuidedTours.Admin:
                    guidedTour = {
                        name: `${GuidedTours[GuidedTours.Admin]}`,
                        steps: [
                            { id: "100", appPolicy: AppPolicy.FeatureAdministration, target: ".sp365-AdminPage .sp365-AdminConsentPanel .sp365-PanelHeaderText", headLine: strings.GuidedTour_Admin_Consent_Title, content: strings.GuidedTour_Admin_Consent_Content, seeMoreLink:strings.GuidedTour_Admin_Consent_MoreLink},
                            { id: "200", appPolicy: AppPolicy.FeatureAdministration, target: ".sp365-AdminPage .sp365-AdminPermissionPanel .sp365-PanelHeaderText", headLine: strings.GuidedTour_Admin_Permissions_Title, content: strings.GuidedTour_Admin_Permissions_Content, seeMoreLink:strings.GuidedTour_Admin_Permissions_MoreLink},
                            { id: "300", appPolicy: AppPolicy.FeatureAdministration, target: ".sp365-AdminPage .sp365-AdminColorPanel .sp365-PanelHeaderText", headLine: strings.GuidedTour_Admin_Colors_Title, content: strings.GuidedTour_Admin_Colors_Content, seeMoreLink:strings.GuidedTour_Admin_Colors_MoreLink},
                            { id: "400", appPolicy: AppPolicy.FeatureAdministration, target: ".sp365-AdminPage .sp365-AdminLogoPanel .sp365-PanelHeaderText", headLine: strings.GuidedTour_Admin_Logo_Title, content: strings.GuidedTour_Admin_Logo_Content, seeMoreLink:strings.GuidedTour_Admin_Logo_MoreLink},
                            { id: "500", appPolicy: AppPolicy.FeatureMyWorkplace, target: ".sp365-AdminPage .sp365-WorkplaceLinks .sp365-PanelHeaderText", headLine: strings.GuidedTour_Admin_GlobalLinks_Title, content: strings.GuidedTour_Admin_GlobalLinks_Content, seeMoreLink: strings.GuidedTour_Admin_GlobalLinks_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Admin_GlobalLinks_ImagePath)}, isWide: true},
                            { id: "600", appPolicy: AppPolicy.FeatureMyWorkplace, target: ".sp365-AdminPage .sp365-AdminPersonalQuickLinkSettingsPanel .sp365-PanelHeaderText", headLine: strings.GuidedTour_Admin_PersonalLinks_Title, content: strings.GuidedTour_Admin_PersonalLinks_Content, seeMoreLink:strings.GuidedTour_Admin_PersonalLinks_MoreLink},
                            { id: "700", appPolicy: AppPolicy.FeatureTemplates, target: ".sp365-AdminPage .sp365-AdminProvisionTemplatesPanel .sp365-PanelHeaderText", headLine: strings.GuidedTour_Admin_Templates_Title, content: strings.GuidedTour_Admin_Templates_Content, seeMoreLink: strings.GuidedTour_Admin_Templates_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Admin_Templates_ImagePath)}, isWide: true},
                            { id: "800", appPolicy: AppPolicy.FeatureProvisioning, target: ".sp365-AdminPage .sp365-AdminClassificationPanel .sp365-PanelHeaderText", headLine: strings.GuidedTour_Admin_Classification_Title, content: strings.GuidedTour_Admin_Classification_Content, seeMoreLink: strings.GuidedTour_Admin_Classification_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Admin_Classification_ImagePath)}},
                            { id: "900", appPolicy: AppPolicy.FeatureWebHook, target: ".sp365-AdminPage .sp365-AdminWebHookPanel .sp365-PanelHeaderText", headLine: strings.GuidedTour_Admin_Webhook_Title, content: strings.GuidedTour_Admin_Webhook_Content, seeMoreLink:strings.GuidedTour_Admin_Webhook_MoreLink},
                        ]
                    };
                    break;
                    case GuidedTours.PortalPersonal:
                        guidedTour = {
                            name: `${GuidedTours[GuidedTours.PortalPersonal]}`,
                            steps: [
                                { id: "100", target: ".sp365-LogoContainer", headLine: strings.GuidedTour_Portal_Welcome_Title, content: strings.GuidedTour_Portal_Welcome_Content, seeMoreLink:strings.GuidedTour_Portal_Welcome_MoreLink },
                                { id: "200", target: ".sp365-InternetNews .sp365-PanelHeaderText", headLine: strings.GuidedTour_Portal_InternetNews_Title, content: strings.GuidedTour_Portal_InternetNews_Content, seeMoreLink:strings.GuidedTour_Portal_InternetNews_MoreLink },
                                { id: "300", target: ".sp365-PersonalWorkplaceLinks .sp365-PanelHeaderText", content: strings.GuidedTour_Portal_PersonalLinks_Content, headLine: strings.GuidedTour_Portal_PersonalLinks_Title, seeMoreLink: strings.GuidedTour_Portal_PersonalLinks_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_PersonalLinks_ImagePath) }, isWide: true },
                                { id: "400", target: ".sp365-PersonalWorkplaceLinks .sp365-PanelActions .sp365-PanelActionEdit", content: strings.GuidedTour_Portal_PersonalLinksEdit_Content, headLine: strings.GuidedTour_Portal_PersonalLinksEdit_Title, seeMoreLink: strings.GuidedTour_Portal_PersonalLinksEdit_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_PersonalLinksEdit_ImagePath) }},
                                { id: "500", target: "div.sp365-PortalNavItem[data-sp365navkey='MyWorkplace']", content: strings.GuidedTour_Portal_QuickBar_MyWorkplace_Content, headLine: strings.GuidedTour_Portal_QuickBar_MyWorkplace_Title, seeMoreLink: strings.GuidedTour_Portal_QuickBar_MyWorkplace_MoreLink },
                                { id: "600", target: "div.sp365-PortalNavItem[data-sp365navkey='Discover']", content: strings.GuidedTour_Portal_QuickBar_Discover_Content, headLine: strings.GuidedTour_Portal_QuickBar_Discover_Title, seeMoreLink: strings.GuidedTour_Portal_QuickBar_Discover_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_QuickBar_Discover_ImagePath) } },
                                { id: "700", target: ".sp365-PortalNavRight .sp365-PortalNavQuickActions", content: strings.GuidedTour_Portal_QuickActions_Content, headLine: strings.GuidedTour_Portal_QuickActions_Title, seeMoreLink: strings.GuidedTour_Portal_QuickActions_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_QuickActions_ImagePath) }, isWide: true },
                                { id: "800", target: ".sp365-PortalNavRight .sp365-PortalNavUser", content: strings.GuidedTour_Portal_UserProfile_Content, headLine: strings.GuidedTour_Portal_UserProfile_Title, seeMoreLink: strings.GuidedTour_Portal_UserProfile_MoreLink, illustrationImage: { src: SmartPortal.buildPortalUrl(strings.GuidedTour_Portal_UserProfile_ImagePath) }, isWide: true },
                                { id: "900", additionalFilter: ()=>{ return !SmartPortal.checkAppPolicy(AppPolicy.FeatureProfessional)}, target: ".sp365-WorkplacePremiumAd.sp365-WorkplacePanel", content: strings.GuidedTour_Portal_PremiumAd_Content, headLine: strings.GuidedTour_Portal_PremiumAd_Title, seeMoreLink: strings.GuidedTour_Portal_PremiumAd_MoreLink }
                            ]
                        };
                        break;
            default:
                break;
        }

        guidedTour.steps = guidedTour.steps.filter(item=>{

            var filter = true;

            if(item.appPolicy)
                filter &&= SmartPortal.checkAppPolicy(item.appPolicy);

            if (item.userRole)
                filter &&= SmartPortal.isUserInRole(item.userRole);

            if(item.userType)
                filter &&= SmartPortal.currentSession.userType === item.userType;

            if(item.additionalFilter)
                filter &&= item.additionalFilter();

            return filter;
        });

        return guidedTour;
    }

    static async resetGuidedTours(){

        const reqSvc = ServiceLoader.GetService(RequestService);
        const data = new Map<string,any>();

        for(let tourName in GuidedTours){
            if(!isNaN(tourName as any)){
                console.log(GuidedTours[tourName]);
                data[`GuidedTour_${GuidedTours[tourName]}`] = {complete:false, completedSteps: []};
            }
        }

        await reqSvc.requestAadService({
          method: "Post",
          url: "/api/UserData/SetProfileFlags",
          header: new Map<string, string>([
            ["Content-Type", "application/json"]
          ]),

          data: JSON.stringify({
           flags: data
          })
        }).then(reqSvc.parseApiResponse).then( async(response) => {
           const cacheService = ServiceLoader.GetService(GenericCacheService).getPrefixedCache(`GuidedTour`);
           for(let tourName in GuidedTours){
              await cacheService.delCacheEntry(`tourComplete_${tourName}_` + SmartPortal.currentUserId);
           }
            location.reload();
        });
    }
}