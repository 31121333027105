import { ServiceLoader } from './ServiceLoader';
import { GraphClientService } from './GraphClientService';
import { Client as MSGraphClient, LargeFileUploadTask, GraphError, LargeFileUploadSession } from "@microsoft/microsoft-graph-client/lib/src/browser";
import { IUploadFile, UploadFileStates, UploadFileErrorTypes } from "../models/UploadFile";
import { GraphBatchService } from './GraphBatchService';

export type OnFileUploadProgress = (file: IUploadFile, progress: number) => void;
export type OnFileUploadError = (file: IUploadFile, error: Error) => void;
export type OnFileUploadComplete = (file: IUploadFile) => void;
export type OnUploadComplete = () => void;
export type FileUploadErrorType = "Conflict" | "Other" | "None";
export type ConflictBehavior = "fail" | "replace" | "rename";

type FileUploadSession = {
    uploadTask: LargeFileUploadTask;
    file: IUploadFile;
}

type FileUploadTarget = {
    graphDriveId?: string;
    graphItemId?: string;
    graphSiteId?: string;
}

export class GraphFileUploadService {
    private onFileUploadProgressCallback: OnFileUploadProgress;
    private onFileUploadCompleteCallback: OnFileUploadComplete;
    private onFileUploadErrorCallback: OnFileUploadError;
    private onUploadComplete: OnUploadComplete;
    private filesToUpload: IUploadFile[];
    private maxConcurrentUploads = 3;
    private graphService: GraphBatchService;
    private graphClient: MSGraphClient;

    private uploadTarget: FileUploadTarget;

    private activeUploadSessions: FileUploadSession[] = [];
    private currentUploadCount = 0;

    public constructor(onFileUploadProgressCallback: OnFileUploadProgress, onFileUploadCompleteCallback: OnFileUploadComplete, onFileUploadErrorCallback: OnFileUploadError, onUploadComplete: OnUploadComplete) {
        this.onFileUploadCompleteCallback = onFileUploadCompleteCallback;
        this.onFileUploadErrorCallback = onFileUploadErrorCallback;
        this.onFileUploadProgressCallback = onFileUploadProgressCallback;
        this.filesToUpload = [];
        this.onUploadComplete = onUploadComplete;

        this.graphService = ServiceLoader.GetService(GraphBatchService);
        this.graphClient = ServiceLoader.GetService(GraphClientService).getClient();
    }

    public addFiles(files: IUploadFile[]) {
        this.filesToUpload = [...this.filesToUpload, ...files];
    }

    public addFile(file: IUploadFile) {
        this.filesToUpload = [...this.filesToUpload, file];
    }

    public removeFile(file: IUploadFile) {
        this.filesToUpload = this.filesToUpload.filter(e => e.id !== file.id);
    }

    public resumeFile(file: IUploadFile, conflictBehavior: ConflictBehavior = "fail") {
        this.uploadFile(file, conflictBehavior);
        this.updateFileProgress();
    }

    public updateUploadProgress(file: IUploadFile): IUploadFile {
        const index = this.activeUploadSessions.findIndex(e => e.file.id === file.id);
        if (index !== -1) {
            const uploadTask = this.activeUploadSessions[index].uploadTask;
            const uploadFile = this.activeUploadSessions[index].file;
            if (uploadFile.state !== UploadFileStates.Error) {
                uploadFile.progress = uploadTask.getNextRange().minValue / uploadFile.file.size;
            }

            this.activeUploadSessions[index].file = uploadFile;

            return uploadFile;
        }
        return undefined;
    }

    public startUpload(uploadTarget: FileUploadTarget) {
        this.uploadTarget = uploadTarget;
        this.processFiles();
        this.updateFileProgress();
    }

    private async getDriveUrl(driveId: string): Promise<string> {
        const site = await ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            version: "v1.0",
            path: "drives/" + driveId + "?$select=webUrl"
        });

        return site.webUrl;
    }

    private async updateFileProgress(retryCounter = 0) {

        this.activeUploadSessions.forEach((uploadSession, index, array) => {
            if (uploadSession.file.state === UploadFileStates.Uploading) {
                const oldProgress = uploadSession.file.progress;
                array[index].file = this.updateUploadProgress(uploadSession.file);

                if (this.onFileUploadProgressCallback && oldProgress !== array[index].file.progress)
                    this.onFileUploadProgressCallback(array[index].file, array[index].file.progress);
            }
        });

        if (this.activeUploadSessions.filter(e => e.file.state == UploadFileStates.Uploading).length !== 0
            || this.filesToUpload.length !== 0
            || retryCounter < 60) {
            window.setTimeout(() => this.updateFileProgress(++retryCounter), 1000);
        }
    }

    private async processFiles() {

        const freeSlots = this.maxConcurrentUploads - this.currentUploadCount;

        for (let index = 0; index < freeSlots; index++) {
            const nextFile = this.filesToUpload.shift(); // Get the first item of the array

            if (nextFile) {
                this.uploadFile(nextFile);
            }
        }
        if (this.filesToUpload.length !== 0) {
            window.setTimeout(() => this.processFiles(), 1000);
        }
    }


    private async uploadFile(file: IUploadFile, conflictBehavior: ConflictBehavior = "fail") {
        try {

            this.currentUploadCount++;
            //Create UploadSession
            const payload = {
                item: {
                    "@microsoft.graph.conflictBehavior": conflictBehavior,
                    name: file.file.name,
                },
            };

            const index = this.activeUploadSessions.findIndex(e => e.file.id === file.id);
            if (index !== -1) {
                const activeSession = this.activeUploadSessions[index];
                activeSession.file.state = UploadFileStates.Uploading;
                activeSession.file.status = "";
                this.activeUploadSessions[index] = activeSession;
                await activeSession.uploadTask.resume();
            } else {
                file.state = UploadFileStates.Uploading;
                file.progress = 0;
                const uploadSession = await this.createFileUploadSession(file.file.name, this.uploadTarget, payload);
                const uploadTask = await new LargeFileUploadTask(this.graphClient, { content: file.file, name: file.file.name, size: file.file.size }, uploadSession);
                this.activeUploadSessions = [...this.activeUploadSessions, { file: file, uploadTask: uploadTask }];
                await uploadTask.upload();
            }

            if (this.onFileUploadCompleteCallback) {
                this.onFileUploadCompleteCallback({ ...file, state: UploadFileStates.Uploaded, progress: 1 });
            }
            this.activeUploadSessions = this.activeUploadSessions.filter(e => e.file.id !== file.id);
            this.currentUploadCount--;
        }
        catch (ex) {
            this.currentUploadCount--;
            let { errorType, errorMessage } = this.getErrorType(ex as GraphError);
            errorMessage = errorMessage ?? ex.message;
            const index = this.activeUploadSessions.findIndex(e => e.file.id === file.id);
            const uploadFile = file;
            if (index !== -1) {
                const uploadSession = this.activeUploadSessions[index];
                uploadSession.file.errorType = errorType;
                uploadSession.file.state = UploadFileStates.Error;
                uploadSession.file.status = errorMessage;
                this.activeUploadSessions[index] = uploadSession;
            } else {
                uploadFile.state = UploadFileStates.Error;
                uploadFile.errorType = errorType;
                uploadFile.status = errorMessage;
            }


            if (this.onFileUploadErrorCallback) {
                this.onFileUploadErrorCallback(uploadFile, ex);
            }
        }

        if (this.filesToUpload.length === 0 && this.activeUploadSessions.filter(e => e.file.state === UploadFileStates.Uploading).length === 0 && this.onUploadComplete) {
            this.onUploadComplete();
        }
    }

    private getErrorType(error: GraphError | any): any {
        const message = error.message;
        let errorType: UploadFileErrorTypes;

        //In case GraphBatchService returned error only
        if (error.code) {
            if (error.code === "nameAlreadyExists") {
                errorType = UploadFileErrorTypes.Conflict;
            }

            if (error.code === "activityLimitReached" || error.code ===  "serviceNotAvailable") {
                errorType = UploadFileErrorTypes.BadRequest;
            }


            if (error.code === "accessDenied") {
                errorType = UploadFileErrorTypes.AccessDenied;
            }

            
        }
        //in case GraphBatchService is used and response object is returned
        if (error.status) {
            if (error.status >= 400 && error.status > 500) {
                errorType = UploadFileErrorTypes.BadRequest;
            }

            if (error.status >= 500 && error.status < 600) {
                errorType = UploadFileErrorTypes.ServerError;
            }

            if (error.status === 409) {
                errorType = UploadFileErrorTypes.Conflict;
            }

            if (error.status === 403) {
                errorType = UploadFileErrorTypes.AccessDenied;
            }
        }

        //default graph error response object
        if(error.statusCode) {
            if (error.statusCode >= 400 && error.statusCode > 500) {
                errorType = UploadFileErrorTypes.BadRequest;
            }

            if (error.statusCode >= 500 && error.statusCode < 600) {
                errorType = UploadFileErrorTypes.ServerError;
            }

            if (error.statusCode === 409) {
                errorType = UploadFileErrorTypes.Conflict;
            }
            if (error.statusCode === 403) {
                errorType = UploadFileErrorTypes.AccessDenied;
            }
        }

        return { message: message, errorType: errorType }
    }

    private async createFileUploadSession(fileName: string, uploadTarget: FileUploadTarget, payload: any): Promise<LargeFileUploadSession> {
        let rsp = await this.graphService.addGraphRequest({
            method: "POST",
            version: "v1.0",
            path: this.constructUploadSessionUrl(fileName, uploadTarget),
            data: payload
        });
        let session: LargeFileUploadSession = {
            url: rsp.uploadUrl,
            expiry: new Date(rsp.expirationDateTime)
        }
        return session;
    }

    private constructUploadSessionUrl(fileName: string, uploadTarget: FileUploadTarget): string {
        let sessionUrl: string;
        if (this.uploadTarget.graphSiteId)
            sessionUrl = `/sites/${this.uploadTarget.graphSiteId}`;
        else
            sessionUrl = `/me`;


        if (uploadTarget.graphDriveId)
            sessionUrl += `/drives/${uploadTarget.graphDriveId}`;
        else
            sessionUrl += `/drive`;

        if (uploadTarget.graphItemId && uploadTarget.graphItemId !== uploadTarget.graphDriveId)
            sessionUrl += `/items/${uploadTarget.graphItemId}`;
        else
            sessionUrl += `/root`;

        sessionUrl += `:/${fileName.trim()}:/createUploadSession`;

        return encodeURI(sessionUrl);
    }

    private getInvalidCharsInFileName(fileName: string): string[] {
        const invalidCharsInOneDrive = ["*", ":", "<", ">", "?", "/", "\\", "|"];
        let invalidCharsinFileName: string[] = [];

        invalidCharsInOneDrive.forEach(invalidChar => {
            if (fileName.indexOf(invalidChar) !== -1) {
                invalidCharsinFileName = [...invalidCharsinFileName, invalidChar];
            }
        });

        return invalidCharsinFileName;
    }
}