import { ICacheOpts, CacheEncoding } from '../models/CacheOptions';
import * as MsgPack from "msgpack-lite";
import * as SnappyJS from "snappyjs";
import * as Uint8ArrayToUtf16 from "../../utils/Uint8ArrayToUtf16";

export abstract class BaseCacheDriver {
    protected cacheName: string;
    protected cacheOpts: ICacheOpts;

    public constructor(cacheName: string, cacheOpts: ICacheOpts) {
        this.cacheName = cacheName;
        this.cacheOpts = cacheOpts;
    }

    protected encodeCacheData(input: any, encoding: CacheEncoding, binary: boolean): string {
        let resultData: any;

        try {
            if (!encoding)
                encoding = CacheEncoding.Plain;

            switch (encoding) {
                case CacheEncoding.Plain:
                    if (binary) {
                        resultData = input;
                    }
                    else {
                        resultData = JSON.stringify(input);
                    }
                    break;
                case CacheEncoding.Compress:
                    let packBuf = MsgPack.encode(input);
                    let packZip = SnappyJS.compress(packBuf);
                    if (binary) {
                        resultData = packZip;
                    }
                    else {
                        resultData = Uint8ArrayToUtf16.encode(packZip);
                    }
                    break;
                default:
                    resultData = input;
                    break;
            }
        } catch (ex) {
            resultData = undefined;
        }
        return resultData;
    }

    protected decodeCacheData(input: any, encoding: CacheEncoding, binary: boolean): any {
        let resultData: any;

        try {
            switch (encoding) {
                case CacheEncoding.Plain:
                    if (binary) {
                        resultData = input;
                    }
                    else {
                        resultData = JSON.parse(input);
                    }
                    break;
                case CacheEncoding.Compress:
                    let packZip: Uint8Array;
                    if (binary) {
                        packZip = input;
                    }
                    else {
                        packZip = Uint8ArrayToUtf16.decode(input);
                    }
                    let packBuf = SnappyJS.uncompress(packZip);
                    resultData = MsgPack.decode(packBuf);
                    break;
                default:
                    resultData = input;
                    break;
            }
        } catch(ex) {
            resultData = undefined;
        }
        return resultData;
    }

}
