import * as React from 'react';
import styles from "./PageStyle.scss";
import { SmartPortal } from '../../SmartPortal';

export interface IPortalPanelProps {
    panelName: string;
    className: string;
}

export interface IPortalPanelOptions {
    content: React.ReactNode | React.ReactNode[];
    renderPromise?: Promise<void>;
    permanent?: boolean;
    onDispose?: () => void;
    selectors?: string[];
}

export interface IPortalPanelState {
    active: boolean;
    panelWidth: number;
    panelFixed: boolean;
    content: React.ReactNode | React.ReactNode[];
    renderPromise: Promise<void>;
    onDispose: () => void;
}

export class PortalPanel extends React.Component<IPortalPanelProps, IPortalPanelState> {
    private overlayActive = false;
    private contentElement: HTMLElement;

    constructor(props: IPortalPanelProps, state: IPortalPanelState) {
        super(props);

        this.state = {
            active: false,
            content: null,
            renderPromise: null,
            onDispose: null,
            panelWidth: null,
            panelFixed: false
        };
    }

    public showPanel(panelOptions: IPortalPanelOptions): void {
        if(!this.overlayActive && !panelOptions.permanent) {
            let contentSelector = panelOptions.selectors || [];
            contentSelector.push("." + this.props.className);
            SmartPortal.currentPage.addPageOverlay({
                overlayName: this.props.panelName,
                contentSelector: contentSelector,
                dismissCallback: () => {
                    this.overlayActive = false;
                    this.hidePanel();
                    return true;
                }
            });
            this.overlayActive = true;
        }
        else if(this.overlayActive && panelOptions.permanent) {
            SmartPortal.currentPage.removePageOverlay(this.props.panelName, true);
            this.overlayActive = false;
        }

        if(this.state.onDispose)
            this.state.onDispose();

        this.setState({
            active: true,
            content: panelOptions.content,
            renderPromise: panelOptions.renderPromise,
            onDispose: panelOptions.onDispose
        });
    }

    public hidePanel(): void {
        if(this.overlayActive) {
            SmartPortal.currentPage.removePageOverlay(this.props.panelName, true);
            this.overlayActive = false;
        }

        if(this.state.onDispose)
            this.state.onDispose();

        this.setState({
            active: false,
            onDispose: null,
            panelWidth: null,
            panelFixed: false
        });
    }

    public render(): React.ReactElement<IPortalPanelProps> {
        if(!this.state.panelWidth && this.state.active) {
            let renderPromise = this.state.renderPromise || Promise.resolve();
            renderPromise.then(() => {
                window.setTimeout(() => {
                    if(!this.contentElement || !this.state.active)
                        return;

                    this.setState({
                        panelWidth: window.visualViewport.width < this.contentElement.clientWidth ? window.visualViewport.width : this.contentElement.clientWidth,
                        panelFixed: true
                    });
                }, 50);
            });
            //TO
        }

        let panelClasses = [styles.PortalPanel, this.props.className];
        if(this.state.active)
            panelClasses.push(styles.PortalPanelActive);

        return (
            <div className={panelClasses.join(" ")}>
                <div className={styles.PortalPanelLayer} style={{
                    width: this.state.active ? this.state.panelWidth ? this.state.panelWidth + "px" : "200px" : "0"
                }}>
                    <div className={styles.PortalPanelContent + (this.state.active && this.state.panelFixed ? " " + styles.PortalPanelContentFixed : "")} ref={(el) => {
                        this.contentElement = el;
                    }}>
                        {this.state.content}
                    </div>
                </div>
            </div>
        );
    }

}
