import api from "!../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../node_modules/@teamsupercell/typings-for-css-modules-loader/src/index.js??ref--4-1!../../../../../node_modules/css-loader/dist/cjs.js??ref--4-2!../../../../../node_modules/sass-loader/dist/cjs.js!./PanelStyle.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};