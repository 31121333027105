import * as React from 'react';
import styles from "./SideBarStyle.scss";
import { SmartPortal } from '../../SmartPortal';
import { PortalLeftBarNavDict } from '../basepage/PortalLeftBar';
import SharePointStyle from "../../appstart/spapp/sharepoint/SharePointStyle.scss";
import SmartPortalStyles from "../../styles/SmartPortal.scss";
import SideBarStrings from './loc/SideBarStrings';
import SideBar from './SideBar';

export interface ISideBarButtonProps {
    buttonContainer: Element;
    navBar?: boolean;
    navItems?: PortalLeftBarNavDict;
}

export interface ISideBarButtonState {
    isActive: boolean;
}

export class SideBarButton extends React.Component<ISideBarButtonProps, ISideBarButtonState> {
    private infoPanelKeyCounter: number = 1;

    constructor(props: ISideBarButtonProps, state: ISideBarButtonState) {
        super(props);

        this.state = {
            isActive: false,
        };
    }

    public componentDidMount(): void {

    }

    public render(): React.ReactElement<ISideBarButtonProps> {
        let buttonClassNames = [
            styles.SideBarButton,
            "o365sx-button",
        ];
        if (this.state.isActive)
            buttonClassNames.push("o365sx-activeButton");

        return (
            <button
                className={buttonClassNames.join(" ")}
                id="SmartPortalSiteInfoButton"
                aria-haspopup="true"
                role="button"
                type="button"
                aria-expanded={this.state.isActive ? "true" : "false"}
                aria-label={SideBarStrings.SideBarButton_Title}
                title={SideBarStrings.SideBarButton_Title}
                onClick={(evt) => this.onButtonClick(evt)}
            >
                <span role="presentation">
                    <img src={SmartPortal.buildPortalUrl("images/AppIcon32-white.png")} />
                </span>
            </button>
        );
    }

    private onButtonClick(evt: React.MouseEvent) {
        let setActive = !this.state.isActive;
        this.setState({
            isActive: setActive,
        });

        if (setActive) {
            let activeButtonEl = this.props.buttonContainer.querySelector("button.o365sx-activeButton") as HTMLButtonElement;
            if (activeButtonEl) {
                activeButtonEl.click();
            }
            let panelKey = this.infoPanelKeyCounter++;
            SmartPortal.currentPage.setRightPanel({
                content: <SideBar navBar={this.props.navBar} navItems={this.props.navBar ? this.props.navItems : null} key={panelKey} />,
                onDispose: () => {
                    this.setState({
                        isActive: false
                    });
                },
                selectors: [
                    "." + styles.SideBarPanel,
                    "." + styles.SideBarButton,
                    "." + SharePointStyle.DynContentContainer + "." + SmartPortalStyles.SmartPortal,
                    ".ms-Callout-container"]
            });
        }
        else {
            SmartPortal.currentPage.setRightPanel(null);
        }
    }

}
