import { BaseCacheDriver } from './BaseCacheDriver';
import { ICacheDriver } from '../models/CacheDriver';
import { BaseCacheService } from '../BaseCacheService';
import { ICacheMetadata } from '../models/CacheMetadata';
import { ICacheSetOpts, ICacheOpts, CacheEncoding } from '../models/CacheOptions';
import { MapBuilder } from '../../utils/MapBuilder';

export class IndexedDBCacheDriver extends BaseCacheDriver implements ICacheDriver {
    private static DatabaseSchemaVersion = 2;
    private idbOpenPromise: Promise<void>;
    private idbDatabase: IDBDatabase;

    public constructor(cacheName: string, cacheOpts: ICacheOpts) {
        super(cacheName, cacheOpts);

        if (!window.indexedDB)
            throw "IndexedDB not supported";

        this.openDatabase();
    }

    public dispose(): void {
        if (this.idbDatabase) {
            this.idbDatabase.close();
            this.idbDatabase = null;
            this.idbOpenPromise = null;
        }
        else if(this.idbOpenPromise) {
            this.idbOpenPromise.then(() => {
                this.dispose();
            });
            this.idbOpenPromise = null;
        }
    }

    public getReadyPromise(): Promise<void> {
        return this.idbOpenPromise;
    }

    private getIdbName(): string {
        return BaseCacheService.CacheKeyPrefix + this.cacheName;
    }

    private openDatabase(): Promise<void> {
        if (this.idbOpenPromise)
            return this.idbOpenPromise;
        
        return this.idbOpenPromise = new Promise((resolve, reject) => {
            let req = indexedDB.open(this.getIdbName(), IndexedDBCacheDriver.DatabaseSchemaVersion);

            req.addEventListener("success", (evt) => {
                this.idbDatabase = req.result;
                resolve();
            });
            req.addEventListener("error", (evt) => {
                reject(req.error);
            });
            req.addEventListener("upgradeneeded", (evt) => {
                let idbDatabase = req.result;

                idbDatabase.createObjectStore("DataStore", {});
                idbDatabase.createObjectStore("MetaStore", {
                    keyPath: "key"
                });
            });
            req.addEventListener("versionchange", (evt) => {
                this.dispose();
            });
        });
    }

    private getDatabaseEntry(key: string): Promise<any> {
        return this.openDatabase().then(() => {
            return new Promise((resolve, reject) => {
                let idbTransaction = this.idbDatabase.transaction(["DataStore"]);
                let idbObjectStore = idbTransaction.objectStore("DataStore");
                let req = idbObjectStore.get(key);

                req.addEventListener("success", (evt) => {
                    resolve(req.result);
                });
                req.addEventListener("error", (evt) => {
                    reject(req.error);
                });
            });
        });
    }

    private putDatabaseEntry(key: string, entry: any): Promise<void> {
        return this.openDatabase().then(() => {
            return new Promise((resolve, reject) => {
                let idbTransaction = this.idbDatabase.transaction(["DataStore"], "readwrite");
                let idbObjectStore = idbTransaction.objectStore("DataStore");
                let req = idbObjectStore.put(entry, key);

                req.addEventListener("success", (evt) => {
                    resolve();
                });
                req.addEventListener("error", (evt) => {
                    reject(req.error);
                });
            });
        });
    }

    private getMetaEntry(key: string): Promise<ICacheMetadata> {
        return this.openDatabase().then(() => {
            return new Promise((resolve, reject) => {
                let idbTransaction = this.idbDatabase.transaction(["MetaStore"]);
                let idbObjectStore = idbTransaction.objectStore("MetaStore");
                let req = idbObjectStore.get(key);

                req.addEventListener("success", (evt) => {
                    resolve(req.result);
                });
                req.addEventListener("error", (evt) => {
                    reject(req.error);
                });
            });
        });
    }

    private putMetaEntry(entry: ICacheMetadata): Promise<void> {
        return this.openDatabase().then(() => {
            return new Promise((resolve, reject) => {
                let idbTransaction = this.idbDatabase.transaction(["MetaStore"], "readwrite");
                let idbObjectStore = idbTransaction.objectStore("MetaStore");
                let req = idbObjectStore.put(entry);

                req.addEventListener("success", (evt) => {
                    resolve();
                });
                req.addEventListener("error", (evt) => {
                    reject(req.error);
                });
            });
        });
    }

    private updateMetaEntry(key: string, patch: Map<keyof ICacheMetadata, any>): Promise<ICacheMetadata> {
        return this.openDatabase().then(() => {
            return new Promise((resolve, reject) => {
                let idbTransaction = this.idbDatabase.transaction(["MetaStore"], "readwrite");
                let idbObjectStore = idbTransaction.objectStore("MetaStore");
                let req = idbObjectStore.get(key);
                let metaObj: ICacheMetadata;

                req.addEventListener("success", (evt) => {
                    metaObj = req.result;
                    if (!metaObj)
                        return;

                    patch.forEach((value, prop) => {
                        if(prop === "key")
                            return;
                        if (typeof value === "function")
                            value = value(metaObj);
                        (metaObj[prop] as any) = value;
                    });

                    idbObjectStore.put(metaObj);
                });
                req.addEventListener("error", (evt) => {
                    reject(req.error);
                });
                idbTransaction.addEventListener("complete", (evt) => {
                    resolve(metaObj);
                });
            });
        });
    }

    public delEntry(key: string): Promise<void> {
        return this.openDatabase().then(() => {
            let idbTransaction = this.idbDatabase.transaction(["DataStore", "MetaStore"], "readwrite");
            return Promise.all([
                this.wrapIdbRequest(idbTransaction.objectStore("DataStore").delete(key)),
                this.wrapIdbRequest(idbTransaction.objectStore("MetaStore").delete(key)),
            ]).then();
        });
    }

    private wrapIdbRequest(idbReq: IDBRequest, ignoreError?: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            idbReq.addEventListener("success", (evt) => {
                resolve();
            });
            idbReq.addEventListener("error", (evt) => {
                if(ignoreError)
                    resolve();
                else
                    reject();
            });
        });
    }

    public setEntry(key: string, value: any, opts?: ICacheSetOpts): Promise<void> {
        return this.getMetaEntry(key).then((metaObj) => {
            if(!metaObj) {
                metaObj = {
                    key: key,
                    rev: 1
                };
            }
            else {
                metaObj.rev++;
            }

            metaObj.enc = (opts && "encode" in opts ? opts.encode : this.cacheOpts.defaultEncoding || CacheEncoding.Plain);
            metaObj.out = (opts && typeof opts.timeout === "number" ? opts.timeout : this.cacheOpts.defaultTimeout || 0);
            metaObj.pri = (opts && typeof opts.priority === "number" ? opts.priority : this.cacheOpts.defaultPriority || 100);

            let timeVal = Math.floor((new Date()).getTime() / 1000);
            metaObj.rac = 0;
            metaObj.lut = timeVal;
            if(metaObj.out)
                metaObj.out += timeVal;

            let dataObj = this.encodeCacheData(value, metaObj.enc, true);
            metaObj.vls = dataObj ? dataObj.length : 0;

            return Promise.all([
                this.putDatabaseEntry(metaObj.key, dataObj),
                this.putMetaEntry(metaObj)
            ]).then();
        });
    }

    public getEntry(key: string, ignoreTimedOut?: boolean): Promise<any> {
        let timeVal = Math.floor((new Date()).getTime() / 1000);
        return Promise.all([
            this.getDatabaseEntry(key),
            this.updateMetaEntry(key, MapBuilder.NewMap<keyof ICacheMetadata, any>([
                ["lat", timeVal],
                ["rac", (metaObj) => { return metaObj.rac + 1; }]
            ])),
        ]).then((resObj) => {
            let dataObj = resObj[0];
            let metaObj = resObj[1];
            if (!dataObj || !metaObj)
                return undefined;

            let timeout = (metaObj.out && metaObj.out < timeVal);
            if (timeout && !ignoreTimedOut) {
                return undefined;
            }

            let resData = this.decodeCacheData(dataObj, metaObj.enc, true);
            return ignoreTimedOut ? {
                data: resData,
                timeout: timeout
            } : resData;
        });
    }

    public runCleanup(): Promise<void> {
        return this.openDatabase().then(() => {
            return new Promise((resolve, reject) => {
                let timeVal = (new Date()).getTime();
                let idbTransaction = this.idbDatabase.transaction(["DataStore", "MetaStore"], "readwrite");
                let metaStore = idbTransaction.objectStore("MetaStore");
                let dataStore = idbTransaction.objectStore("DataStore");

                let idbMetaReq = metaStore.getAll();
                idbMetaReq.addEventListener("success", (evt) => {
                    let metaObjs = idbMetaReq.result;
                    let reqPromises: Promise<void>[] = [];

                    let timeVal = Math.floor((new Date()).getTime() / 1000);
                    for(let idx = 0; idx < metaObjs.length; idx++) {
                        let metaObj = metaObjs[idx] as ICacheMetadata;

                        let clearEntry = false;
                        if(metaObj.out && metaObj.out < timeVal)
                            clearEntry = true;
                        else if(this.cacheOpts.accessTimeout && timeVal - metaObj.lat > this.cacheOpts.accessTimeout)
                            clearEntry = true;
                        else if(this.cacheOpts.updateTimeout && timeVal - metaObj.lut > this.cacheOpts.updateTimeout)
                            clearEntry = true;

                        if(clearEntry) {
                            reqPromises.push(this.wrapIdbRequest(metaStore.delete(metaObj.key), true));
                            reqPromises.push(this.wrapIdbRequest(dataStore.delete(metaObj.key), true));
                        }
                    }

                    Promise.all(reqPromises).then(() => resolve(), reject);
                });
                idbTransaction.addEventListener("error", (evt) => {
                    reject(idbTransaction.error);
                });
            });
        });
    }

}
