import * as React from 'react';
import styles from "./PageStyle.scss";
import dialogStyles from "../../common/controls/Dialog/DialogStyle.scss";
import { Icon, Persona, PersonaSize, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { SmartPortal } from '../../SmartPortal';
import { UserActionMenu } from './UserActionMenu';
import { Dialog } from '../../common/controls/Dialog/Dialog';
import { Action } from "../../common/controls/Panel";
import { QuickActions } from './QuickActions';
import PageStrings from './loc/PageStrings';
import { ServiceLoader } from '../../common/business/ServiceLoader';
import { LicensingService } from '../../common/business/LicensingService';
import { AppPolicy } from '../../common/models/AppPolicy';
import { GuidedTourService } from '../../common/business/GuidedTourService';

const ProfileEditForm = React.lazy(() => import(/* webpackChunkName: "session" */ '../session/ProfileEditForm'));

export interface IPortalTopBarProps {
    onNavToggle?(): void;
}

export interface IPortalTopBarState {
    isUserActionVisible: boolean;
}

export class PortalTopBar extends React.Component<IPortalTopBarProps, IPortalTopBarState> {
    private isQuickActionsVisible: boolean;

    constructor(props: IPortalTopBarProps, state: IPortalTopBarState) {
        super(props);

        this.state = {
            isUserActionVisible: false
        };
    }

    public componentDidMount(): void {

    }

    public render(): React.ReactElement<IPortalTopBarProps> {
        let currentUser = SmartPortal.currentUser;
        return (
            <div className={styles.PortalHeader}>
                <div className={styles.PortalHeaderBar}>
                    <div className={styles.PortalNavLeft}>
                        {this.props.onNavToggle ?
                            <div className={styles.PortalNavButton + " " + styles.PortalNavToggle} onClick={(evt) => {
                                this.props.onNavToggle();
                            }}>
                                <div className={styles.PortalNavToggleIcon}>
                                    <Icon iconName="GlobalNavButton" className={styles.NavToggleIcon} />
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className={styles.PortalLogo}>
                        <div className={styles.LogoContainer}>
                            <a href="/"><img src="/Theming/Logo" alt="SmartPortal365" /></a>
                        </div>
                    </div>
                    <div className={styles.PortalNavRight}>
                        <div className={styles.PortalNavButton + " " + styles.PortalNavQuickActions} onClick={(evt) => {
                            if (this.isQuickActionsVisible)
                                return;
                            this.onQuickActionsClick(evt).then(() => {
                                this.isQuickActionsVisible = false;
                            });
                            this.isQuickActionsVisible = true;
                        }}>
                            <div className={styles.PortalNavQuickActionsIcon}>
                                <Icon iconName="CirclePlus" className={styles.QuickActionsIcon} />
                            </div>
                        </div>
                        <div className={styles.PortalNavButton + " " + styles.PortalNavUser} onClick={(evt) => this.onUserActionClick(evt)}>
                            <div className={styles.PortalNavUserPhoto}>
                                <Persona text={currentUser.name} secondaryText={currentUser.mail} imageUrl="/Session/UserPhoto" size={PersonaSize.size32} imageAlt={currentUser.name} hidePersonaDetails />
                            </div>
                        </div>
                        <div className={styles.PortalNavUserMenu + (this.state.isUserActionVisible ? (" " + styles.UserMenuVisible) : "")}>
                            <UserActionMenu showPersona={true} menuItems={[
                                {
                                    text: PageStrings.UserAction_EditProfile,
                                    onClick: (evt) => {
                                        this.onProfileEditClick(evt).then(() => {
                                            this.setState({
                                                isUserActionVisible: false
                                            });
                                        });
                                    }
                                },
                                {
                                    text: PageStrings.UserAction_Reset_Guided_Tour,
                                    onClick: async (evt) => {
                                        try {
                                            var confirmed = await Dialog.showConfirmDialog(PageStrings.UserAction_Reset_Guided_Tour_Confirm_Title, PageStrings.UserAction_Reset_Guided_Tour_Confirm_Content);
                                            if(confirmed === true){
                                                await GuidedTourService.resetGuidedTours();
                                            }
                                        } catch {}
                                        finally {
                                            this.setState({
                                                isUserActionVisible: false
                                            });
                                        }
                                    }
                                },
                                ,
                                {
                                    text: PageStrings.UserAction_Logout,
                                    link: "/Session/Logout"
                                },
                                {
                                    text: PageStrings.format(PageStrings.UserAction_Version, SmartPortal.appBuildInfo.version, ServiceLoader.GetService(LicensingService).checkPolicy(AppPolicy.FeatureProfessional) ? PageStrings.UserAction_ProEdition : ServiceLoader.GetService(LicensingService).checkPolicy(AppPolicy.FeatureProvisioning) ? PageStrings.UserAction_ProvisioningEdition : PageStrings.UserAction_FreeEdition),
                                    className: styles.UserActionVersion
                                }
                            ]} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderLoadingSplash(): React.ReactNode {
        return <Spinner title="Loading SmartPort365" size={SpinnerSize.large} />;
    }

    public onUserActionClick(evt: React.MouseEvent) {
        let setVisible = !this.state.isUserActionVisible;
        if (setVisible) {
            SmartPortal.currentPage.addPageOverlay({
                overlayName: "UserActionMenu",
                contentSelector: [
                    "." + styles.PortalNavUserMenu,
                    "." + styles.PortalNavButton + "." + styles.PortalNavUser,
                    "." + dialogStyles.Dialog
                ],
                dismissCallback: () => {
                    this.setState({
                        isUserActionVisible: false
                    });
                    return true;
                }
            });
        }

        this.setState({
            isUserActionVisible: setVisible
        });
    }

    public onProfileEditClick(evt: React.MouseEvent): Promise<void> {
        return new Promise((resolve) => {
            const contentKey = "profileEditDialog";

            SmartPortal.currentPage.setDynamicContent(contentKey, (
                <Dialog key={contentKey} showLogo={true} actions={[Action.Close]} onAction={(action) => {
                    if (action === Action.Close) {
                        SmartPortal.currentPage.setDynamicContent(contentKey, null);
                    }
                    return Promise.resolve();
                }}>
                    <React.Suspense fallback={this.renderLoadingSplash()}>
                        <ProfileEditForm onReturn={() => {
                            SmartPortal.currentPage.setDynamicContent(contentKey, null);
                            resolve();
                        }} />
                    </React.Suspense>
                </Dialog>
            ));
        });
    }

    public onQuickActionsClick(evt: React.MouseEvent): Promise<void> {
        return new Promise((resolve) => {
            SmartPortal.currentPage.setRightPanel({
                content: <QuickActions />,
                onDispose: () => {
                    resolve();
                },
                selectors: ["." + styles.PortalNavQuickActions]
            });
        });
    }
}
