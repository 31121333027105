import { LocaleService, BaseStrings, Locale } from "../../../business/LocaleService";

export class FileUploadStrings extends BaseStrings {
    readonly _ModuleName = "FileUploadStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new FileUploadStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./FileUploadStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    FileUpload_DragDropText = "Add documents via drag and drop or open the explorer";
    FileUpload_OpenExplorer = "Open explorer";
    FileUpload_Replace = "replace";
    FileUpload_Rename = "rename";
    FileUpload_Retry = "retry";
}

export default (() => {
    let dict = new FileUploadStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
