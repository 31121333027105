import * as React from 'react';
import styles from "./PageStyle.scss";
import { SmartPortal } from '../../SmartPortal';

export interface IDynPageContentProps {
}

export interface IDynPageContentState {
    dynPageContent: React.ReactNode | React.ReactNode[];
}

export class DynPageContent extends React.Component<IDynPageContentProps, IDynPageContentState> {
    private dynamicPageContent = new Map<string, React.ReactNode | React.ReactNode[]>();

    constructor(props: IDynPageContentProps, state: IDynPageContentState) {
        super(props);

        SmartPortal.currentDynPageContent = this;

        this.state = {
            dynPageContent: []
        };
    }

    public setContent(dcName: string, dcElement: React.ReactNode | React.ReactNode[] | null): void {
        let hasDynContent = this.dynamicPageContent.has(dcName);
        if (!dcElement && hasDynContent)
            this.dynamicPageContent.delete(dcName);
        else if (hasDynContent && this.dynamicPageContent.get(dcName) === dcElement)
            return;
        else
            this.dynamicPageContent.set(dcName, dcElement);

        let dynElements: React.ReactNode[] = [];
        this.dynamicPageContent.forEach((dynEl) => {
            dynElements.push(dynEl);
        });
        this.setState({
            dynPageContent: dynElements
        });
    }

    public render(): React.ReactElement<IDynPageContentProps> {
        let contentElements: React.ReactNode[] = [];
        if (this.state.dynPageContent) {
            if (Array.isArray(this.state.dynPageContent))
                Array.prototype.push.apply(contentElements, this.state.dynPageContent);
            else
                contentElements.push(this.state.dynPageContent);
        }

        return (
            <div className={styles.DynamicContent}>
                {contentElements}
            </div>
        );
    }

}
