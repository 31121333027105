import { FolderPickerDataSource, FolderPickerItemResult, FolderPickerMoreItemResult, FolderPickerSearchItemResult, getSkipToken } from './FolderPickerDataSource';
import { FolderPickerItem } from "../Models/FolderPickerItem"
import { IBreadcrumbItem } from 'office-ui-fabric-react';
import { ServiceLoader } from '../../../business/ServiceLoader';
import { GraphBatchService } from '../../../business/GraphBatchService';
import { GraphSites, GraphSite, GraphDrives, GraphDrive, GraphDriveItem, GraphDriveChildren } from "../Models/GraphResults"
import { PageContext } from '@microsoft/sp-page-context';

export class SharePointFolderPickerDataSource implements FolderPickerDataSource {
    initialFolder: FolderPickerItem;
    private currentFolder: FolderPickerItem;
    private breadcrumbItems: IBreadcrumbItem[];
    private skipToken: string;
    private currentSiteId: string;
    private currentSearch: string;
    initialBreadcrumbItems: IBreadcrumbItem[] = [{ text: "SharePoint", key: "root", as: "h2", onClick: null }];

    constructor(spPageContext?: PageContext) {
        if (spPageContext) {
            const id = `${(new URL(spPageContext.site.absoluteUrl)).host},${spPageContext.site.id},${spPageContext.web.id}`;
            this.initialFolder = {
                key: id + "|none|site",
                id: id,
                name: spPageContext.web.title,
                webUrl: spPageContext.web.absoluteUrl,
                isFolder: true,
                driveId: null,
                itemType: "site"
            };
            this.initialBreadcrumbItems.push({ text: spPageContext.web.title, key: this.initialFolder.key, as: "h2", onClick: null });
        }
    }

    reset(): void {
        this.currentFolder = null;
        this.breadcrumbItems = null;
        this.skipToken = null;
        this.currentSiteId = null;
    }

    loadItems(parentItem: FolderPickerItem): Promise<FolderPickerItemResult> {
        this.currentSearch = null;
        const prom = new Promise<FolderPickerItemResult>((resolve, reject) => {
            if (parentItem) {
                switch (parentItem.itemType) {
                    case "site":
                        this.currentSiteId = parentItem.id;
                        this.getDocumentLibraries(parentItem.id, null, this.currentSearch).then((documentLibraries) => {
                            this.skipToken = getSkipToken(documentLibraries);
                            const items = this.mapDriveToFolderPickerItems(documentLibraries.value, this.currentSiteId);
                            this.currentFolder = parentItem;
                            if (this.breadcrumbItems) {
                                this.breadcrumbItems.push({
                                    key: parentItem.id + "|" + parentItem.driveId + "|site",
                                    text: parentItem.name,
                                    as: "h2",
                                    onClick: null
                                });
                            } else {
                                this.breadcrumbItems = [...this.initialBreadcrumbItems];
                            }
                            resolve({
                                items: items,
                                currentFolder: null,
                                breadcrumbItems: this.breadcrumbItems,
                                hasMoreItems: this.skipToken ? true : false,
                                searchable: false
                            });
                        }).catch((error) => {
                            if (console) console.error(error);
                            reject(error);
                        });
                        break;
                    case "documentlibrary":
                        this.getRootFolderChildren(parentItem.driveId, null, this.currentSearch).then((children) => {
                            this.skipToken = getSkipToken(children);
                            const items = this.mapToFolderPickerItems(children.value, this.currentSiteId);
                            this.currentFolder = parentItem;
                            this.breadcrumbItems.push({
                                key: this.currentFolder.key,
                                text: this.currentFolder.name,
                                as: "h2",
                                onClick: null
                            });
                            resolve({
                                items: items,
                                currentFolder: this.currentFolder,
                                breadcrumbItems: this.breadcrumbItems,
                                hasMoreItems: this.skipToken ? true : false,
                                searchable: false
                            });
                        }).catch((error) => {
                            if (console) console.error(error);
                            reject(error);
                        });
                        break;
                    case "folder":
                        this.getFolderChildren(parentItem.id, parentItem.driveId, null, this.currentSearch).then((children) => {
                            this.skipToken = getSkipToken(children);
                            const items = this.mapToFolderPickerItems(children.value, this.currentSiteId);
                            this.currentFolder = parentItem;
                            this.breadcrumbItems.push({
                                key: this.currentFolder.key,
                                text: this.currentFolder.name,
                                as: "h2",
                                onClick: null
                            });
                            resolve({
                                items: items,
                                currentFolder: this.currentFolder,
                                breadcrumbItems: this.breadcrumbItems,
                                hasMoreItems: this.skipToken ? true : false,
                                searchable: false
                            });
                        }).catch((error) => {
                            if (console) console.error(error);
                            reject(error);
                        });
                        break;
                }
            }
            else {
                Promise.all([
                    this.getSites(null, this.currentSearch)
                ]).then((response) => {
                    const sites = response[0];
                    this.skipToken = getSkipToken(sites);
                    const items = this.mapSiteToFolderPickerItems(sites.value);
                    this.currentFolder = null;
                    this.breadcrumbItems = [...this.initialBreadcrumbItems];
                    resolve({
                        items: items,
                        currentFolder: null,
                        breadcrumbItems: this.breadcrumbItems,
                        hasMoreItems: this.skipToken ? true : false,
                        searchable: true
                    });
                }).catch((error) => {
                    if (console) console.error(error);
                    reject(error);
                });
            }
        });

        return prom;
    }

    loadMoreItems(): Promise<FolderPickerMoreItemResult> {
        const prom = new Promise<FolderPickerMoreItemResult>((resolve, reject) => {
            if (!this.skipToken) {
                resolve({
                    moreItems: [],
                    hasMoreItems: false
                });
                return;
            }

            if (this.currentFolder === null) {
                this.getSites(this.skipToken, this.currentSearch).then((moreChildren) => {
                    this.skipToken = getSkipToken(moreChildren);
                    const moreItems = this.mapSiteToFolderPickerItems(moreChildren.value);
                    resolve({
                        moreItems: moreItems,
                        hasMoreItems: this.skipToken ? true : false
                    });
                }).catch((error) => {
                    if (console) console.error(error);
                    reject(error);
                });
            } else {
                switch (this.currentFolder.itemType) {
                    case "site":
                        this.getDocumentLibraries(this.currentFolder.id, this.skipToken, this.currentSearch).then((moreDrives) => {
                            this.skipToken = getSkipToken(moreDrives);
                            const moreItems = this.mapDriveToFolderPickerItems(moreDrives.value, this.currentSiteId);
                            resolve({
                                moreItems: moreItems,
                                hasMoreItems: this.skipToken ? true : false
                            });
                        }).catch((error) => {
                            if (console) console.error(error);
                            reject(error);
                        });
                        break;
                    case "documentlibrary":
                        this.getRootFolderChildren(this.currentFolder.driveId, this.skipToken, this.currentSearch).then((moreChildren) => {
                            this.skipToken = getSkipToken(moreChildren);
                            const moreItems = this.mapToFolderPickerItems(moreChildren.value, this.currentSiteId);
                            resolve({
                                moreItems: moreItems,
                                hasMoreItems: this.skipToken ? true : false
                            });
                        }).catch((error) => {
                            if (console) console.error(error);
                            reject(error);
                        });
                        break;
                    case "folder":
                        this.getFolderChildren(this.currentFolder.id, this.currentFolder.driveId, this.skipToken, this.currentSearch).then((moreChildren) => {
                            this.skipToken = getSkipToken(moreChildren);
                            const moreItems = this.mapToFolderPickerItems(moreChildren.value, this.currentSiteId);
                            resolve({
                                moreItems: moreItems,
                                hasMoreItems: this.skipToken ? true : false
                            });
                        }).catch((error) => {
                            if (console) console.error(error);
                            reject(error);
                        });
                        break;
                }
            }
        });
        return prom;
    }

    searchItems(search: string): Promise<FolderPickerSearchItemResult> {
        this.currentSearch = search;
        const prom = new Promise<FolderPickerSearchItemResult>((resolve, reject) => {
            if (this.currentFolder === null) {
                this.getSites(null, this.currentSearch).then((children) => {
                    this.skipToken = getSkipToken(children);
                    const items = this.mapSiteToFolderPickerItems(children.value);
                    resolve({
                        items: items,
                        hasMoreItems: this.skipToken ? true : false
                    });
                }).catch((error) => {
                    if (console) console.error(error);
                    reject(error);
                });
            } else {
                switch (this.currentFolder.itemType) {
                    case "site":
                        this.getDocumentLibraries(this.currentFolder.id, null, this.currentSearch).then((drives) => {
                            this.skipToken = getSkipToken(drives);
                            const items = this.mapDriveToFolderPickerItems(drives.value, this.currentSiteId);
                            resolve({
                                items: items,
                                hasMoreItems: this.skipToken ? true : false
                            });
                        }).catch((error) => {
                            if (console) console.error(error);
                            reject(error);
                        });
                        break;
                    case "documentlibrary":
                        this.getRootFolderChildren(this.currentFolder.driveId, null, this.currentSearch).then((children) => {
                            this.skipToken = getSkipToken(children);
                            const items = this.mapToFolderPickerItems(children.value, this.currentSiteId);
                            resolve({
                                items: items,
                                hasMoreItems: this.skipToken ? true : false
                            });
                        }).catch((error) => {
                            if (console) console.error(error);
                            reject(error);
                        });
                        break;
                    case "folder":
                        this.getFolderChildren(this.currentFolder.id, this.currentFolder.driveId, null, this.currentSearch).then((children) => {
                            this.skipToken = getSkipToken(children);
                            const items = this.mapToFolderPickerItems(children.value, this.currentSiteId);
                            resolve({
                                items: items,
                                hasMoreItems: this.skipToken ? true : false
                            });
                        }).catch((error) => {
                            if (console) console.error(error);
                            reject(error);
                        });
                        break;
                }
            }
        });
        return prom;
    }

    private mapSiteToFolderPickerItems(items: GraphSite[]): FolderPickerItem[] {
        return items.map(item => this.mapSiteToFolderPickerItem(item));
    }

    private mapSiteToFolderPickerItem(item: GraphSite): FolderPickerItem {
        return {
            key: item.id + "|none|site",
            id: item.id,
            name: item.displayName ? item.displayName : item.name,
            webUrl: item.webUrl,
            isFolder: true,
            driveId: null,
            itemType: "site"
        }
    }

    private mapDriveToFolderPickerItems(items: GraphDrive[], siteId: string): FolderPickerItem[] {
        return items.map(item => this.mapDriveToFolderPickerItem(item, siteId));
    }

    private mapDriveToFolderPickerItem(item: GraphDrive, siteId: string): FolderPickerItem {
        return {
            key: item.id + "|none|documentlibrary",
            id: item.id,
            name: item.name,
            webUrl: item.webUrl,
            isFolder: true,
            driveId: item.id,
            itemType: "documentlibrary",
            siteId: siteId
        }
    }

    private mapToFolderPickerItems(items: GraphDriveItem[], siteId: string): FolderPickerItem[] {
        return items.map(item => this.mapToFolderPickerItem(item, siteId));
    }

    private mapToFolderPickerItem(item: GraphDriveItem, siteId: string): FolderPickerItem {
        return {
            key: item.id + "|" + item.parentReference.driveId + "|folder",
            id: item.id,
            name: item.name,
            webUrl: item.webUrl,
            isFolder: (item.folder ? true : false),
            driveId: item.parentReference.driveId,
            itemType: "folder",
            siteId: siteId
        }
    }

    onBreadcrumbClick(item: IBreadcrumbItem): Promise<FolderPickerItemResult> {
        if (item.key === "root") {
            return this.loadItems(this.initialFolder);
        } else {
            const index = this.breadcrumbItems.findIndex(e => e.key === item.key);
            this.breadcrumbItems = this.breadcrumbItems.slice(0, index);
            const key = item.key.split("|");
            return this.loadItems({
                id: key[0],
                driveId: key[1],
                name: item.text,
                key: item.key,
                webUrl: "",
                isFolder: true,
                itemType: key[2]
            });
        }
    }

    //private async getFolder(id: string, driveId: string): Promise<GraphDriveItem> {
    //    return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
    //        method: "GET",
    //        version: "v1.0",
    //        path: "drives/" + driveId + "/items/" + id + "?$select=id,webUrl,name,folder,file,parentReference"
    //    });
    //}

    private async getFolderChildren(id: string, driveId: string, skipToken: string | null, search?: string): Promise<GraphDriveChildren> {
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            version: "v1.0",
            path: `drives/${driveId}/items/${id}/children?$select=id,webUrl,name,folder,file,parentReference&$filter=folder ne null` + (search ? " and startswith(name,'" + encodeURIComponent(search) + "')" : "") + (skipToken ? "&$skiptoken=" + skipToken : "")
        });
    }

    private getRootFolderChildren(driveId: string, skipToken: string | null, search?: string): Promise<GraphDriveChildren> {
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            version: "v1.0",
            path: `drives/${driveId}/root/children?$select=id,name,webUrl,folder,parentReference&$filter=folder ne null` + (search ? " and startswith(name,'" + encodeURIComponent(search) + "')" : "") + (skipToken ? "&$skiptoken=" + skipToken : "")
        });
    }

    private getDocumentLibraries(siteId: string, skipToken: string | null, search?: string): Promise<GraphDrives> {
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            version: "v1.0",
            path: `sites/${siteId}/drives?$select=id,name,webUrl` + (search ? "&$filter=startswith(name,'" + encodeURIComponent(search) + "')" : "") + (skipToken ? "&$skiptoken=" + skipToken : "")
        });
    }

    private getSites(skipToken: string | null, search?: string): Promise<GraphSites> {
        if (search) {
            if (search.indexOf("*") === -1)
                search += "*";
        } else
            search = "*";

        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            version: "v1.0",
            path: "sites?search=" + encodeURIComponent(search) + "&$top=10&$select=id,webUrl,displayName,name,siteCollection" + (skipToken ? "&$skiptoken=" + skipToken : "")
        });
    }
}