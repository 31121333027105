import * as React from 'react';
import styles from "./PanelStyle.scss";
import { PanelHeader } from "./PanelHeader";
import { Action, ActionExecution } from "./PanelActions";
import { PanelFooter } from "./PanelFooter";
import { IErrorInfo } from "../../models/SessionInfo";
import { IconButton } from 'office-ui-fabric-react';

export interface IPanelProps {
    className?: string;
    children?: React.ReactNodeArray | React.ReactNode;
    headerText?: string;
    header?: React.ReactNodeArray | React.ReactNode;

    infoTooltip?: string;
    initialExecutionState?: ActionExecution;
    actions?: Action[];
    currentActions?: Action[];
    customActions?: React.ReactNodeArray | React.ReactNode;
    actionTexts?: { [TAction in Action]?: string };

    showFooter?: boolean;
    footer?: React.ReactNodeArray | React.ReactNode;
    showMore?: boolean;

    search?: string;
    errors?: IErrorInfo[];

    onAction?: (action: Action, value?: any) => Promise<void | boolean>;
}

export interface IPanelState {
    editing: boolean;
}

export class Panel extends React.Component<IPanelProps, IPanelState> {

    constructor(props: IPanelProps, state: IPanelState) {
        super(props);

        this.state = {
            editing: false
        };
    }

    public render(): React.ReactElement<{}> {
        return (
            <div className={styles.Panel + (this.state.editing ? ` ${styles.EditMode}` : "") + (this.props.className ? ` ${this.props.className}` : "")}>
                <PanelHeader headerText={this.props.headerText}
                    header={this.props.header}
                    infoTooltip={this.props.infoTooltip}
                    currentActions={this.props.currentActions}
                    customActions={this.props.customActions}
                    initialExecutionState={this.props.initialExecutionState}
                    actions={this.props.actions}
                    actionTexts={this.props.actionTexts}
                    onAction={(action, value) => {

                        if (action === Action.Edit) 
                            this.setState({ editing: true });
                        if (action === Action.Save || action === Action.Reset)
                            this.setState({ editing: false });

                        if (this.props.onAction)
                            return this.props.onAction(action, value);
                        return Promise.resolve();
                    }} 
                    search={this.props.search}
                    errors={this.props.errors}
                    />
                <div className={styles.PanelContent}>
                    {this.props.children}
                </div>
                {this.props.showFooter || this.props.showMore ? <PanelFooter showMore={this.props.showMore} onAction={(action) => {
                    if (this.props.onAction)
                        return this.props.onAction(action);
                    return Promise.resolve();
                }}>{this.props.footer}</PanelFooter> : null}
            </div>
        );
    }
}
