import { LocaleService, BaseStrings, Locale } from "../../../business/LocaleService";

export class DialogStrings extends BaseStrings {
    readonly _ModuleName = "DialogStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new DialogStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./DialogStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    Dialog_ConfirmDialog_Confirm = "Confirm";
    Dialog_ConfirmDialog_Cancel = "Cancel";
}

export default (() => {
    let dict = new DialogStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
