import { Client as MSGraphClient, AuthenticationProvider, Middleware, AuthenticationHandler, RetryHandler, RetryHandlerOptions, HTTPMessageHandler } from "@microsoft/microsoft-graph-client/lib/src/browser";

class BackendAuthenticationProvider implements AuthenticationProvider {
    public async getAccessToken(): Promise<string> {
        return null;
    }
}

export class GraphClientService {
    private _graphClient: MSGraphClient;

    constructor() {
        const clientOptions = {
            middleware: this.initMiddleware()
        };
        this._graphClient = MSGraphClient.initWithMiddleware(clientOptions);
    }

    private initMiddleware(): Middleware {
        // create our own middleware chain _without_ the 'Telemetry' middleware
        // Telemetry middleware adds custom header fields which are not supported in cors requests!

        let authenticationProvider = new BackendAuthenticationProvider();
        let authenticationHandler = new AuthenticationHandler(authenticationProvider);
		let retryHandler = new RetryHandler(new RetryHandlerOptions());
		let httpMessageHandler = new HTTPMessageHandler();
    
        authenticationHandler.setNext(retryHandler);
        retryHandler.setNext(httpMessageHandler);

        return authenticationHandler;
    }

    getClient(): MSGraphClient {
        return this._graphClient;
    }
}