import { LocaleService, BaseStrings, Locale } from "../../../common/business/LocaleService";

export class PageStrings extends BaseStrings {
    readonly _ModuleName = "PageStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new PageStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./PageStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    AppTitle = "SmartPortal365";
    AppNavBar_MyWorkplace = "My workplace";
    AppNavBar_Discover = "Discover";
    AppNavBar_GlobalNavigation = "Navigate";
    AppNavBar_Directory = "Directory";
    AppNavBar_Admin = "Administration";
    AppNavBar_Support = "Help & Support";
    AppNavBar_SupportUrl = "/help/en";

    UserAction_EditProfile = "Edit profile";
    UserAction_Logout = "Logout";
    UserAction_Version = "Version {0} | {1}";
    UserAction_ProEdition = "Pro Edition";
    UserAction_ProvisioningEdition = "Provisioning Edition";
    UserAction_FreeEdition = "Free Edition";
    UserAction_Reset_Guided_Tour = "Reset guided tour";
    UserAction_Reset_Guided_Tour_Confirm_Title = "Reset guided tours?";
    UserAction_Reset_Guided_Tour_Confirm_Content = "Do you want to reset all guides tour? All tours are shown again";
    
    ErrorPage_Intro = "Oops, an error has occured.";
    ErrorPage_Advice = "Please try again.";
    ErrorPage_Support = "(If the problem occurs more frequently, our Support will be happy to help you. Please include the following ID in your ticket: {0})";

    AccessDenied_Title = "Access denied";
    AccessDenied_Intro = "Unfortunately, you don't have permission to access the site.";

    Navigation_PromptMessage = "Are you sure that you want to leave the site - data you have entered may not be saved?";
}

export default (() => {
    let dict = new PageStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
