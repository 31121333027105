import * as React from 'react';
import * as Controls from './controls/index';
import { SmartPortal } from '../../SmartPortal';
import DiscoverTabStrings from './loc/DiscoverTabStrings';
import styles from './DiscoverTabStyle.scss';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { BaseCard, DocumentCard, SiteCard, OtherCard, StreamManager, StreamSource, DiscoverTrendingDataSource, DiscoverRecentDataSource, NewsCard } from '../../common/cardstream';
import { ICardFilter, CardFilter } from '../../common/models/CardFilter';
import { Panel, PanelActions, Action } from "../../common/controls/Panel";
import { ICacheService } from '../../common/cache/models/CacheService';
import { GenericCacheService } from '../../common/cache/GenericCacheService';
import { ServiceLoader } from '../../common/business/ServiceLoader';
import { GraphCacheService } from '../../common/cache/GraphCacheService';
import { ConsumerDiscoverRecentDataSource } from '../../common/cardstream/datasources/ConsumerDiscoverDataSource';
import { UserType } from '../../common/models/SessionInfo';
import GuidedTour from '../../common/controls/GuidedTour/GuidedTour';
import { GuidedTours, GuidedTourService } from '../../common/business/GuidedTourService';

export interface IDiscoverPanelProps {
    rows: number;
    expandLink?: string;
    viewMode?: DiscoverViewMode;
}

export interface IDiscoverPanelState {
    loaded: boolean;
    currentFilter: ICardFilter;
    displayedCards: BaseCard[];
    rows: number;
    areMoreAvailable: boolean;
    currentDisplayAction: Action;
}

export enum View{
    Large,
    Small
}

export enum DiscoverViewMode{
    Default,
    SideBar
}

export default class DiscoverPanel extends React.Component<IDiscoverPanelProps, IDiscoverPanelState> {
    private rendered: boolean = false;
    private updatingCards: boolean = false;
    private streamSource: string;
    private streamManager: StreamManager;
    private cacheService: ICacheService;
    private cardCacheService: ICacheService;
    private bodyRef;
    private cardItemMinWidth = (action: Action): number => { return action === Action.DisplayLargeCard ? 250 : 200; };
    private cardItemHeight = (action: Action): number => { 
        if(SmartPortal.currentSession.userType == UserType.PrivateAccount)
            return action === Action.DisplayLargeCard ? 260 : 230; 
        else
            return action === Action.DisplayLargeCard ? 280 : 250; 
    };
    private cardGridMarginLeft = (action: Action): number => { return 20; };

    private defaultStreamSourceName = "trending";

    constructor(props: IDiscoverPanelProps) {
    super(props);

   
        if(SmartPortal.currentSession.userType == UserType.PrivateAccount)
            this.defaultStreamSourceName = "recent";

        this.cacheService = ServiceLoader.GetService(GenericCacheService).getPrefixedCache("discover");
        this.cardCacheService = ServiceLoader.GetService(GraphCacheService);
        this.streamManager = new StreamManager({
            sortField: null,
            preloadLimit: 40,
        });

        this.state = {
            loaded: false,
            currentFilter: new CardFilter(),
            displayedCards: [],
            rows: this.props.rows,
            areMoreAvailable: false,
            currentDisplayAction: this.props.viewMode == DiscoverViewMode.SideBar ? Action.DisplaySmallCard :  Action.DisplayLargeCard
        };

        this.resizeDisplayedCards = this.resizeDisplayedCards.bind(this);
  }
  public componentDidMount(): void {
    if (!this.state.loaded) {
        let currentDisplayAction: Action;
        Promise.all([
            this.cacheService.getCacheEntry("streamSource:" + SmartPortal.currentUserId),
            this.cacheService.getCacheEntry("currentDisplayAction:" + SmartPortal.currentUserId),
        ]).then((res) => {
            currentDisplayAction = res[1] ? res[1] : Action.DisplayLargeCard;
            this.setStreamSource(res[0] ? res[0] : this.defaultStreamSourceName);

            return this.updateDisplayedCards(this.state.rows, currentDisplayAction);
        });
    }
    window.addEventListener("resize", this.resizeDisplayedCards);
}

componentWillUnmount() {
    window.removeEventListener("resize", this.resizeDisplayedCards);
}

private resizeDisplayedCards() {
    this.updateDisplayedCards(this.state.rows, this.state.currentDisplayAction);
}
  public render() {
    this.rendered = true;
    let mainComponents = [] as JSX.Element[];

    if (!this.state.loaded) {
        mainComponents.push(<Spinner size={SpinnerSize.large} />);
    }
    else {
        let dummyItems = 10;
        let displayedCards = this.state.displayedCards.slice();
        if (displayedCards.length === 0) {
            mainComponents.push(
                <div className={styles.NoCards}>
                    <div className={styles.NoCardsPanel}>
                        <div className={styles.NoCardsImage}>
                            <img src={SmartPortal.buildPortalUrl("/images/NoContent.png")} />
                        </div>
                        <div className={styles.NoCardsText}>
                            <h2 dangerouslySetInnerHTML={{ __html: DiscoverTabStrings.DiscoverTab_NoCards }}></h2>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            for (let dummyIdx = 0; dummyIdx < dummyItems; dummyIdx++)
                displayedCards.push(null);

            mainComponents.push(
                <div className={styles.CardsGrid}>
                    {displayedCards.map((card, idx) => {
                        if (card instanceof DocumentCard)
                            return <Controls.DocumentCard key={card.id} card={card} trending={(this.streamSource === "trending")} minWidth={this.cardItemMinWidth(this.state.currentDisplayAction)} height={this.cardItemHeight(this.state.currentDisplayAction)} display={this.state.currentDisplayAction} />;
                        else if (card instanceof SiteCard)
                            return <Controls.SiteCard key={card.id} card={card} minWidth={this.cardItemMinWidth(this.state.currentDisplayAction)} height={this.cardItemHeight(this.state.currentDisplayAction)} display={this.state.currentDisplayAction} />;
                        else if (card instanceof NewsCard)
                            return <Controls.NewsCard key={card.id} card={card} minWidth={this.cardItemMinWidth(this.state.currentDisplayAction)} height={this.cardItemHeight(this.state.currentDisplayAction)} display={this.state.currentDisplayAction} />;
                        else if (card instanceof OtherCard)
                            return <Controls.OtherCard key={card.id} card={card} minWidth={this.cardItemMinWidth(this.state.currentDisplayAction)} height={this.cardItemHeight(this.state.currentDisplayAction)} display={this.state.currentDisplayAction} />;
                        else if (card === null)
                            return <div key={idx} className={[styles.CardItem, (this.state.currentDisplayAction === Action.DisplayLargeCard ? styles.CardItemLarge : styles.CardItemSmall), styles.SpacerItem].join(" ")} style={{ minWidth: this.cardItemMinWidth(this.state.currentDisplayAction) }}></div>;
                    })}
                </div>
            );
        }
    }
    
    return (
        <>
           { this.state.loaded ?  <GuidedTour tour={GuidedTourService.getGuidedTour(GuidedTours.Discover)} /> : null }
        
        <Panel className={styles.DiscoverTab} headerText={DiscoverTabStrings.DiscoverTab_Title} infoTooltip={ this.props.viewMode === DiscoverViewMode.SideBar ? null : DiscoverTabStrings.DiscoverTab_Description}
        showMore={this.state.areMoreAvailable}
        currentActions={[(this.streamSource === "trending" ? Action.ViewTrending : Action.ViewRecent), this.state.currentDisplayAction]}
        actions={[SmartPortal.currentSession.userType != UserType.PrivateAccount ? Action.ViewTrending: Action.None, Action.ViewRecent, Action.DisplayLargeCard, Action.DisplaySmallCard, this.props.viewMode === DiscoverViewMode.SideBar  ? Action.Expand : Action.None, this.props.viewMode === DiscoverViewMode.SideBar  ? Action.Close : Action.None ]}
        onAction={(action) => {
            if (PanelActions.DisplayActions.includes(action)) {
                this.cacheService.setCacheEntry("currentDisplayAction:" + SmartPortal.currentUserId, action);
                return this.updateDisplayedCards(this.state.rows, action);
            }
            if (PanelActions.ViewActions.includes(action)) {
                if (action === Action.ViewTrending) {
                    this.cacheService.setCacheEntry("streamSource:" + SmartPortal.currentUserId, "trending");
                    this.setStreamSource("trending");
                    return Promise.resolve();
                }
                if (action === Action.ViewRecent) {
                    this.cacheService.setCacheEntry("streamSource:" + SmartPortal.currentUserId, "recent");
                    this.setStreamSource("recent");
                    return Promise.resolve();
                }
            }
            if (action === Action.ShowMore) {
                return this.updateDisplayedCards(this.state.rows + 1, this.state.currentDisplayAction).then(e=> this.dispatchResizeEvent());
            }
            if(action === Action.Expand){
                SmartPortal.navigateTo(this.props.expandLink);
            }
            if(action === Action.Close){
                SmartPortal.currentPage.setRightPanel(null);
            }
            return Promise.resolve();
        }}>
        <div ref={ref => { this.bodyRef = ref; return true; }} className={styles.PanelBody}>
            <div className={styles.LayoutGrid}>
                <div className={styles.CardsContainer} style={{ height: this.cardItemHeight(this.state.currentDisplayAction) * (this.state.rows === 0 ? 2 : this.state.rows) }}>
                    {mainComponents.map((cmp, idx) => {
                        return <div key={idx}>{cmp}</div>;
                    })}
                </div>
            </div>
        </div>
    </Panel>
    </>
    );
  }

  private dispatchResizeEvent(){
    if (typeof(Event) === 'function') {
        window.dispatchEvent(new Event('resize'));
      }
  }

  private updateDisplayedCards(rows: number, display: Action, filter?: ICardFilter) : Promise<void> {
    if (!this.updatingCards) {
        return new Promise<void>((resolve, reject) => {
            this.updatingCards = true;

            const workplaceFilter = this.state.currentFilter;
            if (filter) {
                for (const prop in filter) {
                    workplaceFilter[prop] = filter[prop];
                }
            }

            this.streamManager.requestCardRange(0, this.calculateItemSpace(rows, display)).then((result) => {
                const itemInRow = Math.floor((this.bodyRef.offsetWidth + this.cardGridMarginLeft(display)) / this.cardItemMinWidth(display));
                const rows = Math.ceil(result.cards.length / itemInRow);
                this.updateState({
                    loaded: true,
                    rows: rows,
                    currentDisplayAction: display,
                    areMoreAvailable: result.total > result.cards.length,
                    displayedCards: result.cards,
                });
                this.updatingCards = false;
                resolve();
            }).catch((error) => {
                if (console) console.log(error);
                reject(error);
            });
        });
    }
    return Promise.resolve();
}

private calculateItemSpace(rows: number, display: Action): number {
    let items = 20;
    if (this.bodyRef) {
        items = Math.floor((this.bodyRef.offsetWidth + this.cardGridMarginLeft(display)) / this.cardItemMinWidth(display)) * rows;
    }
    return items;
}

private updateState(stateUpdate: any, callback?: () => void) {
    if (this.rendered)
        this.setState(stateUpdate, callback);
    else {
        for (let prop in stateUpdate) {
            if (!stateUpdate.hasOwnProperty(prop))
                continue;
            this.state[prop] = stateUpdate[prop];
        }
        if (callback)
            callback();
    }
}

private setStreamSource(sourceName: string): void {
    if (this.streamSource === sourceName)
        return;

    this.streamSource = sourceName;
    this.streamManager.clearStreamSources();

    switch (this.streamSource) {
        case "trending":
            this.streamManager.setStreamSortField("weight");

            let trendingStream = new StreamSource(new DiscoverTrendingDataSource(), this.cardCacheService);
            trendingStream.batchSize = 100;
            this.streamManager.addStreamSource(trendingStream);
            break;
        case "recent":
            let recentStream: StreamSource;
            if(SmartPortal.currentSession.userType == UserType.PrivateAccount){
                this.streamManager.setStreamSortField(null);
                recentStream = new StreamSource( new ConsumerDiscoverRecentDataSource(), this.cardCacheService);
            }else{
                this.streamManager.setStreamSortField("updateTime");
                recentStream = new StreamSource( new DiscoverRecentDataSource(), this.cardCacheService);
            }
            recentStream.batchSize = 100;
            this.streamManager.addStreamSource(recentStream);

            /*
            let searchStreamSource = new DiscoverSearchDataSource(this.graphBatchApi, "IsDocument:True AND FileExtension:aspx AND PromotedState:2");
            searchStreamSource.sortList = [
                ["LastModifiedTime", 1]
            ];
            let searchStream = new StreamSource(searchStreamSource, this.cacheService);
            searchStream.batchSize = 100;
            this.streamManager.addStreamSource(searchStream);
            */
            break;
    }

    if (this.rendered) {
        this.updateDisplayedCards(this.props.rows, this.state.currentDisplayAction);
    }
}

}
