import { SmartPortal } from '../../SmartPortal';

export enum Role {
    Admin = "Admin",
    Requester = "Requester"
}

export class RoleService {

    public constructor() {        

    }

    public IsUserInRole(role: Role): boolean {
        return SmartPortal.currentSession.roles.includes(role.toString());
    }
}