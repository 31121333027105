import * as React from 'react';
import styles from "./FolderPickerStyle.scss";
import FolderPickerStrings from "./loc/FolderPickerStrings";
import { Icon, PrimaryButton, DefaultButton, ActionButton, Breadcrumb, IBreadcrumbItem, Selection, SelectionMode, Link, ShimmeredDetailsList, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { FolderPickerDataSource } from './DataSources/FolderPickerDataSource';
import { FolderPickerItem } from './Models/FolderPickerItem';
import { Dialog } from '../Dialog/Dialog';
import { Action } from "../Panel";

export interface IFolderPickerProps {
    selectionMode: FolderPickerSelectionMode;
    dataSource: FolderPickerDataSource | null;
    onSuccess?: (items: FolderPickerResult) => void;
    onCancel?: () => void;
}

export enum FolderPickerSelectionMode {
    Single,
    Multiple
}

export type FolderPickerResult = {
    itemWebUrl: string;
    graphDriveId: string;
    graphItemId: string;
    graphSiteId: string;
    breadCrumb: IBreadcrumbItem[];
}

export interface IFolderPickerState {
    breadcrumbItems: IBreadcrumbItem[];
    search: string;
    items: FolderPickerItem[];
    selected: FolderPickerItem[];
    currentFolder: FolderPickerItem;
    loaded: boolean;
    hasMoreItems: boolean;
    loading: boolean;
    moreLoading: boolean;
    searchable: boolean;
}

export class FolderPicker extends React.Component<IFolderPickerProps, IFolderPickerState> {

    private selection: Selection<FolderPickerItem>;

    constructor(props: IFolderPickerProps, state: IFolderPickerState) {
        super(props);

        this.selection = new Selection<FolderPickerItem>({
            onSelectionChanged: () => this.setState({ selected: this.selection.getSelection() }),
            canSelectItem: (item) =>  !(item.itemType === "site") 
        });

        this.props.dataSource.reset();

        this.state = {
            breadcrumbItems: this.props.dataSource.initialBreadcrumbItems,
            search: null,
            items: [],
            selected: [],
            currentFolder: null,
            loaded: false,
            hasMoreItems: false,
            loading: false,
            moreLoading: false,
            searchable: false
        };
    }

    public componentDidMount(): void {
        if (!this.state.loaded) {
            this.loadItems();
        }
    }

    private loadItems(item?: FolderPickerItem) {
        this.setState({ loading: true });
        this.props.dataSource.loadItems(item ?? this.props.dataSource.initialFolder).then((result) => {
            let breadcrumbItems = result.breadcrumbItems;
            breadcrumbItems = breadcrumbItems.map((e, i) => { return { ...e, onClick: i !== breadcrumbItems.length - 1 ? (ev, item) => { this.onBreadcrumbClick(ev, item); } : null } as IBreadcrumbItem });
            this.setState({
                search: null,
                items: result.items,
                currentFolder: result.currentFolder,
                breadcrumbItems: breadcrumbItems,
                hasMoreItems: result.hasMoreItems,
                selected: [],
                loading: false,
                loaded: true,
                searchable: result.searchable
            });
        });
    }

    private loadMore() {
        this.setState({ moreLoading: true });
        this.props.dataSource.loadMoreItems().then((result) => {
            this.setState({
                items: [...this.state.items, ...result.moreItems],
                hasMoreItems: result.hasMoreItems,
                moreLoading: false
            });
        });
    }

    private search(term: string): Promise<void> {
        this.setState({ search: term, loading: true });
        return this.props.dataSource.searchItems(term).then((result) => {
            this.setState({
                search: term,
                items: result.items,
                hasMoreItems: result.hasMoreItems,
                selected: [],
                loading: false
            });
        });
    }

    private onBreadcrumbClick(ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem): void {
        this.setState({ loading: true, breadcrumbItems: this.props.dataSource.initialBreadcrumbItems });
        this.props.dataSource.onBreadcrumbClick(item).then((result) => {
            let breadcrumbItems = result.breadcrumbItems;
            breadcrumbItems = breadcrumbItems.map((e, i) => { return { ...e, onClick: i !== breadcrumbItems.length - 1 ? (ev, item) => { this.onBreadcrumbClick(ev, item); } : null } as IBreadcrumbItem });
            this.setState({
                items: result.items,
                currentFolder: result.currentFolder,
                breadcrumbItems: breadcrumbItems,
                hasMoreItems: result.hasMoreItems,
                selected: [],
                loading: false,
                searchable: result.searchable
            });
        });
    }

    public render(): React.ReactElement<{}> {
        return (
            <Dialog className={styles.FileUploadFolderPicker} header={
                    <Breadcrumb items={this.state.breadcrumbItems} overflowIndex={this.state.breadcrumbItems.length > 1 ? 1 : 0} />
                } search={this.state.search} actions={[Action.Close, (this.state.searchable ? Action.Search : Action.None)]} onAction={(action, value) => {
                    if (action === Action.Close) {
                        if (this.props.onCancel)
                            this.props.onCancel();
                    }
                    if (action === Action.Search) {
                        return this.search(value);
                    }
                return Promise.resolve();
            }}>
                <div className={styles.FolderPicker}>
                    <div className={styles.FolderPickerList}>
                        <ShimmeredDetailsList
                            items={this.state.items}
                            isHeaderVisible={true}
                            selectionMode={this.props.selectionMode === FolderPickerSelectionMode.Multiple ? SelectionMode.multiple : SelectionMode.single}
                            selection={this.selection}
                            enableShimmer={this.state.loading}
                            enableUpdateAnimations={true}
                            columns={[
                                {
                                    key: "fileType",
                                    name: FolderPickerStrings.FolderPicker_FileTypeColumnName,
                                    iconName: "Page",
                                    isIconOnly: true,
                                    fieldName: "fileType",
                                    minWidth: 16,
                                    maxWidth: 16,
                                    onRender: (item: FolderPickerItem) => {
                                        switch (item.itemType) {
                                            case "site":
                                                return <Icon iconName="sharepointappicon16" style={{ fontSize: "1.1rem" }} />;
                                            case "documentlibrary":
                                                return <Icon iconName="doclibrary" style={{ fontSize: "1.1rem" }} />;
                                        }
                                        if (item.isFolder) return <Icon {...getFileTypeIconProps({ type: 2, size: 16, imageFileType: 'png' })} />;
                                        return <Icon {...getFileTypeIconProps({ extension: this.getFileExtension(item.name), size: 16, imageFileType: 'png' })} />;
                                    }
                                },
                                {
                                    key: "name",
                                    name: FolderPickerStrings.FolderPicker_NameColumnName,
                                    fieldName: "name",
                                    minWidth: 210,
                                    isRowHeader: true,
                                    data: "string",
                                    isPadded: true,
                                    onRender: (item: FolderPickerItem) => {
                                        if (item.isFolder) return <Link key={item.id} onClick={() => this.loadItems(item)} >{item.name}</Link>;
                                        return <span key={item.id}>{item.name}</span>;
                                    }
                                }
                            ]} />

                        {this.state.hasMoreItems && !this.state.moreLoading ?
                            <div className={styles.FolderPickerMoreButton}>
                                <ActionButton iconProps={{ iconName: 'ChevronDown' }} text={FolderPickerStrings.FolderPicker_ShowMore} onClick={() => this.loadMore()} />
                            </div> : null
                        }
                        {this.state.hasMoreItems && this.state.moreLoading ?
                            <div className={styles.FolderPickerMoreButton}>
                                <Spinner title={FolderPickerStrings.FolderPicker_Loading} size={SpinnerSize.small} />
                            </div> : null}
                    </div>
                    <div className={styles.FolderPickerActions}>
                        <PrimaryButton text={FolderPickerStrings.FolderPicker_SelectButton} allowDisabledFocus onClick={(evt) => this.onSelectClick(evt as React.MouseEvent)} disabled={this.state.selected.length === 0 && !this.state.currentFolder} />
                        <DefaultButton text={FolderPickerStrings.FolderPicker_CancelButton} allowDisabledFocus onClick={(evt) => this.onCancelClick(evt as React.MouseEvent)} />
                    </div>
                </div>
            </Dialog>
        );
    }

    private getFileExtension(filename: string): string {
        return filename.substring(filename.lastIndexOf(".") + 1, filename.length) || filename;
    }

    private onSelectClick(evt: React.MouseEvent) {
        if (this.props.onSuccess) {
            const breadcrumbItems = this.state.breadcrumbItems;
            const select = this.state.selected.length === 0 ? this.state.currentFolder : this.state.selected[0];
            if (this.state.selected.length !== 0) {
                breadcrumbItems.push({
                    text: select.name,
                    key: select.key,
                    as: "h2",
                    onClick: null
                });
            }
            this.props.onSuccess({
                itemWebUrl: select.webUrl,
                graphItemId: select.id,
                graphDriveId: select.driveId,
                graphSiteId: select.siteId,
                breadCrumb: breadcrumbItems
            });
        }
    }

    private onCancelClick(evt: React.MouseEvent) {
        if (this.props.onCancel)
            this.props.onCancel();
    }
}