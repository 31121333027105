
export const getParameterByName = (name: string, url?: string): string => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    name = name.replace(/\$/g, '\\$');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const inIframe = (): boolean => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}