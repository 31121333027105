
export class ServiceLoader {
    private static _serviceSymbol = (window.Symbol ? Symbol("ServiceInstance") : "__SvcInstance");
    private static _serviceClasses: object[] = [];
    private static _serviceInstances: object[] = [];

    public static GetService<SvcT extends object, SvcP = any>(serviceClass: new(props: SvcP) => SvcT, serviceProps: SvcP = null): SvcT {
        let serviceIdx: number;
        let serviceObj: SvcT;

        if(!serviceClass)
            return null;

        if (typeof (serviceIdx = serviceClass[this._serviceSymbol]) === "number" || (serviceIdx = this._serviceClasses.indexOf(serviceClass)) !== -1) {
            serviceObj = this._serviceInstances[serviceIdx] as SvcT;
        }
        else {
            serviceObj = new serviceClass(serviceProps);
            serviceIdx = this._serviceClasses.length;
            this._serviceClasses.push(serviceClass);
            this._serviceInstances.push(serviceObj);
            try {
                Object.defineProperty(serviceClass, this._serviceSymbol, {
                    value: serviceIdx,
                    writable: false
                });
            } catch(ex) {}
        }

        if(!(serviceObj instanceof serviceClass))
            throw "ServiceLoader found object that is not an instance of the requested service";

        return serviceObj;
    }

}
