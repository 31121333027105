import { PickerDataSource, IPickerDataSourceProps } from './PickerDataSource';
import { IPeoplePickerItem, PeoplePickerItemType } from '../model/PeoplePickerItem';
import { ServiceLoader } from '../../../business/ServiceLoader';
import { GraphBatchService } from '../../../business/GraphBatchService';

export interface IGraphPickerDataSourceProps extends IPickerDataSourceProps {
    allowUsers?: boolean;
    allowGroups?: boolean;
    searchUsers?: boolean;
    searchGroups?: boolean;
}

export class GraphPickerDataSource extends PickerDataSource<IGraphPickerDataSourceProps> {

    public FilterExternalUsers?: boolean;
    public FilterDisabledUsers?: boolean;
    public FilterUsersWithoutLicences?: string[]; 
    public AllowGroups: boolean;
    public AllowUsers: boolean;    

    public constructor(props?: IGraphPickerDataSourceProps) {
        super(props || {
            allowUsers: true,
            searchUsers: true,
            debounceTime: 500,
            searchMinLen: 2
        });
        this.FilterUsersWithoutLicences = null;
        this.FilterExternalUsers = true;
        this.FilterDisabledUsers = true;
        this.AllowGroups = true,
        this.AllowUsers = true
    }
    
    protected loadSuggestions(term: string): Promise<IPeoplePickerItem[]> {
        let promises: Promise<IPeoplePickerItem[]>[] = [];
        if(this.props.allowUsers && this.props.searchUsers && this.AllowUsers)
            promises.push(this.loadUsers(term));
        if(this.props.allowGroups && this.props.searchGroups && this.AllowGroups)
            promises.push(this.loadGroups(term));

        return Promise.all(promises).then((res) => {
            let items: IPeoplePickerItem[] = [];

            for(let i = 0; i < res.length; i++) {
                Array.prototype.push.apply(items, res[i]);
            }

            items.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));

            return items;
        });
    }

    protected filterSuggestions(items: IPeoplePickerItem[], term: string): IPeoplePickerItem[] {
        let termPattern = new RegExp("^" + term.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "i");
        return items.filter((item) => {
            switch(item.objectType) {
                case PeoplePickerItemType.User:
                    return (
                        termPattern.test(item.objectData.displayName || "") ||
                        termPattern.test(item.objectData.givenName || "") ||
                        termPattern.test(item.objectData.surname || "") ||
                        termPattern.test(item.objectData.mail || "") ||
                        termPattern.test(item.objectData.userPrincipalName || "")
                    );
                case PeoplePickerItemType.Group:
                    return (
                        termPattern.test(item.objectData.displayName || "") ||
                        termPattern.test(item.objectData.mail || "")
                    );
                default:
                    return termPattern.test(item.displayName || "");
            }
        }); 
    }

    private loadUsers(term: string): Promise<IPeoplePickerItem[]> {
        let escapedTerm = encodeURIComponent(term).replace(/'/g, "''");
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            version: "v1.0",
            path: "/users?$select=displayName,userPrincipalName,id,mail,mobilePhone,jobTitle,givenName,surname&$filter=(startswith(displayName,'" + escapedTerm + "') or startswith(givenName,'" + escapedTerm + "') or startswith(surname,'" + escapedTerm + "') or startswith(mail,'" + escapedTerm + "') or startswith(userPrincipalName,'" + escapedTerm + "'))" + (this.FilterDisabledUsers ? " and accountEnabled eq true" : "") + (this.FilterExternalUsers ? " and userType eq 'Member'" : ""),
            scopes: ["User.Read.All"]
        }).then((rsp) => {            
            if (this.FilterUsersWithoutLicences && this.FilterUsersWithoutLicences.length > 0) {

                return this.filterUsersWithoutLicence(rsp.value, this.FilterUsersWithoutLicences);
            }
            else {
                return rsp.value.map((data) => {

                    const item: IPeoplePickerItem = {
                        objectId: data.id,
                        objectType: PeoplePickerItemType.User,
                        objectData: data,
                        displayName: data.displayName,
                        userPrincipal: data.userPrincipalName,
                        mail: data.mail,
                        mobilePhone: data.mobilePhone,
                        jobTitle: data.jobTitle,
                    };
                    return item
                });
            }
        })
    }

    private filterUsersWithoutLicence(data: any, neededLicences: string[]): Promise<IPeoplePickerItem[]>{
        return new Promise<IPeoplePickerItem[]>(resolve => {
            const promises = [];
            const batchService =  ServiceLoader.GetService(GraphBatchService);
            data.forEach((graphUser) => {
                promises.push(batchService.addGraphRequest({
                    method: "GET",
                    version: "v1.0",
                    path: `/users/${graphUser.id}/licenseDetails`,
                    scopes: ["User.Read.All"]
                }));
            });

            Promise.all(promises).then(licenceInfos => {

                const result: IPeoplePickerItem[]= [];

                data.forEach((graphUser, index) => {
                    if (this.hasUserServicePlanNames(neededLicences, licenceInfos[index])) {
                        result.push({
                            objectId: graphUser.id,
                            objectType: PeoplePickerItemType.User,
                            objectData: graphUser,
                            displayName: graphUser.displayName,
                            userPrincipal: graphUser.userPrincipalName,
                            mail: graphUser.mail,
                            mobilePhone: graphUser.mobilePhone,
                            jobTitle: graphUser.jobTitle,
                        });
                    }
                }); 

                resolve(result);
            });
        });
    }

    private hasUserServicePlanNames(planNames: string[], licenceInfo: any): boolean {
        let hasAllPlans = true;

        planNames.forEach((value) => {
            hasAllPlans =  this.hasUserServicePlanName(value, licenceInfo) && hasAllPlans;
        })

        return hasAllPlans;
    }

    private hasUserServicePlanName(planName: string, licenceInfo: any): boolean {
        let hasPlan = false;
       
        if (licenceInfo.value) {
            licenceInfo.value.forEach(sku => {
                if (sku.servicePlans) {
                    sku.servicePlans.forEach(servicePlan => {
                        if (servicePlan.servicePlanName == planName && servicePlan.provisioningStatus == "Success") {
                            hasPlan = true;
                        }
                    });
                }
            });
        }
        
        return hasPlan;
    }

    private loadGroups(term: string): Promise<IPeoplePickerItem[]> {
        let escapedTerm = encodeURIComponent(term).replace(/'/g, "''");
        return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
            method: "GET",
            version: "v1.0",
            path: "/groups?$select=displayName,id,mail,description,groupTypes,securityEnabled,mailEnabled&$filter=startswith(displayName,'" + escapedTerm + "') or startswith(mail,'" + escapedTerm + "')",
        }).then((rsp) => {
            return rsp.value.map((data) => {
                let item: IPeoplePickerItem = {
                    objectId: data.id,
                    objectType: PeoplePickerItemType.Group,
                    objectData: data,
                    displayName: data.displayName,
                    mail: data.mail,
                    description: data.description,
                };
                return item;
            });
        })
    }

    public getPickerItemByField(itemType: PeoplePickerItemType, fieldName: string, fieldValue: string): Promise<IPeoplePickerItem> {
        let escapedValue = encodeURIComponent(fieldValue).replace(/'/g, "''");
        switch(itemType) {
            case PeoplePickerItemType.User:
                return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
                    method: "GET",
                    version: "v1.0",
                    path: "/users?$select=displayName,userPrincipalName,id,mail,mobilePhone,jobTitle,givenName,surname&$filter=userPrincipalName eq '" + escapedValue + "'",
                }).then((rsp) => {
                    let items = rsp.value.map((data) => {
                        let item: IPeoplePickerItem = {
                            objectId: data.id,
                            objectType: PeoplePickerItemType.User,
                            objectData: data,
                            displayName: data.displayName,
                            userPrincipal: data.userPrincipalName,
                            mail: data.mail,
                            mobilePhone: data.mobilePhone,
                            jobTitle: data.jobTitle,
                        };
                        return item;
                    });
                    return items.length ? items[0] : null;
                });
            case PeoplePickerItemType.User:
                return ServiceLoader.GetService(GraphBatchService).addGraphRequest({
                    method: "GET",
                    version: "v1.0",
                    path: "/users?$select=displayName,id,mail,description&$filter=eq(" + fieldName + ",'" + escapedValue + "')",
                }).then((rsp) => {
                    let items = rsp.value.map((data) => {
                        let item: IPeoplePickerItem = {
                            objectId: data.id,
                            objectType: PeoplePickerItemType.Group,
                            objectData: data,
                            displayName: data.displayName,
                            mail: data.mail,
                            description: data.description,
                        };
                        return item;
                    });
                    return items.length ? items[0] : null;
                });
                
        }
    }

}
