import { LocaleService, BaseStrings, Locale } from "../../../common/business/LocaleService";

export class SideBarStrings extends BaseStrings {
    readonly _ModuleName = "SideBarStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new SideBarStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./SideBarStrings.de").then((res) => res.default),

    };
    _CurrentLocale: Locale = Locale.EN;

    SideBarButton_Title = "SmartPortal365 Panel";
    
    SideBarPanel_SiteInfoTitle = "Information about this workspace";
    SideBarPanel_SiteInfo_FormNotValidMessage = "Please fill in all the required fields.";
    SideBarPanel_SiteInfo_Error_NotFound = "This website is not managed by SmartPortal365";
    SideBarPanel_SiteInfo_Error_Generic = "Something went wrong";
    SideBarPanel_ActionsTitle = "My workplace";
    
}

export default (() => {
    let dict = new SideBarStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
