import * as React from "react";
import Truncate from 'react-truncate';
import styles from '../DiscoverTabStyle.scss';
import { Shimmer, ShimmerElementType, IContextualMenuItem, Icon } from "office-ui-fabric-react";
import { renderFriendlyDate } from '../../../common/utils/DateHandler';
import DiscoverTabStrings from '../loc/DiscoverTabStrings';
import * as CardStream from '../../../common/cardstream';
import { CardContextMenu, ICardContextMenuControl } from "./CardContextMenu";
import { SmartPortal } from "../../../SmartPortal";
import { Action } from "../../../common/controls/Panel/PanelActions";
import { BaseCard } from '../../../common/cardstream';
import { UserType } from "../../../common/models/SessionInfo";

export interface IDocumentCardProps {
    card: CardStream.DocumentCard;
    trending: boolean;
    minWidth: number;
    height: number;
    display: Action;
}

export interface IDocumentCardState {
}

export class DocumentCard extends React.Component<IDocumentCardProps, IDocumentCardState> {
    private updateCallback: () => void;
    private listenUpdates: boolean;
    private listenCard: BaseCard;
    private cardContainerEl: HTMLDivElement;
    private ctxmenuCtrl: ICardContextMenuControl;

    private truncateTitleRef;

    constructor(props: IDocumentCardProps, state: IDocumentCardState) {
        super(props);

        this.state = {
        };

        this.updateCallback = () => {
            this.forceUpdate();
        };
    }

    public componentWillUnmount(): void {
        if(this.listenUpdates) {
            this.listenUpdates = false;
            this.props.card.removeUpdateCallback(this.updateCallback);
        }
    }

    public componentDidUpdate(prevProps) {
        if (this.props.display !== prevProps.display) {
            this.truncateTitleRef.onResize();
        }
    }

    public render(): React.ReactElement<{}> {
        if(this.listenCard !== this.props.card && this.listenCard) {
            this.listenUpdates = false;
            this.listenCard.removeUpdateCallback(this.updateCallback);
        }
        if(!this.listenUpdates) {
            this.listenUpdates = true;
            this.listenCard = this.props.card;
            this.props.card.addUpdateCallback(this.updateCallback);
        }

        let cardThumbnail = this.props.card.thumbnail;
        let cardThumbsLoading = this.props.card.isLazyDataLoading("thumbnail");

        let cardActivities = [];

        if(this.props.card.lastModifyUser && SmartPortal.currentSession.userType == UserType.WorkAccount) {
            cardActivities.push({
                id: "edit" + cardActivities.length,
                action: "edit",
                date: this.props.card.lastModifyTime,
                user: this.props.card.lastModifyUser
            });
        }

        if(this.props.card.lastAccessTime && SmartPortal.currentSession.userType == UserType.PrivateAccount){
            cardActivities.push({
                id: "view" + cardActivities.length,
                action: "view",
                date: this.props.card.lastAccessTime,
                user: {mail: SmartPortal.currentUser.mail}
            });
        }

        let cartAnalytics;
        if (this.props.display === Action.DisplayLargeCard && this.props.trending && (cartAnalytics = this.props.card.analytics) && cartAnalytics.allTime && cartAnalytics.lastWeek) {
            cardActivities.unshift({
                id: "stats",
                action: "stats",
                data: {
                    allTime: cartAnalytics ? cartAnalytics.allTime.accessTotal : null,
                    lastWeek: cartAnalytics ? cartAnalytics.lastWeek.accessTotal : null
                }
            });
        }
        var cardActivitiesLoading = this.props.card.isLazyDataLoading("analytics");
        
        let maxActivityCount = SmartPortal.currentSession.userType == UserType.PrivateAccount ? 1 : 2;
        let activityContainerStyle = SmartPortal.currentSession.userType == UserType.PrivateAccount ? {minHeight: 50} : {};
        while (cardActivities.length < maxActivityCount) {
            cardActivities.push({
                id: "filler" + cardActivities.length,
                action: cardActivitiesLoading ? "loading" : "empty",
            });
        }
        while (cardActivities.length > maxActivityCount)
            cardActivities.pop();

        let cardSrcIconClass = "sharepoint";

        return (
            <div className={styles.CardItem + " " + (this.props.display === Action.DisplaySmallCard ? styles.CardItemSmall : styles.CardItemLarge)} style={{ minWidth: this.props.minWidth, height: this.props.height }}>
                <div className={styles.CardContainer + " " + styles.DocumentCard} ref={(el) => {
                    this.cardContainerEl = el;
                }} onContextMenu={(evt) => {
                    let element = evt.target as Element;
                    let isLink = false;
                    do {
                        isLink = element.matches("a");
                    } while (!isLink && (element = element.parentElement));

                    if (!isLink) {
                        this.openContextMenu(evt);
                        evt.preventDefault();
                    }
                }}>
                    <CardContextMenu setControl={(control) => {
                        this.ctxmenuCtrl = control;
                    }} menuItems={this.getCtxMenuItems()} />

                    <div className={styles.CardContent}>
                        <div className={styles.CardImage}>
                            {cardThumbsLoading || !cardThumbnail ?
                                <div className={styles.GenericImage}>
                                    {this.props.card.fileTypeIconClass ? <Icon iconName={this.props.card.fileTypeIconClass} className={styles.FileTypeIcon} /> : null}
                                </div>
                                :
                                <div className={styles.ThumbImage}>
                                    <img src={cardThumbnail} className={styles.ThumbImgEl} onError={() => {
                                        this.props.card.thumbnail = null;
                                    }} />
                                    {this.props.card.fileTypeIconClass ? <Icon iconName={this.props.card.fileTypeIconClass} className={styles.FileTypeIcon} /> : null}
                                </div>
                            }
                        </div>
                        <div className={styles.CardHeader}>
                            <div className={styles.CardTitle}>
                                <a href={this.props.card.webUrl} target="_blank">
                                    <Truncate ref={ref => { this.truncateTitleRef = ref; return true; }} lines={2} trimWhitespace={true}>
                                        {this.props.card.title}
                                    </Truncate>
                                </a>
                            </div>
                        </div>
                        <div className={styles.CardBodySpacer} style={activityContainerStyle}>
                            <div className={styles.CardBody}>

                                <div className={styles.CardActivityList}>
                                    {cardActivities ? cardActivities.map((activity, idx) => {
                                        let activityIdx = "acid-" + idx.toString();
                                        let activityIconEl: JSX.Element;
                                        let activityElements: JSX.Element[] = [];
                                        let activityElCount = 0;
                                        let activityUser: string;


                                        if (!activity.user)
                                            activityUser = "Someone";
                                        else if (SmartPortal.currentUser && activity.user.mail === (SmartPortal.currentUser.mail || SmartPortal.currentUser.upn))
                                            activityUser = "You";
                                        else
                                            activityUser = "User";

                                        switch (activity.action) {
                                            case "edit":
                                                activityIconEl = <Icon iconName="Edit" className={styles.ActivityIcon} />;
                                                activityElements.push(
                                                    <div className={styles.CardActivityTitle} key={(activityElCount++)} dangerouslySetInnerHTML={{
                                                        __html: DiscoverTabStrings.format(DiscoverTabStrings["Activity_Edit_" + activityUser], activity.user?.name)
                                                    }}></div>,
                                                    <div className={styles.CardActivityInfo} key={(activityElCount++)}>
                                                        {renderFriendlyDate(activity.date)}
                                                    </div>
                                                );
                                                break;
                                            case "comment":
                                                activityIconEl = <Icon iconName="Message" className={styles.ActivityIcon} />;
                                                activityElements.push(
                                                    <div className={styles.CardActivityTitle} key={(activityElCount++)} dangerouslySetInnerHTML={{
                                                        __html: DiscoverTabStrings.format(DiscoverTabStrings["Activity_Comment_" + activityUser], activity.user?.name)
                                                    }}></div>,
                                                    <div className={styles.CardActivityInfo} key={(activityElCount++)}>
                                                        {renderFriendlyDate(activity.date)}
                                                    </div>
                                                );
                                                break;
                                            case "stats":
                                                activityIconEl = <Icon iconName="Trending12" className={styles.ActivityIcon} />;
                                                activityElements.push(
                                                    <div className={styles.CardActivityTitle + " " + styles.ActivityTitle} key={(activityElCount++)}>
                                                        <span className={styles.ActivityActor}>{DiscoverTabStrings.format(DiscoverTabStrings.Activity_ViewsWeekly, activity.data.lastWeek)}</span>
                                                    </div>,
                                                    <div className={styles.CardActivityInfo} key={(activityElCount++)}>{DiscoverTabStrings.format(DiscoverTabStrings.Activity_ViewsTotal, activity.data.allTime)}</div>
                                                );
                                                break;
                                            case "loading":
                                                activityIconEl = <Shimmer width="10px" className={styles.ShimmerIcon} />;
                                                activityElements.push(
                                                    <div className={styles.CardActivityTitle} key={(activityElCount++)}>
                                                        <Shimmer shimmerElements={[
                                                            { type: ShimmerElementType.line, width: '25%' },
                                                            { type: ShimmerElementType.gap, width: '6%' },
                                                            { type: ShimmerElementType.line, width: '15%' },
                                                            { type: ShimmerElementType.gap, width: '55%' }
                                                        ]} />
                                                    </div>
                                                );
                                                break;
                                            case "view":
                                                activityIconEl = <Icon iconName="View" className={styles.ActivityIcon} />;
                                                activityElements.push(
                                                    <div className={styles.CardActivityTitle} key={(activityElCount++)} dangerouslySetInnerHTML={{
                                                        __html: DiscoverTabStrings.format(DiscoverTabStrings["Activity_View_" + activityUser], activity.user?.name)
                                                    }}></div>,
                                                    <div className={styles.CardActivityInfo} key={(activityElCount++)}>
                                                        {renderFriendlyDate(activity.date)}
                                                    </div>
                                                );
                                                break;
                                            case "empty":
                                                activityIconEl = null;
                                                break;
                                            default:
                                                activityIconEl = <Icon iconName="Help" className={styles.ActivityIcon} />;
                                                activityElements.push(
                                                    <div className={styles.CardActivityTitle} key={(activityElCount++)} dangerouslySetInnerHTML={{
                                                        __html: DiscoverTabStrings.format(DiscoverTabStrings["Activity_Something_" + activityUser], activity.user?.name, activity.action)
                                                    }}></div>,
                                                    <div className={styles.CardActivityInfo} key={(activityElCount++)}>
                                                        {renderFriendlyDate(activity.date)}
                                                    </div>
                                                );
                                                break;
                                        }

                                        return (<div key={activityIdx} className={styles.CardActivity}>
                                            {activityIconEl ?
                                                <div className={styles.CardActivityIcon}>
                                                    <div className={styles.ActivityCircle}></div>
                                                    {activityIconEl}
                                                </div>
                                                : null}
                                            {activityElements}
                                        </div>);
                                    }) : null}
                                </div>
                            </div>
                        </div>
                        <div className={styles.CardFooter}>
                            <div className={styles.CardFooterSrcIcon}>
                                {cardSrcIconClass ?
                                    <div className={["ms-BrandIcon--icon16", "ms-BrandIcon--" + cardSrcIconClass].join(" ")} />
                                    : null}
                            </div>
                            <div className={styles.CardFooterBreadcrumb} style={{ width: this.props.minWidth }}>
                                {(() => {
                                    let parentLinks: JSX.Element[] = [];
                                    let linkCount = 0;
                                    let parentSite = this.props.card.sites;

                                    while (parentSite && parentSite.item) {
                                        if (linkCount !== 0) {
                                            parentLinks.unshift((
                                                <div key={(linkCount++)} className={styles.CBSeperator}>
                                                    &#x3E;
                                                </div>
                                            ));
                                        }

                                        parentLinks.unshift((
                                            <div key={(linkCount++)}>
                                                <a href={parentSite.item.webUrl} target="_blank" className={styles.CBLink}>
                                                    {parentSite.item.name}
                                                </a>
                                            </div>
                                        ));

                                        parentSite = parentSite.parent;
                                    }

                                    if (linkCount === 0) {
                                        parentLinks.push((
                                            <div key={(linkCount++)}>
                                                <a href={this.props.card.containerUrl} target="_blank" className={styles.CBLink}>
                                                    {this.props.card.containerTitle}
                                                </a>
                                            </div>
                                        ));
                                    }

                                    return parentLinks;
                                })()}
                            </div>
                            <div className={styles.CardFooterMenu}>
                                <div className={styles.MenuActionPnl} onClick={(evt) => {
                                    this.openContextMenu(evt);
                                }}>
                                    <Icon iconName="MoreVertical" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getCtxMenuItems(): IContextualMenuItem[] {
        let menuItems: IContextualMenuItem[] = [];

        let openUrl: string;
        if ((openUrl = this.props.card.openInAppLink)) {
            menuItems.push({
                key: 'openApp',
                text: DiscoverTabStrings.CtxMenu_OpenInApp,
                iconProps: {
                    iconName: 'FabricOpenFolderHorizontal'
                },
                onClick: () => {
                    location.href = openUrl;
                }
            });
        }
        else if ((openUrl = this.props.card.webUrl)) {
            menuItems.push({
                key: 'open',
                text: DiscoverTabStrings.CtxMenu_OpenInTab,
                iconProps: {
                    iconName: 'FabricOpenFolderHorizontal'
                },
                onClick: () => {
                    let win = window.open(openUrl, '_blank');
                    win.focus();
                }
            });
        }

        let davUrl = this.props.card.davUrl || this.props.card.webUrl;
        menuItems.push({
            key: 'copyLink',
            text: DiscoverTabStrings.CtxMenu_CopyLink,
            iconProps: {
                iconName: 'Link'
            },
            onClick: () => {
                let textArea = document.createElement("textarea");
                textArea.value = davUrl;
                textArea.style.top = "0";
                textArea.style.left = "0";
                textArea.style.position = "fixed";

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    document.execCommand('copy');
                } catch (err) {
                }

                document.body.removeChild(textArea);
            }
        });
        menuItems.push({
            key: 'download',
            text: DiscoverTabStrings.CtxMenu_Download,
            iconProps: {
                iconName: 'Download'
            },
            onClick: () => {
                
                let win = window.open(davUrl, '_blank');
                win.focus();
            }
        });

        return menuItems;
    }

    private openContextMenu(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        let rect = this.cardContainerEl.getBoundingClientRect();
        let posX = event.clientX - rect.left;
        let posY = event.clientY - rect.top;
        this.ctxmenuCtrl.showMenu([posX, posY]);
    }

}
