import { LocaleService, BaseStrings, Locale } from "../../../common/business/LocaleService";

export class DiscoverTabStrings extends BaseStrings {
    readonly _ModuleName = "DiscoverTabStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new DiscoverTabStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./DiscoverTabStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    DiscoverTab_Title = "Discover";
    DiscoverTab_Description = "Discover documents from your company that could be relevant for you. See what is being worked on and benefit from new content. Note that you will only see items from OneDrive & SharePoint to which you have access permissions.";
    PortalFilter_Recent = "Recent";
    PortalFilter_Trending = "Trending";
    DiscoverTab_NoCards = "No documents found.";
    Activity_Edit_Someone = "<span class=\"ActivityActor\">Someone</span> edited this";
    Activity_Edit_You = "<span class=\"ActivityActor\">You</span> edited this";
    Activity_Edit_User = "<span class=\"ActivityActor\">{0}</span> edited this";
    Activity_View_You = "<span class=\"ActivityActor\">You</span> viewed this";
    Activity_View_User = "<span class=\"ActivityActor\">{0}</span> viewed this";
    Activity_Comment_Someone = "<span class=\"ActivityActor\">Someone</span> commented on this";
    Activity_Comment_You = "<span class=\"ActivityActor\">You</span> commented on this";
    Activity_Comment_User = "<span class=\"ActivityActor\">{0}</span> commented on this";
    Activity_ViewsWeekly = "{0} views in the last week";
    Activity_ViewsTotal = "{0} total views";
    Activity_Something_Someone = "<span class=\"ActivityActor\">Someone</span> did something ({1})";
    Activity_Something_You = "<span class=\"ActivityActor\">You</span> did something ({1})";
    Activity_Something_User = "<span class=\"ActivityActor\">{0}</span> did something ({1})";
    CtxMenu_OpenInApp = "Open in Desktop App";
    CtxMenu_OpenInTab = "Open in new Tab";
    CtxMenu_Share = "Share";
    CtxMenu_CopyLink = "Copy Link";
    CtxMenu_Download = "Download";
    Stat_Views = "{0} views";
    Stat_Comments = "{0} comments";
    Stat_Like = "{0} likes";
}

export default (() => {
    let dict = new DiscoverTabStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
