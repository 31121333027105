import { LocaleService, BaseStrings, Locale } from "../../../business/LocaleService";

export class PanelStrings extends BaseStrings {
    readonly _ModuleName = "PanelStrings";
    readonly _SupportedLocales = {
        [Locale.EN]: () => Promise.resolve(new PanelStrings),
        [Locale.DE]: () => import(/* webpackChunkName: "locale_de" */ "./PanelStrings.de").then((res) => res.default),
    };
    _CurrentLocale: Locale = Locale.EN;

    ViewAction_All = "All";
    ViewAction_My = "My";
    ViewAction_Recent = "Recent";
    ViewAction_Trending = "Trending";

    SortAction_Ascending = "A - Z";
    SortAction_Descending = "Z - A";
    SortAction_Newest = "Newest";
    SortAction_Oldest = "Oldest";
    SortAction_Manual = "Individual";

    DisplayAction_LargeTiles = "Large tiles";
    DisplayAction_SmallTiles = "Small tiles";
    DisplayAction_List = "List";

    StatusAction_All = "All";
    StatusAction_Active = "Active";
    StatusAction_Archived = "Archived";
    StatusAction_Approval = "In Approval";
    StatusAction_InProgress = "In Progress";
    StatusAction_Failed = "Failed";

    TypeAction_All = "All";
    TypeAction_SharePoint = "SharePoint Sites";
    TypeAction_MSTeams = "MS Teams";
    TypeAction_Favorites = "Favorites";

    Action_Edit = "Edit";
    Action_Save = "Save";
    Action_Saving = "Saving...";
    Action_Reset = "Reset";
    Action_Delete = "Delete";
    Action_Deleting = "Deleting...";
    Action_Archive = "Archive";
    Action_Archiving = "Archiving...";
    Action_Unarchive = "Activate";
    Action_Unarchiving = "Activating...";
    Action_Approve = "Approve";
    Action_Approving = "Approving...";
    Action_Reject = "Reject";
    Action_Rejecting = "Rejecting...";
    Action_Search = "Search";
    Action_Retry = "Retry";
    Action_Retrying = "Retrying...";
    Action_Close = "Close";
    Action_Expand = "Expand";
    Action_Export = "Export";
    Action_Exporting = "Exporting...";

    Action_ShowMore = "Show more";
    Action_Loading = "Loading";

    "Error_Invalid:HubSiteAssociation" = "The selected hub association cannot be set.";
    "Error_UnknownError" = "Unknown Error";
}

export default (() => {
    let dict = new PanelStrings();
    LocaleService.RegisterStrings(dict);
    return dict;
})();
