import { BaseCacheService } from './BaseCacheService';
import { CacheType } from './models/CacheType';


export class GenericCacheService extends BaseCacheService {
    protected cacheName = "GenericCache";    
    protected cacheType = CacheType.LocalStorage;

    public constructor() {
        super();

        this.cacheOptions.accessTimeout = 86400 * 7; // 7 days
    }
    
}
