import * as React from 'react';
import styles from "./FileUploadDialogStyle.scss";
import FileUploadDialogStrings from "./loc/FileUploadDialogStrings";
import { SmartPortal } from '../../SmartPortal';
import { FileUpload } from "../../common/controls/FileUpload/FileUpload";
import { FolderPicker, FolderPickerSelectionMode, FolderPickerResult } from "../../common/controls/FolderPicker/FolderPicker";
import { Dialog } from '../../common/controls/Dialog/Dialog';
import { Action } from "../../common/controls/Panel";
import { GraphFileUploadService } from '../../common/business/GraphFileUploadService';
import { IUploadFile, UploadFileStates, UploadFileErrorTypes, UploadFileResumeTypes } from '../../common/models/UploadFile';
import { PrimaryButton, DefaultButton, Breadcrumb, IBreadcrumbItem, Label } from 'office-ui-fabric-react';
import * as FileUtil from "../../common/utils/FileUtil";
import { OneDriveFolderPickerDataSource } from '../../common/controls/FolderPicker/DataSources/OneDriveFolderPickerDataSource';
import { SharePointFolderPickerDataSource } from '../../common/controls/FolderPicker/DataSources/SharePointFolderPickerDataSource';
import { FolderPickerDataSource } from '../../common/controls/FolderPicker/DataSources/FolderPickerDataSource';
import { SharePointPage } from '../../appstart/spapp/sharepoint/SharePointPage';

export interface IFileUploadDialogProps {
    onReturn: (changes: boolean) => void;
    uploadType: "OneDrive" | "SharePoint";
}

export interface IFileUploadDialogState {
    saving: boolean;
    saved: boolean;
    locations: IBreadcrumbItem[];
    files: IUploadFile[];
    filePickerResult: FolderPickerResult;
}

export class FileUploadDialog extends React.Component<IFileUploadDialogProps, IFileUploadDialogState> {
    
    public static GetFileUploadDialog(type: "OneDrive" | "SharePoint", dialogKey: string, dismiss: () => void, fallback: () => React.ReactNode): React.ReactElement {
        return (
            <Dialog key={dialogKey} headerText={FileUploadDialogStrings.FileUpload_Title} actions={[Action.Close]} onAction={(action) => {
                if (action === Action.Close) {
                    SmartPortal.currentPage.setDynamicContent(dialogKey, null);
                    dismiss();
                }
                return Promise.resolve();
            }}>
                <React.Suspense fallback={fallback()}>
                    <FileUploadDialog onReturn={() => {
                        SmartPortal.currentPage.setDynamicContent(dialogKey, null);
                        dismiss();
                    }} uploadType={type} />
                </React.Suspense>
            </Dialog>
        );
    }

    private fileUploadService: GraphFileUploadService;
    private filePickerDataSource: FolderPickerDataSource;

    constructor(props: IFileUploadDialogProps, state: IFileUploadDialogState) {
        super(props);
        this.fileUploadService = new GraphFileUploadService((file) => this.onFileUploadProgress(file), (file) => this.onFileUploadComplete(file), (file) => this.onFileUploadError(file), () => this.onUploadComplete());

        let filePickerResult: FolderPickerResult = null;
        if (this.props.uploadType === "OneDrive") {
            this.filePickerDataSource = new OneDriveFolderPickerDataSource();
            filePickerResult = { itemWebUrl: SmartPortal.currentSession.personalLocation } as FolderPickerResult;
        }
        else if (this.props.uploadType === "SharePoint") {
            const currentPage = SmartPortal.currentPage;
            if (currentPage instanceof SharePointPage) {
                const spPageContext = (currentPage as SharePointPage).getCurrentPageContext();
                this.filePickerDataSource = new SharePointFolderPickerDataSource(spPageContext);
            } else {
                this.filePickerDataSource = new SharePointFolderPickerDataSource();
            }
        }

        this.state = {
            saving: false,
            saved: false,
            locations: this.filePickerDataSource.initialBreadcrumbItems.map(e => { return { ...e, as: "h3", onClick: null } as IBreadcrumbItem; }),
            files: [],
            filePickerResult: filePickerResult
        };
    }

    public render(): React.ReactElement<{}> {
        return (
            <div className={styles.FileUpload}>
                <FileUpload files={this.state.files} saving={this.state.saving} saved={this.state.saved} onFileAdded={(file) => {
                    file.status = file.size;
                    const files = [...this.state.files, file];
                    this.setState(({
                        files: files
                    }) as IFileUploadDialogState);
                }}
                    onFileRemoved={(index) => {
                        const files = [...this.state.files];
                        files.splice(index, 1);
                        this.setState({
                            files: files
                        });
                    }}
                    onFileResumed={(index, resumeType) => {
                        const files = [...this.state.files];
                        const file = files[index];
                        file.state = UploadFileStates.Queued;
                        this.setState({
                            files: files, saving: true, saved: false
                        });
                        this.fileUploadService.resumeFile(file, resumeType === UploadFileResumeTypes.Rename ? "rename" : UploadFileResumeTypes.Replace ? "replace" : "fail");
                    }}
                />
                <div className={styles.FileUploadLocation}>
                    <div className={styles.FileUploadLocationLabel}>
                        <Label>{FileUploadDialogStrings.FileUpload_Location}</Label>
                    </div>
                    <div className={styles.FileUploadLocationBreadcrumb}>
                        <Breadcrumb items={this.state.locations} overflowIndex={this.state.locations.length > 1 ? 1 : 0} />
                    </div>
                    <div className={styles.FileUploadLocationSelectButton}>
                        <PrimaryButton text={FileUploadDialogStrings.FileUpload_SelectLocation} allowDisabledFocus onClick={(evt) => this.onSelectLocationClick(evt as React.MouseEvent)} disabled={this.state.saving || this.state.saved} />
                    </div>
                </div>
                <div className={styles.FileUploadActions}>
                    <PrimaryButton text={FileUploadDialogStrings.FileUpload_UploadButton} allowDisabledFocus onClick={(evt) => this.onUploadClick(evt as React.MouseEvent)} disabled={this.state.files.length === 0 || this.state.filePickerResult === null || this.state.saving} style={{ "display": this.state.saved ? "none" : "inline-block" }} />
                    <PrimaryButton text={FileUploadDialogStrings.FileUpload_OpenSaveLocationButton} allowDisabledFocus onClick={(evt) => this.onOpenSaveLocationClick(evt as React.MouseEvent)} style={{ "display": this.state.saved ? "inline-block" : "none" }} />
                    <DefaultButton text={FileUploadDialogStrings.FileUpload_CancelButton} allowDisabledFocus onClick={(evt) => this.onCancelClick(evt as React.MouseEvent)} disabled={this.state.saving} style={{ "display": this.state.saved ? "none" : "inline-block" }} />
                    <DefaultButton text={FileUploadDialogStrings.FileUpload_CloseButton} allowDisabledFocus onClick={(evt) => this.onCancelClick(evt as React.MouseEvent)} style={{ "display": this.state.saved ? "inline-block" : "none" }} />
                </div>
            </div>
        );
    }

    private onSelectLocationClick(evt: React.MouseEvent) {
        evt.preventDefault();
        return new Promise((resolve) => {
            const contentKey = "uploadDocumentFolderPickerDialog";

            SmartPortal.currentPage.setDynamicContent(contentKey, (
                <FolderPicker key={contentKey} selectionMode={FolderPickerSelectionMode.Single} dataSource={this.filePickerDataSource}
                    onSuccess={(items) => {
                        this.setState(({ filePickerResult: items, locations: items.breadCrumb.map(breadCrumbItem => { return { ...breadCrumbItem, as: "h3", onClick: null }; }) }) as IFileUploadDialogState);
                        SmartPortal.currentPage.setDynamicContent(contentKey, null);
                        resolve();
                    }}
                    onCancel={() => {
                        SmartPortal.currentPage.setDynamicContent(contentKey, null);
                        resolve();
                    }}
                />
            ));
        });
    }

    private onOpenSaveLocationClick(evt: React.MouseEvent) {
        SmartPortal.navigateTo(this.state.filePickerResult.itemWebUrl);
        if (this.props.onReturn)
            this.props.onReturn(true);
    }

    private async onUploadClick(evt: React.MouseEvent) {
        const files = [...this.state.files];
        files.forEach(file => file.state = UploadFileStates.Queued);
        this.setState({ files: files, saving: true });
        this.fileUploadService.addFiles(this.state.files);
        this.fileUploadService.startUpload({
            graphItemId: this.state.filePickerResult.graphItemId,
            graphDriveId: this.state.filePickerResult.graphDriveId,
            graphSiteId: this.state.filePickerResult.graphSiteId
        });
    }

    private onCancelClick(evt: React.MouseEvent) {
        if (this.props.onReturn)
            this.props.onReturn(false);
    }

    private onFileUploadComplete(file: IUploadFile) {
        console.log(`onFileUploadComplete: ${file.file.name}`);
        file.status = `${file.size} (${(file.progress * 100).toFixed(0)}%) &#xb7; ${FileUploadDialogStrings.FileUpload_Complete}`;
        const files = [...this.state.files];
        const index = files.findIndex(e => e.id === file.id);
        if (index !== -1) {
            files[index] = file;
            this.setState({
                files: files
            });
        }
    }
    private onFileUploadError(file: IUploadFile) {
        console.log(`onFileUploadError: ${file.file.name} - error: ${file.state} - ${file.status} - ${file.errorType} `);
        switch (file.errorType) {
            case UploadFileErrorTypes.AccessDenied:
                file.status = `${file.size} &#xb7; ${FileUploadDialogStrings.FileUpload_AccessDenied}`;
                break;
            case UploadFileErrorTypes.Conflict:
                file.status = `${file.size} &#xb7; ${FileUploadDialogStrings.FileUpload_Conflict}`;
                break;
            default:
                file.status = `${file.size} (${(file.progress * 100).toFixed(0)}%) &#xb7; ${FileUploadDialogStrings.FileUpload_Error}`;
                break;
        }
        const files = [...this.state.files];
        const index = files.findIndex(e => e.id === file.id);
        if (index !== -1) {
            files[index] = file;
            this.setState({
                files: files
            });
        }
    }

    private onFileUploadProgress(file: IUploadFile) {
        console.log(`onFileUploadProgress: ${file.file.name} - ${file.progress}`);
        file.status = `${FileUtil.formatBytes(file.file.size * file.progress, 1)} / ${file.size} (${(file.progress * 100).toFixed(0)}%) &#xb7; ${FileUploadDialogStrings.FileUpload_Progress}`;
        const files = [...this.state.files];
        const index = files.findIndex(e => e.id === file.id);
        if (index !== -1) {
            files[index] = file;
            this.setState({
                files: files
            });
        }
    }

    private onUploadComplete() {
        this.setState({
            saving: false,
            saved: true
        });
    }
}
