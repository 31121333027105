
export enum PeoplePickerItemType {
    User,
    Group,
}

export interface IPeoplePickerBaseItem {
    objectType: PeoplePickerItemType;
    displayName: string;
}

export interface IPeoplePickerItem extends IPeoplePickerBaseItem {
    objectId: string;
    userPrincipal?: string;
    mail?: string;
    mobilePhone?: string;
    jobTitle?: string;
    description?: string;
    objectData: any;
}

export interface IPeoplePickerReference extends IPeoplePickerBaseItem {
    fieldName: string;
    fieldValue: string;
}
