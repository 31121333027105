import { Customizations, createTheme, IPalette } from 'office-ui-fabric-react';
import { SmartPortal } from '../../SmartPortal';

export class ThemeUtil {
    
    public static getDefaultTheme(): Partial<IPalette> {
        return {
            black: "#1d1d1d",
            neutralDark: "#272727",
            neutralLight: "#eaeaea",
            neutralLighter: "#f4f4f4",
            neutralLighterAlt: "#f8f8f8",
            neutralPrimary: "#333333",
            neutralPrimaryAlt: "#4b4b4b",
            neutralQuaternary: "#d0d0d0",
            neutralQuaternaryAlt: "#dadada",
            neutralSecondary: "#858585",
            neutralTertiary: "#c2c2c2",
            neutralTertiaryAlt: "#c8c8c8",
            themeDark: "#00517c",
            themeDarkAlt: "#005f93",
            themeDarker: "#003b5b",
            themeLight: "#9fcce3",
            themeLighter: "#cae3f0",
            themeLighterAlt: "#f1f8fb",
            themePrimary: "#0069a3",
            themeSecondary: "#1579ae",
            themeTertiary: "#509ec8",
            white: "#ffffff"
        };
    }

    public static getTeamsDefaultTheme(): Partial<IPalette> {
        return {
            black: '#000000',
            neutralDark: '#201f1e',
            neutralLight: '#dedddc',
            neutralLighter: '#e8e7e6',
            neutralLighterAlt: '#ecebe9',
            neutralPrimary: '#323130',
            neutralPrimaryAlt: '#3b3a39',
            neutralQuaternary: '#cfcecd',
            neutralQuaternaryAlt: '#cfcecd',
            neutralSecondary: '#605e5c',
            neutralTertiary: '#a19f9d',
            neutralTertiaryAlt: '#bebdbc',
            themeDark: '#4a4c7e',
            themeDarkAlt: '#585a95',
            themeDarker: '#37385d',
            themeLight: '#c8c9e4',
            themeLighter: '#e1e1f1',
            themeLighterAlt: '#f7f7fb',
            themePrimary: '#6264a7',
            themeSecondary: '#7173b0',
            themeTertiary: '#989ac9',
            white: '#f3f2f1',
        };
    }
    public static getTeamsDarkTheme(): Partial<IPalette> {
        return {
            black: '#fdfdfd',
            neutralDark: '#fcfcfb',
            neutralLight: '#1e1e1d',
            neutralLighter: '#201f1e',
            neutralLighterAlt: '#201f1e',
            neutralPrimary: '#F3F2F1',
            neutralPrimaryAlt: '#f9f9f8',
            neutralQuaternary: '#1c1b1b',
            neutralQuaternaryAlt: '#1c1b1b',
            neutralSecondary: '#f8f7f6',
            neutralTertiary: '#f6f6f5',
            neutralTertiaryAlt: '#1a1918',
            themeDark: '#cfccca',
            themeDarkAlt: '#c6c3c0',
            themeDarker: '#dbd9d8',
            themeLight: '#393938',
            themeLighter: '#1f1e1e',
            themeLighterAlt: '#080807',
            themePrimary: '#BEBBB8',
            themeSecondary: '#a8a6a3',
            themeTertiary: '#73716f',
            white: '#201F1E',
        };
    }
    public static getTeamsHighContrastTheme(): Partial<IPalette> {
        return {
            black: '#ffff70',
            neutralDark: '#ffff3d',
            neutralLight: '#1e1e1d',
            neutralLighter: '#201f1e',
            neutralLighterAlt: '#201f1e',
            neutralPrimary: '#ffff01',
            neutralPrimaryAlt: '#e0e000',
            neutralQuaternary: '#1c1b1b',
            neutralQuaternaryAlt: '#1c1b1b',
            neutralSecondary: '#999900',
            neutralTertiary: '#f6f6f5',
            neutralTertiaryAlt: '#65615d',
            themeDark: '#cfccca',
            themeDarkAlt: '#c6c3c0',
            themeDarker: '#dbd9d8',
            themeLight: '#393938',
            themeLighter: '#1f1e1e',
            themeLighterAlt: '#080807',
            themePrimary: '#BEBBB8',
            themeSecondary: '#a8a6a3',
            themeTertiary: '#73716f',
            white: '#11100F',
        };
    }

    public static applyThemeColors() {
        if (SmartPortal.currentSession.fabricColor) {
            Customizations.applySettings({
                theme: createTheme({
                    palette: SmartPortal.currentSession.fabricColor
                })
            });
        } else {
            Customizations.applySettings({
                theme: createTheme({
                    palette:  ThemeUtil.getDefaultTheme()
                })
            });
        }
    }

    public static applyTeamsThemeColors(theme: string): Partial<IPalette> {
        let palette: Partial<IPalette>;
        switch (theme) {
            case "dark":
                palette = ThemeUtil.getTeamsDarkTheme();
                break;
            case "contrast":
                palette = ThemeUtil.getTeamsHighContrastTheme();
                break;
            default:
                palette = ThemeUtil.getTeamsDefaultTheme();
                break;
        }
        Customizations.applySettings({
            theme: createTheme({
                palette: palette
            })
        });
        return palette;
    }

    public static setTeamsThemeColors(palette: Partial<IPalette>) {
        const element = document.getElementById("sp365-MSTeamsThemeColorVariables");
        if (element) {
            element.remove();
        }

        this.addCssColorVariables("sp365-MSTeamsThemeColorVariables", palette);
    }

    public static setSharePointThemeColors() {
        if (document.getElementById("sp365-SPThemeColorVariables")) {
            return;
        }
        if ("__themeState__" in window) {
            const themeState = window["__themeState__"];

            if ('theme' in themeState && themeState.theme !== undefined) {

                const theme: Partial<IPalette> = themeState.theme as Partial<IPalette>;
                this.addCssColorVariables("sp365-SPThemeColorVariables", themeState.theme as Partial<IPalette>);
            }
        }
    }

    private static addCssColorVariables(id: string, theme: Partial<IPalette>) {

        const colors =
            ":root {\r\n" +
            "--color-black: " + theme.black + ";\r\n" +
            "--color-neutral-dark: " + theme.neutralDark + ";\r\n" +
            "--color-neutral-light: " + theme.neutralLight + ";\r\n" +
            "--color-neutral-lighter: " + theme.neutralLighter + ";\r\n" +
            "--color-neutral-lighter-alt: " + theme.neutralLighterAlt + ";\r\n" +
            "--color-neutral-primary: " + theme.neutralPrimary + ";\r\n" +
            "--color-neutral-primary-alt: " + theme.neutralPrimaryAlt + ";\r\n" +
            "--color-neutral-quaternary: " + theme.neutralQuaternary + ";\r\n" +
            "--color-neutral-quaternary-alt: " + theme.neutralQuaternaryAlt + ";\r\n" +
            "--color-neutral-secondary: " + theme.neutralSecondary + ";\r\n" +
            "--color-neutral-tertiary: " + theme.neutralTertiary + ";\r\n" +
            "--color-neutral-tertiary-alt: " + theme.neutralTertiaryAlt + ";\r\n" +
            "--color-theme-dark: " + theme.themeDark + ";\r\n" +
            "--color-theme-dark-alt: " + theme.themeDarkAlt + ";\r\n" +
            "--color-theme-darker: " + theme.themeDarker + ";\r\n" +
            "--color-theme-light: " + theme.themeLight + ";\r\n" +
            "--color-theme-lighter: " + theme.themeLighter + ";\r\n" +
            "--color-theme-lighter-alt: " + theme.themeLighterAlt + ";\r\n" +
            "--color-theme-primary: " + theme.themePrimary + ";\r\n" +
            "--color-theme-secondary: " + theme.themeSecondary + ";\r\n" +
            "--color-theme-tertiary: " + theme.themeTertiary + ";\r\n" +
            "--color-white: " + theme.white + ";\r\n" +
            "}";

        const style = document.createElement('style');
        style.id = id;
        style.innerHTML = colors;
        document.body.appendChild(style);
    }
}

