import * as React from "react";
import Truncate from 'react-truncate';
import styles from '../DiscoverTabStyle.scss';
import * as CardStream from '../../../common/cardstream';
import { Action } from "../../../common/controls/Panel/PanelActions";
import { BaseCard } from '../../../common/cardstream';

export interface IOtherCardProps {
    card: CardStream.OtherCard;
    minWidth: number;
    height: number;
    display: Action;
}

export interface IOtherCardState {
}

export class OtherCard extends React.Component<IOtherCardProps, IOtherCardState> {
    private updateCallback: () => void;
    private listenUpdates: boolean;
    private listenCard: BaseCard;

    private truncateTitleRef;

    constructor(props: IOtherCardProps, state: IOtherCardState) {
        super(props);

        this.state = {
        };

        this.updateCallback = () => {
            this.forceUpdate();
        };
    }

    public componentWillUnmount(): void {
        if(this.listenUpdates) {
            this.listenUpdates = false;
            this.props.card.removeUpdateCallback(this.updateCallback);
        }
    }

    public componentDidUpdate(prevProps) {
        if (this.props.display !== prevProps.display) {
            this.truncateTitleRef.onResize();
        }
    }

    public render(): React.ReactElement<{}> {
        if(this.listenCard !== this.props.card && this.listenCard) {
            this.listenUpdates = false;
            this.listenCard.removeUpdateCallback(this.updateCallback);
        }
        if(!this.listenUpdates) {
            this.listenUpdates = true;
            this.listenCard = this.props.card;
            this.props.card.addUpdateCallback(this.updateCallback);
        }

        return (
            <div className={styles.CardItem + " " + (this.props.display === Action.DisplaySmallCard ? styles.CardItemSmall : styles.CardItemLarge)} style={{ minWidth: this.props.minWidth, height: this.props.height }}>
                <div className={styles.CardContainer + " " + styles.OtherCard}>
                    <div className={styles.CardContent}>
                        <div className={styles.CardImage}>
                            <div className={styles.GenericImage}>
                                {/*{this.props.card.fileTypeIconClass ? <Icon iconName={this.props.card.fileTypeIconClass} className={styles.FileTypeIcon} /> : null}*/}
                            </div>
                        </div>
                        <div className={styles.CardStats}></div>
                        <div className={styles.CardHeader}>
                            <div className={styles.CardTitle}>
                                <a href={this.props.card.webUrl} target="_blank">
                                    <Truncate ref={ref => { this.truncateTitleRef = ref; return true; }} lines={2} trimWhitespace={true}>
                                        {this.props.card.title}
                                    </Truncate>
                                </a>
                            </div>
                        </div>
                        <div className={styles.CardBodySpacer}>
                            <div className={styles.CardBody}>
                                {this.props.card.type}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
