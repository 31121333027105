import * as React from 'react';
import styles from "./PageStyle.scss";
import { Persona, PersonaSize } from 'office-ui-fabric-react';
import { SmartPortal } from '../../SmartPortal';

export interface IUserActionMenuItem {
    text: string;
    link?: string;
    onClick?: (evt: React.MouseEvent) => void;
    className?: string;
}

export interface IUserActionMenuProps {
    showPersona: boolean;
    menuItems: IUserActionMenuItem[];
}

export interface IUserActionMenuState {
}

export class UserActionMenu extends React.Component<IUserActionMenuProps, IUserActionMenuState> {

    constructor(props: IUserActionMenuProps, state: IUserActionMenuState) {
        super(props);

        this.state = {
        };
    }

    public render(): React.ReactElement<IUserActionMenuProps> {
        let currentUser = SmartPortal.currentUser;
        return (
            <div className={styles.UserActionContainer}>
                <div className={styles.UserActionLayer}>
                    {this.props.showPersona ?
                        <div className={styles.UserActionPersona}>
                            <Persona text={currentUser.name} secondaryText={currentUser.mail} imageUrl="/Session/UserPhoto" size={PersonaSize.size40} imageAlt={currentUser.name} />
                        </div>
                        : null}
                    <div className={styles.UserActionList}>
                        {this.props.menuItems.map(this.renderActionItem)}
                    </div>
                </div>
            </div>
        );
    }

    private renderActionItem(item: IUserActionMenuItem, idx: number): React.ReactElement {
        return (
            <div key={"uai-" + idx} className={styles.UserActionItem + (item.className ? " " + item.className : "")} onClick={(evt) => {
                if (item.onClick) item.onClick(evt);
            }}>
                {item.link ?
                    <a href={item.link} className={styles.ActionItemLink}>{item.text}</a> :
                    <span>{item.text}</span>
                }
            </div>
        );
    }

}

