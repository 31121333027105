import * as React from 'react';
import { SmartPortal } from '../../../../SmartPortal';
import { PrimaryButton, MessageBar, MessageBarType, MessageBarButton, Label, TextField } from 'office-ui-fabric-react';
import { Action } from "../../../controls/Panel";
import { Role } from '../../../business/RoleService';
import { SiteDetailsForm, SiteDetailsEditMode, ISiteDetailsFormData } from '../../../../components/provisioning/controls/SiteDetailsForm';
import { ISiteInfo, SiteStatus, SiteProvisioningType, ISiteInfoChangeBase, ISiteInfoChangeTeam, ISiteInfoChangeSite, IApproval } from '../../../models/SiteInfo';
import { ServiceLoader } from '../../../business/ServiceLoader';
import { RequestService } from '../../../business/RequestService';
import { GraphBatchService } from '../../../business/GraphBatchService';
import { Dialog } from '../../../controls/Dialog/Dialog';
import moment from 'moment';
import strings from "../loc/DirectoryStrings"
import styles from "../DirectoryStyle.scss";
import dialogStyles from "../../../controls/Dialog/DialogStyle.scss";

export interface IDirectoryItemProps {
    item: ISiteInfo;
    onClose?: () => void;
    onDelete: () => Promise<void>;
    onUpdate: () => Promise<void>;
    onArchive: (archive: boolean) => Promise<void>;
    onApprove?: (approve: boolean, approvalTaskId: string, comment: string) => Promise<void>;
    onRetry?: () => Promise<void>;
}

export interface IDirectoryItemState {
    isEditMode: boolean;
    canApprove: boolean;
    siteDetails: ISiteDetailsFormData;
    isFormValid?: boolean;
    formValidationMessage?: string;
}

export class DirectoryItem extends React.Component<IDirectoryItemProps, IDirectoryItemState> {
    private formRef: SiteDetailsForm;
    private canApproveChecked = false;
    constructor(props: IDirectoryItemProps, state: IDirectoryItemState) {
        super(props);

        this.state = {
            isEditMode: false,
            canApprove: false,
            siteDetails: DirectoryItem.getFromSiteInfo(props.item)
        };
    }

    componentDidMount(){
        if (!this.canApproveChecked) {
            this.canApproveChecked = true;
            this.canApprove().then(canApprove => {
                this.setState({
                    canApprove: canApprove
                });
            });
        }
    }

    public static getFromSiteInfo(siteInfo: ISiteInfo): ISiteDetailsFormData {
        return {
            title: siteInfo.title,
            nickname: siteInfo.additionalData.Nickname,
            siteUrl: siteInfo.webUrl,
            description: siteInfo.description,
            siteType: siteInfo.provisionTemplate.type,
            siteTemplate: siteInfo.provisionTemplate,
            primaryOwner: siteInfo.primaryOwner,
            primaryOwnerAccount: siteInfo.primaryOwnerAccount,
            secondaryOwner: siteInfo.secondaryOwner,
            secondaryOwnerAccount: siteInfo.secondaryOwnerAccount,
            visibility: siteInfo.additionalData.Visibility,
            sharingCapability: siteInfo.additionalData.SharingCapability,
            guestsAccess: siteInfo.additionalData.GuestsAccess,
            language: siteInfo.additionalData.Language,
            timeZoneId: siteInfo.additionalData.TimeZoneId,
            classification: siteInfo.additionalData.Classification,
            hubSiteId: siteInfo.additionalData.HubSiteId,
            hubSiteTitle: siteInfo.additionalData.HubSiteTitle,
            registerHubSite: siteInfo.additionalData.RegisterHubSite,
            lifeCycle: siteInfo.lifeCycle,
            siteStatus: siteInfo.status,
            changeItem: siteInfo.provisionChange,
            members: siteInfo.additionalData?.Members ?? [],
            visitors: siteInfo.additionalData?.Visitors ?? []
        };
    }

    private getAvailablePanelActions(siteInfo: ISiteInfo, defaultActions?: Action[]): Action[] {
        if (siteInfo.status === SiteStatus.WaitingForApproval || siteInfo.status === SiteStatus.WaitingForChangeApproval) {
            let actions: Action[] = [Action.Close];
            if (defaultActions)
                actions = defaultActions;
            if (this.state.canApprove)
                actions.push(Action.Approve, Action.Reject);
            return actions;
        }
        return DirectoryItem.getAvailablePanelActions(siteInfo, defaultActions);
    }

    public static getAvailablePanelActions(siteInfo: ISiteInfo, defaultActions?: Action[]): Action[] {
        let actions: Action[] = [Action.Close];
        if (defaultActions)
            actions = defaultActions;
        switch (siteInfo.status) {
           case SiteStatus.Active:{
                if(this.isOwner(siteInfo)) actions.push(Action.Delete, Action.Edit, Action.Archive);
                break;
           }
           case SiteStatus.Archived:{
                if(this.isOwner(siteInfo)) actions.push(Action.Delete, Action.Unarchive);
                break;
           }
           case SiteStatus.ProvisionError:
           case SiteStatus.ChangeError:
           case SiteStatus.WebHookError:
           case SiteStatus.WebHookApprovalError:{
                if(this.isOwner(siteInfo)) actions.push(Action.Delete, Action.Retry);
                break;
           }
        }
        return actions;
    }

    public render(): React.ReactElement<IDirectoryItemProps> {
        return (
            <Dialog className={styles.DirectoryItemDialog + " " + styles.DirectoryItemPanel} headerText={this.props.item.title}  actions={this.getAvailablePanelActions(this.props.item)} onAction={(action) => {
                if (action === Action.Close) {
                    this.props.onClose();
                    return Promise.resolve();
                }
                if (action === Action.Edit) {
                    return new Promise((resolve) => {
                        this.setState({
                            isEditMode: true
                        }, resolve);
                    });
                }
                if (action === Action.Save) {
                    if (!this.state.isFormValid)
                        return Promise.reject({ Message: this.state.formValidationMessage ? this.state.formValidationMessage : strings.Directory_Item_FormNotValidMessage });
                    return DirectoryItem.saveItemChanges(this.props.item, this.state.siteDetails).then(() => {
                        this.setState({
                            isEditMode: false,
                        });
                        if (this.props.onUpdate)
                            return this.props.onUpdate();
                    });
                }
                if (action === Action.Delete) {
                    return DirectoryItem.onDelete(this.props.item, this.props.onDelete);
                }
                if (action === Action.Archive) {
                    return DirectoryItem.onArchive(this.props.onArchive);
                }
                if (action === Action.Unarchive) {
                    return DirectoryItem.onUnarchive(this.props.onArchive);
                }
                if (action === Action.Approve) {
                    return this.props.onApprove ? this.props.onApprove(true, this.getApprovalTask(), "") : Promise.resolve();
                }
                if (action === Action.Reject) {
                    return this.reject(strings.Directory_Item_ConfirmRejectTitle, strings.Directory_Item_ConfirmRejectButton).then(result => { return result.confirmed ? (this.props.onApprove ? this.props.onApprove(false, this.getApprovalTask(), result.comment) : Promise.resolve()) : Promise.resolve() });
                }
                if (action === Action.Retry) {
                    return this.props.onRetry();
                }
                return Promise.resolve();
            }}>
                {DirectoryItem.getMessages(this.props.item, this.props.onUpdate)}
                <SiteDetailsForm
                    ref={(ref) => this.formRef = ref}
                    formData={this.state.siteDetails}
                    editMode={this.state.isEditMode ? SiteDetailsEditMode.EditForm : SiteDetailsEditMode.ViewForm}
                    onUpdate={(data: ISiteDetailsFormData, valid: boolean, validationMessage: string) => {
                        this.setState({ siteDetails: data, isFormValid: valid, formValidationMessage: validationMessage });
                    }}
                    siteId={this.props.item.siteId}
                />
            </Dialog>
        );
    }

    public static onDelete(item: ISiteInfo, onDelete: () => Promise<void>): Promise<void> {
        if (item.status === SiteStatus.Active || item.status === SiteStatus.Archived)
            return DirectoryItem.confirm(strings.Directory_Item_ConfirmDeletionTitle, strings.Directory_Item_ConfirmDeletionSubject, strings.Directory_Item_ConfirmDeletionButton).then(confirmed => { return confirmed ? onDelete() : Promise.resolve() });
        return onDelete();
    }

    public static onArchive(onArchive: (archive: boolean) => Promise<void>): Promise<void> {
        return DirectoryItem.confirm(strings.Directory_Item_ConfirmArchivingTitle, strings.Directory_Item_ConfirmArchivingnSubject, strings.Directory_Item_ConfirmArchivingButton).then(confirmed => { return confirmed ? onArchive(true) : Promise.resolve() });
    }

    public static onUnarchive(onArchive: (archive: boolean) => Promise<void>): Promise<void> {
        return DirectoryItem.confirm(strings.Directory_Item_ConfirmUnarchivingTitle, strings.Directory_Item_ConfirmUnarchivingnSubject, strings.Directory_Item_ConfirmUnarchivingButton).then(confirmed => { return confirmed ? onArchive(false) : Promise.resolve() });
    }

    public static getMessages(item: ISiteInfo, onUpdate: () => Promise<void>) {
        return item.status === SiteStatus.Active && DirectoryItem.isOwner(item) && item.lifeCycle && item.lifeCycle.firstArchivingReminderDate && moment().isAfter(moment(item.lifeCycle.firstArchivingReminderDate)) ?
            <MessageBar messageBarType={MessageBarType.warning} isMultiline={true} actions={<div><MessageBarButton onClick={() => { DirectoryItem.postPoneLifeCycle(item, onUpdate); }}>{strings.Directory_Item_ButtonPostpone}</MessageBarButton></div>}>
                <span>{DirectoryItem.replaceLifeWarningMessagePlaceHolders(item, strings.Directory_Item_WarningMessageArchiving, "Archiving")}</span> </MessageBar>
            : item.status === SiteStatus.Archived && DirectoryItem.isOwner(item) && item.lifeCycle && item.lifeCycle.firstDeletionReminderDate && moment().isAfter(moment(item.lifeCycle.firstDeletionReminderDate))
                ? <MessageBar messageBarType={MessageBarType.warning} isMultiline={true} actions={<div><MessageBarButton onClick={() => { DirectoryItem.postPoneLifeCycle(item, onUpdate); }}>{strings.Directory_Item_ButtonPostpone}</MessageBarButton></div>}>
                  <span>{DirectoryItem.replaceLifeWarningMessagePlaceHolders(item, strings.Directory_Item_WarningMessageDeletion, "Deletion")}</span> </MessageBar>
            : null;
    }

    private static replaceLifeWarningMessagePlaceHolders(item: ISiteInfo, message: string, type: "Archiving" | "Deletion"): string {
        const postPoneDate = moment(type === "Archiving" ? item.lifeCycle.archivingDatePostponed : item.lifeCycle.deletionDatePostponed).startOf("day");
        const lifeCycleDate = moment(type === "Archiving" ? item.lifeCycle.archivingDate : item.lifeCycle.deletionDate).startOf("day");
        const today = moment().startOf("day");
        message = message.replace("{{untilAction}}",  today.to(lifeCycleDate, false));  //lifeCycleDate.diff(today, "days").toString());
        message = message.replace("{{postponeBy}}", lifeCycleDate.to(postPoneDate, true));

        return message;
    }

    private static confirm(title: string, subject: string, actionName: string): Promise<boolean> {
        return new Promise((resolve) => {
            const contentKey = "confirmDialog";
            const portalPage = SmartPortal.currentPage;

            portalPage.setDynamicContent(contentKey, (
                <Dialog headerText={title} key={contentKey} className={dialogStyles.ConfirmDialog} actions={[Action.Close]} onAction={(action) => {
                    if (action === Action.Close) {
                        resolve(false);
                        portalPage.setDynamicContent(contentKey, null);
                    }
                    return Promise.resolve();
                }}>
                    <div className={styles.DirectoryItemActionConfirmDialogContent}>
                        <p dangerouslySetInnerHTML={{ __html: subject }}></p>
                        <div className={styles.DirectoryItemActionConfirmDialogActions}>
                            <PrimaryButton text={actionName} allowDisabledFocus onClick={() => {
                                resolve(true);
                                portalPage.setDynamicContent(contentKey, null);
                            }} />
                            <PrimaryButton text={strings.Directory_Item_ConfirmCancelButton} allowDisabledFocus onClick={() => {
                                resolve(false);
                                portalPage.setDynamicContent(contentKey, null);
                            }} />
                        </div>
                    </div>
                </Dialog>
            ));
        });
    }

    private reject(title: string, actionName: string): Promise<{confirmed: boolean, comment: string}> {
        return new Promise((resolve) => {
            const contentKey = "rejectDialog";
            const portalPage = SmartPortal.currentPage;
            let comment = "";
            portalPage.setDynamicContent(contentKey, (
                <Dialog headerText={title} key={contentKey} className={dialogStyles.ConfirmDialog} actions={[Action.Close]} onAction={(action) => {
                    if (action === Action.Close) {
                        resolve({ confirmed: false, comment: "" });
                        portalPage.setDynamicContent(contentKey, null);
                    }
                    return Promise.resolve();
                }}>
                    <div className={styles.DirectoryItemActionConfirmDialogContent}>
                        <Label required= {true}>{strings.Directory_Item_ConfirmRejectCommentFieldLabel}</Label>
                        <TextField multiline={true} resizable={false} id="rejectDialog_comment" onChange={(item,text) =>{comment = text}} ></TextField>
                        <div className={styles.DirectoryItemActionConfirmDialogActions}>
                            <PrimaryButton text={actionName} allowDisabledFocus onClick={() => {
                                const commentField: HTMLTextAreaElement = document.getElementById("rejectDialog_comment") as HTMLTextAreaElement;
                                commentField.required = true;
                                const valid = commentField.checkValidity();
                                commentField.reportValidity();
                                commentField.required = false;
                                if(valid){
                                    resolve({confirmed: true, comment: comment});
                                    portalPage.setDynamicContent(contentKey, null);
                                }
                            }} />
                            <PrimaryButton text={strings.Directory_Item_ConfirmCancelButton} allowDisabledFocus onClick={() => {
                                resolve({confirmed: false, comment: ""});
                                portalPage.setDynamicContent(contentKey, null);
                            }} />
                        </div>
                    </div>
                </Dialog>
            ));
        });
    }

    private static isOwner(siteInfo: ISiteInfo): boolean {
        return SmartPortal.isUserInRole(Role.Admin) ||
            siteInfo.createdById === SmartPortal.currentUserId ||
            siteInfo.primaryOwnerAccount.id === SmartPortal.currentUserId ||
            (siteInfo.secondaryOwnerAccount !== null && siteInfo.secondaryOwnerAccount.id === SmartPortal.currentUserId);
    }

    private canApprove(): Promise<boolean> {

        if (this.props.item.status !== SiteStatus.WaitingForApproval && this.props.item.status !== SiteStatus.WaitingForChangeApproval)
            return Promise.resolve(false);

        let approval: IApproval = null;
        if (this.props.item.status === SiteStatus.WaitingForApproval)
            approval = this.props.item.approval;
        else if (this.props.item.status === SiteStatus.WaitingForChangeApproval)
            approval = this.props.item.provisionChange.approval;
        if (!approval)
            return Promise.resolve(false);

        return new Promise((resolve)=>{
            const groups2Check: string[] = [];           
            
            if (approval.approvalTasks && approval.approvalTasks.length > 0){
                approval.approvalTasks.forEach( (item) => {
                    if (item.approver && item.approver.id === SmartPortal.currentUserId){
                        resolve(true);
                        return;
                    }
                    if(item.approverGroupId)
                        groups2Check.push(item.approverGroupId);
                });
            }

            if(groups2Check.length === 0 ){
                resolve(false);
                return;
            }

            ServiceLoader.GetService(GraphBatchService).addGraphRequest({
                method: "POST",
                version: "v1.0",
                path: "/me/checkMemberGroups",
                data: {groupIds:  groups2Check.filter((n, i) => groups2Check.indexOf(n) === i)}
            }).then((rsp) => {    
                if(rsp.value.length > 0){
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            }); 
        });
    }
    
    private getApprovalTask(): string {

        let approval: IApproval = null;
        if (this.props.item.status === SiteStatus.WaitingForApproval)
            approval = this.props.item.approval;
        else if (this.props.item.status === SiteStatus.WaitingForChangeApproval)
            approval = this.props.item.provisionChange.approval;
        if (!approval)
            return "";
        
        if (approval.approvalTasks && approval.approvalTasks.length > 0) {
            return approval.approvalTasks[0].id; // we currently support only a single approval task
        }

        return "";
    }

    private static postPoneLifeCycle(item: ISiteInfo, onUpdate: () => Promise<void>): Promise<void> {
        const requestService = ServiceLoader.GetService(RequestService);
        return requestService.requestAadService({
            method: "POST",
            url: `/api/directory/items/${item.internalId}/lifecycle/postpone`
        }).then(requestService.parseApiResponse).then(() => {
            onUpdate();
        });
    }

    public static saveItemChanges(siteInfo: ISiteInfo, formData: ISiteDetailsFormData): Promise<void> {
        const siteInfoChange: ISiteInfoChangeBase = {
            title: formData.title,
            description: formData.description,
            primaryOwner: formData.primaryOwner,
            secondaryOwner: formData.secondaryOwner,
            timeZoneId: formData.timeZoneId,
            sharingCapability: formData.sharingCapability,
            classification: formData.classification,
            hubSiteId: formData.hubSiteId,
            hubSiteTitle: formData.hubSiteTitle,
            registerHubSite: formData.registerHubSite,
            directoryItemId: siteInfo.internalId,
            visibility: formData.visibility
        };

        let changeApiUrl: string = "";
        switch (siteInfo.provisionTemplate.type) {
            case SiteProvisioningType.MSTeams:
            {
                const teamsChangeObj: ISiteInfoChangeTeam = siteInfoChange as ISiteInfoChangeTeam;
                changeApiUrl = "/api/directory/teams/" + siteInfo.internalId + "/changes";
                teamsChangeObj.guestsAccess = formData.guestsAccess;
                break;
            }
            case SiteProvisioningType.SPOSite:
            {
                const siteChangeObj: ISiteInfoChangeSite = siteInfoChange as ISiteInfoChangeSite;
                changeApiUrl = "/api/directory/sites/" + siteInfo.internalId + "/changes";
                siteChangeObj.classification = formData.classification;
                break;
            }
        }

        const reqsvc = ServiceLoader.GetService(RequestService);

        return reqsvc.requestAadService({
            method: "POST",
            url: changeApiUrl,
            header: new Map<string, string>([
                ["Content-Type", "application/json"]
            ]),
            data: JSON.stringify(siteInfoChange)
        }).then(reqsvc.parseApiResponse).then((changeRsp: ISiteInfoChangeBase) => {

        });
    }
}
