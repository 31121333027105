import { ITag, TagPicker } from 'office-ui-fabric-react';
import * as React from 'react';
import { IPeoplePickerItem, IPeoplePickerBaseItem, IPeoplePickerReference, PeoplePickerItemType } from './model/PeoplePickerItem';
import { GraphPickerDataSource } from './datasource/GraphPickerDataSource';
import { PickerDataSource, IPickerDataSourceProps } from './datasource/PickerDataSource';
import styles from "./PeoplePickerStyle.scss";
import PeoplePickerUserCreationCallout from './PeoplePickerUserCreationCallout';
import strings from "./loc/PeoplePickerStrings"

export interface IPeoplePickerProps {
    inputId?: string;
    onChange?: (items: IPeoplePickerItem[]) => void;
    defaultItems?: IPeoplePickerBaseItem[];
    datasource?: PickerDataSource<IPickerDataSourceProps>;
    itemLimit?: number;
    disabled?: boolean;
    enableUserCreation?: boolean;
    uniqueItemsOnly?: boolean;
}

export interface IPeoplePickerState {
    selectedItems: IPeoplePickerItem[];
    showUserCreationCallout: boolean;
}

interface IPeoplePickerTag extends ITag {
    _refItem: IPeoplePickerItem;
}

export default class PeoplePicker extends React.Component<IPeoplePickerProps, IPeoplePickerState> {
    private datasource: PickerDataSource<IPickerDataSourceProps>;
    _menuButtonElement: HTMLElement; 

    constructor(props: IPeoplePickerProps) {
        super(props);

        this.datasource = this.props.datasource || new GraphPickerDataSource();

        this.state = {
            selectedItems: this.props.defaultItems as IPeoplePickerItem[] || [],
            showUserCreationCallout: false,
        }
    }

    public componentDidMount() {
        this.parseValues(this.state.selectedItems).then((resolved) => {
            if (resolved)
                this.forceUpdate();
        });
    }

    private parseValues(items: IPeoplePickerBaseItem[]): Promise<boolean> {
        let promises: Promise<void>[] = [];

        for (let i = 0; i < items.length; i++) {
            let itemRef = items[i] as IPeoplePickerReference;
            if (itemRef.fieldName && itemRef.fieldValue) {
                ((i) => {
                    promises.push(this.datasource.getPickerItemByField(itemRef.objectType, itemRef.fieldName, itemRef.fieldValue).then((item) => {
                        if (item)
                            items[i] = item;
                    }));
                })(i);
            }
        }

        if (promises.length)
            return Promise.all(promises).then(() => true);
        else
            return Promise.resolve(false);
    }

    public getItems(): IPeoplePickerItem[] {
        return this.state.selectedItems;
    }

    public render() {
        return (
            <div className={styles.PeoplePicker} >
                <TagPicker
                    inputProps={{
                        id: this.props.inputId
                    }}
                    disabled={this.props.disabled}
                    selectedItems={this.getCurrentTagItems()}
                    onChange={(items: IPeoplePickerTag[]) => this.onTagItemsChange(items)}
                    onResolveSuggestions={(filter, selectedItems) => this.onResolveSuggestions(filter)}
                    getTextFromItem={(item: IPeoplePickerTag) => item.name}
                    itemLimit={this.props.itemLimit || 1}
                />
                 {
                    this.props.enableUserCreation ?<a ref={ (menuButton) => this._menuButtonElement = menuButton } className={styles.PeoplePickerCreateeNewUserLink} href="#" onClick={(e)=> { e.preventDefault(); this.setState((state,props)=>({showUserCreationCallout: !state.showUserCreationCallout}))}}>{strings.PeoplePicker_UserCreation_Link}</a> : null
                }
                 {
                    this.state.showUserCreationCallout ? 
                       <PeoplePickerUserCreationCallout onUserCreated={(user)=>{ this.onTagItemsChange([{key: user.id, name: user.displayName, _refItem: { objectId: user.id, displayName: user.displayName, userPrincipal: user.userPrincipalName, objectData: {sp365CreatedUserPassword: user.password}, objectType: PeoplePickerItemType.User}}]); this.setState((prevState)=>({showUserCreationCallout: !prevState.showUserCreationCallout})) }} onCalloutClose={()=> { this.setState((prevState)=>({showUserCreationCallout: !prevState.showUserCreationCallout}))}} targetEl={this._menuButtonElement}></PeoplePickerUserCreationCallout>
                    : null
                }
            </div>
        );
    }

    private getCurrentTagItems(): IPeoplePickerTag[] {
        return this.state.selectedItems.map((item) => {
            return {
                key: item.objectId || item.displayName,
                name: item.displayName,
                _refItem: item
            };
        });
    }

    private onTagItemsChange(items: IPeoplePickerTag[]) {
        var peoplePickerItems: IPeoplePickerTag[] = [];

        if(this.props.uniqueItemsOnly === true){
            items.forEach(item =>{
                if(peoplePickerItems.filter((distinctItem)=> distinctItem._refItem.objectId === item._refItem.objectId).length === 0){
                    peoplePickerItems.push(item);
                }
            });
        }else
        {
            peoplePickerItems = [...items];
        }
       
        this.setState({
            selectedItems: peoplePickerItems.map((item) => item._refItem)
        }, () => {
            if (this.props.onChange)
                this.props.onChange(this.state.selectedItems);
        });
    }

    private onResolveSuggestions(term: string): Promise<IPeoplePickerTag[]> {
        let searchPromise = this.datasource.getSuggestions(term);
        if (!searchPromise)
            return null;

        return searchPromise.then((items) => {
            if(!items)
                return [];
            else
                return items.map((item) => {
                    return {
                        key: item.objectId,
                        name: item.displayName,
                        _refItem: item
                    };
                });
        });
    }
    
}
