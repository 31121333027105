import * as React from 'react';
import styles from "./PageStyle.scss";
import { Icon, TooltipHost, DirectionalHint } from 'office-ui-fabric-react';
import { SmartPortal } from '../../SmartPortal';
import { Role } from '../../common/business/RoleService';
import { ServiceLoader } from '../../common/business/ServiceLoader';
import { PromptService } from '../../common/business/PromptService';

export interface ISmartPortalNavItem {
    iconName: string;
    className?: string;
    navLabel: string;
    onClick?: (evt: React.MouseEvent) => boolean | Promise<boolean>;
    navLink?: string;
    openInTab?: boolean;
    unselectable?: boolean;
    hidden?: boolean;
    spacer?: boolean;
}

export type PortalLeftBarNavDict = { [navKey: string]: ISmartPortalNavItem };

export interface IPortalLeftBarProps {
    activeNavKey?: string;
    navItems?: PortalLeftBarNavDict;
}

export interface IPortalLeftBarState {
    activeNavKey: string;
    isNavExpanded: boolean;
}

export class PortalLeftBar extends React.Component<IPortalLeftBarProps, IPortalLeftBarState> {
    private activeKeyHistory: string[];

    constructor(props: IPortalLeftBarProps, state: IPortalLeftBarState) {
        super(props);

        this.activeKeyHistory = [];
        this.state = {
            activeNavKey: this.props.activeNavKey,
            isNavExpanded: false
        };
    }

    public componentDidMount(): void {

    }

    public setActiveNavKey(navKey: string, history: boolean = true) {
        if (navKey === this.state.activeNavKey)
            return;
        if(this.state.activeNavKey && history)
            this.activeKeyHistory.push(this.state.activeNavKey);

        this.setState({
            activeNavKey: navKey
        });
    }

    public setPreviousNavKey() {
        let prevNavKey = this.activeKeyHistory.pop();
        this.setActiveNavKey(prevNavKey, false);
    }

    public setNavExpanded(expanded?: boolean) {
        if (typeof expanded !== "boolean") {
            expanded = !this.state.isNavExpanded;
        }
        if (expanded) {
            SmartPortal.currentPage.addPageOverlay({
                overlayName: "NavigationMenu",
                contentSelector: [
                    "." + styles.PortalLeftBar,
                    "." + styles.PortalNavButton + "." + styles.PortalNavToggle
                ],
                dismissCallback: () => {
                    this.setState({
                        isNavExpanded: false
                    });
                    return true;
                }
            });
        }

        this.setState({
            isNavExpanded: expanded
        });
    }

    public render(): React.ReactElement<IPortalLeftBarProps> {
        return (
            <div className={styles.PortalLeftBar + (this.state.isNavExpanded ? (" " + styles.PortalBarExpanded) : "")}>
                <div className={styles.PortalLeftNavBar + (this.state.isNavExpanded ? (" " + styles.PortalNavExpanded) : "")}>
                    {this.props.navItems ? Object.keys(this.props.navItems).map((navKey) => {
                        let navItem = this.props.navItems[navKey];
                        return navItem.hidden ? null : this.renderNavButton(navKey, navItem);
                    }) : null}
                </div>
            </div>
        );
    }

    private renderNavButton(navKey: string, navItem: ISmartPortalNavItem): React.ReactElement {
        if (navItem.navLink) {           
            if (navItem.navLink.startsWith("/Admin") || navItem.navLink.startsWith("/admin")) {
                if (!SmartPortal.isUserInRole(Role.Admin))
                    return null;
            }
        }

        let isActive = (this.state.activeNavKey === navKey);
        let content: React.ReactNode[] = [];
        if(!navItem.spacer) {
            let navIconEl = (
                <div className={styles.PortalNavIcon} onClick={(evt) => this.onNavItemClick(evt, navKey)}>
                    <Icon iconName={navItem.iconName} className={styles.NavIcon} />
                </div>
            );

            content.push((
                <div key="button" className={styles.PortalNavButton}>
                    <TooltipHost content={this.state.isNavExpanded || isActive ? null : navItem.navLabel} className={styles.PortalNavTooltip} calloutProps={{
                        directionalHint: DirectionalHint.rightCenter
                    }}>
                        {navIconEl}
                    </TooltipHost>
                </div>
            ));

            if(this.state.isNavExpanded) {
                content.push((
                    <div key="label" className={styles.PortalNavLabel} onClick={(evt) => this.onNavItemClick(evt, navKey)}>
                        {navItem.navLabel}
                    </div>
                ));
            }
        }

        return (
            <div key={navKey} data-sp365NavKey={navKey} className={styles.PortalNavItem + (isActive ? (" " + styles.PortalNavActive) : "") + (navItem.className ? " " + navItem.className : "")}>
                {content}
            </div>
        );
    }

    private onNavItemClick(evt: React.MouseEvent, navKey: string) {
        let navItem = this.props.navItems[navKey];
        if (!navItem)
            return;

        let clickPromise: Promise<boolean>;
        if(navItem.onClick) {
            let onClickRes = navItem.onClick(evt);
            if(typeof onClickRes === "boolean")
                clickPromise = Promise.resolve(onClickRes);
            else if(onClickRes && typeof onClickRes.then === "function")
                clickPromise = onClickRes;
            else
                clickPromise = Promise.resolve(true);
        }
        else
            clickPromise = Promise.resolve(true);

        clickPromise.catch(() => false).then((onClickRes) => {
            if (!onClickRes)
                return;

            this.setState({
                isNavExpanded: false
            });

            if (navItem.navLink && navItem.openInTab)
                SmartPortal.navigateTo(navItem.navLink, true);
            else if (navItem.navLink)
                SmartPortal.navigateTo(navItem.navLink);

            if (!ServiceLoader.GetService(PromptService).isNavigationBlocked())
                if (!navItem.unselectable)
                    this.setActiveNavKey(navKey);
        });
    }
}
