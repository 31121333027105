import React from 'react';
import { SmartPortal } from '../../SmartPortal';
import PageStyles from '../basepage/PageStyle.scss';
import styles from './NavigationStyle.scss';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { PromiseDfd } from '../../common/utils/PromiseDfd';
import { AppPolicy } from '../../common/models/AppPolicy';

const Navigation = React.lazy(() => import(/* webpackChunkName: "Navigation" */ './Navigation'));

export class NavigationLoader {
    private static NavIsVisible = false;

    public static OpenNavHandler(): boolean {
        if (!SmartPortal.currentPage)
            return false;
        if (NavigationLoader.NavIsVisible)
            return false;

        NavigationLoader.NavIsVisible = true;
        let readyDfd = new PromiseDfd<void>();

        SmartPortal.currentPage.setLeftPanel({
            content: (
                SmartPortal.checkAppPolicy(AppPolicy.FeatureNavigation) ?
                    <React.Suspense fallback={() => (
                        <Spinner title="Loading SmartPort365" size={SpinnerSize.large} />
                    )}>
                        <Navigation ref={(ref) => {
                            readyDfd.resolve();
                        }} />
                    </React.Suspense> : null
            ),
            renderPromise: readyDfd.promise,
            onDispose: () => {
                NavigationLoader.NavIsVisible = false;
                SmartPortal.currentPage.setPreviousNavKey();
            },
            selectors: ["." + PageStyles.PortalNavActive, "." + styles.NavigationContextualMenu]
        });
        return true;
    }

    public static OpenRightNavHandler(): boolean {
        if (!SmartPortal.currentPage)
            return false;
        if (NavigationLoader.NavIsVisible)
            return false;

        NavigationLoader.NavIsVisible = true;
        let readyDfd = new PromiseDfd<void>();

        SmartPortal.currentPage.setRightPanel({
            content: (
                SmartPortal.checkAppPolicy(AppPolicy.FeatureNavigation) ?
                    <React.Suspense fallback={() => (
                        <Spinner title="Loading SmartPort365" size={SpinnerSize.large} />
                    )}>
                        <Navigation ref={(ref) => {
                            readyDfd.resolve();
                        }} />
                    </React.Suspense> : null
            ),
            renderPromise: readyDfd.promise,
            onDispose: () => {
                NavigationLoader.NavIsVisible = false;
                SmartPortal.currentPage.setPreviousNavKey();
            },
            selectors: ["." + PageStyles.PortalNavActive, "." + styles.NavigationContextualMenu]
        });
        return true;
    }
}