import * as React from 'react';
import { Prompt } from "react-router-dom";
import styles from "./PanelStyle.scss";
import PanelStrings from "./loc/PanelStrings";
import { Icon, IconButton, IButtonProps, Spinner, SpinnerSize, IContextualMenuItem, TooltipHost, DirectionalHint, SearchBox, ISearchBox, Button, PrimaryButton, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { IErrorList, IErrorInfo } from '../../models/SessionInfo';
import { ServiceLoader } from '../../business/ServiceLoader';
import { PromptService } from '../../business/PromptService';
import { getNewGuid } from '../../utils/GuidHandler';

export enum Action {
    None = 0,

    ViewAll = 16,
    ViewMy = 17,
    ViewRecent = 18,
    ViewTrending = 19,

    SortAscending = 32,
    SortDescending = 33,
    SortNewest = 34,
    SortOldest = 35,
    SortManual = 36,

    DisplayLargeCard = 48,
    DisplaySmallCard = 49,
    DisplayList = 50,

    StatusAll = 64,
    StatusActive = 65,
    StatusArchived = 66,
    StatusApproval = 67,
    StatusInProgress = 68,
    StatusFailed = 69,

    TypeAll = 80,
    TypeSharePoint = 81,
    TypeMSTeams = 82,
    TypeFavorites = 83,

    ShowMore = 96,
    Edit = 97,
    Save = 98,
    Reset = 99,
    Delete = 100,
    Search = 101,
    Archive = 102,
    Unarchive = 103,
    Approve = 104,
    Reject = 105,
    Retry = 106,
    Close = 107,
    Expand = 108,
    Export = 109
}

enum ActionType {
    View,
    Sort,
    Display,
    Edit,
    Reset,
    Delete,
    Search,
    Archive,
    Unarchive,
    Approve,
    Reject,
    Status,
    Retry,
    Close,
    Expand,
    Type,
    Export
}

export enum ActionExecution {
    Nothing,
    Editing,
    Saving,
    Searching,
    Archiving,
    Unarchiving,
    Deleting,
    Approving,
    Rejecting,
    Retrying,
    Exporting
}

export interface IPanelActionsProps {
    infoTooltip?: string;
    initialExecutionState?: ActionExecution;
    actions?: Action[];    
    currentActions?: Action[];
    customActions?: React.ReactNodeArray | React.ReactNode;
    search?: string;
    onAction?: (action: Action, value?: any) => Promise<void | boolean>;
    actionTexts?: { [TAction in Action]?: string };
    errors?: IErrorInfo[];

    contextualMenuClassName?: string;
}

export interface IPanelActionsState {
    execution: ActionExecution;
    search: string;
    error: IErrorList;
}

export class PanelActions extends React.Component<IPanelActionsProps, IPanelActionsState> {
    private _searchBoxRef = React.createRef<ISearchBox>();
    private _key: string;

    public static ViewActions = [
        Action.ViewAll,
        Action.ViewMy,
        Action.ViewRecent,
        Action.ViewTrending
    ];

    public static SortActions = [
        Action.SortAscending,
        Action.SortDescending,
        Action.SortNewest,
        Action.SortOldest,
        Action.SortManual
    ];

    public static DisplayActions = [
        Action.DisplayLargeCard,
        Action.DisplaySmallCard,
        Action.DisplayList
    ];

    public static StatusActions = [
        Action.StatusAll,
        Action.StatusActive,
        Action.StatusArchived,
        Action.StatusApproval,
        Action.StatusInProgress,
        Action.StatusFailed
    ];

    public static TypeActions = [
        Action.TypeAll,
        Action.TypeSharePoint,
        Action.TypeMSTeams,
        Action.TypeFavorites
    ];
    
    constructor(props: IPanelActionsProps, state: IPanelActionsState) {
        super(props);
        this._key = getNewGuid();
        this.state = {
            execution: this.props.search ? ActionExecution.Searching : (this.props.initialExecutionState ? this.props.initialExecutionState : ActionExecution.Nothing),
            search: this.props.search ? this.props.search :  "",
            error: null
        };
    }

    componentWillUnmount(): void { ServiceLoader.GetService(PromptService).releaseNavigation(this._key); }

    public render() {
        return ([
            //<Prompt when={this.state.execution !== ActionExecution.Nothing} message={() =>  "Are you sure to leave - data you have entered may not be saved?"} />,
            <div className={styles.PanelActions}>
                {this.containsActions(ActionType.Search) && this.state.execution === ActionExecution.Searching ? <div className={styles.PanelSearch}><SearchBox componentRef={this._searchBoxRef} placeholder={PanelStrings.Action_Search} value={this.state.search}
                    onChange={(evt, value) => {
                        this.setState({ search: value});
                        if (this.props.onAction)
                            this.props.onAction(Action.Search, value);
                    }} onClear={() => {
                        this.setState({ search: "", execution: ActionExecution.Nothing });
                    }} onEscape={() => {
                        this.setState({ search: "", execution: ActionExecution.Nothing });
                    }} /></div> : null}
                {this.containsActions(ActionType.Search) && this.state.execution === ActionExecution.Nothing ? <IconButton className={styles.PanelActionSearch} title={PanelStrings.Action_Search} iconProps={{ iconName: "Search" }}
                    onClick={() => {
                        this.setState({ search: "", execution: ActionExecution.Searching }, () => {
                            if (this._searchBoxRef && this._searchBoxRef.current) {
                                this._searchBoxRef.current.focus();
                            }
                        });
                    }} /> : null}
                {this.containsActions(ActionType.View) ? <IconButton className={styles.PanelActionView} {...this.getActionProps(ActionType.View)} /> : null}
                {this.containsActions(ActionType.Status) ? <IconButton className={styles.PanelActionStatus} {...this.getActionProps(ActionType.Status)} /> : null}
                {this.containsActions(ActionType.Type) ? <IconButton className={styles.PanelActionType} {...this.getActionProps(ActionType.Type)} /> : null}
                {this.containsActions(ActionType.Sort) ? <IconButton className={styles.PanelActionSort} {...this.getActionProps(ActionType.Sort)} /> : null}
                {this.containsActions(ActionType.Display) ? <IconButton className={styles.PanelActionDisplay} {...this.getActionProps(ActionType.Display)} /> : null}
                {this.props.customActions ? this.props.customActions : null}
                {this.containsActions(ActionType.Edit) && this.state.execution === ActionExecution.Nothing ? <IconButton className={styles.PanelActionEdit} title={PanelStrings.Action_Edit} iconProps={{ iconName: "Edit" }}
                    onClick={() => {
                        ServiceLoader.GetService(PromptService).blockNavigation(this._key);
                        this.setState({ execution: ActionExecution.Editing });
                        if (this.props.onAction)
                            this.props.onAction(Action.Edit);
                    }} /> : null}
                {this.containsActions(ActionType.Edit) && this.state.execution === ActionExecution.Saving ? <Spinner title={PanelStrings.Action_Saving} size={SpinnerSize.small} /> : null}
                {this.containsActions(ActionType.Reset) && this.state.execution === ActionExecution.Editing ? <IconButton className={styles.PanelActionReset} title={PanelStrings.Action_Reset} iconProps={{ iconName: "RevToggleKey" }}
                    onClick={() => {
                        this.setState({ execution: ActionExecution.Saving });
                        if (this.props.onAction)
                            this.props.onAction(Action.Reset).then((reload) => {
                                this.setState({ error: null, execution: ActionExecution.Nothing }, () => {
                                    ServiceLoader.GetService(PromptService).releaseNavigation(this._key);
                                    if (reload) location.reload(false);
                                });
                            }).catch((error) => {
                                this.setState({ error: this.parseError(error), execution: ActionExecution.Editing });
                            });
                    }} /> : null}
                {this.containsActions(ActionType.Edit) && this.state.execution === ActionExecution.Editing ? <IconButton className={styles.PanelActionSave} title={PanelStrings.Action_Save} iconProps={{ iconName: "Save" }}
                    onClick={() => {
                        this.setState({ execution: ActionExecution.Saving });
                        if (this.props.onAction)
                            this.props.onAction(Action.Save).then((reload) => {
                                this.setState({ error: null, execution: ActionExecution.Nothing }, () => {
                                    ServiceLoader.GetService(PromptService).releaseNavigation(this._key);
                                    if (reload) location.reload(false);
                                });
                            }).catch((error) => {
                                this.setState({ error: this.parseError(error), execution: ActionExecution.Editing });
                            });
                    }} /> : null}
                {this.containsActions(ActionType.Archive) && this.state.execution === ActionExecution.Archiving ? <Spinner title={PanelStrings.Action_Archiving} size={SpinnerSize.small} /> : null}
                {this.containsActions(ActionType.Archive) && this.state.execution === ActionExecution.Nothing ? <IconButton className={styles.PanelActionArchive} title={PanelStrings.Action_Archive} iconProps={{ iconName: "Archive" }}
                    onClick={() => {
                        this.setState({ execution: ActionExecution.Archiving });
                        if (this.props.onAction)
                            this.props.onAction(Action.Archive).then(() => {
                                this.setState({ error: null, execution: ActionExecution.Nothing });
                            }).catch((error) => {
                                this.setState({ error: this.parseError(error), execution: ActionExecution.Nothing });
                            });
                    }} /> : null}
                {this.containsActions(ActionType.Unarchive) && this.state.execution === ActionExecution.Unarchiving ? <Spinner title={PanelStrings.Action_Unarchiving} size={SpinnerSize.small} /> : null}
                {this.containsActions(ActionType.Unarchive) && this.state.execution === ActionExecution.Nothing ? <IconButton className={styles.PanelActionUnarchive} title={PanelStrings.Action_Unarchive} iconProps={{ iconName: "ArchiveUndo" }}
                    onClick={() => {
                        this.setState({ execution: ActionExecution.Unarchiving });
                        if (this.props.onAction)
                            this.props.onAction(Action.Unarchive).then(() => {
                                this.setState({ error: null, execution: ActionExecution.Nothing });
                            }).catch((error) => {
                                this.setState({ error: this.parseError(error), execution: ActionExecution.Nothing });
                            });
                    }} /> : null}
                {this.containsActions(ActionType.Approve) && this.state.execution === ActionExecution.Approving ? <Spinner title={PanelStrings.Action_Approving} size={SpinnerSize.small} /> : null}
                {this.containsActions(ActionType.Approve) && this.state.execution === ActionExecution.Nothing ? <IconButton className={styles.PanelActionApprove} title={PanelStrings.Action_Approve} iconProps={{ iconName: "ReceiptCheck" }} styles={{ icon: { color: "green", fontSize: "24px" }}}
                    onClick={() => {
                        this.setState({ execution: ActionExecution.Approving });
                        if (this.props.onAction)
                            this.props.onAction(Action.Approve).then(() => {
                                this.setState({ error: null, execution: ActionExecution.Nothing });
                            }).catch((error) => {
                                this.setState({ error: this.parseError(error), execution: ActionExecution.Nothing });
                            });
                    }} /> : null}
                {this.containsActions(ActionType.Reject) && this.state.execution === ActionExecution.Rejecting ? <Spinner title={PanelStrings.Action_Rejecting} size={SpinnerSize.small} /> : null}
                {this.containsActions(ActionType.Reject) && this.state.execution === ActionExecution.Nothing ? <IconButton className={styles.PanelActionReject} title={PanelStrings.Action_Reject} iconProps={{ iconName: "ReceiptUndelivered" }} styles={{ icon: { color: "red", fontSize: "24px" } }}
                    onClick={() => {
                        this.setState({ execution: ActionExecution.Rejecting });
                        if (this.props.onAction)
                            this.props.onAction(Action.Reject).then(() => {
                                this.setState({ error: null, execution: ActionExecution.Nothing });
                            }).catch((error) => {
                                this.setState({ error: this.parseError(error), execution: ActionExecution.Nothing });
                            });
                    }} /> : null}
                {this.containsActions(ActionType.Retry) && this.state.execution === ActionExecution.Retrying ? <Spinner title={PanelStrings.Action_Retrying} size={SpinnerSize.small} /> : null}
                {this.containsActions(ActionType.Retry) && this.state.execution === ActionExecution.Nothing ? <IconButton className={styles.PanelActionRetry} title={PanelStrings.Action_Retry} iconProps={{ iconName: "Reset" }} 
                    onClick={() => {
                        this.setState({ execution: ActionExecution.Retrying });
                        if (this.props.onAction)
                            this.props.onAction(Action.Retry).then(() => {
                                this.setState({ error: null, execution: ActionExecution.Nothing });
                            }).catch((error) => {
                                this.setState({ error: this.parseError(error), execution: ActionExecution.Nothing });
                            });
                    }} /> : null}
                {this.containsActions(ActionType.Delete) && this.state.execution === ActionExecution.Deleting ? <Spinner title={PanelStrings.Action_Deleting} size={SpinnerSize.small} /> : null}
                {this.containsActions(ActionType.Delete) && this.state.execution === ActionExecution.Nothing ? <IconButton className={styles.PanelActionDelete} title={PanelStrings.Action_Delete} iconProps={{ iconName: "Delete" }}
                    onClick={() => {
                        this.setState({ execution: ActionExecution.Deleting });
                        if (this.props.onAction)
                            this.props.onAction(Action.Delete).then(() => {
                                this.setState({ error: null, execution: ActionExecution.Nothing });
                            }).catch((error) => {
                                this.setState({ error: this.parseError(error), execution: ActionExecution.Nothing });
                            });
                    }} /> : null}
                {this.containsActions(ActionType.Export) && this.state.execution === ActionExecution.Exporting ? <Spinner title={PanelStrings.Action_Exporting} size={SpinnerSize.small} /> : null}
                {this.containsActions(ActionType.Export) && this.state.execution === ActionExecution.Nothing ? <IconButton className={styles.PanelActionExport} title={PanelStrings.Action_Export} iconProps={{ iconName: "Download" }}
                    onClick={() => {
                        this.setState({ execution: ActionExecution.Exporting });
                        if (this.props.onAction)
                            this.props.onAction(Action.Export).then(() => {
                                this.setState({ error: null, execution: ActionExecution.Nothing });
                            }).catch((error) => {
                                this.setState({ error: this.parseError(error), execution: ActionExecution.Nothing });
                            });
                    }} /> : null}
                {this.containsActions(ActionType.Expand) ? <IconButton className={styles.PanelActionExpand} title={PanelStrings.Action_Expand} iconProps={{ iconName: "MiniExpand" }}
                    onClick={() => {
                        if (ServiceLoader.GetService(PromptService).checkBlockedNavigation(this._key))
                            if (this.props.onAction)
                                this.props.onAction(Action.Expand);
                    }} /> : null}
                {this.containsActions(ActionType.Close) ? <IconButton className={styles.PanelActionClose} title={PanelStrings.Action_Close} iconProps={{ iconName: "Cancel" }}
                    onClick={() => {
                        if (ServiceLoader.GetService(PromptService).checkBlockedNavigation(this._key))
                            if (this.props.onAction)
                                this.props.onAction(Action.Close);
                    }} /> : null}
                {this.props.infoTooltip ? <TooltipHost content={this.props.infoTooltip} directionalHint={DirectionalHint.bottomRightEdge}><span><Icon iconName="Info" /></span></TooltipHost> : null}

            </div>,
            this.props.errors && this.props.errors.length > 0 ? 
                <div className={styles.PanelError}>
                    {this.props.errors.map((error, idx) => <MessageBar key={"PanelPropsError_" + idx} messageBarType={MessageBarType.error} isMultiline={true} dismissButtonAriaLabel="Close">{error.message}</MessageBar>)}
                </div> : 
                null,
            this.state.error && this.state.error.errors && this.state.error.errors.length > 0 ?
                <div className={styles.PanelError}>
                    {this.state.error.errors.map((error, idx) => <MessageBar key={"PanelError_" + idx} messageBarType={MessageBarType.error} isMultiline={true} dismissButtonAriaLabel="Close">{error.message}</MessageBar>)}
                </div> :
                this.state.error ?
                <div className={styles.PanelError}>
                        <MessageBar messageBarType={MessageBarType.error} isMultiline={false} truncated={true} dismissButtonAriaLabel="Close">{this.state.error && (this.state.error as any).Message ? (this.state.error as any).Message : (this.state.error as any).message ? (this.state.error as any).message : JSON.stringify(this.state.error)}</MessageBar>
                </div> :
                null
        ]);
    }

    private parseError(error: any): IErrorList {
        let errorList: IErrorList;

        if (error?.errors) {
            errorList = error;
        } else if (error.validationMessage) {
            errorList = {
                errors: error.validationMessage.split("; ").map(m => {
                    return {
                        type: "ValidationError",
                        message: m
                    }
                })
            }
        } else if (error?.Message) {
            errorList = {
                errors: [
                    {
                        type: "Error",
                        message: error.Message
                    }
                ]
            }
        } else if (error?.message) {
            errorList = {
                errors: [
                    {
                        type: "Error",
                        message: error.message
                    }
                ]
            }
        } else if (error) {
            errorList = {
                errors: [
                    {
                        type: "Error",
                        message: JSON.stringify(error)
                    }
                ]
            }
        } else {
            errorList = {
                errors: [
                    {
                        type: "UnknownError",
                        message: "UnknownError"
                    }
                ]
            }
        }

        return this.translateError(errorList);
    }

    private translateError(errorList: IErrorList): IErrorList {

        if (errorList?.errors) {
            errorList.errors.forEach(e => {
                if (e.message && PanelStrings[`Error_${e.message}`]) {
                    e.message = PanelStrings[`Error_${e.message}`];
                }
            });
        }

        return errorList;
    }

    private containsActions(actionType: ActionType): boolean {
        if (actionType === ActionType.View) {
            return this.containsAction(this.props.actions, Action.ViewAll, Action.ViewMy, Action.ViewRecent, Action.ViewTrending);
        }
        if (actionType === ActionType.Sort) {
            return this.containsAction(this.props.actions, Action.SortAscending, Action.SortDescending, Action.SortNewest, Action.SortOldest, Action.SortManual);
        }
        if (actionType === ActionType.Display) {
            return this.containsAction(this.props.actions, Action.DisplayLargeCard, Action.DisplaySmallCard, Action.DisplayList);
        }
        if (actionType === ActionType.Edit) {
            return this.containsAction(this.props.actions, Action.Edit, Action.Save);
        }
        if (actionType === ActionType.Reset) {
            return this.containsAction(this.props.actions, Action.Reset);
        }
        if (actionType === ActionType.Delete) {
            return this.containsAction(this.props.actions, Action.Delete);
        }
        if (actionType === ActionType.Search) {
            return this.containsAction(this.props.actions, Action.Search);
        }
        if (actionType === ActionType.Archive) {
            return this.containsAction(this.props.actions, Action.Archive);
        }
        if (actionType === ActionType.Unarchive) {
            return this.containsAction(this.props.actions, Action.Unarchive);
        }
        if (actionType === ActionType.Approve) {
            return this.containsAction(this.props.actions, Action.Approve);
        }
        if (actionType === ActionType.Reject) {
            return this.containsAction(this.props.actions, Action.Reject);
        }
        if (actionType === ActionType.Status) {
            return this.containsAction(this.props.actions, Action.StatusAll, Action.StatusActive, Action.StatusArchived, Action.StatusApproval, Action.StatusInProgress, Action.StatusFailed);
        }
        if (actionType === ActionType.Type) {
            return this.containsAction(this.props.actions, Action.TypeAll, Action.TypeSharePoint, Action.TypeMSTeams, Action.TypeFavorites);
        }
        if (actionType === ActionType.Retry) {
            return this.containsAction(this.props.actions, Action.Retry);
        }
        if (actionType === ActionType.Close) {
            return this.containsAction(this.props.actions, Action.Close);
        }
        if (actionType === ActionType.Expand) {
            return this.containsAction(this.props.actions, Action.Expand);
        }
        if (actionType === ActionType.Export) {
            return this.containsAction(this.props.actions, Action.Export);
        }
        return false;
    }

    private containsAction(actions: Action[], ...hasActions: Action[]): boolean {
        return actions ? hasActions.some(a => actions.includes(a)) : false;
    }

    private getActionProps(actionType: ActionType): IButtonProps {
        const action = this.getCurrentAction(actionType);
        return {
            title: this.getActionText(action),
            iconProps: { iconName: this.getActionIcon(action) },
            menuProps: {
                items: this.getActionItems(actionType),
                onItemClick: (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
                    if (item && this.props.onAction)
                        this.props.onAction(Action[item.key]);
                },
                className: this.props.contextualMenuClassName
            }
        };
    }

    private getCurrentAction(actionType: ActionType): Action {
        if (actionType === ActionType.View) {
            if (this.containsAction(this.props.currentActions, Action.ViewAll))
                return Action.ViewAll;
            if (this.containsAction(this.props.currentActions, Action.ViewMy))
                return Action.ViewMy;
            if (this.containsAction(this.props.currentActions, Action.ViewRecent))
                return Action.ViewRecent;
            if (this.containsAction(this.props.currentActions, Action.ViewTrending))
                return Action.ViewTrending;
        }
        if (actionType === ActionType.Sort) {
            if (this.containsAction(this.props.currentActions, Action.SortAscending))
                return Action.SortAscending;
            if (this.containsAction(this.props.currentActions, Action.SortDescending))
                return Action.SortDescending;
            if (this.containsAction(this.props.currentActions, Action.SortNewest))
                return Action.SortNewest;
            if (this.containsAction(this.props.currentActions, Action.SortOldest))
                return Action.SortOldest;
            if (this.containsAction(this.props.currentActions, Action.SortManual))
                return Action.SortManual;
        }
        if (actionType === ActionType.Display) {
            if (this.containsAction(this.props.currentActions, Action.DisplayLargeCard))
                return Action.DisplayLargeCard;
            if (this.containsAction(this.props.currentActions, Action.DisplaySmallCard))
                return Action.DisplaySmallCard;
            if (this.containsAction(this.props.currentActions, Action.DisplayList))
                return Action.DisplayList;
        }
        if (actionType === ActionType.Status) {
            if (this.containsAction(this.props.currentActions, Action.StatusAll))
                return Action.StatusAll;
            if (this.containsAction(this.props.currentActions, Action.StatusActive))
                return Action.StatusActive;
            if (this.containsAction(this.props.currentActions, Action.StatusArchived))
                return Action.StatusArchived;
            if (this.containsAction(this.props.currentActions, Action.StatusApproval))
                return Action.StatusApproval;
            if (this.containsAction(this.props.currentActions, Action.StatusInProgress))
                return Action.StatusInProgress;
            if (this.containsAction(this.props.currentActions, Action.StatusFailed))
                return Action.StatusFailed;
        }
        if (actionType === ActionType.Type) {
            if (this.containsAction(this.props.currentActions, Action.TypeAll))
                return Action.TypeAll;
            if (this.containsAction(this.props.currentActions, Action.TypeSharePoint))
                return Action.TypeSharePoint;
            if (this.containsAction(this.props.currentActions, Action.TypeMSTeams))
                return Action.TypeMSTeams;
            if (this.containsAction(this.props.currentActions, Action.TypeFavorites))
                return Action.TypeFavorites;
        }
        return undefined;
    }

    private getActionItems(actionType: ActionType): IContextualMenuItem[] {
        const actions: IContextualMenuItem[] = [];
        if (actionType === ActionType.View) {
            if (this.containsAction(this.props.actions, Action.ViewAll))
                actions.push(this.getActionItem(Action.ViewAll));
            if (this.containsAction(this.props.actions, Action.ViewMy))
                actions.push(this.getActionItem(Action.ViewMy));
            if (this.containsAction(this.props.actions, Action.ViewRecent))
                actions.push(this.getActionItem(Action.ViewRecent));
            if (this.containsAction(this.props.actions, Action.ViewTrending))
                actions.push(this.getActionItem(Action.ViewTrending));
        }
        if (actionType === ActionType.Sort) {
            if (this.containsAction(this.props.actions, Action.SortAscending))
                actions.push(this.getActionItem(Action.SortAscending));
            if (this.containsAction(this.props.actions, Action.SortDescending))
                actions.push(this.getActionItem(Action.SortDescending));
            if (this.containsAction(this.props.actions, Action.SortNewest))
                actions.push(this.getActionItem(Action.SortNewest));
            if (this.containsAction(this.props.actions, Action.SortOldest))
                actions.push(this.getActionItem(Action.SortOldest));
            if (this.containsAction(this.props.actions, Action.SortManual))
                actions.push(this.getActionItem(Action.SortManual));
        }
        if (actionType === ActionType.Display) {
            if (this.containsAction(this.props.actions, Action.DisplayLargeCard))
                actions.push(this.getActionItem(Action.DisplayLargeCard));
            if (this.containsAction(this.props.actions, Action.DisplaySmallCard))
                actions.push(this.getActionItem(Action.DisplaySmallCard));
            if (this.containsAction(this.props.actions, Action.DisplayList))
                actions.push(this.getActionItem(Action.DisplayList));
        }
        if (actionType === ActionType.Status) {
            if (this.containsAction(this.props.actions, Action.StatusAll))
                actions.push(this.getActionItem(Action.StatusAll));
            if (this.containsAction(this.props.actions, Action.StatusActive))
                actions.push(this.getActionItem(Action.StatusActive));
            if (this.containsAction(this.props.actions, Action.StatusArchived))
                actions.push(this.getActionItem(Action.StatusArchived));
            if (this.containsAction(this.props.actions, Action.StatusApproval))
                actions.push(this.getActionItem(Action.StatusApproval));
            if (this.containsAction(this.props.actions, Action.StatusInProgress))
                actions.push(this.getActionItem(Action.StatusInProgress));
            if (this.containsAction(this.props.actions, Action.StatusFailed))
                actions.push(this.getActionItem(Action.StatusFailed));
        }
        if (actionType === ActionType.Type) {
            if (this.containsAction(this.props.actions, Action.TypeAll))
                actions.push(this.getActionItem(Action.TypeAll));
            if (this.containsAction(this.props.actions, Action.TypeSharePoint))
                actions.push(this.getActionItem(Action.TypeSharePoint));
            if (this.containsAction(this.props.actions, Action.TypeMSTeams))
                actions.push(this.getActionItem(Action.TypeMSTeams));
            if (this.containsAction(this.props.actions, Action.TypeFavorites))
                actions.push(this.getActionItem(Action.TypeFavorites));
        }
        return actions;
    }

    private getActionItem(action: Action): IContextualMenuItem {
        return {
            key: Action[action],
            text: this.getActionText(action),
            iconProps: { iconName: this.getActionIcon(action) },
        };
    }

    private getActionText(action: Action): string {

        if (this.props.actionTexts && this.props.actionTexts[action]) {
            return this.props.actionTexts[action];
        }

        switch (action) {
            case Action.ViewAll:
                return PanelStrings.ViewAction_All;
            case Action.ViewMy:
                return PanelStrings.ViewAction_My;
            case Action.ViewRecent:
                return PanelStrings.ViewAction_Recent;
            case Action.ViewTrending:
                return PanelStrings.ViewAction_Trending;
            case Action.SortAscending:
                return PanelStrings.SortAction_Ascending;
            case Action.SortDescending:
                return PanelStrings.SortAction_Descending;
            case Action.SortNewest:
                return PanelStrings.SortAction_Newest;
            case Action.SortOldest:
                return PanelStrings.SortAction_Oldest;
            case Action.SortManual:
                return PanelStrings.SortAction_Manual;
            case Action.DisplayLargeCard:
                return PanelStrings.DisplayAction_LargeTiles;
            case Action.DisplaySmallCard:
                return PanelStrings.DisplayAction_SmallTiles;
            case Action.DisplayList:
                return PanelStrings.DisplayAction_List;
            case Action.StatusAll:
                return PanelStrings.StatusAction_All;
            case Action.StatusActive:
                return PanelStrings.StatusAction_Active;
            case Action.StatusArchived:
                return PanelStrings.StatusAction_Archived;
            case Action.StatusApproval:
                return PanelStrings.StatusAction_Approval;
            case Action.StatusInProgress:
                return PanelStrings.StatusAction_InProgress;
            case Action.StatusFailed:
                return PanelStrings.StatusAction_Failed;
            case Action.TypeAll:
                return PanelStrings.TypeAction_All;
            case Action.TypeSharePoint:
                return PanelStrings.TypeAction_SharePoint;
            case Action.TypeMSTeams:
                return PanelStrings.TypeAction_MSTeams;
            case Action.TypeFavorites:
                return PanelStrings.TypeAction_Favorites;
        }
        return "Unknown";
    }

    private getActionIcon(action: Action): string {
        switch (action) {
            case Action.ViewAll:
                return "ViewAll2";
            case Action.ViewMy:
                return "Contact";
            case Action.ViewRecent:
                return "Recent";
            case Action.ViewTrending:
                return "Trending12";
            case Action.SortAscending:
                return "Ascending";
            case Action.SortDescending:
                return "Descending";
            case Action.SortNewest:
                return "SortLines";
            case Action.SortOldest:
                return "SortLines";
            case Action.SortManual:
                return "FavoriteList";
            case Action.DisplayLargeCard:
                return "GridViewMedium";
            case Action.DisplaySmallCard:
                return "GridViewSmall";
            case Action.DisplayList:
                return "List";
            case Action.StatusAll:
                return "PageList";
            case Action.StatusActive:
                return "CheckMark";
            case Action.StatusArchived:
                return "Archive";
            case Action.StatusApproval:
                return "DocumentApproval";
            case Action.StatusInProgress:
                return "ProgressLoopInner";
            case Action.StatusFailed:
                return "Error";
            case Action.TypeAll:
                return "Globe";
            case Action.TypeSharePoint:
                return "SharepointLogoInverse";
            case Action.TypeMSTeams:
                return "TeamsLogoInverse";
            case Action.TypeFavorites:
                return "FavoriteStarFill";
        }
        return "StatusCircleQuestionMark";
    }
}