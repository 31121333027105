import moment from "moment";

export const renderFriendlyDate = (date: Date): string => {
    return moment(date).fromNow();
}

export const renderDate = (date: Date): string => {
    return moment(date).format("L");
}

export const renderDateTime = (date: Date): string => {
    return `${renderDate(date)} ${moment(date).format("LT")}`;
}

export const renderSortableDateTime = (date: Date): string => {
    return moment(date).format("YYYYMMDDHHmmss");
}


export const days = (dateFrom: Date, dateTo?: Date): number => {
    const from = moment(dateFrom);
    const to = dateTo ? moment(dateTo) : moment(new Date(new Date().setHours(0,0,0,0)));
    return from.diff(to, 'days');
}