import * as React from 'react';
import { ISmartPortalNavItem, PortalLeftBar, PortalLeftBarNavDict } from '../basepage/PortalLeftBar';
import styles from "./SideBarStyle.scss"
import { cloneDeep } from "lodash";
import { SmartPortal } from '../../SmartPortal';
import SmartPortalStyles from "../../styles/SmartPortal.scss";
import SharePointStyle from "../../appstart/spapp/sharepoint/SharePointStyle.scss";
import { PromiseDfd } from '../../common/utils/PromiseDfd';
import { Spinner } from 'office-ui-fabric-react/lib/components/Spinner/Spinner';
import { SpinnerSize } from 'office-ui-fabric-react/lib/components/Spinner/Spinner.types';
import SidebarPanelSiteInfo from './SideBarPanelSiteInfo';
import SideBarPanelDiscover from './SideBarPanelDiscover';
import SideBarPanelDirectory from './SideBarPanelDirectory';

export interface ISideBarProps {
    navBar?: boolean;
    navItems?: PortalLeftBarNavDict;
}

export interface ISideBarState {
    navBar: boolean;
    navItems: PortalLeftBarNavDict;
    navItem: ISmartPortalNavItem,
    homeNavKey: string;
    navLink: string;
}

const Navigation = React.lazy(() => import(/* webpackChunkName: "Navigation" */ '../navigation/Navigation'));
const DirectoryPanel = React.lazy(() => import(/* webpackChunkName: "DirectoryPanel" */ './SideBarPanelDirectory'));

export default class SideBar extends React.Component<ISideBarProps, ISideBarState> {

    private leftBarControl: PortalLeftBar;
    private activeNavKey: string;

    constructor(props: ISideBarProps) {
        super(props);

        const navItems = cloneDeep(props.navItems);
        let homeNavKey: string;
        let navLink: string;
        if (props.navBar) {
            if (navItems.MyWorkplace && !navItems.MyWorkplace.hidden) {
                navLink = navItems.MyWorkplace.navLink;
                homeNavKey = "MyWorkplace";
                navItems.MyWorkplace.navLink = null;
                navItems.MyWorkplace.onClick = (evt) => { this.openPanel(this.state.homeNavKey); return false; };
                this.setActiveNavKey(homeNavKey);
            } else if (navItems.Directory && !navItems.Directory.hidden) {
                navLink = navItems.Directory.navLink;
                homeNavKey = "Directory";
                navItems.Directory.navLink = null;
                navItems.Directory.onClick = (evt) => { this.openPanel(this.state.homeNavKey); return false; };
                this.setActiveNavKey(homeNavKey);
            } else {
                navLink = SmartPortal.buildPortalUrl("/");
            }
            if (navItems.GlobalNav) {
                navItems.GlobalNav.onClick = this.openNavHandler.bind(this);
            }
            if (navItems.Directory) {
                navItems.Directory.onClick = (evt) => { this.openPanel("Directory"); return false; };
            }
            if (navItems.Discover) {
                navItems.Discover.onClick = (evt) => { this.openPanel("Discover"); return false; };
            }
        } else {
            this.setActiveNavKey("MyWorkplace");
        }

        this.state = {
            navBar: false,
            navItems: navItems,
            homeNavKey: homeNavKey,
            navLink: navLink,
            navItem: null
        }
    }

    public render() {
        const readyDfd = new PromiseDfd<void>();
        return (
            <div className={SmartPortalStyles.SmartPortal}>
                <div className={styles.SideBarContainer}>
                    {
                        this.props.navBar ? <div className={SharePointStyle.LeftBarContainer + " " + styles.SideBarNavigationContainer}>
                            <PortalLeftBar ref={(ref) => { this.leftBarControl = ref; }} navItems={this.state.navItems} activeNavKey={this.activeNavKey} />
                        </div> : null
                    }
                    {
                        this.state.navBar ? <React.Suspense fallback={() => (<Spinner title="Loading" size={SpinnerSize.large} />)}>
                            <Navigation ref={(ref) => { readyDfd.resolve(); }} showClose={true} onClose={() => {
                                SmartPortal.currentPage.setRightPanel(null);
                                return Promise.resolve();
                            }} />
                        </React.Suspense> : null
                    }
                    {
                        this.state.navBar == false ?
                            <div className={styles.SideBarPanels}>
                                <div className={styles.SideBarPanel}>
                                    {
                                        this.activeNavKey === "MyWorkplace" ? <SidebarPanelSiteInfo showQuickActions={this.props.navBar && this.state.homeNavKey == "MyWorkplace"} expandLink={this.state.navLink} /> : null
                                    }
                                    {
                                        this.activeNavKey === "Directory" ?
                                            <React.Suspense fallback={() => (<Spinner title="Loading" size={SpinnerSize.large} />)}>
                                                <DirectoryPanel expandLink={this.state.navItem?.navLink} />
                                            </React.Suspense> : null
                                    }
                                    {
                                        this.activeNavKey === "Discover" ? <SideBarPanelDiscover expandLink={this.state.navItem?.navLink} /> : null
                                    }
                                </div>
                            </div> : null
                    }
                </div>
            </div>
        );
    }

    public setActiveNavKey(navKey: string) {
        if (this.leftBarControl) {
            this.leftBarControl.setActiveNavKey(navKey);
        }
        this.activeNavKey = navKey;
    }

    public openNavHandler() {
        this.setActiveNavKey("GlobalNav");
        this.setState({ navBar: true });
    }

    public openPanel(key: string) {
        this.setActiveNavKey(key);
        this.setState({ navBar: false, navItem: this.state.navItems[key] });
    }
}
