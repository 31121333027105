import * as React from 'react';
import styles from "../ProvisioningStyle.scss";
import strings from '../loc/ProvisioningStrings';
import Truncate from 'react-truncate';
import { FontIcon, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { SmartPortal } from '../../../SmartPortal';
import { ServiceLoader } from '../../../common/business/ServiceLoader';
import { RequestService } from '../../../common/business/RequestService';
import { IProvisionTemplate, SiteProvisioningType } from '../../../common/models/SiteInfo';

export interface ISiteTemplateFormProps {
    onReturn(provisionTemplate?: IProvisionTemplate): void;
}

export interface ISiteTemplateFormState {
    provisionTemplateLoaded: boolean;
    availableProvisionTemplates: IProvisionTemplate[];
}

export class SiteTemplateForm extends React.Component<ISiteTemplateFormProps, ISiteTemplateFormState> {

    private provisionTemplateLoading: boolean = false;

    constructor(props: ISiteTemplateFormProps, state: ISiteTemplateFormState) {
        super(props);

        this.state = {
            provisionTemplateLoaded: false,
            availableProvisionTemplates: []
        };
    }

    public componentDidMount(): void {
        this.loadProvisionTemplates();
    }

    public componentDidUpdate(): void {
        this.loadProvisionTemplates();
    }

    private loadProvisionTemplates(): Promise<void> {
        if (!this.state.provisionTemplateLoaded && !this.provisionTemplateLoading) {
            this.provisionTemplateLoading = true;
            const reqSvc = ServiceLoader.GetService(RequestService);
            return reqSvc.requestAadService({
                method: "GET",
                url: "/api/directory/provisiontemplates"
            }).then(reqSvc.parseApiResponse).then((result: IProvisionTemplate[]) => {

                result = result.filter(t => t.showInRequest);

                result.sort((a, b) => {
                    if (a.order > b.order) return 1;
                    if (a.order < b.order) return -1;
                    return a.name.localeCompare(b.name);
                });

                this.setState({
                    provisionTemplateLoaded: true,
                    availableProvisionTemplates: result
                }, () => {
                    this.provisionTemplateLoading = false;
                });
            });
        }
        return Promise.resolve();
    }

    public render(): React.ReactElement<ISiteTemplateFormProps> {
        return (
            <div className={styles.SiteTemplateForm}>
                <div className={styles.SiteTemplateFormDescription} dangerouslySetInnerHTML={{ __html: strings.SiteTemplateForm_Description }} />
                <div className={styles.SiteTemplateFormTemplates + " " + (this.state.provisionTemplateLoaded && this.state.availableProvisionTemplates && this.state.availableProvisionTemplates.length < 2 ? styles.SiteTemplateFormTemplatesOneRow : this.state.provisionTemplateLoaded && this.state.availableProvisionTemplates && this.state.availableProvisionTemplates.length < 5 ? styles.SiteTemplateFormTemplatesLessThanFive : "")}>
                    { !this.state.provisionTemplateLoaded ? <Spinner size={SpinnerSize.large}/> : null }
                    { this.state.provisionTemplateLoaded && this.state.availableProvisionTemplates && this.state.availableProvisionTemplates.length > 0 ? 
                        this.state.availableProvisionTemplates.map(template => {
                            return <div className={styles.SiteTemplateFormTemplate} onClick={(evt) => {
                                evt.preventDefault(); 
                                this.props.onReturn(template);
                            }}>
                                <div className={styles.SiteTemplateFormTemplateHeader}>
                                    <div className={styles.SiteTemplateFormTemplateLogo}>
                                        <div className={styles.SiteTemplateFormTemplateLogoIcon}>
                                            { template.icon ?
                                                <FontIcon iconName={template.icon} /> : 
                                                <FontIcon iconName="Page" />
                                            }
                                        </div>
                                    </div>
                                    <div className={styles.SiteTemplateFormTemplateName}>
                                        <div className={styles.SiteTemplateFormTemplateBox}>
                                            <Truncate lines={3} trimWhitespace={true}>
                                                {template.name}
                                            </Truncate>
                                        </div>
                                    </div>
                                    <div className={styles.SiteTemplateFormTemplateType + " " + (template.type === SiteProvisioningType.MSTeams ? styles.SiteTemplateFormTemplateTypeMSTeams : template.type === SiteProvisioningType.SPOSite ? styles.SiteTemplateFormTemplateTypeSPO : "" )}>
                                        <div className={styles.SiteTemplateFormTemplateTypeIcon}>
                                            { template.type === SiteProvisioningType.MSTeams ?
                                                <FontIcon iconName="TeamsLogoInverse" /> :
                                                    template.type === SiteProvisioningType.SPOSite ?
                                                    <FontIcon iconName="SharepointLogoInverse" /> :
                                                        null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.SiteTemplateFormTemplateBody}>
                                    <div className={styles.SiteTemplateFormTemplateDescription}>
                                        <Truncate lines={6} trimWhitespace={true}>
                                            {template.description}
                                        </Truncate>
                                    </div>
                                </div>
                            </div>
                        }) : null }
                </div>
                <div className={styles.SiteRequestActions}>
                    <div className={styles.SpacerAction}></div>
                    <div className={styles.LinkAction}>
                        <a href="#" onClick={(evt) => { evt.preventDefault(); this.props.onReturn() }}>{strings.SiteTemplateForm_SkipIntroLink}</a>
                    </div>
                </div>
            </div>
        );
    }
}
