
export interface IUploadFile {
    id: string;
    file: File;
    status?: string;
    size: string;
    state: UploadFileStates;
    progress: number;
    errorType: UploadFileErrorTypes | undefined;
}

export enum UploadFileErrorTypes {
    Conflict,
    BadRequest,
    AccessDenied,
    ServerError
}

export enum UploadFileResumeTypes {
    Rename,
    Replace,
    Retry
}

export enum UploadFileStates {
    Added,
    Queued,
    Uploading,
    Uploaded,
    Error
}
