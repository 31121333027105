import { BaseCacheService } from './BaseCacheService';
import { CacheType } from './models/CacheType';
import { CacheEncoding } from './models/CacheOptions';


export class GraphCacheService extends BaseCacheService {
    protected cacheName = "GraphCache";    
    protected cacheType = CacheType.IndexedDB;

    public constructor() {
        super();

        this.cacheOptions.defaultEncoding = CacheEncoding.Compress;
        this.cacheOptions.accessTimeout = 86400 * 7; // 7 days
    }
    
    protected handleCacheException(issue: any): void {
        if(this.cacheType === CacheType.IndexedDB) {
            // IndexedDB fails in firefox in-private browsing. fallback to session storage
            this.cacheType = CacheType.SessionStorage;
            this.cacheDriver.dispose();
            this.cacheDriver = null;
            this.getCacheDriver();
        }
    }

}
