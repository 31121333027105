import * as React from 'react';
import styles from "./PageStyle.scss";
import { SmartPortal } from '../../SmartPortal';
import { DynPageContent } from './DynPageContent';
import { PortalTopBar } from './PortalTopBar';
import { PortalLeftBar, PortalLeftBarNavDict } from './PortalLeftBar';
import { PortalPanel } from './PortalPanel';
import { IPortalPanelOptions } from './PortalPanel';
import { IPortalPageOverlay, IBasePage } from './BasePage';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';

export interface IPortalPageProps {
    renderTopBar?: boolean;
    renderLeftBar?: boolean;
    renderPanels?: boolean;
    activeNavKey?: string;
    activeNavPathIdx?: number;
    navItems?: PortalLeftBarNavDict;
    children?: React.ReactNode | React.ReactNode[];
}

export interface IPortalPageState {
}

export class PortalPage extends React.Component<IPortalPageProps, IPortalPageState> implements IBasePage {
    protected pageClassName: string = styles.SmartPortalPage;
    private dynPageContent: JSX.Element;
    private leftBarControl: PortalLeftBar;
    private leftPanelControl: PortalPanel;
    private rightPanelControl: PortalPanel;
    private activeNavKey: string;
    private activeOverlays: IPortalPageOverlay[] = [];
    private pageBodyEl: HTMLElement;

    constructor(props: IPortalPageProps, state: IPortalPageState) {
        super(props);

        SmartPortal.currentPage = this;
        this.dynPageContent = <DynPageContent />;

        this.activeNavKey = this.props.activeNavKey;
        this.state = {};
    }

    public setActiveNavKey(navKey: string) {
        if (this.leftBarControl)
            this.leftBarControl.setActiveNavKey(navKey);
        else
            this.activeNavKey = navKey;
    }

    public setPreviousNavKey() {
        if (this.leftBarControl)
            this.leftBarControl.setPreviousNavKey();
    }

    public setDynamicContent(name: string, children: React.ReactNode | React.ReactNode[] | null): void {
        if (SmartPortal.currentDynPageContent) {
            SmartPortal.currentDynPageContent.setContent(name, children);
        }
    }

    public setLeftPanel(panelProps: IPortalPanelOptions): void {
        if (!this.leftPanelControl)
            return;
        
        if(panelProps)
            this.leftPanelControl.showPanel(panelProps);
        else
            this.leftPanelControl.hidePanel();
    }

    public setRightPanel(panelProps: IPortalPanelOptions): void {
        if (!this.rightPanelControl)
            return;
        
        if(panelProps)
            this.rightPanelControl.showPanel(panelProps);
        else
            this.rightPanelControl.hidePanel();
    }

    public addPageOverlay(overlay: IPortalPageOverlay) {
        for (let i = 0; i < this.activeOverlays.length; i++) {
            if (this.activeOverlays[i].overlayName === overlay.overlayName) {
                // dismiss active overlay
                this.activeOverlays[i].dismissCallback();
                this.activeOverlays.splice(i, 1);
                break;
            }
        }

        this.activeOverlays.push(overlay);
    }

    public removePageOverlay(overlayName: string, runDismiss: boolean = true) {
        for (let i = 0; i < this.activeOverlays.length; i++) {
            if (this.activeOverlays[i].overlayName === overlayName) {
                // dismiss active overlay
                if(runDismiss)
                    this.activeOverlays[i].dismissCallback();
                this.activeOverlays.splice(i, 1);
                break;
            }
        }
    }

    public setBodyScrollTop(scrollTop: number): void {
        if(!this.pageBodyEl)
            return;

        this.pageBodyEl.scrollTop = scrollTop;
    }

    public componentDidMount(): void {

    }

    public render(): React.ReactElement<IPortalPageProps> {
        let contentElements: React.ReactNode[] = [];

        if (this.props.children) {
            if (Array.isArray(this.props.children))
                Array.prototype.push.apply(contentElements, this.props.children);
            else
                contentElements.push(this.props.children);
        }

        return (
            <div className={this.pageClassName} onMouseDown={(evt) => this.onPageMouseDown(evt)}>
                {this.props.renderTopBar ?
                    <PortalTopBar 
                        onNavToggle={this.props.renderLeftBar ? () => {
                            this.leftBarControl.setNavExpanded();
                        } : null}
                    />
                : null}
                <div className={styles.PortalBodyContainer + (this.props.renderTopBar ? (" " + styles.PortalWithHeader) : "")}>
                    {this.props.renderLeftBar ?
                        <PortalLeftBar
                            ref={(ref) => {
                                this.leftBarControl = ref;
                                if (this.activeNavKey) {
                                    this.leftBarControl.setActiveNavKey(this.activeNavKey);
                                }
                            }}
                            navItems={this.props.navItems}
                            activeNavKey={this.activeNavKey}
                        />
                    : null}
                    {this.props.renderPanels ? 
                        <PortalPanel panelName="PortalLeftPanel" className={styles.PortalLeftPanel} ref={(ref) => this.leftPanelControl = ref} />
                    : null}
                    <div className={styles.PortalBody} ref={(ref) => this.pageBodyEl = ref}>
                        <React.Suspense fallback={this.renderLoadingSplash()}>
                            {contentElements}
                            {this.dynPageContent}
                        </React.Suspense>
                    </div>
                    {this.props.renderPanels ? 
                        <PortalPanel panelName="PortalRightPanel" className={styles.PortalRightPanel} ref={(ref) => this.rightPanelControl = ref} />
                    : null}
                </div>
            </div>
        );
    }

    private renderLoadingSplash(): React.ReactNode {
        return <Spinner title="Loading SmartPort365" size={SpinnerSize.large} />;
    }

    private onPageMouseDown(evt: React.MouseEvent) {
        let targetEl = evt.target as Element;

        for (let i = 0; i < this.activeOverlays.length; i++) {
            if (this.checkTargetInOverlay(this.activeOverlays[i], targetEl))
                continue;

            if (this.activeOverlays[i].dismissCallback()) {
                this.activeOverlays.splice(i, 1);
                i--;
            }
        }
    }

    private checkTargetInOverlay(overlay: IPortalPageOverlay, target: Element): boolean {
        let container = target;
        //if a teaching bubble is currently shown do not close any overlays
        const teachingBubble = document.querySelector(".ms-TeachingBubble-content");
        if (teachingBubble) {
            return true;
        }
        do {
            for (let i = 0; i < overlay.contentSelector.length; i++) {
                let doesMatch = (container.matches || (container as any).msMatchesSelector).call(container, overlay.contentSelector[i]);
                if (doesMatch)
                    return true;
            }
        } while ((container = container.parentElement) && container !== document.body);

        return false;
    }

}
