import { BaseCard, StreamSource } from "..";

export class SiteCard extends BaseCard {
  public get hostUrl(): string { return this.getDataField("hostUrl"); };
  public set hostUrl(val: string) { this.setDataField("hostUrl", val); };

  public get siteId(): string { return this.getDataField("siteId"); };
  public set siteId(val: string) { this.setDataField("siteId", val); };

  public get webId(): string { return this.getDataField("webId"); };
  public set webId(val: string) { this.setDataField("webId", val); };

  public get modifyTime(): Date { let val = this.getDataField("modifyTime"); return val ? new Date(val) : null; };
  public set modifyTime(val: Date) { this.setDataField("modifyTime", val ? val.toString() : null); };

  public constructor(source: StreamSource, itemRef: any){
    super(source, itemRef);
  }
  
}
